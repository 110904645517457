import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  atomActionType,
  atomFiltersAction,
  atomFiltersDropdownAction,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import {
  EActionTypes,
  IActionStatus,
  IFiltersDropdownTableActions,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { clearObjectFalsy } from '@/modules/CampaignsModule/utils';

export const useDropdownFilters = () => {
  const { t } = useTranslation();

  const [filtersDropdownAction, setFiltersDropdownAction] = useAtom(atomFiltersDropdownAction);
  const [filtersAction, setFiltersAction] = useAtom(atomFiltersAction);
  const actionType = useAtomValue(atomActionType);

  const countDropdownFilters = useMemo(() => {
    const { status, channel } = filtersAction ?? {};
    return (status?.length ?? 0) + (channel?.length ?? 0);
  }, [filtersAction]);

  const placeholderFilters = useMemo(() => {
    return countDropdownFilters > 0
      ? `${t('TABLE_FILTER_BAR.filters')} (${countDropdownFilters})`
      : t('TABLE_FILTER_BAR.filters');
  }, [countDropdownFilters, t]);

  const isNotDraftOrFinished = useMemo(() => {
    return !!(actionType !== EActionTypes.DRAFT && actionType !== EActionTypes.FINISHED);
  }, [actionType]);

  const handleApplyFilters = useCallback(() => {
    const { status, channel } = filtersDropdownAction ?? {};

    const clearedFilters = clearObjectFalsy({
      ...filtersAction,
      channel,
      status,
    });

    setFiltersAction(clearedFilters);
  }, [filtersDropdownAction, filtersAction, setFiltersAction]);

  const handleCheckboxChange = useCallback(
    (key: keyof IFiltersDropdownTableActions, value: string | number, checked: boolean) => {
      const updatedFilters = { ...filtersDropdownAction };

      if (key === 'status') {
        if (checked) {
          if (!updatedFilters[key]) {
            updatedFilters[key] = [value as IActionStatus];
          } else {
            (updatedFilters[key] as IActionStatus[]).push(value as IActionStatus);
          }
        } else if (updatedFilters[key]) {
          updatedFilters[key] = (updatedFilters[key] as IActionStatus[]).filter(
            (item) => item !== value
          );
          if ((updatedFilters[key] as IActionStatus[]).length === 0) {
            delete updatedFilters[key];
          }
        }
      } else if (key === 'channel') {
        if (checked) {
          if (!updatedFilters[key]) {
            updatedFilters[key] = [value as number];
          } else {
            (updatedFilters[key] as number[]).push(value as number);
          }
        } else if (updatedFilters[key]) {
          updatedFilters[key] = (updatedFilters[key] as number[]).filter((item) => item !== value);
          if ((updatedFilters[key] as number[]).length === 0) {
            delete updatedFilters[key];
          }
        }
      }
      setFiltersDropdownAction(updatedFilters);
    },
    [filtersDropdownAction, setFiltersDropdownAction]
  );

  return {
    state: {
      placeholderFilters,
      filtersDropdownAction,
      isNotDraftOrFinished,
    },
    events: {
      handleCheckboxChange,
      handleApplyFilters,
    },
  };
};
