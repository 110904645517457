import classNames from 'classnames';
import { useAtomValue } from 'jotai';

import { TableSkeleton } from '@/lib/components';
import { Flex, If } from '@/lib/v2/components';

import { CampaignsHeaderTable } from '@/src/modules/CampaignsModule/components/CampaignsHeaderTable';

import { RecurrentFilterBar } from './components/RecurrentFilterBar';
import { RecurrentToolBar } from './components/RecurrentToolBar';

import {
  atomCampaignsRecurrentList,
  atomTotalSelectedRows,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { CampaignRecurrentTable } from '@/modules/CampaignsModule/components/CampaignsRecurrent/CampaignsRecurrentTable';

const stickyHeaderModule = true;

export function TableContainer() {
  const recurrentMessageList = useAtomValue(atomCampaignsRecurrentList);
  const totalRecurrentMessageList = useAtomValue(atomTotalsProcess);
  const totalSelectedRows = useAtomValue(atomTotalSelectedRows);

  const wrapperActionsTable = classNames({
    'mt-[-52px]': recurrentMessageList && recurrentMessageList?.length > 0,
  });

  if (!recurrentMessageList) {
    return (
      <Flex column>
        <div className="size-full py-10">
          <TableSkeleton columns={7} />
        </div>
      </Flex>
    );
  }

  return (
    <Flex column>
      <If condition={totalRecurrentMessageList?.total > 0}>
        <CampaignsHeaderTable
          filterBar={<RecurrentFilterBar />}
          selectedItems={totalSelectedRows}
          stickyHeader={stickyHeaderModule}
          toolBar={<RecurrentToolBar />}
        />
      </If>
      <Flex column withGap className={wrapperActionsTable} itemAlignment="stretch">
        <CampaignRecurrentTable stickyHeader={stickyHeaderModule} />
      </Flex>
    </Flex>
  );
}
