import React, { cloneElement, memo, ReactElement, useRef } from 'react';

import { useOnClickOutside } from '@/lib/v2/hooks/useOnClickOutside';

export interface IDropdownCustom {
  children: ReactElement;
  open: boolean;
  action: ReactElement;
  noPadding?: boolean;
  onToggle?: (isOpen: boolean) => void;
}

const DropdownCustom: React.FC<IDropdownCustom> = ({
  children,
  action,
  open,
  noPadding = false,
  onToggle,
}) => {
  const toggleRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const actionRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useOnClickOutside(toggleRef, (event) => {
    if (actionRef.current && actionRef.current.contains(event.target as Node)) return;
    if (open) onToggle?.(false);
  });

  const handleToggle = () => onToggle?.(!open);

  return (
    <div className="relative">
      {cloneElement(action, { ref: actionRef, onClick: handleToggle })}
      {open && (
        <div
          ref={toggleRef}
          className={
            'absolute left-0 top-full mt-2 max-h-[200] w-auto min-w-52 rounded-lg bg-white shadow-md ' +
            (noPadding ? '' : 'p-6')
          }
        >
          {cloneElement(children, { onClose: handleToggle })}
        </div>
      )}
    </div>
  );
};

export default memo(DropdownCustom);
