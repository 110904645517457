import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useActionsTableColumns } from '@/src/modules/CampaignsModule/components/CampaignActions/ActionsTable/hooks/useActionsTableColumns';

import { atomCreateActionsModalIsOpen } from '@/modules/CampaignsModule/atoms/createActionsAtom';
import {
  atomFiltersRecurrentAction,
  atomPagerRecurrentAction,
} from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';
import { useRecurrentActionFilters } from '@/modules/CampaignsModule/hooks/useRecurrentActionFilters';
import { ITableCampaignActions } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { ITablePager } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useHandleEvents = () => {
  const navigate = useNavigate();

  const [pagerRecurrentAction, setPagerRecurrentAction] = useAtom(atomPagerRecurrentAction);
  const filtersRecurrentAction = useAtomValue(atomFiltersRecurrentAction);
  const setCreateActionsModalIsOpen = useSetAtom(atomCreateActionsModalIsOpen);

  const { ChannelInfoByID } = useActionsTableColumns();
  const { getActions, resetFilters } = useRecurrentActionFilters();

  const handleCreateActionByCampaign = useCallback(() => {
    setCreateActionsModalIsOpen(true);
  }, [setCreateActionsModalIsOpen]);

  const handleRowClick = useCallback(
    (row: ITableCampaignActions) => {
      const { channel } = row.action;
      const { route } = ChannelInfoByID[channel as keyof typeof ChannelInfoByID];
      navigate(`${CAMPAIGNS_PATH}/${row.action.campaignId}/${route}/${row.action.actionId}`);
    },
    [ChannelInfoByID, navigate]
  );

  const handleChangeTablePage = useCallback(
    (payload?: ITablePager) => {
      const { pageIndex = 1, pageSize = 10 } = payload ?? {};
      const newLimit = pageSize;
      const newPage = pageIndex + 1;

      if (pagerRecurrentAction?.page !== newPage || pagerRecurrentAction?.limit !== newLimit) {
        setPagerRecurrentAction({ page: newPage, limit: newLimit });
        void getActions({
          ...filtersRecurrentAction,
          page: newPage,
          limit: newLimit,
        });
      }
    },
    [filtersRecurrentAction, pagerRecurrentAction, getActions, setPagerRecurrentAction]
  );

  const handleClickEmptyScreenNoResult = useCallback(() => {
    resetFilters();
  }, [resetFilters]);

  return {
    handleChangeTablePage,
    handleCreateActionByCampaign,
    handleClickEmptyScreenNoResult,
    handleRowClick,
  };
};
