import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Alert, Button, Modal, Option, Select, Text } from '@/lib/v2/components';

import {
  atomBeefreePristine,
  atomBeefreeSaving,
} from '@/src/modules/CampaignsModule/atoms/beefreeAtom';
import { usePreviewContacts } from '@/src/modules/CampaignsModule/hooks/usePreviewContacts';

interface IPreviewLive {
  open: boolean;
  onClose: () => void;
  onSend: (actionId: number, contact: Option, group: Option) => Promise<void>;
  onSave: () => Promise<void>;
}

const PreviewLive = ({ open, onClose, onSend, onSave }: IPreviewLive) => {
  const { t } = useTranslation();
  const { previewContacts, previewGroups } = usePreviewContacts();
  const { actionId } = useParams();
  const [previewContactSelected, setPreviewContactSelected] = useState<Option>();
  const [previewGroupSelected, setPreviewGroupSelected] = useState<Option>();
  const [waitForSave, setWaitForSave] = useState(false);
  const beefreeSaving = useAtomValue(atomBeefreeSaving);
  const pristine = useAtomValue(atomBeefreePristine);
  const isReady = !(!actionId || !previewContactSelected || !previewGroupSelected);

  const handleSend = useCallback(async () => {
    if (!isReady) return;
    await onSave();
    setWaitForSave(true);
  }, [isReady, onSave]);
  const finishSend = useCallback(async () => {
    if (!isReady) return;
    await onSend(parseInt(actionId), previewContactSelected, previewGroupSelected);
    setWaitForSave(false);
    onClose();
  }, [actionId, isReady, onClose, onSend, previewContactSelected, previewGroupSelected]);

  useEffect(() => {
    if (!isReady) return;
    if (waitForSave && !beefreeSaving) {
      void finishSend();
    }
  }, [waitForSave, beefreeSaving, finishSend, isReady]);

  return (
    <Modal externalClose open={open} onClose={onClose}>
      <div className="mb-6">
        <Text
          className="mb-4 border-b-[1px] border-gray-100 pb-4"
          fontWeight="medium"
          variant="sub-headline"
        >
          {t('EDITOR_CONTENT.PREVIEW_LIVE.title')}
        </Text>

        {pristine && (
          <div className="mb-4">
            <Alert
              description={t('EDITOR_CONTENT.PREVIEW_LIVE.SAVE_WARNING.description')}
              title={t('EDITOR_CONTENT.PREVIEW_LIVE.SAVE_WARNING.title')}
              type="warning"
            />
          </div>
        )}

        <div className="mb-4 flex flex-col">
          <Text variant="text-sm">{t('EDITOR_CONTENT.PREVIEW_LIVE.contactTest')}</Text>
          <Select
            options={previewContacts ?? []}
            value={previewContactSelected}
            onChange={(value) => value && setPreviewContactSelected(value)}
          />
        </div>
        <div className="mb-4 flex flex-col">
          <Text variant="text-sm">{t('EDITOR_CONTENT.PREVIEW_LIVE.groupTest')}</Text>
          <Select
            options={previewGroups ?? []}
            value={previewGroupSelected}
            onChange={(value) => value && setPreviewGroupSelected(value)}
          />
        </div>
      </div>

      <div className="mt-6 flex w-full justify-end gap-4">
        <Button outline onClick={onClose}>
          {t('EDITOR_CONTENT.PREVIEW_LIVE.cancel')}
        </Button>
        <Button disabled={beefreeSaving} onClick={handleSend}>
          {t('EDITOR_CONTENT.PREVIEW_LIVE.send')}
        </Button>
      </div>
    </Modal>
  );
};

export default PreviewLive;
