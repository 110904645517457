import { useMemo } from 'react';

import {
  prepareHtml,
  secureLinks,
} from '@/src/modules/CampaignsModule/components/HtmlUpload/util/htmlModifiers';

export const useHtmlProcessor = (htmlContent: string = '') => {
  return useMemo(() => {
    const { preparedHtml } = prepareHtml(htmlContent);
    return secureLinks(preparedHtml);
  }, [htmlContent]);
};
