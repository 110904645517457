import { Text } from '@/lib/v2/components';

interface ICountWhatsAppProps {
  count: number;
  length: number;
  maxLength: number;
}

const CountWhatsApp = ({ length, maxLength }: ICountWhatsAppProps) => {
  return (
    <div className="w-32 pr-2">
      <Text alignment="right" className="mt-1" fontWeight="normal" variant="text">
        {length}/{maxLength}
      </Text>
    </div>
  );
};

export default CountWhatsApp;
