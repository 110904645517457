import { useTranslation } from 'react-i18next';

import { Button, Card, Flex, IconSvg, Text } from '@/lib/v2/components';
import { CloseIcon } from '@/lib/v2/icons/outline';
import { TextSmsIcon } from '@/lib/v2/icons/solid';

import { TRANSLATE_WHATSAPP_ACTION_FORM } from '@/src/modules/CampaignsModule/constants';

interface TemplateCardProps {
  templateName: string;
  onDelete: () => void;
}

const TemplateCard = ({ templateName, onDelete }: TemplateCardProps) => {
  const { t } = useTranslation('inbox');

  return (
    <Card className="!py-2 !pl-4 !pr-2">
      <Flex noGrow alignment="spaceBetween" itemAlignment="center">
        <Flex noGrow withGap alignment="start" gapSize="small" itemAlignment="center">
          <IconSvg height="16" svgComponent={<TextSmsIcon />} width="16" />
          <Text className="-mt-0.5" fontWeight="medium" variant="text">
            {templateName ?? '-'}
          </Text>
        </Flex>
        <Flex noGrow withGap alignment="end" gapSize="small" itemAlignment="center">
          <Button disabled outline secondary size="small">
            {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.edit`)}
          </Button>
          <Button link secondary size="small" onClick={onDelete}>
            <IconSvg fillColor="gray-dark" svgComponent={<CloseIcon />} />
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

export default TemplateCard;
