import { atom, WritableAtom } from 'jotai';

import { calculateSMSMetrics } from '@/src/modules/CampaignsModule/components/actionManagement/SmsAction/utils';
import {
  TEMPLATE_OPTION,
  TemplateOption,
} from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/constants';
import {
  IWhatsAppInfoPayload,
  IWhatsAppInfoResponseV1,
  IWhatsAppRecipientsResponseV1,
  IWhatsAppReportActionResponseV1,
  MessageStateWhatsApp,
  UpdateTypeStateWhatsApp,
} from '@/src/modules/CampaignsModule/interfaces/WhatsApp';
import { getCustomFields } from '@/src/modules/CampaignsModule/utils';

export const atomActionInfoWhatsApp = atom<IWhatsAppInfoResponseV1>({} as IWhatsAppInfoResponseV1);
atomActionInfoWhatsApp.debugLabel = 'atomActionInfoWhatsApp';

export const atomActionReportWhatsApp = atom<IWhatsAppReportActionResponseV1>(
  {} as IWhatsAppReportActionResponseV1
);
atomActionReportWhatsApp.debugLabel = 'atomActionReportWhatsApp';

export const atomActionRecipients = atom<IWhatsAppRecipientsResponseV1 | undefined>(undefined);
atomActionRecipients.debugLabel = 'atomActionRecipients';

export const atomMessageWhatsApp: WritableAtom<
  MessageStateWhatsApp,
  [UpdateTypeStateWhatsApp],
  void
> = atom(
  {
    original: '',
    shortened: '',
    track: false,
    length: 0,
    count: 0,
    customFields: 0,
  },
  (get, set, update) => {
    const currentState = get(atomMessageWhatsApp);
    const newState = { ...currentState, ...update };

    const message = newState.track ? newState.shortened : newState.original;
    const { length, count } = calculateSMSMetrics(message);
    const customFields = getCustomFields(message).length;

    set(atomMessageWhatsApp, {
      ...newState,
      length,
      count,
      customFields,
    });
  }
);
atomMessageWhatsApp.debugLabel = 'atomMessageWhatsApp';

export const atomInfoWhatsAppPayload = atom<IWhatsAppInfoPayload>({} as IWhatsAppInfoPayload);
atomInfoWhatsAppPayload.debugLabel = 'atomInfoWhatsAppPayload';

export interface WhatsAppMessageState {
  To: string;
  From: string;
  ContentSid: string;
  ContentVariables: {
    [key: string]: string;
  };
}

export const atomMessageWhatsapp = atom<WhatsAppMessageState>({
  To: '',
  From: '',
  ContentSid: '',
  ContentVariables: {},
});
atomMessageWhatsapp.debugLabel = 'atomMessageWhatsapp';

export const atomTemplateOption = atom<TemplateOption>(TEMPLATE_OPTION.IDLE);
atomTemplateOption.debugLabel = 'atomTemplateOption';

export const atomCurrentWhatsAppMessage = atom<string>('');
atomCurrentWhatsAppMessage.debugLabel = 'atomCurrentWhatsAppMessage';

export const atomMediaUrl = atom<string>('');
atomMediaUrl.debugLabel = 'atomMediaUrl';

export const atomIsTemplateCreated = atom<boolean>(false);
atomIsTemplateCreated.debugLabel = 'atomIsTemplateCreated';
