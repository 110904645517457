import { useAtomValue } from 'jotai';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Card, CardSection, Flex, IconSvg, If, Text } from '@/lib/v2/components';
import { BadgeText } from '@/lib/v2/components/BadgeText';
import { ActionMasterIcon, EditPencilIcon } from '@/lib/v2/icons/outline';
import { SectionHeader } from '@/lib/v2/organisms/SectionHeader';

import { atomActionMaster } from '@/src/modules/CampaignsModule/atoms/recurrentActionsAtom';
import { ChannelInfoByID } from '@/src/modules/CampaignsModule/components/RecurrentActions/ActionsTable/hooks/useActionsTableColumns';
import { TableContainer } from '@/src/modules/CampaignsModule/components/RecurrentActions/TableContainer';
import { SectionHeaderSubtitle } from '@/src/modules/CampaignsModule/components/SectionHeaderSubtitle';
import { CAMPAIGNS_PATH } from '@/src/modules/CampaignsModule/constants';
import { useCampaignLastPath } from '@/src/modules/CampaignsModule/hooks/useCampaignLastPath';
import { useRecurrentActionFilters } from '@/src/modules/CampaignsModule/hooks/useRecurrentActionFilters';
import { useRecurrentActionsData } from '@/src/modules/CampaignsModule/hooks/useRecurrentActionsData';
import { useRecurrentMaster } from '@/src/modules/CampaignsModule/hooks/useRecurrentMaster';
import { ELanguageMap, formattedNumber } from '@/src/modules/CampaignsModule/utils/formattedNumber';

import { atomDetailCampaignRecurrent } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';

const CampaignRecurrentActionsMain = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { name } = useAtomValue(atomDetailCampaignRecurrent);
  const actionMaster = useAtomValue(atomActionMaster);

  const {
    state: { activeCampaign, loadingMaster },
  } = useRecurrentMaster();
  const {
    recurrentActionsList,
    loadingProcessRecurrentAction,
    totalsProcessRecurrentAction,
    totalApplyFilters,
  } = useRecurrentActionsData();
  const { resetUnmount } = useRecurrentActionFilters();
  const { campaignLastPath } = useCampaignLastPath();

  useEffect(() => {
    return () => resetUnmount();
  }, [resetUnmount]);

  const handleEditRecurrent = useCallback(() => {
    if (!actionMaster) return;

    const { channel, campaignId, actionId } = actionMaster.action;
    const { route } = ChannelInfoByID[channel as keyof typeof ChannelInfoByID];
    navigate(`${CAMPAIGNS_PATH}/${campaignId}/${route}/${actionId}`);
  }, [actionMaster, navigate]);

  const handleBackHeader = useCallback(() => {
    navigate(campaignLastPath.recurrent);
  }, [campaignLastPath.recurrent, navigate]);

  const titleDetail = useMemo(() => {
    if (recurrentActionsList?.length && !loadingProcessRecurrentAction) {
      return (
        <>
          <Text
            as="span"
            className="mr-1"
            color="primary"
            fontWeight="medium"
            id="total-count-actions"
          >
            {totalsProcessRecurrentAction.total &&
              ` (${
                formattedNumber(
                  totalsProcessRecurrentAction.total,
                  i18n.language as keyof typeof ELanguageMap
                ) || 0
              })`}
          </Text>
          <If condition={!loadingMaster}>
            <BadgeText
              text={
                activeCampaign
                  ? t('CAMPAIGNS_MAIN.STATES.active')
                  : t('CAMPAIGNS_MAIN.STATES.inactive')
              }
              variantColor={activeCampaign ? 'success' : 'inactive'}
            />
          </If>
        </>
      );
    } else {
      return !recurrentActionsList?.length ? '' : ' (0)';
    }
  }, [
    recurrentActionsList,
    loadingProcessRecurrentAction,
    loadingMaster,
    totalsProcessRecurrentAction,
    i18n.language,
    activeCampaign,
    t,
  ]);

  return (
    <>
      <Card>
        <CardSection noPadding>
          <Flex column>
            <SectionHeader
              actionsButtons={
                <Button
                  iconLeft={<IconSvg strokeColor="white" svgComponent={<EditPencilIcon />} />}
                  id="edit-recurrent-button"
                  size="small"
                  onClick={handleEditRecurrent}
                >
                  {t('RECURRENT_MESSAGE.edit')}
                </Button>
              }
              backButton={true}
              icon={
                <IconSvg
                  fillColor="success"
                  height="20px"
                  svgComponent={<ActionMasterIcon />}
                  width="20px"
                />
              }
              subtitle={
                <SectionHeaderSubtitle
                  countFilters={totalApplyFilters}
                  countRecordsPartial={totalsProcessRecurrentAction.partial}
                  dataList={recurrentActionsList}
                  i18nKey="CAMPAIGN_ACTIONS_MAIN.search"
                  loading={loadingProcessRecurrentAction}
                />
              }
              title={name}
              titleDetail={titleDetail}
              onBack={handleBackHeader}
            />
            <Flex column withGap gapSize="medium">
              <TableContainer />
            </Flex>
          </Flex>
        </CardSection>
      </Card>
    </>
  );
};

export default memo(CampaignRecurrentActionsMain);
