import { FieldErrors, RegisterOptions } from 'react-hook-form';

import CopyExternalUrl from './components/CopyExternalUrl';

import GroupContacts from '@/modules/RulesModule/components/FormRule/components/GroupContacts';
import RedirectExternalUrl from '@/modules/RulesModule/components/FormRule/components/RedirectExternalUrl/RedirectExternalUrl';
import RepeatedEmails from '@/modules/RulesModule/components/FormRule/components/RepeatedEmails';
import { IFormProps, IRuleForm } from '@/modules/RulesModule/interfaces';

interface IFormExternalCallProps {
  errors: FieldErrors<IRuleForm>;
  form: IFormProps<IRuleForm>;
  disabled?: boolean;
  rules: Record<string, RegisterOptions>;
}
const FormExternalCall = ({ form, errors, disabled = false, rules }: IFormExternalCallProps) => {
  return (
    <>
      <CopyExternalUrl />
      <RepeatedEmails disabled={disabled} form={form} />
      <GroupContacts disabled={disabled} errors={errors} form={form} />
      <RedirectExternalUrl disabled={disabled} form={form} rules={rules} type="success" />
      <RedirectExternalUrl disabled={disabled} form={form} rules={rules} type="error" />
    </>
  );
};

export default FormExternalCall;
