import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { memo } from 'react';

import { TableSkeleton } from '@/lib/components';
import { Flex, If } from '@/lib/v2/components';

import { ActionsTable } from '@/src/modules/CampaignsModule/components/CampaignActions/ActionsTable';
import { CampaignsHeaderTable } from '@/src/modules/CampaignsModule/components/CampaignsHeaderTable';

import { ActionsFilterBar } from './components/ActionsFilterBar';
import { ActionsToolBar } from './components/ActionsToolBar';

import {
  atomActionsList,
  atomTotalActionsSelectedRows,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomTotalsProcess } from '@/modules/CampaignsModule/atoms/campaignAtom';

export const TableContainer = () => {
  const actionsList = useAtomValue(atomActionsList);
  const totalSelectedRows = useAtomValue(atomTotalActionsSelectedRows);
  const totalActionList = useAtomValue(atomTotalsProcess);

  const stickyHeaderModule = true;

  //TODO: refactor in customHook
  const wrapperActionsTable = classNames({
    'mt-[-52px]': actionsList && actionsList?.length > 0,
  });

  if (!actionsList) {
    return (
      <Flex column>
        <div className="size-full py-10">
          <TableSkeleton columns={6} />
        </div>
      </Flex>
    );
  }

  return (
    <Flex column>
      <If condition={totalActionList?.total > 0}>
        <CampaignsHeaderTable
          filterBar={<ActionsFilterBar />}
          selectedItems={totalSelectedRows}
          stickyHeader={stickyHeaderModule}
          toolBar={<ActionsToolBar />}
        />
      </If>
      <Flex column withGap className={wrapperActionsTable} itemAlignment="stretch">
        <ActionsTable stickyHeader={stickyHeaderModule} />
      </Flex>
    </Flex>
  );
};

export default memo(TableContainer);
