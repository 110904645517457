import { datadogRum } from '@datadog/browser-rum';
import { useCallback } from 'react';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';

export const useBeefreeLogger = () => {
  const service = useService();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logger = useCallback(
    async (name: string, type: 'action' | 'error', data: unknown = {}) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { email, companyId } = await service.getUserData();

      if (type === 'action') {
        datadogRum.addAction('beefree.' + name, {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          email,
          accountId: Number(companyId),
        });
      } else {
        datadogRum.addError('beefree.' + name, {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          email,
          accountId: Number(companyId),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          error: data,
        });
      }
    },
    [service]
  );

  return { logger };
};
