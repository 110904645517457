import classNames from 'classnames';
import { FC, memo, MouseEventHandler, ReactNode, useEffect, useRef, useState } from 'react';

import './Card.tailwind.css';

type Color = 'primary' | 'secondary' | 'tertiary' | 'destructive' | 'white' | 'gray';

export interface CardProps {
  children?: ReactNode | JSX.Element | string;
  /** Adjust color of Card */
  color?: Color;
  /** Card without shadows */
  noShadow?: boolean;
  noPadding?: boolean;
  overflowHidden?: boolean;
  noBorder?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  id?: string;
  className?: string;
  tooltip?: string;
  isHeightAuto?: boolean;
  isFullHeight?: boolean;
}

const Card: FC<CardProps> = ({
  children,
  color = 'white',
  noShadow = false,
  onClick,
  overflowHidden,
  noBorder = false,
  noPadding = false,
  id,
  className,
  tooltip,
  isHeightAuto = false,
}): JSX.Element => {
  const [noFooterAndSection, setNoFooterAndSection] = useState(false);

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current !== undefined && !noPadding) {
      const cardsWithFooter = divRef?.current?.querySelectorAll('.eb-card > .eb-card-footer');
      const cardsWithSection = divRef?.current?.querySelectorAll('.eb-card > .eb-card-section');
      if (cardsWithFooter && cardsWithSection)
        setNoFooterAndSection(cardsWithFooter.length === 0 && cardsWithSection.length === 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = classNames(
    'eb-card',
    {
      'card-shadow': !noShadow,
      [`eb-card-${color}`]: true,
      'eb-white-content': color !== 'white' && color !== 'gray',
      'border-transparent': noBorder,
      'overflow-hidden': overflowHidden,
      'cursor-pointer': onClick,
      'py-4 px-4 2xl:px-12': noFooterAndSection,
      'h-full': !isHeightAuto,
      'h-auto': isHeightAuto,
    },
    className
  );

  return (
    <div
      ref={divRef}
      aria-hidden
      className={classes}
      data-testid="card-component"
      id={id}
      role={onClick && 'button'}
      tabIndex={0}
      title={tooltip}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default memo(Card);
