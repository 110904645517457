import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  atomCampaignQuickTools,
  atomFiltersCampaign,
  atomSelectedRowsWithID,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleDeleteModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';
import { useCampaignFilters } from '@/modules/CampaignsModule/hooks/useCampaignFilters';
import { useCampaign } from '@/modules/CampaignsModule/hooks/useCampaigns';

export const useDeleteCampaigns = () => {
  const navigate = useNavigate();

  const [toggleDeleteModal, setToggleDeleteModal] = useAtom(atomToggleDeleteModal);
  const filtersCampaign = useAtomValue(atomFiltersCampaign);
  const campaignQuickTools = useAtomValue(atomCampaignQuickTools);
  const campaignsRowsSelected = useAtomValue(atomSelectedRowsWithID);

  const { deleteCampaign, loading } = useCampaign();
  const { getCampaigns } = useCampaignFilters();

  const campaigns = useMemo(() => {
    return campaignsRowsSelected?.length > 0
      ? campaignsRowsSelected
      : [campaignQuickTools?.id as number];
  }, [campaignsRowsSelected, campaignQuickTools?.id]);

  const handleConfirmDeleteCampaigns = useCallback(async () => {
    await deleteCampaign(campaigns);
    setToggleDeleteModal(false);

    campaignQuickTools?.redirect
      ? navigate(CAMPAIGNS_PATH)
      : await getCampaigns({ ...filtersCampaign });
  }, [
    campaignQuickTools,
    campaigns,
    deleteCampaign,
    filtersCampaign,
    getCampaigns,
    navigate,
    setToggleDeleteModal,
  ]);

  return {
    state: {
      campaignsSelected: campaigns,
      toggleDeleteModal,
      loadingCampaign: loading,
    },
    events: {
      handleConfirmDeleteCampaigns,
    },
    setters: {
      setToggleDeleteModal,
    },
  };
};
