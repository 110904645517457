import { useAtomValue } from 'jotai';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextSkeleton } from '@/lib/components';
import { Button, Flex, IconSvg, Text } from '@/lib/v2/components';
import { EditPencilIconNew } from '@/lib/v2/icons/solid';

import { atomDetailCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';

interface IContentProps {
  loading: boolean;
  toggleModal: () => void;
}

const Content = ({ loading, toggleModal }: IContentProps) => {
  const { t } = useTranslation();
  const detailCampaign = useAtomValue(atomDetailCampaign);
  const { name, goal } = detailCampaign;

  return (
    <div className="flex flex-col">
      <Flex withGap alignment="start" gapSize="small">
        <Text as="h3" color="dark" fontWeight="medium" variant="sub-headline">
          {loading && Object.keys(detailCampaign).length === 0 ? (
            <TextSkeleton size="sm" />
          ) : (
            <Flex withGap alignment="start" gapSize="small">
              {name}
              <Button
                link
                className="!h-auto !p-0"
                iconLeft={
                  <IconSvg height="16px" svgComponent={<EditPencilIconNew />} width="16px" />
                }
                id="back-to-campaigns-button"
                onClick={toggleModal}
              />
            </Flex>
          )}
        </Text>
      </Flex>
      <Text as="span" className="leading-4" color="dark" variant="text">
        {loading ? (
          <TextSkeleton size="sm" />
        ) : (
          goal && `${t('CAMPAIGN_ACTIONS_MAIN.HEADER.goal')} ${goal}`
        )}
      </Text>
    </div>
  );
};

export default memo(Content);
