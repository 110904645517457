import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ITableButtonProps } from '@/lib/v2/examples/TableToolsBar/components/TableToolsBarButton/TableToolsBarButton';
import { DuplicateIconNew, EditPencilIconNew, RemoveIconNew } from '@/lib/v2/icons/solid';

import {
  atomSelectedRowsWithID,
  atomTotalSelectedRows,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomModalTypeProcess,
  atomToggleDeleteModal,
  atomToggleEditCreateCampaignModal,
} from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaign } from '@/modules/CampaignsModule/hooks/useCampaigns';
import { ModalType } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useCampaignToolBar = () => {
  const { t } = useTranslation();
  const { getDetailCampaign } = useCampaign();

  const totalSelectedRows = useAtomValue(atomTotalSelectedRows);
  const selectedRowsWithID = useAtomValue(atomSelectedRowsWithID);
  const setToggleDeleteModal = useSetAtom(atomToggleDeleteModal);
  const setModalTypeProcess = useSetAtom(atomModalTypeProcess);
  const setToggleEditCategoryModal = useSetAtom(atomToggleEditCreateCampaignModal);

  const handleModalDeleteCampaigns = useCallback(() => {
    setToggleDeleteModal(true);
  }, [setToggleDeleteModal]);

  const handleCampaignManagement = useCallback(
    (modalType: ModalType) => {
      if (totalSelectedRows > 1) return;

      void getDetailCampaign({ campaignID: Number(selectedRowsWithID[0]) });
      setModalTypeProcess(modalType);
      setToggleEditCategoryModal(true);
    },
    [
      getDetailCampaign,
      selectedRowsWithID,
      setModalTypeProcess,
      setToggleEditCategoryModal,
      totalSelectedRows,
    ]
  );

  const ICONS_LIST_ACTIONS = useMemo<ITableButtonProps[]>(
    () => [
      {
        id: 'edit-campaign',
        label: t('CAMPAIGNS_MAIN.ACTIONS.tooltipEdit'),
        icon: <EditPencilIconNew />,
        onClick: () => handleCampaignManagement(ModalType.EDIT),
        tooltip: t('CAMPAIGNS_MAIN.ACTIONS.tooltipEdit'),
        hide: totalSelectedRows > 1,
      },
      {
        id: 'duplicate-campaign-action',
        label: t('CAMPAIGNS_MAIN.ACTIONS.tooltipDuplicate'),
        icon: <DuplicateIconNew />,
        onClick: () => handleCampaignManagement(ModalType.DUPLICATE),
        tooltip: t('CAMPAIGNS_MAIN.ACTIONS.tooltipDuplicate'),
        hide: totalSelectedRows > 1,
      },
      {
        id: 'delete-campaign',
        label: t('CAMPAIGNS_MAIN.ACTIONS.tooltipDelete'),
        icon: <RemoveIconNew />,
        onClick: handleModalDeleteCampaigns,
        tooltip: t('CAMPAIGNS_MAIN.ACTIONS.tooltipDelete'),
        hide: false,
      },
    ],
    [handleCampaignManagement, handleModalDeleteCampaigns, t, totalSelectedRows]
  );

  return {
    state: {
      ICONS_LIST_ACTIONS,
    },
  };
};
