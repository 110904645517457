import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { Option } from '@/lib/v2/components';

import {
  defaultOrderBy,
  defaultPager,
  ITEMS_PER_PAGE_SEARCH_CAMPAIGNS,
} from '@/src/modules/CampaignsModule/constants';
import { ICampaignActions } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import {
  ECampaignsTypeOptions,
  ICampaignDetail,
  ICampaignDetailV1,
  ICampaignLastPath,
  IFiltersDropdownTableCampaign,
  IFiltersTableCampaign,
  IHistorySearchCampaign,
  IListSearchCampaigns,
  IMoveCampaignPayload,
  initialCampaignLastPath,
  IOrderByTable,
  IPager,
  IPayloadSearchCampaigns,
  IQuickToolsItem,
  IStrategy,
  ITableCampaigns,
  ITotalsCampaign,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';

export const atomRefetchDetailData = atomWithReset<boolean>(false);
atomRefetchDetailData.debugLabel = 'atomRefetchDetailData';

export const atomDetailCampaign = atomWithReset<ICampaignDetail>({});
atomDetailCampaign.debugLabel = 'atomDetailCampaign';

export const atomDetailAction = atomWithReset<ICampaignActions>({} as ICampaignActions);
atomDetailAction.debugLabel = 'atomDetailAction';

export const atomLoadingDetail = atom<boolean>(false);
atomLoadingDetail.debugLabel = 'atomLoadingDetail';

export const atomCampaignList = atomWithReset<ITableCampaigns[] | undefined>(undefined);
atomCampaignList.debugLabel = 'atomCampaignList';

export const atomCampaignListAll = atomWithReset<ITableCampaigns[] | undefined>([]);
atomCampaignListAll.debugLabel = 'atomCampaignListAll';

export const atomLoadingProcess = atomWithReset<boolean>(false);
atomLoadingProcess.debugLabel = 'atomLoadingProcess';

//TODO: review this refactor campaigns
export const atomRefetchAllData = atomWithReset<boolean>(false);
atomRefetchAllData.debugLabel = 'atomRefetchAllData';

export const atomTotalsProcess = atomWithReset<ITotalsCampaign>({ total: 0, partial: 0 });
atomTotalsProcess.debugLabel = 'atomTotalsProcess';

export const atomCurrentCampaignID = atom<number>(0);
atomCurrentCampaignID.debugLabel = 'atomCurrentCampaignID';

export const atomSelectedRows = atomWithReset<number[]>([]);
atomSelectedRows.debugLabel = 'atomSelectedRows';

export const atomTotalSelectedRows = atom<number>((get) => {
  const selectedRows = get(atomSelectedRows);
  return selectedRows.length || 0;
});
atomTotalSelectedRows.debugLabel = 'atomTotalSelectedRows';

export const atomSelectedRowsWithID = atom((get) => {
  return get(atomSelectedRows)
    .map((index) => get(atomCampaignList)?.[index]?.campaign?.campaignId)
    .filter((campaignId) => campaignId !== undefined) as number[];
});
atomSelectedRowsWithID.debugLabel = 'atomSelectedRowsWithID';

export const atomStrategyList = atom<IStrategy[]>([]);
atomStrategyList.debugLabel = 'atomStrategyList';

export const atomDropdownCampaignList = atom((get): Option[] => {
  const currentCampaignID = get(atomCurrentCampaignID);
  const campaignListAll = get(atomCampaignListAll);
  if (!campaignListAll) return [] as Option[];

  const allCampaignsOptions = campaignListAll
    ?.filter((value) => {
      return value.campaign?.campaignTypeId === ECampaignsTypeOptions.MANUAL;
    })
    .map((value): Option => {
      const { campaignId, name } = value.campaign;
      return {
        id: campaignId,
        name: `${name}`,
        value: campaignId,
        disabled: campaignId === currentCampaignID,
      };
    });

  return allCampaignsOptions;
});
atomDropdownCampaignList.debugLabel = 'atomDropdownCampaignList';

export const atomMoveCampaignPayload = atomWithReset<IMoveCampaignPayload | undefined>(undefined);
atomMoveCampaignPayload.debugLabel = 'atomMoveCampaignPayload';

export const atomMoveSuccess = atomWithReset<boolean>(false);
atomMoveSuccess.debugLabel = 'atomMoveSuccess';

//TODO: review this refactor campaigns
export const atomInfoCampaignV1 = atomWithReset<ICampaignDetailV1>({} as ICampaignDetailV1);

export const atomFiltersCampaign = atomWithReset<IFiltersTableCampaign>({ ...defaultPager });
atomFiltersCampaign.debugLabel = 'atomFiltersCampaign';

export const atomFiltersDropdownCampaign = atomWithReset<IFiltersDropdownTableCampaign | undefined>(
  undefined
);
atomFiltersDropdownCampaign.debugLabel = 'atomFiltersDropdownCampaign';

export const atomPagerCampaign = atomWithReset<IPager>(defaultPager);
atomPagerCampaign.debugLabel = 'atomPagerCampaign';

export const atomOrderBy = atom<IOrderByTable>(defaultOrderBy);
atomOrderBy.debugLabel = 'atomOrderBy';

//TODO: review this refactor campaigns
export const atomHistorySearchPagerCampaign = atom<IHistorySearchCampaign | undefined>(undefined);
atomHistorySearchPagerCampaign.debugLabel = 'atomHistorySearchPagerCampaign';

export const atomActionData = atomWithReset<null | ICampaignActions>(null);
atomActionData.debugLabel = 'atomActionData';

export const atomListSearchCampaigns = atom<IListSearchCampaigns[] | undefined>(undefined);
atomListSearchCampaigns.debugLabel = 'atomListSearchCampaigns';

export const atomLoadingSearchCampaigns = atom<boolean>(false);
atomLoadingSearchCampaigns.debugLabel = 'atomLoadingSearchCampaigns';

export const atomHasMoreSearchCampaigns = atom<boolean>(false);
atomHasMoreSearchCampaigns.debugLabel = 'atomHasMoreSearchCampaigns';

export const atomKeywordSearchCampaigns = atom<string | undefined>(undefined);
atomKeywordSearchCampaigns.debugLabel = 'atomKeywordSearchCampaigns';

export const atomPayloadSearchCampaigns = atom<IPayloadSearchCampaigns>({
  page: 1,
  limit: ITEMS_PER_PAGE_SEARCH_CAMPAIGNS,
  search: '',
  sortField: 'name',
  sortOrder: 'asc',
});
atomPayloadSearchCampaigns.debugLabel = 'atomPayloadSearchCampaigns';

export const atomCampaignQuickTools = atomWithReset<IQuickToolsItem | undefined>(undefined);
atomCampaignQuickTools.debugLabel = 'atomCampaignQuickTools';

export const atomIframeDocument = atom<HTMLIFrameElement | undefined>(undefined);
atomIframeDocument.debugLabel = 'atomIframeDocument';

export const atomLoadingDuplicateAction = atom<boolean>(false);
atomLoadingDuplicateAction.debugLabel = 'atomLoadingDuplicateAction';

export const atomLoadingDuplicateCampaign = atom<boolean>(false);

//TODO: review this refactor campaigns
export const atomRefetchStrategyList = atomWithReset<boolean>(false);
atomRefetchStrategyList.debugLabel = 'atomRefetchStrategyList';

export const atomCampaignLastPath = atomWithReset<ICampaignLastPath>(initialCampaignLastPath);
atomCampaignLastPath.debugLabel = 'atomCampaignLastPath';
