/* cspell: disable*/

export const InactiveContactsIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 25 24" width="25" xmlns="http://www.w3.org/2000/svg">
      <g id="Grupo 9233">
        <g id="Grupo 9234">
          <path
            d="M17.1177 23.1091H3.76865C3.35361 23.1458 2.93574 23.085 2.54838 22.9315C2.16101 22.778 1.8149 22.5361 1.53765 22.2251C1.29532 21.8311 1.13297 21.3932 1.05988 20.9364C0.986782 20.4797 1.00438 20.013 1.11165 19.5631C1.32415 18.7224 1.62412 17.9062 2.00665 17.1281C2.76433 15.4864 3.93988 14.0726 5.41565 13.0281C5.53873 12.9357 5.6888 12.8865 5.84265 12.8881C6.00807 12.8899 6.16961 12.9384 6.30865 13.0281C7.56294 13.8985 9.05395 14.3633 10.5807 14.3601C12.097 14.3616 13.5768 13.8946 14.8177 13.0231C14.9474 12.935 15.1009 12.8886 15.2577 12.8901C15.4186 12.8901 15.5761 12.9363 15.7117 13.0231C15.7687 13.0601 15.8257 13.0991 15.8837 13.1391C15.7837 13.2641 15.6837 13.3971 15.5937 13.5391C15.1394 14.2293 14.8977 15.0377 14.8987 15.8641C14.9077 16.6411 15.2212 17.3836 15.7717 17.9321C16.1207 18.2847 16.5494 18.5481 17.0217 18.7001C17.0052 18.8769 16.9985 19.0545 17.0017 19.2321C16.9982 19.6694 17.085 20.1028 17.2567 20.5051C17.0225 20.9506 16.901 21.4467 16.9027 21.9501C16.9008 22.3471 16.9738 22.741 17.1177 23.1111V23.1091ZM10.5227 12.9091C9.33398 12.9093 8.17196 12.5569 7.18357 11.8966C6.19518 11.2362 5.42484 10.2976 4.96999 9.19941C4.51514 8.1012 4.39623 6.89277 4.62828 5.72697C4.86034 4.56116 5.43294 3.49038 6.27365 2.65006C7.40082 1.52289 8.92959 0.889648 10.5237 0.889648C12.1177 0.889648 13.6465 1.52289 14.7737 2.65006C15.9008 3.77723 16.5341 5.306 16.5341 6.90006C16.5341 8.49412 15.9008 10.0229 14.7737 11.1501C14.2166 11.7098 13.5541 12.1535 12.8244 12.4555C12.0947 12.7574 11.3123 12.9116 10.5227 12.9091Z"
            fill="#96A6CE"
            id="Sustracci&#195;&#179;n 1"
          />
          <g id="Grupo 7156">
            <g id="Grupo 7154">
              <path
                d="M22.9317 14.4211C22.6498 14.0069 22.2581 13.6793 21.8007 13.475C21.289 13.2468 20.7339 13.1325 20.1737 13.1401C19.566 13.1272 18.9649 13.2697 18.4277 13.5541C17.9619 13.7992 17.5659 14.1584 17.2767 14.5981C17.0282 14.9728 16.8897 15.4096 16.8767 15.8591C16.8816 16.1197 16.9894 16.3678 17.1767 16.5491C17.275 16.6492 17.3924 16.7286 17.5219 16.7827C17.6514 16.8368 17.7903 16.8645 17.9307 16.8641C18.1723 16.8655 18.4066 16.7811 18.5919 16.626C18.7772 16.471 18.9015 16.2552 18.9427 16.0171C19.0362 15.7009 19.1911 15.4063 19.3987 15.1501C19.6157 14.9729 19.8937 14.8881 20.1727 14.9141C20.4412 14.9017 20.7041 14.9934 20.9067 15.1701C20.9931 15.2458 21.0611 15.3401 21.1058 15.4459C21.1505 15.5518 21.1706 15.6664 21.1647 15.7811C21.1692 15.8915 21.1417 16.0009 21.0857 16.0961C21.0243 16.2135 20.9439 16.3199 20.8477 16.4111C20.7477 16.5111 20.5477 16.6871 20.3117 16.9031C20.0607 17.1121 19.8223 17.3358 19.5977 17.5731C19.4012 17.7776 19.2463 18.0184 19.1417 18.2821C19.0162 18.588 18.9553 18.9165 18.9627 19.2471C18.9556 19.392 18.9786 19.5369 19.0302 19.6725C19.0818 19.8081 19.161 19.9315 19.2627 20.035C19.4497 20.2132 19.6984 20.3121 19.9567 20.311C20.189 20.3257 20.4181 20.2497 20.5956 20.099C20.7731 19.9483 20.8853 19.7347 20.9087 19.5031C20.9487 19.3261 20.9877 19.1881 21.0087 19.1091C21.0267 19.0467 21.0533 18.9871 21.0877 18.9321C21.1249 18.8595 21.1716 18.7922 21.2267 18.7321L21.5267 18.4321C22.0027 17.9991 22.3597 17.6831 22.5387 17.5061C22.7481 17.2946 22.9284 17.0562 23.0747 16.7971C23.2361 16.4888 23.3179 16.145 23.3127 15.7971C23.3326 15.3099 23.1994 14.8287 22.9317 14.4211Z"
                fill="#96A6CE"
                id="Trazado 4463"
              />
            </g>
            <g id="Grupo 7155">
              <path
                d="M20.0357 20.804C19.7253 20.8046 19.427 20.9245 19.2027 21.139C19.0947 21.2438 19.0092 21.3695 18.9513 21.5084C18.8934 21.6473 18.8643 21.7965 18.8657 21.947C18.8574 22.106 18.8852 22.2647 18.947 22.4114C19.0089 22.5581 19.1031 22.6889 19.2227 22.794C19.4402 22.9903 19.7237 23.0974 20.0167 23.094C20.3087 23.0938 20.5906 22.9871 20.8096 22.794C20.9276 22.6876 21.0208 22.5566 21.0825 22.4103C21.1442 22.2639 21.1729 22.1057 21.1666 21.947C21.1681 21.7965 21.1389 21.6473 21.081 21.5084C21.0231 21.3695 20.9376 21.2438 20.8297 21.139C20.7285 21.0302 20.6054 20.9441 20.4686 20.8863C20.3317 20.8286 20.1842 20.8006 20.0357 20.804Z"
                fill="#96A6CE"
                id="Trazado 4464"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
