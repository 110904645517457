import { Children, ReactElement } from 'react';

import { Flex } from '@/lib/v2/components';

import { TableToolsBarButton } from './components/TableToolsBarButton';

export interface ITableToolsBarProps {
  children: ReactElement<typeof TableToolsBarButton>[];
}

const TableToolsBar = ({ children }: ITableToolsBarProps) => {
  Children.forEach(children, (child) => {
    if (child.type !== TableToolsBarButton) {
      throw new Error('All children of TableToolsBar must be of type TableToolsBar.Button.');
    }
  });

  return (
    <Flex withGap alignment="start" gapSize="small">
      {children}
    </Flex>
  );
};

TableToolsBar.Button = TableToolsBarButton;

export default TableToolsBar;
