import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  atomFiltersRecurrent,
  atomSelectedRowsWithID,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { atomToggleDeleteRecurrentModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaignRecurrent } from '@/modules/CampaignsModule/hooks/useCampaignRecurrent';
import { useCampaignRecurrentFilters } from '@/modules/CampaignsModule/hooks/useCampaignRecurrentFilters';

export const useDeleteRecurrent = () => {
  const { t } = useTranslation();

  const [toggleDeleteRecurrentModal, setToggleDeleteRecurrentModal] = useAtom(
    atomToggleDeleteRecurrentModal
  );
  const selectedCampaignsByID = useAtomValue(atomSelectedRowsWithID);
  const filtersRecurrent = useAtomValue(atomFiltersRecurrent);

  const { deleteRecurrent, loading } = useCampaignRecurrent();
  const { getRecurrentCampaigns } = useCampaignRecurrentFilters();

  const handleConfirmDeleteCampaignsRecurrent = useCallback(async () => {
    await deleteRecurrent(selectedCampaignsByID);
    setToggleDeleteRecurrentModal(false);
    await getRecurrentCampaigns({ ...filtersRecurrent });
  }, [
    deleteRecurrent,
    filtersRecurrent,
    getRecurrentCampaigns,
    selectedCampaignsByID,
    setToggleDeleteRecurrentModal,
  ]);

  const messagesDeleteRecurrence = useMemo(() => {
    return {
      description:
        selectedCampaignsByID?.length > 1
          ? t('RECURRENT_MESSAGE.DELETE_RECURRENT.MULTIPLE.description')
          : t('RECURRENT_MESSAGE.DELETE_RECURRENT.SINGLE.description'),
      title:
        selectedCampaignsByID?.length > 1
          ? t('RECURRENT_MESSAGE.DELETE_RECURRENT.MULTIPLE.title')
          : t('RECURRENT_MESSAGE.DELETE_RECURRENT.SINGLE.title'),
    };
  }, [selectedCampaignsByID, t]);

  return {
    state: {
      toggleDeleteRecurrentModal,
      messagesDeleteRecurrence,
      loading,
    },
    events: {
      handleConfirmDeleteCampaignsRecurrent,
    },
    setters: {
      setToggleDeleteRecurrentModal,
    },
  };
};
