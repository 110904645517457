import { useAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { atomFiltersRecurrentAction } from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';

export const useInputSearch = () => {
  const { t } = useTranslation();

  const [filtersRecurrentAction, setFiltersRecurrentAction] = useAtom(atomFiltersRecurrentAction);
  const [reset, setReset] = useState(false);

  const inputDefaultValue = useMemo(
    () => filtersRecurrentAction?.search ?? '',
    [filtersRecurrentAction]
  );
  const inputPlaceholder = useMemo(() => t('CAMPAIGN_ACTIONS_MAIN.FILTERS.searchPlaceholder'), [t]);

  const handleOnClickSearch = useCallback(
    (querySearch: string) => {
      setFiltersRecurrentAction({
        ...filtersRecurrentAction,
        search: querySearch,
      });
    },
    [filtersRecurrentAction, setFiltersRecurrentAction]
  );

  const onClearSearch = useCallback(() => {
    setFiltersRecurrentAction({
      ...filtersRecurrentAction,
      search: '',
    });
    setReset(true);
  }, [filtersRecurrentAction, setFiltersRecurrentAction]);

  const resetInputSearch = useCallback(() => {
    setReset(true);
    setTimeout(() => setReset(false), 0);
  }, []);

  return {
    state: {
      inputDefaultValue,
      inputPlaceholder,
      inputReset: reset,
    },
    events: {
      handleOnClickSearch,
      onClearSearch,
      resetInputSearch,
    },
  };
};
