import { useAtomValue } from 'jotai';
import { ChangeEvent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Text } from '@/lib/v2/components';
import { DropdownItemProps } from '@/lib/v2/components/Dropdown';

import { useDropdownFilters } from '@/src/modules/CampaignsModule/components/Campaigns/TableContainer/components/CampaignFilterBar/hooks/useDropdownFilters';

import { atomStrategyList } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { STRATEGY_MAP_BY_ID } from '@/modules/CampaignsModule/components/CampaignsModal/ManagementCampaign/constants';
import { strategyOrderOtherOption } from '@/modules/CampaignsModule/utils';

const FilterCampaignStrategy = () => {
  const { t } = useTranslation();

  const strategyList = useAtomValue(atomStrategyList);

  const {
    state: { filtersDropdownCampaign },
    events: { handleCheckboxChange },
  } = useDropdownFilters();

  const strategiesList = useMemo<DropdownItemProps[]>(
    () => strategyOrderOtherOption(strategyList),
    [strategyList]
  );

  const handleCheck = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = event.target;
      handleCheckboxChange('strategy', Number(value), checked);
    },
    [handleCheckboxChange]
  );

  const strategiesItems = useMemo(() => {
    return strategiesList
      .map((strategy) => ({
        ...strategy,
        label: t(
          `CAMPAIGNS_MAIN.DROPDOWN_STRATEGIES.${STRATEGY_MAP_BY_ID[Number(strategy.id)]}`,
          strategy.label
        ),
        value: t(`CAMPAIGNS_MAIN.DROPDOWN_STRATEGIES.${strategy.value}`, String(strategy.value)),
        checked: filtersDropdownCampaign?.strategy?.includes(Number(strategy.id)),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [strategiesList, t, filtersDropdownCampaign]);

  return (
    <div className="mb-4" id="filters-campaign-strategy">
      <Text className="mb-2" fontWeight="medium">
        {t('CAMPAIGNS_MAIN.FILTERS.strategy')}
      </Text>
      <div className="flex max-h-[150px] flex-col gap-2">
        {strategiesItems.map((item) => (
          <Checkbox
            key={item.id}
            checked={item.checked}
            id={`strategy-${item.id}`}
            label={item.label}
            value={item.id}
            onChange={handleCheck}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(FilterCampaignStrategy);
