//cspell:disable
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { useRulesService } from '@/src/infrastructure/Protocol/Rules/useRulesService';

import {
  atomIsLoadingRuleData,
  atomRuleDataRaw,
  atomTouchRules,
} from '@/modules/RulesModule/atoms/rules';
import { PayloadSaveRules, RulesOptions, RuleTypes } from '@/modules/RulesModule/interfaces';

export const useSaveDataRules = () => {
  const rulesService = useRulesService();

  const [touchRules, setTouchRules] = useAtom(atomTouchRules);
  const ruleDataRaw = useAtomValue(atomRuleDataRaw);
  const setIsLoadingRuleData = useSetAtom(atomIsLoadingRuleData);

  const updateRuleData = (formData: RulesOptions): PayloadSaveRules | undefined => {
    if (!ruleDataRaw) return;

    let fechaEnvio = 'de inmediato';

    if (formData.trigger?.value === RuleTypes.DATE) {
      const isImmediate = formData.days?.value === 'de inmediato';
      const execution = formData.execution?.value;
      const period = formData.period?.value;
      const frequency = formData.frequency?.value;

      if (frequency && execution === 'mismo') {
        fechaEnvio = `de inmediato|${frequency}|mismo|de inmediato`;
      } else if (isImmediate) {
        if (execution || period || frequency) {
          fechaEnvio = `${formData.days?.value}|${frequency}|${execution}|${period}`;
        } else {
          fechaEnvio = 'de inmediato';
        }
      } else if (frequency && execution && period) {
        fechaEnvio = `1|${frequency}|${execution}|${period}`;
      }

      const horaEnvio =
        formData.hours?.value && formData.minutes?.value
          ? `${String(formData.hours?.value)}:${String(formData.minutes?.value)}`
          : null;

      let group_rules_in;

      if (formData.presenceCondition?.id === 0) {
        group_rules_in = 0;
      } else if (formData.presenceCondition?.id === 1) {
        group_rules_in = 1;
      } else {
        group_rules_in = null;
      }

      const updatedRuleData: PayloadSaveRules = {
        acumuladaCantidad: ruleDataRaw.acumuladaCantidad,
        campoDTO: {
          ...ruleDataRaw.campoDTO,
          id: Number(formData.customField?.id) || ruleDataRaw.campoDTO.id,
          nombre:
            formData.customField?.name?.toString().toLowerCase() ||
            ruleDataRaw.campoDTO.nombre.toLowerCase(),
          integracion_id: null,
        },
        camposPersonalizadosDTO: ruleDataRaw.camposPersonalizadosDTO,
        desencadenante: String(formData.trigger?.value ?? ruleDataRaw.desencadenante),
        elementoId: ruleDataRaw.elementoId,
        elementosHnosCantidad: ruleDataRaw.elementosHnosCantidad,
        enviarCadaAnio: ruleDataRaw.enviarCadaAnio,
        envioRecurrente: ruleDataRaw.envioRecurrente,
        fechaEnvio,
        group_rules: Number(formData.selectedGroupOrSegment?.id) || null,
        group_rules_in,
        group_rules_type: formData.selectedGroupOrSegment?.value?.toString() || '',
        grupoDTO: ruleDataRaw.grupoDTO,
        horaEnvio,
        landingPageDTO: ruleDataRaw.landingPageDTO,
        landingPageErrorDTO: ruleDataRaw.landingPageErrorDTO,
        redireccionamientoFinal: ruleDataRaw.redireccionamientoFinal,
        redireccionamientoFinalError: ruleDataRaw.redireccionamientoFinalError,
        tag_id: ruleDataRaw.tag_id,
        tipo: ruleDataRaw.tipo ?? 'Clicks',
        urlDestino: ruleDataRaw.urlDestino,
        urlDestinoError: ruleDataRaw.urlDestinoError,
      };

      return updatedRuleData;
    }

    if (formData.trigger?.value === RuleTypes.TAG) {
      const { frequency, hours, minutes, tagsGroupField } = formData;

      const sendHour =
        hours?.value && minutes?.value ? `${String(hours?.value)}:${String(minutes?.value)}` : null;
      const tagIds = tagsGroupField?.map((tag) => Number(tag.id)) || [];

      let group_rules_in;

      if (formData.presenceCondition?.id === 0) {
        group_rules_in = 0;
      } else if (formData.presenceCondition?.id === 1) {
        group_rules_in = 1;
      } else {
        group_rules_in = null;
      }

      const updatedRuleData: PayloadSaveRules = {
        acumuladaCantidad: null,
        campoDTO: ruleDataRaw.campoDTO,
        camposPersonalizadosDTO: [],
        desencadenante: String(formData.trigger?.value ?? ruleDataRaw.desencadenante),
        elementoId: ruleDataRaw.elementoId,
        elementosHnosCantidad: ruleDataRaw.elementosHnosCantidad,
        enviarCadaAnio: ruleDataRaw.enviarCadaAnio,
        envioRecurrente: ruleDataRaw.envioRecurrente,
        fechaEnvio: String(frequency?.value),
        group_rules: Number(formData.selectedGroupOrSegment?.id) || null,
        group_rules_in,
        group_rules_type: formData.selectedGroupOrSegment?.value?.toString() || '',
        grupoDTO: null,
        horaEnvio: sendHour,
        landingPageDTO: null,
        landingPageErrorDTO: null,
        tag_id: tagIds,
        tipo: 'Clicks',
        urlDestino: null,
        urlDestinoError: null,
      };

      return updatedRuleData;
    }

    if (formData.trigger?.value === RuleTypes.API_INTEGRATOR) {
      const updatedRuleData: PayloadSaveRules = {
        acumuladaCantidad: ruleDataRaw.acumuladaCantidad,
        campoDTO: ruleDataRaw.campoDTO,
        camposPersonalizadosDTO: ruleDataRaw.camposPersonalizadosDTO,
        desencadenante: String(formData.trigger?.value ?? ruleDataRaw.desencadenante),
        elementoId: ruleDataRaw.elementoId,
        elementosHnosCantidad: ruleDataRaw.elementosHnosCantidad,
        enviarCadaAnio: ruleDataRaw.enviarCadaAnio,
        envioRecurrente: Boolean(formData.repeatEmails?.value),
        fechaEnvio: ruleDataRaw.fechaEnvio,
        group_rules: ruleDataRaw.group_rules,
        group_rules_in: ruleDataRaw.group_rules_in,
        group_rules_type: ruleDataRaw.group_rules_type,
        grupoDTO:
          formData.groupContacts?.id && formData.groupContacts.id !== 0
            ? {
                id: Number(formData.groupContacts.id),
                nombre: formData.groupContacts.name?.toString(),
              }
            : null,
        horaEnvio: ruleDataRaw.horaEnvio,
        landingPageDTO: ruleDataRaw.landingPageDTO,
        landingPageErrorDTO: ruleDataRaw.landingPageErrorDTO,
        redireccionamientoFinal: ruleDataRaw.redireccionamientoFinal,
        redireccionamientoFinalError: ruleDataRaw.redireccionamientoFinalError,
        tag_id: ruleDataRaw.tag_id,
        tipo: ruleDataRaw.tipo,
        urlDestino: ruleDataRaw.urlDestino,
        urlDestinoError: ruleDataRaw.urlDestinoError,
      };

      return updatedRuleData;
    }

    if (formData.trigger?.value === RuleTypes.FLOW_TEMPLATE) {
      const updatedRuleData: PayloadSaveRules = {
        acumuladaCantidad: ruleDataRaw.acumuladaCantidad,
        campoDTO: ruleDataRaw.campoDTO,
        camposPersonalizadosDTO: ruleDataRaw.camposPersonalizadosDTO,
        desencadenante: String(formData.trigger?.value ?? ruleDataRaw.desencadenante),
        elementoId: ruleDataRaw.elementoId,
        elementosHnosCantidad: ruleDataRaw.elementosHnosCantidad,
        enviarCadaAnio: ruleDataRaw.enviarCadaAnio,
        envioRecurrente: ruleDataRaw.envioRecurrente,
        fechaEnvio: ruleDataRaw.fechaEnvio,
        group_rules: ruleDataRaw.group_rules,
        group_rules_in: ruleDataRaw.group_rules_in,
        group_rules_type: ruleDataRaw.group_rules_type,
        grupoDTO: ruleDataRaw.grupoDTO,
        horaEnvio: ruleDataRaw.horaEnvio,
        landingPageDTO: ruleDataRaw.landingPageDTO,
        landingPageErrorDTO: ruleDataRaw.landingPageErrorDTO,
        redireccionamientoFinal: ruleDataRaw.redireccionamientoFinal,
        redireccionamientoFinalError: ruleDataRaw.redireccionamientoFinalError,
        tag_id: ruleDataRaw.tag_id,
        tipo: ruleDataRaw.tipo,
        urlDestino: ruleDataRaw.urlDestino,
        urlDestinoError: ruleDataRaw.urlDestinoError,
      };

      return updatedRuleData;
    }

    if (formData.trigger?.value === RuleTypes.EXTERNAL_CALL) {
      const updatedRuleData: PayloadSaveRules = {
        acumuladaCantidad: ruleDataRaw.acumuladaCantidad,
        campoDTO: ruleDataRaw.campoDTO,
        camposPersonalizadosDTO: ruleDataRaw.camposPersonalizadosDTO,
        desencadenante: String(formData.trigger?.value ?? ruleDataRaw.desencadenante),
        elementoId: ruleDataRaw.elementoId,
        elementosHnosCantidad: ruleDataRaw.elementosHnosCantidad,
        enviarCadaAnio: ruleDataRaw.enviarCadaAnio,
        envioRecurrente: Boolean(formData.repeatEmails?.value),
        fechaEnvio: ruleDataRaw.fechaEnvio,
        group_rules: ruleDataRaw.group_rules,
        group_rules_in: ruleDataRaw.group_rules_in,
        group_rules_type: ruleDataRaw.group_rules_type,
        grupoDTO:
          formData.groupContacts?.id && formData.groupContacts.id !== 0
            ? {
                id: Number(formData.groupContacts.id),
                nombre: formData.groupContacts.name?.toString(),
              }
            : null,
        horaEnvio: ruleDataRaw.horaEnvio,
        landingPageDTO: ruleDataRaw.landingPageDTO,
        landingPageErrorDTO: ruleDataRaw.landingPageErrorDTO,
        redireccionamientoFinal: String(formData.redirectSuccess?.value),
        redireccionamientoFinalError: String(formData.redirectError?.value),
        tag_id: ruleDataRaw.tag_id,
        tipo: ruleDataRaw.tipo,
        urlDestino: String(formData.destinationSuccessUrl),
        urlDestinoError: String(formData.destinationErrorUrl),
      };

      return updatedRuleData;
    }
  };

  const saveRule = async (formData: RulesOptions) => {
    setIsLoadingRuleData(true);
    const updatedRuleData = updateRuleData(formData);

    if (updatedRuleData) {
      await rulesService.saveRule(updatedRuleData);
    }

    if (formData.touchRules !== undefined && touchRules !== formData.touchRules) {
      setTouchRules(formData.touchRules);
      await rulesService.setTouchRules({
        active: Boolean(formData.touchRules),
        actionId: Number(ruleDataRaw?.elementoId),
      });
    }
    setIsLoadingRuleData(false);
  };

  return { saveRule };
};
//cspell:enable
