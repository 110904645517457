import { Route, Routes } from 'react-router-dom';

import { MY_PLAN_PATHS } from '@/src/modules/MyPlanModule/constants';
import { BillingAndConsumption, ManagePlan } from '@/src/modules/MyPlanModule/screens';

export const MyPlanModuleRouter = () => {

  //TODO: Fix this to prevents flaky bad redirects
  // const navigate = useNavigate();
  // const { userData } = useStateUserContext();
  // const hiddenUsageAndBilling = useFeatureFlag('hiddenUsageAndBilling');
  // const hasRoleAdmin =
  //   ROLES.SUPER_ADMIN_ADMIN === userData.role || ROLES.AGENCY_ADMIN === userData.role;

  // useEffect(() => {
  //   if (!hasRoleAdmin && hiddenUsageAndBilling) {
  //     navigate('/v2');
  //   }
  // }, [hasRoleAdmin, hiddenUsageAndBilling, navigate]);

  return (
    <Routes>
      <Route
        element={<BillingAndConsumption />}
        path={MY_PLAN_PATHS.BILLING_AND_CONSUMPTION_PATH}
      />
      <Route element={<ManagePlan />} path={MY_PLAN_PATHS.MANAGE_PLAN} />
    </Routes>
  );
};
