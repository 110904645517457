import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/lib/v2/components';

import { ELanguageMap, formattedNumber } from '@/src/modules/CampaignsModule/utils/formattedNumber';

interface TotalCountDisplayProps {
  total: number | undefined;
  isLoading: boolean;
  hasItems: boolean;
}

const TotalCountDisplay = ({ total, isLoading, hasItems }: TotalCountDisplayProps) => {
  const { i18n } = useTranslation();

  const formattedTotal = useMemo(() => {
    if (!isLoading && hasItems && total) {
      return formattedNumber(total, i18n.language as keyof typeof ELanguageMap);
    }
    return '0';
  }, [isLoading, hasItems, total, i18n.language]);

  return (
    <Text as="span" color="primary" fontWeight="medium" id="total-count-campaigns">
      {` (${String(formattedTotal)})`}
    </Text>
  );
};

export default memo(TotalCountDisplay);
