/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable pii/no-email */
import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { chatShow } from '@/lib/components/SupportCard';
import { UserProfile } from '@/lib/components/UserProfile';
import { UserProfileProps } from '@/lib/components/UserProfile/UserProfile';
import { ChatIcon, EmblueIcon } from '@/lib/icon';
import { BarIcon } from '@/lib/icon/BarIcon';
import { Button, IconSvg, Text, Tooltip } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { useShowSmartFinder } from '@/lib/v2/hooks/SmartFinder';
import { CloseIcon, OpenNewWindow, SearchIcon } from '@/lib/v2/icons/outline';

import { useInboxContractStatus } from '@/src/application/hooks/useInboxContractStatus';
import { useVersion } from '@/src/application/hooks/useVersion';
import { getEnv } from '@/src/application/hooks/util/useEnv';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import configData from '@/src/config.json';

import { RouteEnum } from './routeEnum';

import './TopBar.tailwind.css';

import { useInboxMetrics } from '@/modules/InboxModule/hooks/useInboxMetrics';
import { BadgeNewIcon } from '@/modules/InboxModule/images/icons';
import { MY_PLAN_PATHS } from '@/modules/MyPlanModule/constants';
import { useCanChangePlan } from '@/modules/MyPlanModule/hooks/useCanChangePlan';

const TopBarLink = ({
  path,
  children,
  onClick,
  icon,
  iconHeight = '24px',
  iconWidth = '24px',
}: {
  path: string;
  children: string;
  onClick?: () => void;
  icon?: JSX.Element | ReactNode;
  iconHeight?: string;
  iconWidth?: string;
}) => {
  const { version } = useVersion();
  const { pathname } = useLocation();

  useEffect(() => {
    document.body.setAttribute('data-tab', pathname);
  }, [pathname]);

  let link = path;
  if (version === 'v2' && path !== RouteEnum.Home && !path.includes('/v2')) {
    link = '/v2' + path;
  }
  if (link === '') {
    link = '/v2';
  }
  if (path.startsWith('https')) {
    link = path;
  }

  return (
    <div
      className={`h-10 px-2 md:h-full md:hover:bg-[#3170C9] 2xl:px-4 ${
        (path === RouteEnum.Home && pathname === '/v2') ||
        (path !== RouteEnum.Home && pathname.replace('/v2', '').includes(path))
          ? 'bg-gradient-hover border-b-[0.125rem] pt-0.5 md:border-b-white'
          : ''
      }`}
    >
      <NavLink className="size-full" to={link} onClick={onClick}>
        <Flex alignment="center" className="gap-2 text-black md:text-white">
          {(path === RouteEnum.Home && pathname === '/v2') ||
          (path !== RouteEnum.Home && pathname.replace('/v2', '').includes(path)) ? (
            <b>{children}</b>
          ) : (
            children
          )}
          {icon && <IconSvg height={iconHeight} svgComponent={icon} width={iconWidth} />}
        </Flex>
      </NavLink>
    </div>
  );
};

export const TopBar = ({ email, accountId, companyName, days }: UserProfileProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const showNpsModule = useFeatureFlag('showNpsModule');
  const showSmartFinder = useFeatureFlag('showSmartFinder');
  const { openSmartFinder } = useShowSmartFinder();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { canChangePlan } = useCanChangePlan('Free Trial');
  const { hasInbox, isLoadingCheckInbox } = useInboxContractStatus();
  const { trackInboxLandingPageClick } = useInboxMetrics();

  /** cspell:disable */
  const cookiePreProd = document.cookie
    .split('; ')
    .find((row) => row.startsWith('EMA-APP-PREPROD='))
    ?.split('=')[1];
  /** cspell:enable */

  let background = 'bg-emblue-primary';

  const env = getEnv();

  if (env === 'production' && cookiePreProd === '1') {
    background = 'bg-emblue-success';
  }

  if (env === 'staging') {
    background = 'bg-emblue-sleepy';
  }

  const classesMenu = classNames(
    'fixed transition-all py-4 md:py-0 top-0 z-[100] md:left-0 flex h-screen w-screen md:h-full md:w-auto flex-col',
    'items-center bg-white md:relative md:flex-row md:bg-transparent',
    'justify-between md:justify-normal',
    {
      'left-0': menuIsOpen,
      '-left-full': !menuIsOpen,
    }
  );

  const closeMenu = () => {
    setMenuIsOpen(false);
  };

  const goToInbox = () => {
    if (!hasInbox) {
      trackInboxLandingPageClick();
      return;
    }

    const baseUrl = configData.endpoints[env === 'storybook' ? 'development' : env].URL_INBOX;
    const loginPath = '/emblue/login';
    const token = String((window as any).__JWTToken);
    const url = `${baseUrl}${loginPath}?token=${token}`;
    window.open(url, '_blank');
  };

  return (
    <div
      className={`top-bar h-16 w-full pr-6 text-white md:text-12 lg:text-14 2xl:text-16 ${background}`}
    >
      <Flex>
        <div className="flex h-full w-7/12 px-8 lg:w-8/12 2xl:w-1/2">
          <div className="flex h-full items-center">
            <EmblueIcon />
            <div className="flex flex-none md:hidden">
              <button onClick={() => setMenuIsOpen(true)}>
                <BarIcon />
              </button>
            </div>
          </div>
          <nav className={classesMenu}>
            <button
              className="absolute right-4 top-4 size-5 md:hidden"
              onClick={() => setMenuIsOpen(false)}
            >
              <CloseIcon />
            </button>
            <TopBarLink path={RouteEnum.Home} onClick={closeMenu}>
              {t('TOP_BAR.home')}
            </TopBarLink>
            {/* <TopBarLink path={RouteEnum.Dashboard} onClick={closeMenu}>
              {t('TOP_BAR.dashboard')}
            </TopBarLink> */}
            <TopBarLink path={RouteEnum.Contacts} onClick={closeMenu}>
              {t('TOP_BAR.contacts')}
            </TopBarLink>
            <TopBarLink path={RouteEnum.Campaigns} onClick={closeMenu}>
              {t('TOP_BAR.campaigns')}
            </TopBarLink>
            <TopBarLink path={RouteEnum.Reports} onClick={closeMenu}>
              {t('TOP_BAR.reports')}
            </TopBarLink>
            <TopBarLink path={RouteEnum.Automation} onClick={closeMenu}>
              {t('TOP_BAR.automation')}
            </TopBarLink>
            <TopBarLink path={RouteEnum.OnSite} onClick={closeMenu}>
              {t('TOP_BAR.on_site')}
            </TopBarLink>
            {showNpsModule && (
              <TopBarLink path={RouteEnum.Nps} onClick={closeMenu}>
                {t('TOP_BAR.nps')}
              </TopBarLink>
            )}
            {!isLoadingCheckInbox && (
              <>
                <TopBarLink
                  icon={hasInbox ? <OpenNewWindow /> : <BadgeNewIcon />}
                  iconHeight={hasInbox ? '16px' : '28px'}
                  iconWidth={hasInbox ? '16px' : '28px'}
                  path={hasInbox ? pathname : RouteEnum.Inbox}
                  onClick={goToInbox}
                >
                  {t('TOP_BAR.inbox')}
                </TopBarLink>
              </>
            )}
          </nav>
        </div>
        <Flex noGrow withGap alignment="end" gapSize="medium">
          {showSmartFinder && (
            <div className="hidden md:block">
              <Tooltip isDark content="Ctrl+k">
                <button
                  className="flex w-36 gap-4 rounded-3xl bg-black/25 p-2"
                  onClick={openSmartFinder}
                >
                  <SearchIcon color="white" /> <Text color="white">{t('TOP_BAR.goTo')}</Text>
                </button>
              </Tooltip>
            </div>
          )}

          {typeof days === 'number' && days > 0 && (
            <div className="flex-none px-2">
              <div className="flex items-center gap-4">
                <Text className="hidden md:block" color="white" variant="text-sm">
                  {t('TOP_BAR.demoText', { days })}
                </Text>
                {canChangePlan && (
                  <Button
                    tertiary
                    size="small"
                    onClick={() =>
                      navigate(`/v2/${MY_PLAN_PATHS.MODULE_PATH}/${MY_PLAN_PATHS.MANAGE_PLAN}`)
                    }
                  >
                    {t('TOP_BAR.choosePlan')}
                  </Button>
                )}
              </div>
            </div>
          )}
          <button className="pr-2" onClick={chatShow}>
            <ChatIcon />
          </button>
          <div className="pd-2 mr-4 flex h-full min-w-max">
            <UserProfile dropDown accountId={accountId} companyName={companyName} email={email} />
          </div>
        </Flex>
      </Flex>
    </div>
  );
};
