import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { memo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Flex, IconSvg } from '@/lib/v2/components';
import { FolderIcon, FolderToolIcon } from '@/lib/v2/icons/solid';
import { HeaderBanner } from '@/lib/v2/organisms';

import { ActionButtons } from './components/ActionButtons';
import { Content } from './components/Content';
import Footer from './components/Footer/Footer';

import {
  atomDetailCampaign,
  atomLoadingDetail,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomModalTypeProcess,
  atomScreenTypeProcess,
  atomToggleEditCreateCampaignModal,
} from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaign } from '@/modules/CampaignsModule/hooks/useCampaigns';
import {
  ECampaignsTypeOptions,
  ModalType,
  ScreenType,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

const HeaderBannerActions = () => {
  const { campaignId } = useParams();

  const [detailCampaign, setDetailCampaign] = useAtom(atomDetailCampaign);
  const loadingDetail = useAtomValue(atomLoadingDetail);
  const setModalTypeProcess = useSetAtom(atomModalTypeProcess);
  const setScreenTypeProcess = useSetAtom(atomScreenTypeProcess);
  const setToggleEditCategoryModal = useSetAtom(atomToggleEditCreateCampaignModal);

  const { getDetailCampaign } = useCampaign();

  useEffect(() => {
    return () => setDetailCampaign(RESET);
  }, [setDetailCampaign]);

  useEffect(() => {
    void (async () => {
      await getDetailCampaign({ campaignID: Number(campaignId) });
    })();
  }, [getDetailCampaign, campaignId]);

  const handleClickEditCampaign = useCallback(() => {
    setModalTypeProcess(ModalType.EDIT);
    setScreenTypeProcess(ScreenType.DETAIL);
    setToggleEditCategoryModal(true);
  }, [setModalTypeProcess, setToggleEditCategoryModal, setScreenTypeProcess]);

  return (
    <Flex column>
      <HeaderBanner
        withFooter
        actionsButtons={<ActionButtons toggleModal={handleClickEditCampaign} />}
        content={
          <Content loading={!campaignId || loadingDetail} toggleModal={handleClickEditCampaign} />
        }
        footerContent={<Footer />}
        icon={
          <IconSvg
            rounded
            bgColor="headingIcon"
            height="auto"
            svgComponent={
              detailCampaign.campaignTypeId === ECampaignsTypeOptions.MANUAL ? (
                <FolderIcon />
              ) : (
                <FolderToolIcon />
              )
            }
            width="40px"
            withPadding="small"
          />
        }
      />
    </Flex>
  );
};

export default memo(HeaderBannerActions);
