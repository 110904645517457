import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import RouterSkeleton from '@/src/modules/AutomationModuleNew/components/RouterSkeleton';
import { AUTOMATION_PATHS } from '@/src/modules/AutomationModuleNew/constants';

const AutomationHome = lazy(
  () => import('@/src/modules/AutomationModuleNew/screens/AutomationHome/AutomationHome')
);

const RulesHome = lazy(
  () => import('@/src/modules/AutomationModuleNew/screens/RulesHome/RulesHome')
);

const RecurringMessagesHome = lazy(
  () =>
    import('@/src/modules/AutomationModuleNew/screens/RecurringMessagesHome/RecurringMessagesHome')
);

const JourneysHome = lazy(
  () => import('@/src/modules/AutomationModuleNew/screens/JourneysHome/JourneysHome')
);

const FlowDetail = lazy(
  () => import('@/src/modules/AutomationModuleNew/screens/FlowDetail/FlowDetail')
);

const CreateOrEditEvent = lazy(
  () => import('@/src/modules/AutomationModuleNew/screens/CreateOrEditEvent/CreateOrEditEvent')
);

export const AutomationModuleNewRouter = () => {
  return (
    <Suspense fallback={<RouterSkeleton />}>
      <Routes>
        <Route index element={<AutomationHome />} />
        <Route element={<RulesHome />} path={`${AUTOMATION_PATHS.RULES_PATH}`} />
        <Route element={<RecurringMessagesHome />} path={`${AUTOMATION_PATHS.RECURRENCES_PATH}`} />
        <Route element={<JourneysHome />} path={AUTOMATION_PATHS.JOURNEYS_PATH} />
        <Route
          element={<FlowDetail />}
          path={`${AUTOMATION_PATHS.JOURNEYS_PATH}/${AUTOMATION_PATHS.FLOW_DETAIL_PATH}/:guid`}
        />
        <Route
          element={<CreateOrEditEvent isEditView />}
          path={`${AUTOMATION_PATHS.JOURNEYS_PATH}/${AUTOMATION_PATHS.EVENT_PATH}/:eventId`}
        />
        <Route
          element={<CreateOrEditEvent />}
          path={`${AUTOMATION_PATHS.JOURNEYS_PATH}/${AUTOMATION_PATHS.CREATE_EVENT_PATH}`}
        />
      </Routes>
    </Suspense>
  );
};
