import classNames from 'classnames';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { Card, IconSvg, Text, Tooltip } from '@/lib/v2/components';
import { OpenNewWindow } from '@/lib/v2/icons/outline';

import { ICardContacts } from '@/modules/InsightsModule/interfaces/ContactsSummary';

interface ICardContactsProps extends ICardContacts {
  className: string;
}

const CardContacts = (props: ICardContactsProps) => {
  const { wordings, icon, bgIcon, total, linkTo, className } = props;

  const contentClassNames = classNames({
    'w-5/6': icon,
    'w-full': !icon,
  });

  const cardClassNames = classNames('flex flex-row items-center justify-center gap-4', className);
  const iconClassNames = classNames('rounded-full p-4', bgIcon);

  return (
    <Tooltip
      isDark
      content={
        wordings?.tooltip ? (
          <Text className="w-56 text-white" variant="text-sm">
            {wordings?.tooltip}
          </Text>
        ) : null
      }
      position="top"
    >
      <Link className="text-emblue-primary" to={linkTo}>
        <Card noPadding noShadow className={cardClassNames} isFullHeight={false}>
          {icon && <div className={iconClassNames}>{icon}</div>}
          <div className={contentClassNames}>
            <div className="flex w-full items-center justify-between">
              <Text className="uppercase" fontWeight="medium" variant="text">
                {wordings?.title}
              </Text>
              <IconSvg bgColor="primary" svgComponent={<OpenNewWindow />} />
            </div>
            <div className="box-content flex w-14">
              {typeof total === 'string' && <Text variant="title-1">{total}</Text>}
              {typeof total !== 'string' && total}
            </div>
            {wordings?.description && (
              <Text color="light-black" variant="text-sm">
                {wordings?.description}
              </Text>
            )}
          </div>
        </Card>
      </Link>
    </Tooltip>
  );
};

export default memo(CardContacts);
