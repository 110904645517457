import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ITableButtonProps } from '@/lib/v2/examples/TableToolsBar/components/TableToolsBarButton/TableToolsBarButton';
import { DuplicateIconNew, EditPencilIconNew, RemoveIconNew } from '@/lib/v2/icons/solid';

import {
  atomSelectedRowsWithID,
  atomTotalSelectedRows,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import {
  atomModalTypeProcess,
  atomToggleDeleteRecurrentModal,
  atomToggleDuplicateRecurrentModal,
  atomToggleEditCreateRecurrentModal,
} from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaignRecurrent } from '@/modules/CampaignsModule/hooks/useCampaignRecurrent';
import { ModalType } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useRecurrentToolBar = () => {
  const { t } = useTranslation();
  const { getDetailRecurrentMessage } = useCampaignRecurrent();

  const totalSelectedRows = useAtomValue(atomTotalSelectedRows);
  const selectedRowsWithID = useAtomValue(atomSelectedRowsWithID);
  const setToggleDeleteRecurrentModal = useSetAtom(atomToggleDeleteRecurrentModal);
  const setToggleDuplicateRecurrentModal = useSetAtom(atomToggleDuplicateRecurrentModal);
  const setToggleEditCreateRecurrentModal = useSetAtom(atomToggleEditCreateRecurrentModal);
  const setModalTypeProcess = useSetAtom(atomModalTypeProcess);

  const handleModalDeleteCampaigns = useCallback(() => {
    setToggleDeleteRecurrentModal(true);
  }, [setToggleDeleteRecurrentModal]);

  const handleClickEditCampaign = useCallback(() => {
    if (totalSelectedRows > 1) return;
    setModalTypeProcess(ModalType.EDIT);
    setToggleEditCreateRecurrentModal(true);
  }, [setModalTypeProcess, setToggleEditCreateRecurrentModal, totalSelectedRows]);

  const handleDuplicateCampaign = useCallback(() => {
    void getDetailRecurrentMessage({ campaignID: Number(selectedRowsWithID[0]) });
    setToggleDuplicateRecurrentModal(true);
  }, [getDetailRecurrentMessage, selectedRowsWithID, setToggleDuplicateRecurrentModal]);

  const ICONS_LIST_ACTIONS = useMemo<ITableButtonProps[]>(
    () => [
      {
        icon: <EditPencilIconNew />,
        onClick: handleClickEditCampaign,
        tooltip: t('CAMPAIGNS_MAIN.ACTIONS.tooltipEdit'),
        hide: totalSelectedRows > 1,
        id: 'edit-campaign',
        label: t('CAMPAIGNS_MAIN.ACTIONS.tooltipEdit'),
      },
      {
        icon: <DuplicateIconNew />,
        onClick: handleDuplicateCampaign,
        tooltip: t('CAMPAIGNS_MAIN.ACTIONS.tooltipDuplicate'),
        hide: totalSelectedRows > 1,
        id: 'duplicate-campaign-action',
        label: t('CAMPAIGNS_MAIN.ACTIONS.tooltipDuplicate'),
      },
      {
        icon: <RemoveIconNew />,
        onClick: handleModalDeleteCampaigns,
        tooltip: t('CAMPAIGNS_MAIN.ACTIONS.tooltipDelete'),
        hide: false,
        id: 'delete-campaign',
        label: t('CAMPAIGNS_MAIN.ACTIONS.tooltipDelete'),
      },
    ],
    [
      handleClickEditCampaign,
      handleDuplicateCampaign,
      handleModalDeleteCampaigns,
      t,
      totalSelectedRows,
    ]
  );

  return {
    state: {
      ICONS_LIST_ACTIONS,
    },
  };
};
