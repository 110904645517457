import { useAtomValue } from 'jotai';
import { memo, useMemo } from 'react';

import { If, Iframe, Spinner } from '@/lib/v2/components';

import ErrorAlert from './ErrorAlert/ErrorAlert';

import { atomActionInfoEmail } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { EmailPreviewButtons } from '@/modules/CampaignsModule/components/EmailPreviewButtons';
import { useEmailPreviewButtons } from '@/modules/CampaignsModule/components/EmailPreviewButtons/useEmailPreviewButtons.hooks';
import { useHtmlProcessor } from '@/modules/CampaignsModule/hooks/useHtmlProcessor';
import { IContent } from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface IMessageDisplay {
  html?: IContent;
  isLoading?: boolean;
  disabled?: boolean;
}

const MessageDisplay = ({ html, isLoading, disabled = false }: IMessageDisplay) => {
  const actionInfoEmail = useAtomValue(atomActionInfoEmail);

  const {
    state: { loadingDelete },
    events: { handleDeleteMessage },
  } = useEmailPreviewButtons({ sourceEditor: actionInfoEmail?.content?.editorVersion });

  const hasErrors = useMemo(
    () => (html?.errors ? html?.errors?.length > 0 : false),
    [html?.errors]
  );

  const processedMessage = useHtmlProcessor(html?.message ?? '');

  return (
    <>
      <If condition={isLoading}>
        <Spinner withoutOverlay />
      </If>
      <If condition={!isLoading && Boolean(html)}>
        <div className="size-full">
          <If condition={hasErrors}>
            <div className="flex flex-col gap-2 px-10 py-2">
              <ErrorAlert errors={html?.errors || []} />
            </div>
          </If>
          <div className="flex justify-end gap-2 px-10 pb-6 pt-5">
            <EmailPreviewButtons
              disabled={disabled}
              loading={isLoading || loadingDelete}
              sourceEditor={actionInfoEmail?.content.editorVersion}
              onDelete={handleDeleteMessage}
            />
          </div>
          <div
            className={`relative grid ${
              hasErrors ? 'min-h-[calc(100vh-245px)]' : 'min-h-[calc(100vh-200px)]'
            } place-items-center px-10 ${hasErrors ? 'pb-6' : 'pb-6'}`}
          >
            <div className="relative size-full">
              <Iframe html={processedMessage} id="message-display-iframe" title="html" />
            </div>
          </div>
        </div>
      </If>
    </>
  );
};

export default memo(MessageDisplay);
