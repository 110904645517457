import { memo } from 'react';

import ContactsSummary from '@/modules/InsightsModule/components/ContactsSummary';

const InsightsMain = () => {
  return (
    <div className="flex min-h-svh flex-col flex-wrap gap-8 bg-emblue-secondary-hover p-4 align-baseline">
      {/* Contacts */}
      <ContactsSummary />
    </div>
  );
};

export default memo(InsightsMain);
