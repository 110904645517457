import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
  BackIcon,
  BookIcon,
  ChangeAccount,
  ConfigurationIcon,
  LogoutIcon,
  RayIcon,
  SupportIcon,
  VideoIcon,
} from '@/lib/icon';
import { DownArrowIcon } from '@/lib/icon/DownArrowIcon';
import { MyPlanIcon } from '@/lib/icon/MyPlanIcon';
import { Avatar, Text } from '@/lib/v2/components';
import { Divide, Flex } from '@/lib/v2/components/Layout/Stack';
import { SendersIcon } from '@/lib/v2/icons/outline';
import { ApiConfigIcon } from '@/lib/v2/icons/solid';

import { ROLES } from '@/src/application/constants';
import { useMutationUserContext, useStateUserContext } from '@/src/application/context/UserContext';
import { useOnClickOutside } from '@/src/application/hooks/useOnClickOutside';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { atomShowAutomationV1 } from '@/src/atoms/Automation';
import { atomShowCampaignsV1 } from '@/src/atoms/Campaigns';
import configData from '@/src/config.json';

import { MY_PLAN_PATHS } from '@/modules/MyPlanModule/constants';

export type UserProfileProps = {
  name?: string;
  email?: string;
  accountId?: string;
  companyName?: string;
  dropDown?: boolean;
  days?: number;
};

export const CompanyData = ({ accountId, companyName }: UserProfileProps) => {
  const { t } = useTranslation();

  const onChangeAccount = useCallback(() => {
    window.location.href = configData.TOP_BAR.CHANGE_ACCOUNT;
  }, []);

  return (
    <div className="flex w-full flex-col px-4 py-2">
      <div className="flex justify-between">
        <Text fontWeight="medium" variant="text">
          {companyName}
        </Text>
        <button onClick={onChangeAccount}>
          <ChangeAccount />
        </button>
      </div>
      <Text variant="text-sm">
        {t('USER_PROFILE.ID')}: {accountId}
      </Text>
    </div>
  );
};

export const UserProfile = ({ email, dropDown, accountId, companyName }: UserProfileProps) => {
  const navigate = useNavigate();
  const { userData } = useStateUserContext();
  const { logout } = useMutationUserContext();
  const [showDropDown, setShowDropDown] = useState(false);
  const [crashRender, setCrashRender] = useState(false);
  const toggleRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const showCrashButton = useFeatureFlag('showCrashButton');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const showCampaignToLegacy = (window as any).showCampaignToLegacy;
  const showCampaigns = useFeatureFlag('showCampaigns');

  const showAutomation = useFeatureFlag('showAutomation');
  const showAutomationToLegacy = userData.email.includes('@embluemail.com');

  const showConfigMenuApiManager = useFeatureFlag('configMenuApiManager');
  const name = useMemo(() => {
    return userData?.name?.split(' ')[0];
  }, [userData]);
  const lastName = useMemo(() => {
    return userData?.name?.split(' ')[1];
  }, [userData]);
  const isLangEs = userData.language === 'es';
  useOnClickOutside(toggleRef, () => setShowDropDown(false));
  const { t } = useTranslation();
  const [showCampaignV1, setShowCampaignV1] = useAtom(atomShowCampaignsV1);
  const [showAutomationV1, setShowAutomationV1] = useAtom(atomShowAutomationV1);
  const hiddenUsageAndBilling = useFeatureFlag('hiddenUsageAndBilling');

  if (crashRender) {
    throw new Error('I crashed the render like a newbie');
  }

  const hasRoleAdmin =
    ROLES.SUPER_ADMIN_ADMIN === userData.role || ROLES.AGENCY_ADMIN === userData.role;

  const configOptions = useMemo(() => {
    const items = [
      !hasRoleAdmin && hiddenUsageAndBilling
        ? null
        : {
            id: 'usage-and-billing',
            icon: <MyPlanIcon />,
            title: t('USER_PROFILE.myPlan'),
            onAction: () => {
              setShowDropDown(false);
              navigate(
                `${MY_PLAN_PATHS.MODULE_PATH}/${MY_PLAN_PATHS.BILLING_AND_CONSUMPTION_PATH}`
              );
            },
          },
      {
        id: 'senders',
        icon: <SendersIcon color="#000000" />,
        title: t('USER_PROFILE.senders'),
        onAction: () => {
          setShowDropDown(false);
          navigate('/v2/configuration/senders');
        },
      },
      showConfigMenuApiManager
        ? {
            id: 'apiManager',
            icon: <ApiConfigIcon color="#000000" />,
            title: t('USER_PROFILE.apiManager'),
            onAction: () => {
              setShowDropDown(false);
              navigate('/v2/api-manager');
            },
          }
        : null,
      {
        id: 'configuration',
        icon: <ConfigurationIcon />,
        title: t('USER_PROFILE.configuration'),
        onAction: () => {
          setShowDropDown(false);
          navigate('/v2/configuration');
        },
      },
      {
        id: 'support',
        icon: <SupportIcon />,
        title: t('USER_PROFILE.support'),
        onAction: () => {
          setShowDropDown(false);
          window.open(configData.TOP_BAR.SUPPORT.url, '_blank');
        },
      },
      {
        id: 'academy',
        icon: <VideoIcon />,
        title: t('ONBOARDING.RELEVANT_LINKS.academyTitle'),
        onAction: () => {
          setShowDropDown(false);
          window.open(configData.TOP_BAR.ACADEMY.url, '_blank');
        },
      },
      {
        id: 'help-manual',
        icon: <BookIcon />,
        title: t('USER_PROFILE.help_manual'),
        onAction: () => {
          setShowDropDown(false);
          window.open(configData.TOP_BAR.MANUAL.url, '_blank');
        },
      },
      isLangEs
        ? {
            id: 'types-by-emblue',
            icon: <BookIcon />,
            title: t('USER_PROFILE.emblue_mail_tips'),
            onAction: () => {
              setShowDropDown(false);
              window.open(configData.TOP_BAR.TIPS.url, '_blank');
            },
          }
        : null,
      showCrashButton
        ? {
            id: 'crash',
            icon: <RayIcon />,
            title: t('USER_PROFILE.show_me_how_you_crash_baby'),
            onAction: () => {
              throw new Error('iCrashed');
            },
          }
        : null,
      showCrashButton
        ? {
            id: 'crash-rendering',
            icon: <RayIcon />,
            title: t('USER_PROFILE.show_me_how_you_crash_when_rendering'),
            onAction: () => {
              setCrashRender(true);
            },
          }
        : null,
      showCampaigns && showCampaignToLegacy
        ? {
            id: 'campaigns',
            icon: <BackIcon color="currentColor" />,
            title:
              showCampaignV1 === 'true'
                ? t('USER_PROFILE.goToCampaignV2')
                : t('USER_PROFILE.goToCampaignV1'),
            onAction: () => {
              setShowCampaignV1((!(showCampaignV1 === 'true')).toString());
              window.location.reload();
            },
          }
        : null,
      showAutomation && showAutomationToLegacy
        ? {
            id: 'automation',
            icon: <BackIcon color="currentColor" />,
            title:
              showAutomationV1 === 'true'
                ? t('USER_PROFILE.goToAutomationV2')
                : t('USER_PROFILE.goToAutomationV1'),
            onAction: () => {
              setShowAutomationV1((!(showAutomationV1 === 'true')).toString());
              window.location.reload();
            },
          }
        : null,
      {
        id: 'logout',
        icon: <LogoutIcon />,
        title: t('USER_PROFILE.sign_off'),
        onAction: () => {
          setShowDropDown(false);
          void logout();
        },
      },
    ].filter((x) => x);

    return items;
  }, [
    hasRoleAdmin,
    hiddenUsageAndBilling,
    isLangEs,
    logout,
    navigate,
    setShowAutomationV1,
    setShowCampaignV1,
    showAutomation,
    showAutomationToLegacy,
    showAutomationV1,
    showCampaignToLegacy,
    showCampaignV1,
    showCampaigns,
    showConfigMenuApiManager,
    showCrashButton,
    t,
  ]);

  const watchOpenSenders = useCallback(
    (evt: MessageEvent<any>) => {
      if (evt.data && evt.data.type === 'openSenders') {
        navigate('/v2/configuration/senders');
      }
    },
    [navigate]
  );

  useEffect(() => {
    window.addEventListener('message', watchOpenSenders);

    return () => {
      window.removeEventListener('message', watchOpenSenders);
    };
  }, [watchOpenSenders]);

  return (
    <div ref={toggleRef}>
      <button
        className="w-full rounded-2xl text-white shadow-sm"
        id="profile-dropdown"
        type="button"
        onClick={() => setShowDropDown(!showDropDown)}
      >
        <div className="flex items-center">
          <Avatar email={email} lastName={lastName} name={userData.name} status="frequent" />
          <DownArrowIcon color="white" />
        </div>
      </button>
      {showDropDown && dropDown && (
        <div
          className="configuration absolute right-[31px] z-50 mt-4 min-w-[300px] bg-white shadow-md"
          style={{ border: '1px solid #D3DDEA' }}
        >
          <div className="py-1" role="none">
            <Divide>
              <Flex column itemAlignment="start">
                <CompanyData accountId={accountId} companyName={companyName} />
                <Flex>
                  <div className="px-4 py-2">
                    <Flex column itemAlignment="start">
                      <Text fontWeight="medium" variant="text">
                        {name}
                      </Text>
                      <Text variant="text-sm">{email}</Text>
                    </Flex>
                  </div>
                </Flex>
                {configOptions.map((element) => {
                  return (
                    <button
                      key={element?.title}
                      className="text-sm group flex w-full items-center px-4 py-2 text-gray-700 hover:bg-[#CCDEF8]"
                      id="menu-item-0"
                      role="menuitem"
                      onClick={element?.onAction}
                    >
                      {element?.icon}
                      <div className="ml-4">{element?.title}</div>
                    </button>
                  );
                })}
              </Flex>
            </Divide>
          </div>
        </div>
      )}
    </div>
  );
};
