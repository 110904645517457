import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { memo, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactFlow } from 'reactflow';

import { Button, IconSvg, If, Text } from '@/lib/v2/components';
import { DeleteIcon as DeleteIconSolid } from '@/lib/v2/icons/solid';

import InitialNode from '@/src/modules/RulesModule/components/InitialNode';
import { SkeletonNode } from '@/src/modules/RulesModule/components/Skeleton';
import { TRANSLATE_RULES_LABELS } from '@/src/modules/RulesModule/constants';
import { FormTypes, RulesOptions, RuleTypes } from '@/src/modules/RulesModule/interfaces';

import NodeContentDate from './NodeContentDate';

import {
  atomIsConditionFormComplete,
  atomIsLoadingRuleData,
  atomRuleData,
  atomTouchRules,
} from '@/modules/RulesModule/atoms/rules';
import { useSaveDataRules } from '@/modules/RulesModule/components/FormRule/hooks/useSaveDataRules';
import { createEdge } from '@/modules/RulesModule/hooks/useFlowManagement';
import { useRuleStatus } from '@/modules/RulesModule/hooks/useRuleStatus';
import { RulesConditionIcon } from '@/modules/RulesModule/images/icons/RulesConditionIcon';

const ConditionNodeContent = () => {
  const { t } = useTranslation('rules');
  const { setNodes, setEdges, getNodes, getEdges } = useReactFlow();
  const { saveRule } = useSaveDataRules();
  const { isRuleActive } = useRuleStatus();

  const isLoadingRuleData = useAtomValue(atomIsLoadingRuleData);
  const [isConditionFormComplete, setIsConditionFormComplete] = useAtom(
    atomIsConditionFormComplete
  );
  const setTouchRules = useSetAtom(atomTouchRules);
  const [rulesDataAtom, setRulesDataAtom] = useAtom<RulesOptions>(atomRuleData);

  const triggerType = rulesDataAtom.trigger?.value;

  const handleDeleteCondition = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const nodes = getNodes();
      const edges = getEdges();

      const conditionNode = nodes.find((node) => node.type === FormTypes.CONDITION);
      if (!conditionNode) return;

      const relatedEdges = edges.filter(
        (edge) => edge.source === conditionNode.id || edge.target === conditionNode.id
      );

      if (relatedEdges.length !== 2) return;

      const [edge1, edge2] = relatedEdges;
      const parentNode = edge1.source === conditionNode.id ? edge1.target : edge1.source;
      const childNode = edge2.source === conditionNode.id ? edge2.target : edge2.source;

      const newEdge = createEdge({ source: parentNode, target: childNode });

      setNodes((nds) => nds.filter((node) => node.id !== conditionNode.id));
      setEdges((eds) => eds.filter((edge) => !relatedEdges.includes(edge)).concat(newEdge));

      const updatedRulesData = {
        ...rulesDataAtom,
        selectedGroupOrSegment: undefined,
        presenceCondition: undefined,
      };

      setIsConditionFormComplete(false);
      setRulesDataAtom(updatedRulesData);
      setTouchRules(false);

      await saveRule(updatedRulesData);
    },
    [
      getNodes,
      getEdges,
      setNodes,
      setEdges,
      rulesDataAtom,
      setRulesDataAtom,
      saveRule,
      setTouchRules,
      setIsConditionFormComplete,
    ]
  );

  if (isLoadingRuleData) {
    return <SkeletonNode />;
  }

  if (!isConditionFormComplete) {
    return (
      <InitialNode
        deletable
        icon={<RulesConditionIcon />}
        title={t(`${TRANSLATE_RULES_LABELS}.condition`)}
        onDelete={handleDeleteCondition}
      />
    );
  }

  return (
    <>
      <div className="mb-2 flex items-center">
        <IconSvg
          fillColor="primary"
          height="24px"
          id="cube"
          svgComponent={<RulesConditionIcon />}
          width="24px"
        />
        <Text className="ml-2 mt-1" fontWeight="semibold" variant="text">
          {t(`${TRANSLATE_RULES_LABELS}.condition`)}
        </Text>
        <div className="z-10 ml-auto">
          <Button
            gray
            outline
            disabled={isRuleActive}
            id="delete-condition"
            size="small"
            title="Delete condition"
            onClick={handleDeleteCondition}
          >
            <IconSvg
              fillColor="gray-dark"
              height="16px"
              svgComponent={<DeleteIconSolid />}
              width="13px"
            />
          </Button>
        </div>
      </div>
      <hr className="w-full min-w-64" />
      <div className="flex flex-col justify-center pb-4 pt-2">
        <If condition={triggerType === RuleTypes.DATE || triggerType === RuleTypes.TAG}>
          <div className="w-full truncate">
            <NodeContentDate />
          </div>
        </If>
      </div>
    </>
  );
};

export default memo(ConditionNodeContent);
