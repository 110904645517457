import { useCallback } from 'react';

/**
 * Interface for a function that parses a string into an array of numbers.
 *
 * @param {string | null} value - The input string to parse.
 * @returns {number[] | undefined} - An array of numbers parsed from the input string or undefined if input is invalid.
 */
interface IParseNumberArrayParams {
  (value: string | null): number[] | undefined;
}

/**
 * Interface for a function that parses a string into a Date object.
 *
 * @param {string | null} value - The input string to parse.
 * @returns {Date | undefined} - A Date object parsed from the input string or undefined if input is invalid.
 */
interface IParseDateTimestampParams {
  (value: string | null): Date | undefined;
}

const useParseParamsURL = () => {
  /**
   * Parses a string into an array of numbers.
   *
   * @param {string | null} value - The input string to parse.
   * @returns {number[] | undefined} - An array of numbers parsed from the input string or undefined if input is invalid.
   */
  const parseNumberArray: IParseNumberArrayParams = useCallback(
    (value: string | null): number[] | undefined => {
      if (!value) return undefined;
      const numbers = value
        .split(',')
        .map(Number)
        .filter((n) => !isNaN(n));
      return numbers.length > 0 ? numbers : undefined;
    },
    []
  );

  /**
   * Parses a string into a Date object.
   *
   * @param {string | null} value - The input string to parse.
   * @returns {Date | undefined} - A Date object parsed from the input string or undefined if input is invalid.
   */
  const parseDateTimestamp: IParseDateTimestampParams = useCallback(
    (value: string | null): Date | undefined => {
      if (!value) return undefined;
      const timestamp = Number(value);
      return !isNaN(timestamp) ? new Date(timestamp * 1000) : undefined;
    },
    []
  );

  return {
    parseNumberArray,
    parseDateTimestamp,
  };
};

export default useParseParamsURL;
