import { useAtom, useSetAtom } from 'jotai';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Flex, If, Input, InputTextArea } from '@/lib/v2/components';

import { CountWhatsApp } from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/components/WhatsAppForm/components/CountWhatsApp';
import {
  MAX_LENGTH_WHATSAPP,
  TEMPLATE_OPTION,
} from '@/src/modules/CampaignsModule/components/actionManagement/WhatsAppAction/constants';
import { SubjectButtons } from '@/src/modules/CampaignsModule/components/SubjectButtons';
import {
  EButtonType,
  ISubjectChangeButtonsProps,
} from '@/src/modules/CampaignsModule/components/SubjectButtons/SubjectButtons.interface';
import { TRANSLATE_WHATSAPP_ACTION_FORM } from '@/src/modules/CampaignsModule/constants';

import { useWhatsAppTemplateForm } from './hooks/useWhatsAppTemplateForm';

import {
  atomCurrentWhatsAppMessage,
  atomMediaUrl,
  atomTemplateOption,
} from '@/modules/CampaignsModule/atoms/actionWhatsAppAtom';

interface WhatsAppTemplateInputsProps {
  disabled: boolean;
  loading?: boolean;
  actionId: number;
}

const WhatsAppTemplateInputs = ({ disabled, loading, actionId }: WhatsAppTemplateInputsProps) => {
  const { t } = useTranslation('inbox');

  const [currentMessage, setCurrentMessage] = useAtom(atomCurrentWhatsAppMessage);
  const [messageLength, setMessageLength] = useState(0);
  const [showInputMedia, setShowInputMedia] = useState(false);

  const setTemplateOption = useSetAtom(atomTemplateOption);
  const setMediaUrl = useSetAtom(atomMediaUrl);

  const { control, RULES, handleSubmitTemplate, setValue } = useWhatsAppTemplateForm();

  const handleChangeMessage = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      setCurrentMessage(value);
    },
    [setCurrentMessage]
  );

  const handleChangeSubjectButton = useCallback(
    ({ item }: ISubjectChangeButtonsProps) => {
      const message = currentMessage ? `${currentMessage} ${item}` : item;
      setValue('message', message);
      setCurrentMessage(message);
    },
    [currentMessage, setValue, setCurrentMessage]
  );

  const handleChangeMedia = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setMediaUrl(value);
    },
    [setMediaUrl]
  );

  const handleChangeActivateInputMedia = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setShowInputMedia(checked);
    },
    [setShowInputMedia]
  );

  useEffect(() => {
    setMessageLength(currentMessage.length);

    return () => {
      setMessageLength(0);
    };
  }, [currentMessage]);

  return (
    <div className="flex w-full flex-col gap-4">
      <Input
        control={control}
        name="title"
        placeHolder={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.titleTemplatePlaceholder`)}
        rules={RULES.title}
        type="text"
      />
      <div className="-mt-1 w-full">
        <div className="flex gap-4">
          <div className="flex-1">
            <InputTextArea
              control={control}
              disabled={disabled}
              id="whatsapp_message"
              isLoading={loading}
              name="message"
              rows={7}
              rules={RULES.message}
              onChange={handleChangeMessage}
            />
            {!loading && (
              <div className="flex justify-end gap-1">
                <CountWhatsApp
                  count={messageLength}
                  length={messageLength}
                  maxLength={MAX_LENGTH_WHATSAPP}
                />
              </div>
            )}
          </div>
          <div className="w-fit">
            <SubjectButtons
              vertical
              actionId={actionId}
              disabled={disabled}
              exclude={[EButtonType.TAG]}
              id="whatsapp_form-buttons"
              searchPlaceholder={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.searchDropdown`)}
              onChangeButtons={handleChangeSubjectButton}
            />
          </div>
        </div>
      </div>
      <div className="mb-4 flex flex-col gap-1">
        <Checkbox
          control={control}
          id="activateInputMedia"
          label={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.addImage`)}
          name="activateInputMedia"
          onChange={handleChangeActivateInputMedia}
        />
        <If condition={showInputMedia}>
          <Input
            control={control}
            name="media"
            placeHolder={t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.mediaTemplatePlaceholder`)}
            rules={RULES.media}
            type="text"
            onChange={handleChangeMedia}
          />
        </If>
      </div>
      <Flex withGap alignment="end" gapSize="small" itemAlignment="center">
        <Button
          outline
          secondary
          id="cancel-button"
          size="small"
          onClick={() => setTemplateOption(TEMPLATE_OPTION.IDLE)}
        >
          {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.cancel`)}
        </Button>
        <Button primary id="validate-button" size="small" onClick={handleSubmitTemplate}>
          {t(`${TRANSLATE_WHATSAPP_ACTION_FORM}.validate`)}
        </Button>
      </Flex>
    </div>
  );
};

export default WhatsAppTemplateInputs;
