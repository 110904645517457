import { FilterIcon } from '@heroicons/react/solid';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconSvg } from '@/lib/v2/components';
import DropdownCustom from '@/lib/v2/organisms/DropdownCustom';
import { IDropdownCustom } from '@/lib/v2/organisms/DropdownCustom/DropdownCustom';

export interface IDropdownFilterProps
  extends Omit<IDropdownCustom, 'action' | 'open' | 'onToggle'> {
  id: string;
  label: string;
  onAction?: () => void;
}

const DropdownFilter = ({ children, id, label, onAction }: IDropdownFilterProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = useCallback(() => setIsOpen((prevShow) => !prevShow), [setIsOpen]);

  const handleClickApply = useCallback(() => {
    onAction?.();
    handleToggle?.();
  }, [onAction, handleToggle]);

  const ButtonFilter = useMemo(
    () => (
      <Button
        gray
        outline
        standard
        className="shadow-none"
        iconLeft={<IconSvg fillColor="gray-dark" svgComponent={<FilterIcon />} />}
        id={`button-${id}`}
      >
        {label}
      </Button>
    ),
    [id, label]
  );

  return (
    <DropdownCustom action={ButtonFilter} open={isOpen} onToggle={handleToggle}>
      <>
        <div className="visible-scrollbar flex w-300 flex-col overflow-y-auto">{children}</div>
        <div className="mt-4 flex justify-end">
          <Button id="button-apply-filters-campaign" size="small" onClick={handleClickApply}>
            {t('TABLE_FILTER_BAR.apply')}
          </Button>
        </div>
      </>
    </DropdownCustom>
  );
};

export default memo(DropdownFilter);
