import { useSetAtom } from 'jotai';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, IconSvg } from '@/lib/v2/components';
import { DropdownItemProps } from '@/lib/v2/components/Dropdown';
import {
  DuplicateIconNew,
  EditPencilIconNew,
  MenuDotsIcon,
  MoveIconNew,
  RemoveIconNew,
} from '@/lib/v2/icons/solid';

import {
  atomToggleDeleteActionsModal,
  atomToggleDuplicateActionsModal,
  atomToggleEditActionsModal,
  atomToggleMoveActionsModal,
} from '@/src/modules/CampaignsModule/atoms/modalsCampaignAtom';

interface ActionMenuToolsProps {
  onClick?: () => void;
  id: string;
}

const ActionMenuTools = ({ id, onClick }: ActionMenuToolsProps) => {
  const { t } = useTranslation();

  const setToggleEditActionsModal = useSetAtom(atomToggleEditActionsModal);
  const setToggleMoveActionsModal = useSetAtom(atomToggleMoveActionsModal);
  const setToggleDeleteActionsModal = useSetAtom(atomToggleDeleteActionsModal);
  const setToggleDuplicateActionsModal = useSetAtom(atomToggleDuplicateActionsModal);

  const listDropdownActions: DropdownItemProps[] = useMemo(() => {
    const actions = [
      {
        id: 'edit-campaign',
        value: 'edit',
        label: t('CAMPAIGN_ACTIONS_MAIN.HEADER.DROPDOWN.edit'),
        icon: (
          <IconSvg
            fillColor="gray-dark"
            strokeColor="gray-dark"
            svgComponent={<EditPencilIconNew />}
          />
        ),
      },
      {
        id: 'move-campaign',
        value: 'move',
        label: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipMove'),
        icon: (
          <IconSvg fillColor="gray-dark" strokeColor="gray-dark" svgComponent={<MoveIconNew />} />
        ),
      },
      {
        id: 'duplicate-campaign',
        value: 'duplicate',
        label: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipDuplicate'),
        icon: (
          <IconSvg
            fillColor="gray-dark"
            strokeColor="gray-dark"
            svgComponent={<DuplicateIconNew />}
          />
        ),
      },
      {
        id: 'delete-campaign',
        value: 'delete',
        label: t('CAMPAIGN_ACTIONS_MAIN.HEADER.DROPDOWN.delete'),
        icon: (
          <IconSvg fillColor="gray-dark" strokeColor="gray-dark" svgComponent={<RemoveIconNew />} />
        ),
      },
    ];

    return actions;
  }, [t]);

  const handleClickItemDropdown = useCallback(
    (type: string) => {
      onClick?.();

      const modalActions = {
        edit: setToggleEditActionsModal,
        move: setToggleMoveActionsModal,
        duplicate: setToggleDuplicateActionsModal,
        delete: setToggleDeleteActionsModal,
      };

      const setModal = modalActions[type as keyof typeof modalActions];
      if (setModal) setModal(true);
    },
    [
      onClick,
      setToggleDeleteActionsModal,
      setToggleDuplicateActionsModal,
      setToggleEditActionsModal,
      setToggleMoveActionsModal,
    ]
  );

  return (
    <Dropdown
      menuOnTheLeft
      onlyIcon
      dropdownItems={listDropdownActions.map((item) => ({
        ...item,
        onClick: () => handleClickItemDropdown(String(item.value)),
      }))}
      iconRight={<IconSvg fillColor="gray-dark" svgComponent={<MenuDotsIcon />} />}
      id={id}
      label=""
    />
  );
};

export default memo(ActionMenuTools);
