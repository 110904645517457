import { useAtomValue } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Option, toast } from '@/lib/v2/components';

import { CreateCheckoutBody } from '@/src/infrastructure/interfaces/IRequestParams.interface';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { BillingForm } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/BillingForm';
import { PlanPriceInformation } from '@/src/modules/MyPlanModule/screens/ManagePlan/components/PlanPriceInformation';

import { atomBillingInformation } from '@/modules/MyPlanModule/atoms/BillingAndConsumption';
import { BILLING_FORM_DATA_KEY, MY_PLAN_PATHS } from '@/modules/MyPlanModule/constants';
import { useFormManagePlan } from '@/modules/MyPlanModule/hooks/useFormManagePlan';
import { usePlanPriceInformation } from '@/modules/MyPlanModule/hooks/usePlanPriceInformation';
import { useProrationStripe } from '@/modules/MyPlanModule/hooks/useProrationStripe';
import { useSelectedPlanData } from '@/modules/MyPlanModule/hooks/useSelectedPlanData';
import { BillingDataPayment } from '@/modules/MyPlanModule/types';

export type BillingFormState = {
  businessName: string;
  fantasyName: string;
  rut: string;
  address: string;
  city: string;
  country?: Option;
  billingContact: string;
  billingEmail: string;
};
export interface Frequency {
  value: string;
  label: string;
  priceSuffix: string;
}

const StepThreeManagePlan = () => {
  const service = useService();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [createCheckoutIsLoading, setCreateCheckoutIsLoading] = useState(false);
  const billingInformation = useAtomValue(atomBillingInformation);

  const { plan, frequency, emailAmount, onsiteAmount, smsAmount, setCurrentStep } =
    useSelectedPlanData();

  const {
    priceIncreaseEmail,
    priceIncreaseOnsite,
    priceIncreaseSms,
    increaseEmail,
    increaseOnsite,
    increaseSms,
    proratedPrices,
    hasProrations,
    textConfirmButton,
    handleConfirm,
    disabledButton,
  } = usePlanPriceInformation();

  const {
    formState: { isValid, ...formState },
    billingEmails,
  } = useFormManagePlan();

  const getBillingData = useCallback((): CreateCheckoutBody['billingData'] => {
    const { formData } = formState;
    const businessName = (formData?.businessName?.value ??
      formData?.businessName?.defaultValue ??
      '') as string;
    const country = (formData?.country?.value?.value ??
      formData?.country?.defaultValue?.value ??
      0) as number;
    const rut = (formData?.rut?.value ?? formData?.rut?.defaultValue ?? '') as string;
    const fantasyName = (formData?.fantasyName?.value ??
      formData?.fantasyName?.defaultValue ??
      '') as string;
    const address = (formData?.address?.value ?? formData?.address?.defaultValue ?? '') as string;
    const billingContact = (formData?.billingContact?.value ??
      formData?.billingContact?.defaultValue ??
      '') as string;
    const billingEmail =
      billingEmails &&
      ((formData?.billingEmail?.value ?? formData?.billingEmail?.defaultValue ?? '') as string);
    const city = (formData?.city?.value ?? formData?.city?.defaultValue ?? '') as string;

    return {
      businessName,
      country,
      rut,
      fantasyName,
      address,
      billingContact,
      billingEmail,
      city,
    };
  }, [formState]);

  const body = useMemo(() => {
    const body: CreateCheckoutBody = {
      billingData: getBillingData(),
      planId: plan?.id ?? '',
      planName: plan?.name ?? 'Free',
      period: frequency.value,
      volume: { email: emailAmount, onsite: onsiteAmount, sms: smsAmount },
    };
    return body;
  }, [emailAmount, frequency.value, getBillingData, onsiteAmount, plan?.id, plan?.name, smsAmount]);

  const handleClickCreateCheckout = useCallback(async () => {
    setCreateCheckoutIsLoading(true);
    const body: CreateCheckoutBody = {
      mode: 'subscription',
      billingData: getBillingData(),
      planId: plan?.id ?? '',
      planName: plan?.name ?? 'Free',
      embedded: false,
      period: frequency.value,
      volume: { email: emailAmount, onsite: onsiteAmount, sms: smsAmount },
    };
    const { url, errorType } = await service.createCheckoutSession(body);
    setCreateCheckoutIsLoading(false);
    if (errorType) {
      toast({
        id: 'createCheckoutError',
        title: t('MANAGE_PLAN.STEP_THREE.payNotificationError.title'),
        body: t(`MANAGE_PLAN.STEP_THREE.payNotificationError.${errorType}`),
        variant: 'error',
      });
      return;
    }
    body.billingData.country = Number(billingInformation?.country);
    const dataPayment: BillingDataPayment = {
      ...body,
      priceIncreaseEmail,
      priceIncreaseSms,
      priceIncreaseOnsite,
      increaseEmail,
      increaseOnsite,
      increaseSms,
      isEditingPlan: plan?.actualPlan ?? false,
      proratedPrices,
    };
    localStorage.setItem(BILLING_FORM_DATA_KEY, JSON.stringify(dataPayment));
    window.open(url, '_self');
  }, [
    billingInformation?.country,
    emailAmount,
    frequency.value,
    getBillingData,
    increaseEmail,
    increaseOnsite,
    increaseSms,
    onsiteAmount,
    plan?.actualPlan,
    plan?.id,
    plan?.name,
    priceIncreaseEmail,
    priceIncreaseOnsite,
    priceIncreaseSms,
    proratedPrices,
    service,
    smsAmount,
    t,
  ]);

  const {
    prorationAmount,
    isLoading: prorationAmountIsLoading,
    isTrial,
  } = useProrationStripe(body, billingInformation?.hasStripeSubscription && hasProrations);

  const handlePurchase = useCallback(async () => {
    setCreateCheckoutIsLoading(true);
    const response = await service.paymentCompleted(body);

    setCreateCheckoutIsLoading(false);
    if (response.success) {
      toast({
        title: t('MANAGE_PLAN.STEP_THREE.payNotificationOk.title'),
        body: t('MANAGE_PLAN.STEP_THREE.payNotificationOk.description'),
        id: 'payment-ok',
      });
      navigate(`/v2/${MY_PLAN_PATHS.MODULE_PATH}/${MY_PLAN_PATHS.BILLING_AND_CONSUMPTION_PATH}`);
      setCurrentStep(1);
    } else {
      toast({
        variant: 'error',
        title: t('MANAGE_PLAN.STEP_THREE.payNotificationError.title'),
        body: t(`MANAGE_PLAN.STEP_THREE.payNotificationError.${response.errorType ?? 'UNKNOWN'}`),
        id: 'payment-error',
      });
    }
  }, [body, navigate, service, setCurrentStep, t]);

  const handleSendData = useCallback(() => {
    if (handleConfirm === 'paymentCompleted') {
      void handlePurchase();
    } else {
      void handleClickCreateCheckout();
    }
  }, [handleClickCreateCheckout, handleConfirm, handlePurchase]);

  return (
    <div className="relative size-full w-full px-6">
      <div className="flex flex-col gap-8 md:flex-row">
        <div className="flex basis-2/3">
          <BillingForm />
        </div>
        <div className="flex basis-1/3">
          <PlanPriceInformation
            isStepThree
            checkoutButton={
              <Button
                fullWidth
                disabled={!isValid || disabledButton || isTrial}
                id="pay"
                isLoading={createCheckoutIsLoading || prorationAmountIsLoading}
                onClick={handleSendData}
              >
                {textConfirmButton}
              </Button>
            }
            prorationAmount={prorationAmount}
            prorationAmountIsLoading={prorationAmountIsLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default StepThreeManagePlan;
