import { memo } from 'react';

import { TableToolsBar } from '@/lib/v2/organisms';

import { useActionsToolBar } from './useActionsToolBar.hooks';

const ActionsToolBar = () => {
  const {
    state: { ICONS_LIST_ACTIONS },
  } = useActionsToolBar();

  return (
    <TableToolsBar>
      {ICONS_LIST_ACTIONS.filter((element) => !element.hide).map((element) => {
        const { label, icon, tooltip, onClick: handleOnClick, id } = element;
        return (
          <TableToolsBar.Button
            key={id}
            icon={icon}
            id={id}
            label={label}
            tooltip={tooltip}
            onClick={handleOnClick}
          />
        );
      })}
    </TableToolsBar>
  );
};

export default memo(ActionsToolBar);
