import { FC, memo } from 'react';

import { Button, Card, CardSection, IconSvg, Text } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';
import { Check } from '@/lib/v2/icons/solid/Check';

import './CardTemplate.tailwind.css';

export interface CardTemplateProps {
  id: string;
  description: string;
  title: string;
  latestUpdate: string;
  buttonText: string;
  onClick?: () => void;
  isApproved?: boolean;
}

const CardTemplate: FC<CardTemplateProps> = ({
  id,
  description,
  title,
  latestUpdate,
  buttonText,
  onClick,
  isApproved = false,
}) => {
  return (
    <Card key={id} className="relative h-60 w-full overflow-hidden">
      <CardSection className="flex h-full flex-col justify-between">
        <div className="card-content size-full">
          <div className={`${isApproved ? 'default-content' : ''} h-40`}>
            <Flex column noGrow className="h-full p-3">
              <Text className="line-clamp-4" fontWeight="normal" variant="sub-headline">
                {description}
              </Text>
            </Flex>
          </div>
          {isApproved && (
            <div className="hover-content h-40">
              <Flex alignment="center" className="size-full" itemAlignment="center">
                <Button primary onClick={onClick}>
                  {buttonText}
                </Button>
              </Flex>
            </div>
          )}
          <div>
            <Flex noGrow alignment="spaceBetween" className="px-3" itemAlignment="center">
              <Text fontWeight="medium" variant="text">
                {title}
              </Text>
              <IconSvg svgComponent={<Check />} />
            </Flex>
            <Flex noGrow alignment="start" className="px-3" itemAlignment="center">
              <Text alignment="left" fontWeight="light" variant="text">
                {latestUpdate}
              </Text>
            </Flex>
          </div>
        </div>
      </CardSection>
    </Card>
  );
};

export default memo(CardTemplate);
