export const ArrowIcon = () => {
  return (
    <svg fill="none" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
      <mask
        height="18"
        id="mask0_6383_16186"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="18"
        x="0"
        y="0"
      >
        <rect fill="#D9D9D9" height="18" width="18" />
      </mask>
      <g mask="url(#mask0_6383_16186)">
        <path
          d="M10.225 15.2062C10.075 15.0562 10 14.8812 10 14.6812C10 14.4812 10.075 14.3062 10.225 14.1562L12.3813 12H5.5C5.0875 12 4.73438 11.8531 4.44063 11.5594C4.14688 11.2656 4 10.9125 4 10.5V3.75C4 3.5375 4.07187 3.35938 4.21562 3.21562C4.35938 3.07187 4.5375 3 4.75 3C4.9625 3 5.14062 3.07187 5.28437 3.21562C5.42813 3.35938 5.5 3.5375 5.5 3.75V10.5H12.3813L10.2063 8.325C10.0563 8.175 9.98438 8 9.99063 7.8C9.99688 7.6 10.0688 7.425 10.2063 7.275C10.3563 7.125 10.5313 7.04688 10.7313 7.04063C10.9313 7.03438 11.1063 7.10625 11.2563 7.25625L14.725 10.725C14.8 10.8 14.8531 10.8812 14.8844 10.9688C14.9156 11.0562 14.9312 11.15 14.9312 11.25C14.9312 11.35 14.9156 11.4438 14.8844 11.5312C14.8531 11.6187 14.8 11.7 14.725 11.775L11.2938 15.2062C11.1438 15.3562 10.9656 15.4313 10.7594 15.4313C10.5531 15.4313 10.375 15.3562 10.225 15.2062Z"
          fill="#364365"
        />
      </g>
    </svg>
  );
};
