import { useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useParseParamsURL from '@/src/application/hooks/useParseParamsURL';
import { atomTotalsProcess } from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import {
  ACTION_TYPE_SORT_CRITERIA,
  defaultPager,
  DROPDOWN_ACTIONS_STATE_GROUPED,
  initialOrderByKeys,
  initialPagerKeys,
} from '@/src/modules/CampaignsModule/constants';
import {
  EActionTypes,
  IActionStatus,
  ICampaignActionsListPayload,
  IFilterTableActions,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import {
  IOrderByTable,
  IPager,
  PATH_TYPES_CAMPAIGN,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { useCampaignActions } from './useCampaignActions';
import { useCampaignLastPath } from './useCampaignLastPath';

import {
  atomActionsList,
  atomFiltersAction,
  atomFiltersDropdownAction,
  atomOrderByAction,
  atomPagerAction,
} from '@/modules/CampaignsModule/atoms/actionsAtom';

export const useActionsFilters = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const { pathname } = useLocation();

  const orderByAction = useAtomValue(atomOrderByAction);
  const pageParams = useAtomValue(atomPagerAction);
  const setFiltersAction = useSetAtom(atomFiltersAction);
  const setFiltersDropdownAction = useSetAtom(atomFiltersDropdownAction);
  const setActionsList = useSetAtom(atomActionsList);
  const setTotalsProcess = useSetAtom(atomTotalsProcess);

  const { getList } = useCampaignActions();
  const { parseNumberArray, parseDateTimestamp } = useParseParamsURL();
  const { setCampaignPath } = useCampaignLastPath();

  const actionType = useMemo(() => {
    if (pathname.includes('actions-draft')) return EActionTypes.DRAFT;
    if (pathname.includes('actions-all')) return EActionTypes.ALL;
    if (pathname.includes('actions-finished')) return EActionTypes.FINISHED;
    if (campaignId) return EActionTypes.DETAIL;

    return EActionTypes.ALL;
  }, [pathname, campaignId]);

  const getStatusValues = useCallback((statuses: IActionStatus[]): number[] => {
    return statuses.flatMap((status) => {
      const group = DROPDOWN_ACTIONS_STATE_GROUPED.find((group) => group.label === status);
      return group ? group.values : [];
    });
  }, []);

  const getSortOrder = useCallback(
    (sortOrder: string) => ({ asc: true, desc: false }[sortOrder]),
    []
  );

  const sortCriteria = useMemo(
    () => ACTION_TYPE_SORT_CRITERIA[actionType || EActionTypes.ALL],
    [actionType]
  );

  const parseURLParams = useCallback((): Partial<IFilterTableActions> => {
    const searchParams = new URLSearchParams(location.search);
    return {
      status: searchParams.get('status')?.split(',') as IActionStatus[],
      channel: parseNumberArray(searchParams.get('channel')),
      search: searchParams.get('search') || undefined,
      startDate: parseDateTimestamp(searchParams.get('startDate')),
      endDate: parseDateTimestamp(searchParams.get('endDate')),
      page: Number(searchParams.get('page')),
      limit: Number(searchParams.get('limit')),
      sortField: searchParams.get('sortField') || sortCriteria?.sortField,
      sortOrder: searchParams.get('sortOrder') || sortCriteria?.sortOrder,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType]);

  const updateURLFromFilters = useCallback(
    (filters: IFilterTableActions | undefined) => {
      if (!filters) return;

      const searchParams = new URLSearchParams();
      Object.entries(filters).forEach(([key, value]) => {
        if (
          value !== undefined &&
          value !== null &&
          !(
            (initialPagerKeys.includes(key) && value === defaultPager[key as keyof IPager]) ||
            (initialOrderByKeys.includes(key) && value === sortCriteria[key as keyof IOrderByTable])
          )
        ) {
          if (Array.isArray(value)) {
            searchParams.set(key, value.join(','));
          } else if (value instanceof Date) {
            const timeStampCast = new Date(value).getTime() / 1000;
            searchParams.set(key, timeStampCast.toString());
          } else {
            searchParams.set(key, String(value));
          }
        }
      });

      setCampaignPath({
        pathType: PATH_TYPES_CAMPAIGN.ACTION,
        path: `${location.pathname}${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`,
      });
      /* navigate(`?${searchParams.toString()}`, { replace: false }); */
      window.history.replaceState(
        {},
        '',
        `${location.pathname}${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`
      );
    },
    [setCampaignPath, sortCriteria]
  );

  const getActions = useCallback(
    async (payload: IFilterTableActions) => {
      await getList({
        ...(payload as ICampaignActionsListPayload),
        ...(campaignId ? { campaignId: Number(campaignId) } : {}),
        type: actionType,
        sortField: orderByAction.sortField,
        sortOrder: orderByAction.sortOrder,
      });

      updateURLFromFilters({
        ...payload,
        sortField: orderByAction.sortField,
        sortOrder: orderByAction.sortOrder,
      });
    },
    [campaignId, actionType, orderByAction, getList, updateURLFromFilters]
  );

  const resetFilters = useCallback(() => {
    setFiltersAction({ ...pageParams, page: 1 });
    setFiltersDropdownAction(RESET);

    const allowedParams = ['sortField', 'sortOrder', 'limit'];
    const newSearchParams = new URLSearchParams(
      Object.fromEntries(
        [...new URLSearchParams(location.search)].filter(([key]) => allowedParams.includes(key))
      )
    );

    navigate(
      { pathname: location.pathname, search: newSearchParams.toString() },
      { replace: true }
    );
  }, [navigate, pageParams, setFiltersAction, setFiltersDropdownAction]);

  const resetUnmount = useCallback(() => {
    setActionsList(RESET);
    setFiltersDropdownAction(RESET);
    setFiltersAction(RESET);
    setTotalsProcess(RESET);
  }, [setActionsList, setFiltersAction, setFiltersDropdownAction, setTotalsProcess]);

  return {
    getStatusValues,
    getSortOrder,
    parseURLParams,
    updateURLFromFilters,
    getActions,
    resetFilters,
    resetUnmount,
  };
};
