import { useAtomValue, useSetAtom } from 'jotai';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TableSkeleton } from '@/lib/components';
import { Table } from '@/lib/components/Table';
import { Text } from '@/lib/v2/components';
import { EmptyCampaignsIcon, EmptyContactsIcon } from '@/lib/v2/icons/general';
import { EmptyScreenTable } from '@/lib/v2/organisms';

import { PhonePreviewModal } from '@/src/modules/CampaignsModule/components/CampaignsModal';

import { useActionsTableColumns } from './hooks/useActionsTableColumns';
import { useHandleEvents } from './hooks/useHandleEvents';

import {
  atomActionsList,
  atomCurrentActionId,
  atomPagerAction,
  atomSelectedRowsAction,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomLoadingProcess,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { defaultPager } from '@/modules/CampaignsModule/constants';

interface IActionsTableProps {
  stickyHeader?: boolean;
}

const ActionsTable = ({ stickyHeader = false }: IActionsTableProps) => {
  const { t } = useTranslation();

  const actionsList = useAtomValue(atomActionsList);
  const loadingProcess = useAtomValue(atomLoadingProcess);
  const totalsActionList = useAtomValue(atomTotalsProcess);
  const pager = useAtomValue(atomPagerAction);
  const currentActionId = useAtomValue(atomCurrentActionId);
  const setSelectedRows = useSetAtom(atomSelectedRowsAction);

  const { actionsColumns } = useActionsTableColumns();
  const {
    handleCreateActionByCampaign,
    handleClickEmptyScreenNoResult,
    handleRowClick,
    handleChangeTablePage,
  } = useHandleEvents();

  const gotoPageIndex = useMemo(() => (pager?.page ? pager.page - 1 : 0), [pager]);

  const totalRecords = useMemo(
    () => Math.ceil(totalsActionList.partial / (pager?.limit || defaultPager.limit)),
    [pager?.limit, totalsActionList.partial]
  );

  const withPagination = useMemo(
    () => ({
      totalPages: totalRecords,
      fetchData: handleChangeTablePage,
    }),
    [handleChangeTablePage, totalRecords]
  );

  const emptyScreen = useMemo(
    () => ({
      totalCount: totalsActionList.total || 0,
      noResult: (
        <EmptyScreenTable
          actionText={t(
            'CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsActionText'
          )}
          description={t(
            'CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsDescription'
          )}
          id="noResults-actions"
          image={<EmptyContactsIcon />}
          title={t('CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsTitle')}
          onClickAction={handleClickEmptyScreenNoResult}
        />
      ),
      noData: (
        <EmptyScreenTable
          actionText={
            <Text className="text-center !text-14 text-emblue-text" fontWeight="normal">
              {t('CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsDescriptionAlt')}
              <button
                className="cursor-pointer !text-14 font-medium text-emblue-primary"
                data-testid="nodata-actions"
                id="nodata-actions"
                role="link"
                onClick={handleCreateActionByCampaign}
              >
                {t('CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsAction')}
              </button>
            </Text>
          }
          description={t('CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsDescription')}
          image={<EmptyCampaignsIcon />}
          title={t('CAMPAIGN_ACTIONS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsTitle')}
        />
      ),
    }),
    [handleClickEmptyScreenNoResult, t, handleCreateActionByCampaign, totalsActionList]
  );

  if (loadingProcess) {
    return <TableSkeleton columns={7} />;
  }

  return (
    <div className="w-full text-base text-[#364365] 2xl:text-14">
      <Table
        noNegativeMargin
        withCheckbox
        classNamePagination="px-4 mb-2"
        columns={actionsColumns}
        data={actionsList}
        defaultSize={pager?.limit}
        emptyScreen={emptyScreen}
        gotoPageIndex={gotoPageIndex}
        isLoading={loadingProcess}
        lastPageViewed={pager?.page}
        setSelectedRows={setSelectedRows}
        stickyHeader={stickyHeader}
        withPagination={withPagination}
        onRowClick={handleRowClick}
      />
      {currentActionId && <PhonePreviewModal />}
    </div>
  );
};

export default memo(ActionsTable);
