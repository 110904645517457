import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';

import {
  atomCampaignList,
  atomFiltersCampaign,
  atomFiltersDropdownCampaign,
  atomLoadingProcess,
  atomPagerCampaign,
  atomTotalsProcess,
} from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import { EXCLUDE_PAGER_KEYS } from '@/src/modules/CampaignsModule/constants';
import { clearObjectFalsy } from '@/src/modules/CampaignsModule/utils';

import { useCampaignFilters } from './useCampaignFilters';

//TODO: [campaigns] Delete this methods from utils: atomRefetchAllData, getParamsCampaigns,getParamsPager
export const useCampaignData = () => {
  const [filtersCampaign, setFiltersCampaign] = useAtom(atomFiltersCampaign);
  const campaignList = useAtomValue(atomCampaignList);
  const loadingProcess = useAtomValue(atomLoadingProcess);
  const totalsCampaignList = useAtomValue(atomTotalsProcess);
  const setFiltersDropdownCampaign = useSetAtom(atomFiltersDropdownCampaign);
  const setPageParams = useSetAtom(atomPagerCampaign);

  const isInitialMount = useRef(true);

  const { getCampaigns, parseURLParams, resetUnmount } = useCampaignFilters();

  const totalApplyFilters = useMemo(() => {
    return Object.keys(filtersCampaign).filter((key) => !EXCLUDE_PAGER_KEYS.includes(key)).length;
  }, [filtersCampaign]);

  useEffect(() => {
    return () => resetUnmount();
  }, [resetUnmount]);

  useEffect(() => {
    const urlParams = parseURLParams();
    if (isInitialMount.current) {
      const clearFilters = clearObjectFalsy(urlParams);

      setFiltersDropdownCampaign({
        state: clearFilters.state,
        strategy: clearFilters.strategy,
      });

      setFiltersCampaign({ ...filtersCampaign, ...clearFilters });
      setPageParams({ page: urlParams.page || 1, limit: urlParams.limit || 10 });

      isInitialMount.current = false;
      return;
    } else {
      void getCampaigns({
        ...filtersCampaign,
      });
      setPageParams({ page: filtersCampaign.page || 1, limit: urlParams.limit || 10 });
    }
  }, [
    getCampaigns,
    filtersCampaign,
    setPageParams,
    parseURLParams,
    setFiltersCampaign,
    setFiltersDropdownCampaign,
  ]);

  return {
    getCampaigns,
    campaignList,
    loadingProcess,
    totalsCampaignList,
    totalApplyFilters,
  };
};
