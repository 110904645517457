import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ITableButtonProps } from '@/lib/v2/examples/TableToolsBar/components/TableToolsBarButton/TableToolsBarButton';
import {
  DuplicateIconNew,
  EditPencilIconNew,
  MoveIconNew,
  RemoveIconNew,
} from '@/lib/v2/icons/solid';

import { atomSelectedActionsRowsWithID } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomDetailCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomToggleDeleteActionsModal,
  atomToggleDuplicateActionsModal,
  atomToggleEditActionsModal,
  atomToggleMoveActionsModal,
} from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { ECampaignsTypeOptions } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useActionsToolBar = () => {
  const { t } = useTranslation();

  const selectedActionsRowsWithID = useAtomValue(atomSelectedActionsRowsWithID);
  const detailCampaign = useAtomValue(atomDetailCampaign);

  const setToggleMoveActionsModal = useSetAtom(atomToggleMoveActionsModal);
  const setToggleDuplicateActionsModal = useSetAtom(atomToggleDuplicateActionsModal);
  const setToggleDeleteActionsModal = useSetAtom(atomToggleDeleteActionsModal);
  const setToggleEditActionsModal = useSetAtom(atomToggleEditActionsModal);

  const handleDuplicateAction = useCallback(() => {
    setToggleDuplicateActionsModal(true);
  }, [setToggleDuplicateActionsModal]);

  const hasMultipleSelectedRows = useMemo(
    () => selectedActionsRowsWithID.length > 1,
    [selectedActionsRowsWithID.length]
  );
  const isAutomaticCampaign = useMemo(
    () => detailCampaign?.campaignTypeId === ECampaignsTypeOptions.AUTOMATIC,
    [detailCampaign?.campaignTypeId]
  );

  const ICONS_LIST_ACTIONS = useMemo<ITableButtonProps[]>(
    () => [
      {
        icon: <EditPencilIconNew />,
        onClick: () => {
          setToggleEditActionsModal(true);
        },
        tooltip: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipEdit'),
        hide: selectedActionsRowsWithID.length > 1,
        id: 'edit-campaign-action',
        label: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipEdit'),
      },
      {
        icon: <MoveIconNew />,
        onClick: () => {
          setToggleMoveActionsModal(true);
        },
        tooltip: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipMove'),
        hide: false,
        id: 'move-campaign-action',
        label: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipMove'),
      },
      {
        icon: <DuplicateIconNew />,
        onClick: handleDuplicateAction,
        tooltip: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipDuplicate'),
        hide: hasMultipleSelectedRows || isAutomaticCampaign,
        id: 'duplicate-campaign-action',
        label: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipDuplicate'),
      },
      {
        icon: <RemoveIconNew />,
        onClick: () => {
          setToggleDeleteActionsModal(true);
        },
        tooltip: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipDelete'),
        hide: false,
        id: 'delete-campaign-action',
        label: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipDelete'),
      },
    ],
    [
      t,
      selectedActionsRowsWithID.length,
      handleDuplicateAction,
      hasMultipleSelectedRows,
      isAutomaticCampaign,
      setToggleEditActionsModal,
      setToggleMoveActionsModal,
      setToggleDeleteActionsModal,
    ]
  );

  return {
    state: {
      ICONS_LIST_ACTIONS,
    },
  };
};
