import { ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { ServiceMethods } from '@/src/infrastructure/Protocol/EmblueService';
import { ITagsEmblueService } from '@/src/infrastructure/Protocol/ServiceInterfaces';
import { useEmblue } from '@/src/infrastructure/Protocol/useEmblue';

export const useTagCloudList = ({
  identifier,
  search,
  tagsCategoriesFilter,
  tagsDataSourceFilter,
  lastRefresh,
}: ITagsEmblueService): [
  (
    | {
        list: { tag: ITag }[];
        count: number;
      }
    | undefined
  ),
  Error | undefined,
  boolean | undefined
] => {
  const [tagCloudListValue, tagCloudError, isLoading] = useEmblue(ServiceMethods.getTagCloudInfo, {
    identifier,
    search,
    tagsCategoriesFilter,
    tagsDataSourceFilter,
    lastRefresh,
  });

  return [tagCloudListValue, tagCloudError, isLoading];
};
