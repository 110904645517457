import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

import { atomFiltersAction } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { EXCLUDE_PAGER_KEYS } from '@/modules/CampaignsModule/constants';
import { useActionsFilters } from '@/modules/CampaignsModule/hooks/useActionsFilters';

interface resetButtonProps {
  resets: Array<() => void>;
}

export const useResetButton = ({ resets }: resetButtonProps) => {
  const filtersAction = useAtomValue(atomFiltersAction);
  const { resetFilters } = useActionsFilters();

  const filteredKeys = useMemo(() => {
    return Object.keys(filtersAction).filter((key) => !EXCLUDE_PAGER_KEYS.includes(key));
  }, [filtersAction]);

  const buttonDisabled = useMemo(() => filteredKeys.length === 0, [filteredKeys]);

  const handleClickReset = useCallback(() => {
    resets.forEach((reset) => reset());
    resetFilters();
  }, [resets, resetFilters]);

  return {
    state: {
      buttonDisabled,
    },
    events: {
      handleClickReset,
    },
  };
};
