import classNames from 'classnames';
import { memo, ReactElement, useEffect, useRef, useState } from 'react';

import { TableFilters } from './components/TableFilters';
import { TableTools } from './components/TableTools';

type TableChild = ReactElement<typeof TableTools> | ReactElement<typeof TableFilters>;

interface TableControlPanelProps {
  children: TableChild | TableChild[];
  isSticky?: boolean;
}

const TableControlPanel = ({ children, isSticky = false }: TableControlPanelProps) => {
  const childrenArray = Array.isArray(children) ? children : [children];
  childrenArray.forEach((child) => {
    if (child.type !== TableTools && child.type !== TableFilters) {
      throw new Error(
        `Invalid child type provided: ${child?.type?.toString()}. Only TableTools or TableFilters are allowed.`
      );
    }
  });

  const stickyRef = useRef<HTMLDivElement>(null);
  const [isStickyContainer, setIsStickyContainer] = useState(false);

  useEffect(() => {
    const cachedRef = stickyRef.current;
    if (!cachedRef) return;

    const observer = new IntersectionObserver(
      ([e]) => setIsStickyContainer(e.boundingClientRect.top <= 0),
      { threshold: [1] }
    );

    observer.observe(cachedRef);

    const handleScroll = () => {
      const stickyTop = cachedRef.getBoundingClientRect().top;
      setIsStickyContainer(stickyTop <= 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      observer.unobserve(cachedRef);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const classesSticky = classNames(
    'flex w-full flex-col justify-center px-4 bg-white transition-all',
    {
      'sticky top-0 z-[2]': isSticky,
      '!h-16': !isStickyContainer,
      '!h-14': isStickyContainer,
    }
  );

  return (
    <div ref={stickyRef} className={classesSticky}>
      {children}
    </div>
  );
};

TableControlPanel.Tools = TableTools;
TableControlPanel.Filters = TableFilters;

const MemoizedTableControlPanel = memo(TableControlPanel) as unknown as typeof TableControlPanel & {
  Tools: typeof TableTools;
  Filters: typeof TableFilters;
};

MemoizedTableControlPanel.Tools = TableTools;
MemoizedTableControlPanel.Filters = TableFilters;

export default MemoizedTableControlPanel;
