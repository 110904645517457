import { useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { atomToggleMoveActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaign } from '@/modules/CampaignsModule/hooks/useCampaigns';
import { ICampaignListPayload } from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useMoveActions = () => {
  const { t } = useTranslation();

  const toggleMoveActionsModal = useAtomValue(atomToggleMoveActionsModal);

  const { getListAll, loading: loadingCampaign } = useCampaign();

  useEffect(() => {
    void (async () => {
      if (toggleMoveActionsModal) {
        const payload: ICampaignListPayload = { sortField: 'name', sortOrder: 'asc' };
        await getListAll(payload);
      }
    })();
  }, [getListAll, toggleMoveActionsModal]);

  const VALIDATION_RULES = useMemo(
    () => ({
      campaigns: {
        required: {
          value: true,
          message: t('FORMS_RULES_MESSAGES.required'),
        },
      },
    }),
    [t]
  );

  return {
    loadingCampaign,
    VALIDATION_RULES,
  };
};
