import { IEntityContentJson, IPluginRow } from '@mailupinc/bee-plugin/dist/types/bee';

import { ICategory, ICollection, ITag } from '@/src/ContactsModule/interfaces/Interfaces';
import { APIService } from '@/src/infrastructure/Protocol/APIService';
import { ICustomField } from '@/src/modules/CampaignsModule/hooks/useCustomFields';

export interface IAuthBeefree {
  client_id: string;
  client_secret: string;
  grant_type: string;
}

export interface IAuthBeefreeResponse {
  [key: string]: any;
}

export interface IBeefreeParamStart {
  api: APIService;
  jwt: string;
  url_ema_app_v1: string;
  url_ema_app_v2: string;
}

export interface IBeefreeMailPayload {
  actionId: number;
  accountAutoId: number;
  defaultTemplateId: number;
  contentHtml: string;
  contentHtmlEditable: string;
  contentCss: string;
  type: string;
}

export enum IBeefreeRowType {
  EMBLUE = 'emblue',
  USER = 'user',
}

export enum ISaveRowError {
  NAME_ALREADY_EXISTS = 'NAME_ALREADY_EXISTS',
}

export interface IBeefreeRowPayload {
  name: string;
  json: string;
  type: string;
}
// cspell:disable
export interface IBeefreeSendResponse {
  content: null;
  id_notification:
    | 'ELEMENTO_CONFIRMADO_OK'
    | 'ELEMENTO_CONFIRMADO_KO'
    | 'ELEMENTO_CONFIRMADO_KO_NO_CONFIRMABLE';
}

export interface IBeefreeSaveLinks {
  modifiedLinks: IModifiedLink[];
  LinksTemporal?: INewLink[];
  newLinks: INewLink[];
}

interface IModifiedLink {
  linkId: string;
  nombre: string;
}

export interface INewLink {
  linkTempId: string;
  linkId: number;
  nombre: string;
  url: string;
}

export interface IBeefreeSaveLinksResponse {
  content: string | null;
  id_notification: 'LINKS_MODIFICADOS_OK' | 'LINKS_MODIFICADOS_KO';
}

export interface IBeefreeSaveLinksResponseContent {
  ModifiedLinks: IModifiedLink[];
  NewLinks: INewLink[];
}

export interface IBeefreePreviewResponseV1 {
  elementoId: number;
  elementoEnvioId: number;
  emailFrom: string;
  emailReply: string;
  nombreFromId: number;
  nameFrom: string;
  remarketing: boolean;
  asunto: string;
  mensaje: string;
  header: string;
  footer: string;
  origenId: number;
  tieneHistorial: boolean;
  errores: string;
}

// cspell:enable

export interface IBeefreePreviewResponse {
  id: number;
  sendId: number;
  emailFrom: string;
  emailReply: string;
  fromId: number;
  name: string;
  remarketing: boolean;
  subject: string;
  message: string;
  header: string;
  footer: string;
  origenId: number;
  history: boolean;
  errors: string;
}

export interface IBeefreeSaveTemplatePayload {
  jsonData: string;
  htmlFile: string;
  name: string;
  tags: number[];
  categories: number[];
  collection: number;
  designer: string | number;
  version: string;
  createdBy: number;
}

export interface IBeefreeSaveTemplateResponse {
  error?: string;
  type?: string;
  success?: boolean;
}

export interface IEmblueCatalogTemplatesResponse {
  data: IEmailOfCatalog[];
  pagination: {
    page: number;
    total: number;
  };
}

export interface IEmblueMyCatalogTemplatesResponse {
  data: IEmailOfCatalog[];
  pagination: {
    page: number;
    total: number;
  };
}
export interface IBeefreePartialSaveTemplatePayload
  extends Pick<IBeefreeSaveTemplatePayload, 'name'> {
  collection: ICollection;
  asEmblueTemplate: boolean;
}
export interface IBeefreeSaveResponse {
  [key: string]: any;
}

export interface IBeefreeCatalogTagsResponse {
  data: ITag[];
}

export interface IBeefreeCatalogCollectionsResponse {
  data: ICollection[];
}

export interface IBeefreeCatalog {
  count: number;
  results: IEmailOfCatalog[];
}
export interface IBeefreeCatalogCategoriesResponse {
  data: ICategory[];
}
export interface IEmailOfCatalog {
  catalogId?: number;
  categories: string[];
  collection: string;
  description: string;
  designer: IDesigner | null;
  htmlData: string;
  htmlUrl: string;
  id: number;
  isBlank: boolean;
  jsonData: IEntityContentJson;
  order: string;
  publishedAt: string;
  tags: string[];
  templateType: string;
  thumbnailLarge: string;
  thumbnail: string;
  title: string;
}

export interface IDesigner {
  avatarUrl: string;
  description: string;
  displayName: string;
  id: string;
}

export type IEmblueRowsResponse = IPluginRow[];

export type ISaveMail = (jsonFile: string, htmlFile: string) => Promise<boolean>;

export interface ISpecialLink {
  type: string;
  label: string;
  link: string;
}

export interface IRowSaveData {
  name: string;
  isEmblueRow: boolean;
}
export interface IUseEditor {
  onSaveRow: (data: IRowSaveData, row: IPluginRow) => Promise<boolean>;
  onSaveMail: (jsonFile: string, htmlFile: string) => Promise<boolean>;
  onSend: () => void;
  onSaveTemplate: (pageJson: string, templateVersion: number) => void;
  onRemoveRow: (id: string) => Promise<void>;
  tags: ITag[] | null;
  accountId: number;
  listCustomField: ICustomField[];
  signature: ISpecialLink[];
}

export type BeefreeLanguages = 'es' | 'en' | 'pt';

export interface IUseServicesEditor {
  actionId: number;
  accountId: number;
  listCustomField: ICustomField[];
  tags: ITag[] | null;
  signature: ISpecialLink[];
}
