import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';

import {
  atomModalTypeProcess,
  atomToggleDeleteModal,
  atomToggleDuplicateRecurrentModal,
  atomToggleEditCreateRecurrentModal,
} from '@/src/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { ModalType } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

export const useRecurrentModal = () => {
  const setModalTypeProcess = useSetAtom(atomModalTypeProcess);
  const [toggleEditCreateRecurrentModal, setToggleEditCreateRecurrentModal] = useAtom(
    atomToggleEditCreateRecurrentModal
  );
  const setToggleDuplicateRecurrentModal = useSetAtom(atomToggleDuplicateRecurrentModal);
  const toggleDeleteModal = useAtomValue(atomToggleDeleteModal);

  const handleOnOpenModal = useCallback(() => {
    setModalTypeProcess(ModalType.CREATE);
    setToggleEditCreateRecurrentModal(true);
  }, [setModalTypeProcess, setToggleEditCreateRecurrentModal]);

  const handleOnCloseModal = useCallback(() => {
    setToggleEditCreateRecurrentModal(false);
  }, [setToggleEditCreateRecurrentModal]);

  const handleOnOpenDuplicateModal = useCallback(() => {
    setModalTypeProcess(ModalType.DUPLICATE);
    setToggleDuplicateRecurrentModal(true);
  }, [setModalTypeProcess, setToggleDuplicateRecurrentModal]);

  const handleOnCloseDuplicateModal = useCallback(
    () => setToggleDuplicateRecurrentModal(false),
    [setToggleDuplicateRecurrentModal]
  );

  return {
    handleOnOpenModal,
    handleOnCloseModal,
    toggleDeleteModal,
    toggleEditCreateRecurrentModal,
    handleOnCloseDuplicateModal,
    handleOnOpenDuplicateModal,
  };
};
