import { memo, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Alert, Button, Input } from '@/lib/v2/components';

import { ValidatePasswordInformation } from '@/src/modules/AuthModule/components';
import HeaderLogin from '@/src/modules/AuthModule/components/HeaderLogin';
import { useLogin } from '@/src/modules/AuthModule/hooks/useLogin';
import { usePasswordValidation } from '@/src/modules/AuthModule/hooks/usePasswordValidation';
import { FormChangePassword } from '@/src/modules/AuthModule/types/Auth';

const ChangePassword = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormChangePassword>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
  });

  const {
    passwordErrors,
    isFocusPasswordInput,
    passwordRulesAreValid,
    handleOnChangePassword,
    handleFocusPasswordInput,
    handleBlurPasswordInput,
    CHANGE_RULES,
  } = usePasswordValidation(setValue, watch);

  /** cspell:disable */
  const userId = searchParams.get('usuarioId');
  const token = searchParams.get('token');
  //** cspell:enable */

  const { error, isLoading, changePassword, setError, checkToken } = useLogin();

  const onSubmitChangePassword: SubmitHandler<FormChangePassword> = useCallback(
    (data) => {
      if (!passwordRulesAreValid || !token || !userId) return;
      const { password, repeatPassword } = data;
      if (password === repeatPassword) {
        void changePassword(password, token, userId);
      } else {
        setError(t('AUTH.CHANGE_PASSWORD.passwordsDoNotMatch'));
      }
    },
    [changePassword, passwordRulesAreValid, setError, t, token, userId]
  );

  useEffect(() => {
    function fetchData() {
      //if (token && userId) await checkToken(token, userId);
      if (token && userId) console.log('checkToken', token, userId);
    }

    if (token && userId) void fetchData();
  }, [checkToken, token, userId]);

  return (
    <div className="flex size-full flex-col items-center justify-center gap-4 text-center">
      <HeaderLogin
        subTitle={t('AUTH.CHANGE_PASSWORD.subtitle')}
        title={t('AUTH.CHANGE_PASSWORD.title')}
      />
      <form className="flex w-full justify-center" onSubmit={handleSubmit(onSubmitChangePassword)}>
        <div className="relative flex w-full flex-col items-center gap-4 sm:w-[300px]">
          <ValidatePasswordInformation
            isShow={isFocusPasswordInput}
            passwordRules={passwordErrors}
          />
          <Input
            {...register('password', CHANGE_RULES.password)}
            error={errors?.password && true}
            id="password-input"
            label={t('AUTH.CHANGE_PASSWORD.newPassword')}
            message={errors?.password?.message}
            placeHolder="ej. JohnC@rt$32,#"
            type="password"
            onBlur={handleBlurPasswordInput}
            onChange={handleOnChangePassword}
            onFocus={handleFocusPasswordInput}
          />
          <Input
            {...register('repeatPassword', CHANGE_RULES.repeatPassword)}
            error={errors?.repeatPassword && true}
            id="repeat-password-input"
            label={t('AUTH.CHANGE_PASSWORD.confirmPassword')}
            message={errors?.repeatPassword?.message}
            placeHolder="ej. JohnC@rt$32,#"
            type="password"
          />
          {error && <p className={'message-error !text-center'}>{error}</p>}
          <Button
            fullWidth
            disabled={!passwordRulesAreValid}
            id="change-password-button"
            isLoading={isLoading}
            type="submit"
          >
            {t('AUTH.CHANGE_PASSWORD.create')}
          </Button>
          <Alert
            description={t('AUTH.CHANGE_PASSWORD.ALERT.description')}
            title={t('AUTH.CHANGE_PASSWORD.ALERT.title')}
            type="warning"
          />
        </div>
      </form>
    </div>
  );
};

export default memo(ChangePassword);
