import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  atomActionInfoEmail,
  atomProcessingAction,
} from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { haveMessageAtom } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';
import { useActionEmailData } from '@/modules/CampaignsModule/hooks/useActionEmailData';
import { atomRuleData } from '@/modules/RulesModule/atoms/rules';
import { useSaveDataRules } from '@/modules/RulesModule/components/FormRule/hooks/useSaveDataRules';
import { useResetRulesState } from '@/modules/RulesModule/hooks/useResetRulesState';
import { IRulesEmailFormInfo, IRulesEmailInfoPayload } from '@/modules/RulesModule/interfaces';

export const useRulesEmailFormSubmit = () => {
  const navigate = useNavigate();
  const { campaignId, actionId, actionType } = useParams();

  const actionInfoEmail = useAtomValue(atomActionInfoEmail);
  const setHaveMessage = useSetAtom(haveMessageAtom);
  const setProcessingAction = useSetAtom(atomProcessingAction);

  const { saveInfoAction } = useActionEmailData(Number(actionId));
  const { saveRule } = useSaveDataRules();
  const ruleData = useAtomValue(atomRuleData);

  const { refetchData } = useResetRulesState();

  const rulePath = useMemo(() => {
    return `${CAMPAIGNS_PATH}/${String(campaignId)}/${actionType}/${String(actionId)}`;
  }, [actionId, campaignId, actionType]);

  const handleSubmitData = useCallback(
    async (data: IRulesEmailFormInfo) => {
      if (!actionInfoEmail?.content?.message) {
        setHaveMessage(true);
        return;
      }

      setProcessingAction(true);

      const { sender, subject, firstLine } = data;

      const payload: IRulesEmailInfoPayload = {
        senderId: Number(sender.id),
        subject: subject,
        firstLine: firstLine,
      };

      await saveInfoAction(payload);
      await saveRule(ruleData);

      refetchData();

      navigate(`${rulePath}`);
      setProcessingAction(false);
    },
    [
      actionInfoEmail?.content?.message,
      navigate,
      refetchData,
      ruleData,
      rulePath,
      saveInfoAction,
      saveRule,
      setHaveMessage,
      setProcessingAction,
    ]
  );

  return {
    handleSubmitData,
  };
};
