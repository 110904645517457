import { memo, ReactNode } from 'react';

interface TableFiltersProps {
  children: ReactNode;
}

const TableFilters = ({ children }: TableFiltersProps) => {
  return <div className="flex">{children}</div>;
};

export default memo(TableFilters);
