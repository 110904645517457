import { Outlet } from 'react-router-dom';

import { CampaignActionModals } from '@/src/modules/CampaignsModule/components/CampaignActionModals';

const CampaignActionsLayout = () => {
  return (
    <>
      <Outlet />
      <CampaignActionModals options={['campaigns', 'actions']} />
    </>
  );
};

export default CampaignActionsLayout;
