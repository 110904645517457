export const MAX_LENGTH_WHATSAPP = 1024;
export const URL_REGEX = /https?:\/\/\S+/g;
export const URL_TO_REPLACE = 'https://w.eulb.me/xxxxxxxx';

export const TEMPLATE_OPTION = {
  IDLE: 'idle',
  SELECT: 'select',
  CREATE: 'create',
} as const;

export type TemplateOption = (typeof TEMPLATE_OPTION)[keyof typeof TEMPLATE_OPTION];
