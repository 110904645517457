import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

import { atomFiltersRecurrentAction } from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';
import { EXCLUDE_PAGER_KEYS } from '@/modules/CampaignsModule/constants';
import { useRecurrentActionFilters } from '@/modules/CampaignsModule/hooks/useRecurrentActionFilters';

interface resetButtonProps {
  resets: Array<() => void>;
}

export const useResetButton = ({ resets }: resetButtonProps) => {
  const filtersRecurrentAction = useAtomValue(atomFiltersRecurrentAction);
  const { resetFilters } = useRecurrentActionFilters();

  const filteredKeys = useMemo(() => {
    return Object.keys(filtersRecurrentAction).filter((key) => !EXCLUDE_PAGER_KEYS.includes(key));
  }, [filtersRecurrentAction]);

  const buttonDisabled = useMemo(() => filteredKeys.length === 0, [filteredKeys]);

  const handleClickReset = useCallback(() => {
    resets.forEach((reset) => reset());
    resetFilters();
  }, [resets, resetFilters]);

  return {
    state: {
      buttonDisabled,
    },
    events: {
      handleClickReset,
    },
  };
};
