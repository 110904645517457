import { FieldErrors } from 'react-hook-form';

import GroupContacts from '@/modules/RulesModule/components/FormRule/components/GroupContacts';
import RepeatedEmails from '@/modules/RulesModule/components/FormRule/components/RepeatedEmails';
import { IFormProps, IRuleForm } from '@/modules/RulesModule/interfaces';

interface IFormApiProps {
  errors: FieldErrors<IRuleForm>;
  form: IFormProps<IRuleForm>;
  disabled?: boolean;
}
const FormApi = ({ form, errors, disabled = false }: IFormApiProps) => {
  return (
    <>
      <RepeatedEmails disabled={disabled} form={form} />
      <GroupContacts disabled={disabled} errors={errors} form={form} />
    </>
  );
};

export default FormApi;
