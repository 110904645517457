import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { atomDetailCampaign } from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomModalTypeProcess,
  atomScreenTypeProcess,
  atomToggleDeleteModal,
  atomToggleEditCreateCampaignModal,
} from '@/src/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { CAMPAIGNS_PATH } from '@/src/modules/CampaignsModule/constants';
import { ModalType, ScreenType } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

export const useCampaignModal = () => {
  const navigate = useNavigate();

  const [modalTypeProcess, setModalTypeProcess] = useAtom(atomModalTypeProcess);
  const [screenTypeProcess, setScreenTypeProcess] = useAtom(atomScreenTypeProcess);
  const [toggleEditCreateCampaignModal, setToggleEditCreateCampaignModal] = useAtom(
    atomToggleEditCreateCampaignModal
  );
  const setDetailCampaign = useSetAtom(atomDetailCampaign);
  const toggleDeleteModal = useAtomValue(atomToggleDeleteModal);

  const handleOnOpenModal = useCallback(() => {
    setModalTypeProcess(ModalType.CREATE);
    setToggleEditCreateCampaignModal(!toggleEditCreateCampaignModal);
  }, [setModalTypeProcess, setToggleEditCreateCampaignModal, toggleEditCreateCampaignModal]);

  const handleOnCloseModal = useCallback(() => {
    setToggleEditCreateCampaignModal(false);

    if (modalTypeProcess === ModalType.EDIT && screenTypeProcess === ScreenType.MAIN) {
      setDetailCampaign(RESET);
      setModalTypeProcess(RESET);
      setScreenTypeProcess(RESET);
    }
  }, [
    setDetailCampaign,
    setModalTypeProcess,
    setToggleEditCreateCampaignModal,
    modalTypeProcess,
    screenTypeProcess,
    setScreenTypeProcess,
  ]);

  const handleLinkCalendar = useCallback(() => {
    navigate(`${CAMPAIGNS_PATH}/calendar`);
  }, [navigate]);

  return {
    handleOnOpenModal,
    handleOnCloseModal,
    handleLinkCalendar,
    toggleDeleteModal,
    toggleEditCreateCampaignModal,
  };
};
