import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

import {
  WhatsAppCTATemplate,
  WhatsAppMediaTemplate,
  WhatsAppTemplate,
  WhatsAppTemplateTypes,
  WhatsAppTextTemplate,
} from '@/src/infrastructure/Protocol/Inbox/IInboxService';

import { atomWhatsAppTemplates } from '@/modules/ContactsModule/atoms/WhatsApp';

interface UseFilteredTemplatesParams {
  filterBy?: 'pending' | 'approved' | 'rejected';
  searchTerm?: string;
}

export const useFilteredTemplates = ({ filterBy, searchTerm = '' }: UseFilteredTemplatesParams) => {
  const templates = useAtomValue(atomWhatsAppTemplates);

  const getTemplateContent = (template: WhatsAppTemplate) => {
    const templateType = Object.keys(template.types)[0] as keyof WhatsAppTemplateTypes;
    const templateData = template.types[templateType] as
      | WhatsAppTextTemplate
      | WhatsAppMediaTemplate
      | WhatsAppCTATemplate;

    if (!templateData) return '';

    switch (templateType) {
      case 'twilio/media': {
        const mediaTemplate = templateData as WhatsAppMediaTemplate;
        return `${mediaTemplate.body} ${mediaTemplate.media.join(' ')}`;
      }
      case 'twilio/call-to-action': {
        const ctaTemplate = templateData as WhatsAppCTATemplate;
        const actions = ctaTemplate.actions
          .map((action) => `${action.title} ${action.url || action.phone || ''}`)
          .join(' ');
        return `${ctaTemplate.body} ${actions}`;
      }
      case 'twilio/text':
        return templateData.body;
      default:
        return '';
    }
  };

  const filteredTemplates = useMemo(() => {
    let filtered = [...templates];

    if (filterBy) {
      filtered = filtered.filter(
        (template) => template.approvalStatus?.toLowerCase() === filterBy.toLowerCase()
      );
    }

    if (searchTerm) {
      filtered = filtered.filter((template) => {
        const searchIn = `${template.friendly_name} ${getTemplateContent(template)} ${
          template.sid
        }`.toLowerCase();
        return searchIn.includes(searchTerm.toLowerCase());
      });
    }

    return filtered;
  }, [templates, filterBy, searchTerm]);

  return { filteredTemplates };
};
