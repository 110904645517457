import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { RulesOptions, RuleTypes } from '@/src/modules/RulesModule/interfaces';

import { atomIsTriggerFormComplete } from '@/modules/RulesModule/atoms/rules';

const checkIsTriggerFormComplete = (rulesData: RulesOptions): boolean => {
  if (!rulesData) return false;

  const {
    trigger,
    customField,
    frequency,
    execution,
    days,
    period,
    hours,
    minutes,
    redirectSuccess,
    destinationSuccessUrl,
    redirectError,
    destinationErrorUrl,
    tagTypeField,
    tagsGroupField,
  } = rulesData;

  if (trigger?.value === RuleTypes.DATE) {
    if (execution?.id === 1) {
      return Boolean(
        trigger?.value &&
          customField?.value &&
          frequency?.value &&
          execution?.value &&
          hours?.value &&
          minutes?.value
      );
    }

    return Boolean(
      trigger?.value &&
        customField?.value &&
        frequency?.value &&
        execution?.value &&
        days?.value &&
        period?.value &&
        hours?.value &&
        minutes?.value
    );
  }

  if (trigger?.value === RuleTypes.TAG) {
    return Boolean(
      trigger?.value &&
        (tagTypeField?.value || tagsGroupField?.length) &&
        frequency?.value &&
        hours?.value &&
        minutes?.value
    );
  }

  if (trigger?.value === RuleTypes.EXTERNAL_CALL) {
    return Boolean(trigger?.value && redirectSuccess?.value && redirectError?.value);
  }

  if (trigger?.value === RuleTypes.API_INTEGRATOR || trigger?.value === RuleTypes.FLOW_TEMPLATE) {
    return true;
  }

  return false;
};

const useIsTriggerFormComplete = (rulesData: RulesOptions) => {
  const setIsTriggerFormComplete = useSetAtom(atomIsTriggerFormComplete);

  useEffect(() => {
    const isComplete = checkIsTriggerFormComplete(rulesData);
    setIsTriggerFormComplete(isComplete);
  }, [rulesData, setIsTriggerFormComplete]);
};

export default useIsTriggerFormComplete;
