/* cspell: disable*/

export const NewContactsIcon = () => {
  return (
    <svg fill="none" height="25" viewBox="0 0 26 25" width="26" xmlns="http://www.w3.org/2000/svg">
      <g id="Grupo 9229">
        <g id="Grupo 9230">
          <path
            d="M10.6488 13.438C14.1033 13.438 16.9038 10.638 16.9038 7.18399C16.9038 3.73 14.1033 0.929993 10.6488 0.929993C7.19426 0.929993 4.3938 3.73 4.3938 7.18399C4.3938 10.638 7.19426 13.438 10.6488 13.438Z"
            fill="#18C678"
            id="Elipse 1695"
          />
          <path
            d="M24.5168 17.698H21.7609V14.941C21.7603 14.6192 21.6322 14.3107 21.4047 14.0832C21.1771 13.8556 20.8686 13.7275 20.5468 13.727C20.2261 13.7311 19.9198 13.8604 19.693 14.0872C19.4662 14.3139 19.337 14.6203 19.3328 14.941V17.698H16.5759C16.2539 17.6983 15.9451 17.8262 15.7173 18.0538C15.4896 18.2814 15.3614 18.59 15.3608 18.912C15.365 19.2327 15.4942 19.5391 15.721 19.7658C15.9478 19.9926 16.2541 20.1218 16.5748 20.126H19.3318V22.883C19.3324 23.2048 19.4604 23.5133 19.688 23.7408C19.9155 23.9684 20.224 24.0965 20.5458 24.097C20.8665 24.0929 21.1729 23.9636 21.3997 23.7368C21.6264 23.5101 21.7557 23.2037 21.7598 22.883V20.127H24.5168C24.8388 20.127 25.1476 19.9991 25.3752 19.7714C25.6029 19.5438 25.7308 19.235 25.7308 18.913C25.7308 18.591 25.6029 18.2822 25.3752 18.0546C25.1476 17.8269 24.8388 17.698 24.5168 17.698Z"
            fill="#18C678"
            id="Trazado 3800"
          />
          <g id="Grupo 6433">
            <path
              d="M17.2739 22.882V22.182H16.5739C15.7063 22.182 14.8744 21.8374 14.2609 21.2239C13.6475 20.6105 13.3029 19.7785 13.3029 18.911C13.3029 18.0435 13.6475 17.2115 14.2609 16.598C14.8744 15.9846 15.7063 15.64 16.5739 15.64H17.2739V14.94C17.2704 14.8436 17.2771 14.747 17.2939 14.652C16.8933 14.2573 16.4582 13.8992 15.9938 13.582C15.8564 13.4914 15.6955 13.4431 15.5309 13.4431C15.3663 13.4431 15.2053 13.4914 15.0679 13.582C13.7756 14.4909 12.2342 14.9786 10.6544 14.9786C9.0745 14.9786 7.53313 14.4909 6.24086 13.582C6.10556 13.486 5.94375 13.4344 5.77785 13.4344C5.61194 13.4344 5.45019 13.486 5.31489 13.582C1.83789 15.907 0.849867 20.412 0.849867 20.412C0.849867 20.412 -0.0551133 24.112 3.60689 24.112H17.5568C17.3582 23.7332 17.2608 23.3095 17.2739 22.882Z"
              fill="#18C678"
              id="Trazado 3801"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
