import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from '@/lib/v2/components';

import { ECustomFieldsByType } from '@/src/application/hooks/interfaces/ICustomField';
import { useCustomField } from '@/src/application/hooks/useCustomField';

import {
  TAG_TYPE_FIELD_OPTIONS,
  TRANSLATE_RULES_EXECUTION_OPTIONS,
  TRANSLATE_RULES_FREQUENCY_OPTIONS,
  TRANSLATE_RULES_PERIOD_OPTIONS,
  TRANSLATE_RULES_REDIRECT_PAGE_OPTIONS,
  TRANSLATE_RULES_REPEATED_EMAIL_OPTIONS,
  TRANSLATE_RULES_TAG_FREQUENCY_OPTIONS,
  TRANSLATE_RULES_TAG_TYPE_OPTIONS,
  TRANSLATE_RULES_TRIGGER_OPTIONS,
} from '@/modules/RulesModule/constants';
import { DataTypeCustomField } from '@/modules/RulesModule/interfaces';

/* cspell:disable*/
export const useRuleDefaultOptions = () => {
  const { t } = useTranslation('rules');

  const {
    events: { getCustomFieldsByType, translateCustomField },
  } = useCustomField();

  const triggerOptions = useMemo(
    () => [
      {
        id: 'fechadesencadenante',
        name: `${t(`${TRANSLATE_RULES_TRIGGER_OPTIONS}.fechadesencadenante`)}`,
        value: 'Fecha desencadenante',
        disabled: false,
      },
      {
        id: 'tagasociado',
        name: `${t(`${TRANSLATE_RULES_TRIGGER_OPTIONS}.tagasociado`)}`,
        value: 'Tag asociado',
        disabled: false,
      },
      {
        id: 'llamadaexternaaunaurl',
        name: `${t(`${TRANSLATE_RULES_TRIGGER_OPTIONS}.llamadaexternaaunaurl`)}`,
        value: 'Llamada externa a una URL',
        disabled: false,
      },
      {
        id: 'apiintegrador',
        name: `${t(`${TRANSLATE_RULES_TRIGGER_OPTIONS}.apiintegrador`)}`,
        value: 'Api Integrador',
        disabled: false,
      },
      {
        id: 'formulario',
        name: `${t(`${TRANSLATE_RULES_TRIGGER_OPTIONS}.formulario`)}`,
        value: 'Formulario',
        disabled: true,
      },
      {
        id: 'flows',
        name: `${t(`${TRANSLATE_RULES_TRIGGER_OPTIONS}.flows`)}`,
        value: 'Flows',
        disabled: false,
      },
    ],
    [t]
  );

  const sortedTriggerOptions = useMemo(() => {
    return triggerOptions.slice().sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }, [triggerOptions]);

  const frequencyOptions = useMemo(
    () => [
      { id: 'anio', name: `${t(`${TRANSLATE_RULES_FREQUENCY_OPTIONS}.anio`)}`, value: 'anio' },
      { id: 'mes', name: `${t(`${TRANSLATE_RULES_FREQUENCY_OPTIONS}.mes`)}`, value: 'mes' },
      { id: 'dia', name: `${t(`${TRANSLATE_RULES_FREQUENCY_OPTIONS}.dia`)}`, value: 'dia' },
    ],
    [t]
  );

  const tagFrequencyOptions = useMemo(
    () => [
      { id: 'day', name: `${t(`${TRANSLATE_RULES_TAG_FREQUENCY_OPTIONS}.day`)}`, value: '1' },
      { id: 'week', name: `${t(`${TRANSLATE_RULES_TAG_FREQUENCY_OPTIONS}.week`)}`, value: '7' },
      { id: 'month', name: `${t(`${TRANSLATE_RULES_TAG_FREQUENCY_OPTIONS}.month`)}`, value: '30' },
    ],
    [t]
  );

  const executionOptions = useMemo(
    () => [
      { id: 1, name: t(`${TRANSLATE_RULES_EXECUTION_OPTIONS}.sameDay`), value: 'mismo' },
      { id: 2, name: t(`${TRANSLATE_RULES_EXECUTION_OPTIONS}.before`), value: 'antes' },
      { id: 3, name: t(`${TRANSLATE_RULES_EXECUTION_OPTIONS}.after`), value: 'despues' },
    ],
    [t]
  );

  const periodOptions = useMemo(
    () => [
      { id: 'dia', name: `${t(`${TRANSLATE_RULES_PERIOD_OPTIONS}.dia`)}`, value: 'dia' },
      { id: 'mes', name: `${t(`${TRANSLATE_RULES_PERIOD_OPTIONS}.mes`)}`, value: 'mes' },
    ],
    [t]
  );

  const hourOptions = useMemo(
    () =>
      Array.from({ length: 24 }, (_, i) => ({
        id: i,
        name: i.toString().padStart(2, '0'),
        value: i.toString().padStart(2, '0'),
      })),
    []
  );

  const minuteOptions = useMemo(
    () =>
      Array.from({ length: 12 }, (_, i) => ({
        id: i * 5,
        name: (i * 5).toString().padStart(2, '0'),
        value: (i * 5).toString().padStart(2, '0'),
      })),
    []
  );

  const dayOptions = useMemo(
    () =>
      Array.from({ length: 31 }, (_, i) => ({
        id: (i + 1).toString(),
        name: (i + 1).toString(),
        value: (i + 1).toString(),
      })),
    []
  );

  const customFieldOptions = useMemo(() => {
    const fields = getCustomFieldsByType({ type: ECustomFieldsByType.ALL });
    const filteredFieldsByTypeDate = fields.filter(
      (field) => field.type === DataTypeCustomField.DATE
    );

    return filteredFieldsByTypeDate.map(
      (field): Option => ({
        id: field.id,
        name: translateCustomField(field.name).toLocaleLowerCase(),
        value: field.id,
      })
    );
  }, [getCustomFieldsByType, translateCustomField]);

  const repeatEmailsOptions = useMemo(
    () => [
      { id: 0, name: t(`${TRANSLATE_RULES_REPEATED_EMAIL_OPTIONS}.once`), value: false },
      { id: 1, name: t(`${TRANSLATE_RULES_REPEATED_EMAIL_OPTIONS}.always`), value: true },
    ],
    [t]
  );

  const redirectPageOptions = useMemo(
    () => [
      {
        id: 0,
        name: t(`${TRANSLATE_RULES_REDIRECT_PAGE_OPTIONS}.páginapredeterminadadeemblue`),
        value: 'Página predeterminada de emBlue',
      },
      {
        id: 1,
        name: t(`${TRANSLATE_RULES_REDIRECT_PAGE_OPTIONS}.urlexterna`),
        value: 'URL externa',
      },
    ],
    [t]
  );

  const tagTypeOptions = useMemo(
    () => [
      {
        id: '1',
        name: t(`${TRANSLATE_RULES_TAG_TYPE_OPTIONS}.anyTag`),
        value: TAG_TYPE_FIELD_OPTIONS.ANY_TAG,
      },
      {
        id: '2',
        name: t(`${TRANSLATE_RULES_TAG_TYPE_OPTIONS}.groupTags`),
        value: TAG_TYPE_FIELD_OPTIONS.GROUP_TAGS,
      },
    ],
    [t]
  );

  return {
    triggerOptions: sortedTriggerOptions,
    customFieldOptions,
    frequencyOptions,
    tagFrequencyOptions,
    executionOptions,
    periodOptions,
    hourOptions,
    minuteOptions,
    dayOptions,
    repeatEmailsOptions,
    redirectPageOptions,
    tagTypeOptions,
  };
};
