import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { formValidations } from '@/src/constants/FormValidations';
import { atomActionDragDrop } from '@/src/modules/CampaignsModule/atoms/recurrentActionsAtom';
import {
  TRANSLATE_MODAL_DUPLICATE,
  WHATSAPP_PREFIX,
} from '@/src/modules/CampaignsModule/constants';

import { useActionsFilters } from './useActionsFilters';
import { useDeprecatedBanner } from './useDeprecatedBanner';

import {
  atomActionDetailQuickTools,
  atomFiltersAction,
  atomSelectedRowActionName,
  atomSelectedRowActionType,
  atomSelectedRowsAction,
  atomSelectedRowsQuickTools,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomLoadingDuplicateAction } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleDuplicateActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaignActions } from '@/modules/CampaignsModule/hooks/useCampaignActions';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface useDuplicateActionProps {
  campaignId?: string | number;
  actionId?: string | number;
}

export const useDuplicateActions = ({ campaignId, actionId }: useDuplicateActionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { campaignId: campaignIdPath, actionId: actionIdPath } = useParams();

  // TODO remove this when the feature flag showDragDropRemovalNotice is removed
  const disableLegacyAction = useFeatureFlag('disableLegacyActionDuplication');

  const { inputLengths, formRegExp } = formValidations;

  const [loading, setLoading] = useAtom(atomLoadingDuplicateAction);
  const [actionDetailQuickTools, setActionDetailQuickTools] = useAtom(atomActionDetailQuickTools);
  const filtersAction = useAtomValue(atomFiltersAction);
  const actionTypeNumber = useAtomValue(atomSelectedRowActionType);
  const rowActionName = useAtomValue(atomSelectedRowActionName);
  const setToggleDuplicateActionsModal = useSetAtom(atomToggleDuplicateActionsModal);
  const actionDragDrop = useAtomValue(atomActionDragDrop);
  const [selectedRowsQuickTools, setSelectedRowsQuickTools] = useAtom(atomSelectedRowsQuickTools);
  const setSelectedRows = useSetAtom(atomSelectedRowsAction);

  const { fetchServiceValidName } = useValidNameResource();
  const { duplicateAction } = useCampaignActions();
  const { showDeprecatedDragDropForRow } = useDeprecatedBanner();
  const { getActions } = useActionsFilters();

  const VALIDATION_RULES = useMemo(
    () => ({
      input: {
        required: {
          value: true,
          message: t('FORMS_RULES_MESSAGES.required'),
        },
        minLength: {
          value: inputLengths.min,
          message: t('FORMS_RULES_MESSAGES.minLength'),
        },
        maxLength: {
          value: inputLengths.max,
          message: t('FORMS_RULES_MESSAGES.maxLength'),
        },
        pattern: {
          value: formRegExp.lettersNumbersAndSpecials,
          message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
        },
      },
    }),
    [formRegExp, inputLengths, t]
  );

  const campaignIdMemo = useMemo(
    () => (campaignId ? Number(campaignId) : Number(campaignIdPath)),
    [campaignId, campaignIdPath]
  );

  const actionIdMemo = useMemo(
    () => (actionId ? Number(actionId) : Number(actionIdPath)),
    [actionId, actionIdPath]
  );

  const actionTypeMemo = useMemo(
    () => (actionTypeNumber ? actionTypeNumber : actionDetailQuickTools?.type),
    [actionTypeNumber, actionDetailQuickTools?.type]
  );

  const actionName = useMemo(
    () => rowActionName || actionDetailQuickTools?.name,
    [actionDetailQuickTools?.name, rowActionName]
  );

  const inputValue = useMemo(() => {
    return `${String(actionName?.replace(WHATSAPP_PREFIX, ''))} ${t(
      `${TRANSLATE_MODAL_DUPLICATE}.copy`
    )}`;
  }, [actionName, t]);

  const disabledSubmitButton = useMemo(
    () => disableLegacyAction && actionDragDrop,
    [disableLegacyAction, actionDragDrop]
  );

  const alertConfig = useMemo(() => {
    let config = {
      description: '',
      type: 'info' as 'info' | 'warning',
    };

    if (disabledSubmitButton) {
      config = {
        description: t('CAMPAIGN_ACTIONS_MAIN.DUPLICATE_ACTIONS.disableActions'),
        type: 'warning',
      };
    } else if (!showDeprecatedDragDropForRow) {
      config = {
        description: t(`${TRANSLATE_MODAL_DUPLICATE}.description`),
        type: 'info',
      };
    } else {
      config = {
        description: t('CAMPAIGN_ACTIONS_MAIN.DUPLICATE_ACTIONS.deprecated.featured'),
        type: 'warning',
      };
    }

    return config;
  }, [disabledSubmitButton, showDeprecatedDragDropForRow, t]);

  const handleCloseModal = useCallback(() => {
    setToggleDuplicateActionsModal(false);
    if (selectedRowsQuickTools) {
      setSelectedRowsQuickTools(false);
      setSelectedRows([]);
    }
  }, [
    selectedRowsQuickTools,
    setSelectedRows,
    setSelectedRowsQuickTools,
    setToggleDuplicateActionsModal,
  ]);

  const handleValidation = useCallback(
    async (value: string) => {
      const isValidName = await fetchServiceValidName({
        name: value,
        resource: EResourceType.ACTION,
        campaignId: campaignIdMemo,
      });
      return {
        isValidInput: isValidName,
        message: t('CAMPAIGNS_MAIN.CREATE_EDIT_CAMPAIGNS.existCampaign'),
      };
    },
    [campaignIdMemo, fetchServiceValidName, t]
  );

  const handleSubmitData = useCallback(
    async (nameValue: string) => {
      setLoading(true);

      const duplicatedActionId = await duplicateAction({
        actionName: actionName?.startsWith('whatsapp-')
          ? `${WHATSAPP_PREFIX}${nameValue}`
          : nameValue,
        campaignActionId: actionIdMemo,
        actionType: actionTypeMemo as number,
      });

      handleCloseModal();

      if (duplicatedActionId) {
        const actionRedirect = window.location.pathname.replace(
          `/${Number(actionId)}`,
          `/${duplicatedActionId}`
        );
        actionDetailQuickTools?.origin === 'detail'
          ? navigate(actionRedirect, { replace: true })
          : await getActions({ ...filtersAction });
      }

      setActionDetailQuickTools(RESET);

      setLoading(false);
    },
    [
      setLoading,
      duplicateAction,
      actionName,
      actionIdMemo,
      actionTypeMemo,
      handleCloseModal,
      setActionDetailQuickTools,
      actionId,
      actionDetailQuickTools?.origin,
      navigate,
      getActions,
      filtersAction,
    ]
  );

  return {
    state: {
      inputValue,
      alertConfig,
      disabledSubmitButton,
      VALIDATION_RULES,
      loading,
    },
    events: {
      handleValidation,
      handleCloseModal,
      handleSubmitData,
    },
  };
};
