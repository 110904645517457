import { useAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';

import { atomFiltersRecurrent } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';

export const useInputDate = () => {
  const { t } = useTranslation();

  const [filtersRecurrent, setFiltersRecurrent] = useAtom(atomFiltersRecurrent);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    filtersRecurrent?.startDate ?? null,
    filtersRecurrent?.endDate ?? null,
  ]);

  const datePlaceholder = useMemo(() => t('RECURRENT_MESSAGE.FILTERS.date'), [t]);

  const handleOnClickDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (Array.isArray(event)) {
        setDateRange(event as [Date | null, Date | null]);
        if (event[0] && event[1]) {
          setFiltersRecurrent({
            ...filtersRecurrent,
            startDate: event[0],
            endDate: event[1],
          });
        }
      }
    },
    [filtersRecurrent, setFiltersRecurrent]
  );

  const resetDateRange = useCallback(() => {
    setDateRange([null, null]);
  }, []);

  return {
    state: {
      dateRange,
      datePlaceholder,
    },
    events: {
      handleOnClickDate,
      resetDateRange,
    },
  };
};
