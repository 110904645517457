import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { formValidations } from '@/src/constants/FormValidations';
import { WHATSAPP_PREFIX } from '@/src/modules/CampaignsModule/constants';

import { useActionsFilters } from './useActionsFilters';

import {
  atomActionDetailQuickTools,
  atomFiltersAction,
  atomSelectedRowActionName,
  atomSelectedRowsAction,
  atomSelectedRowsQuickTools,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomInfoCampaignV1 } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleEditActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useActionData } from '@/modules/CampaignsModule/hooks/useActionData';
import { useCampaignActions } from '@/modules/CampaignsModule/hooks/useCampaignActions';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';

interface useEditActionProps {
  campaignId?: string | number;
  actionId?: string | number;
}

export const useEditActions = ({ campaignId, actionId }: useEditActionProps) => {
  const { campaignId: campaignIdPath } = useParams();
  const { t } = useTranslation();
  const { refetch: refetchDetailAction } = useActionData(Number(actionId));
  const { editAction, loading } = useCampaignActions();
  const { fetchServiceValidName, loadingValidation } = useValidNameResource();

  const { inputLengths, formRegExp } = formValidations;

  const [infoCampaignV1, setInfoCampaignV1] = useAtom(atomInfoCampaignV1);
  const filtersAction = useAtomValue(atomFiltersAction);
  const rowActionName = useAtomValue(atomSelectedRowActionName);
  const setToggleEditActionsModal = useSetAtom(atomToggleEditActionsModal);
  const [actionDetailQuickTools, setActionDetailQuickTools] = useAtom(atomActionDetailQuickTools);
  const [selectedRowsQuickTools, setSelectedRowsQuickTools] = useAtom(atomSelectedRowsQuickTools);
  const setSelectedRows = useSetAtom(atomSelectedRowsAction);

  const { getActions } = useActionsFilters();

  const VALIDATION_RULES = useMemo(
    () => ({
      input: {
        required: {
          value: true,
          message: t('FORMS_RULES_MESSAGES.required'),
        },
        minLength: {
          value: inputLengths.min,
          message: t('FORMS_RULES_MESSAGES.minLength'),
        },
        maxLength: {
          value: inputLengths.max,
          message: t('FORMS_RULES_MESSAGES.maxLength'),
        },
        pattern: {
          value: formRegExp.lettersNumbersAndSpecials,
          message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
        },
      },
    }),
    [formRegExp, inputLengths, t]
  );

  const actionName = useMemo(() => {
    const name = rowActionName || actionDetailQuickTools?.name;
    return name?.replace(WHATSAPP_PREFIX, '');
  }, [actionDetailQuickTools?.name, rowActionName]);

  const handleCloseModal = useCallback(() => {
    setToggleEditActionsModal(false);
    if (selectedRowsQuickTools) {
      setSelectedRowsQuickTools(false);
      setSelectedRows([]);
    }
  }, [
    selectedRowsQuickTools,
    setSelectedRows,
    setSelectedRowsQuickTools,
    setToggleEditActionsModal,
  ]);

  const handleValidation = useCallback(
    async (value: string) => {
      const isValidName = await fetchServiceValidName({
        name: value,
        resource: EResourceType.ACTION,
        ...(campaignId
          ? { campaignId: Number(campaignId) }
          : { campaignId: Number(campaignIdPath) }),
      });
      return {
        isValidInput: isValidName,
        message: t('CAMPAIGN_ACTIONS_MAIN.existAction'),
      };
    },
    [campaignId, campaignIdPath, fetchServiceValidName, t]
  );

  const handleSubmitData = useCallback(
    async (nameValue: string) => {
      if (rowActionName === nameValue) {
        handleCloseModal();
        return;
      }

      const newName = rowActionName?.startsWith(WHATSAPP_PREFIX)
        ? `whatsapp-${nameValue}`
        : nameValue;
      await editAction({ name: newName, campaignElementId: Number(actionId) });

      handleCloseModal();
      setActionDetailQuickTools(RESET);

      actionDetailQuickTools?.origin === 'detail'
        ? refetchDetailAction()
        : await getActions({ ...filtersAction });
    },
    [
      rowActionName,
      actionId,
      actionDetailQuickTools?.origin,
      filtersAction,
      editAction,
      handleCloseModal,
      setActionDetailQuickTools,
      refetchDetailAction,
      getActions,
    ]
  );

  const handleApplyRename = useCallback(
    async (value: string) => {
      const newName = rowActionName?.startsWith(WHATSAPP_PREFIX) ? `whatsapp-${value}` : value;

      const statusOK = await editAction({ name: newName, campaignElementId: Number(actionId) });
      if (statusOK) {
        refetchDetailAction();

        setInfoCampaignV1({
          ...infoCampaignV1,
          action: { id: Number(actionId), name: newName },
        });
      }
      handleCloseModal();
    },
    [
      rowActionName,
      editAction,
      actionId,
      handleCloseModal,
      refetchDetailAction,
      setInfoCampaignV1,
      infoCampaignV1,
    ]
  );

  return {
    state: {
      VALIDATION_RULES,
      actionName,
      loading: loading || loadingValidation,
    },
    events: {
      handleValidation,
      handleApplyRename,
      handleSubmitData,
      handleCloseModal,
    },
  };
};
