/* eslint-disable no-console */
import { memo, useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useSearchParams } from 'react-router-dom';

import { Button, Input, SelectPhoneNumber } from '@/lib/v2/components';

import { formValidations } from '@/src/constants/FormValidations';
import { ValidatePasswordInformation } from '@/src/modules/AuthModule/components';
import HeaderLogin from '@/src/modules/AuthModule/components/HeaderLogin';
import { useLogin } from '@/src/modules/AuthModule/hooks/useLogin';
import { usePasswordValidation } from '@/src/modules/AuthModule/hooks/usePasswordValidation';
import { CompleteRegisterForm, FormChangePassword } from '@/src/modules/AuthModule/types/Auth';

const CompleteRegister = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { error, isLoading, completeRegister, checkToken } = useLogin();
  const { formRegExp } = formValidations;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<CompleteRegisterForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      repeatPassword: '',
      phone: '',
    },
  });

  const {
    passwordErrors,
    isFocusPasswordInput,
    passwordRulesAreValid,
    handleOnChangePassword,
    handleFocusPasswordInput,
    handleBlurPasswordInput,
    CHANGE_RULES,
  } = usePasswordValidation(
    setValue as unknown as UseFormSetValue<FormChangePassword>,
    watch as unknown as UseFormWatch<FormChangePassword>
  );

  /** cspell:disable-next-line */
  const userId = searchParams.get('userId');
  const token = searchParams.get('token');
  const registerType = searchParams.get('type');

  const phone = watch('phone');

  const disableButtonByPhone = useMemo(() => {
    // Phone is required only for new users created from configuration module
    if (registerType === 'configuration') return Boolean(errors.phone || !phone);
    return false;
  }, [registerType, errors.phone, phone]);

  const PHONE_RULES = {
    pattern: {
      value: formRegExp.lettersNumbersAndSpecials,
      message: t('FORMS_RULES_MESSAGES.lettersNumbersAndSpecials'),
    },
    required: t('FORMS_RULES_MESSAGES.required'),
    validate: (value: string) => {
      const isValidPhone = isValidPhoneNumber(value ?? '');
      return isValidPhone || t('SIGN_UP_FORM_ONE.phoneNoValid');
    },
  };

  const onSubmitCompleteRegister: SubmitHandler<CompleteRegisterForm> = useCallback(
    (data) => {
      if (!passwordRulesAreValid || !token || !userId || disableButtonByPhone) return;
      const { password, phone } = data;
      void completeRegister({ password, token, userId, phone });
    },
    [completeRegister, passwordRulesAreValid, token, userId, disableButtonByPhone]
  );

  useEffect(() => {
    async function fetchData() {
      if (token && userId) await checkToken(token, userId);
    }

    if (token && userId) void fetchData();
  }, [checkToken, token, userId]);

  return (
    <div className="flex size-full flex-col items-center justify-center gap-4 text-center">
      <HeaderLogin
        subTitle={t('AUTH.COMPLETE_REGISTER.subtitle')}
        title={t('AUTH.COMPLETE_REGISTER.title')}
      />
      <form
        className="flex w-full justify-center"
        onSubmit={handleSubmit(onSubmitCompleteRegister)}
      >
        <div className="relative flex w-full flex-col items-center gap-4 sm:w-[300px]">
          <ValidatePasswordInformation
            isShow={isFocusPasswordInput}
            passwordRules={passwordErrors}
          />
          <Input
            {...register('password', CHANGE_RULES.password)}
            error={errors?.password && true}
            id="password-input"
            label={t('AUTH.CHANGE_PASSWORD.newPassword')}
            message={errors?.password?.message}
            placeHolder="ej. JohnC@rt$32,#"
            type="password"
            onBlur={handleBlurPasswordInput}
            onChange={handleOnChangePassword}
            onFocus={handleFocusPasswordInput}
          />
          <Input
            {...register('repeatPassword', CHANGE_RULES.repeatPassword)}
            error={errors?.repeatPassword && true}
            id="repeat-password-input"
            label={t('AUTH.CHANGE_PASSWORD.confirmPassword')}
            message={errors?.repeatPassword?.message}
            placeHolder="ej. JohnC@rt$32,#"
            type="password"
          />
          {/* Only for new users created from configuration module */}
          {registerType === 'configuration' && (
            <SelectPhoneNumber
              {...register('phone', PHONE_RULES)}
              control={control}
              id="phone-input"
              label={t('SIGN_UP_FORM_ONE.phone')}
              name="phone"
            />
          )}
          {error && <p className={'message-error !text-center'}>{error}</p>}
          <Button
            fullWidth
            disabled={!passwordRulesAreValid || disableButtonByPhone}
            id="complete-register-button"
            isLoading={isLoading}
            type="submit"
          >
            {t('AUTH.COMPLETE_REGISTER.create')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default memo(CompleteRegister);
