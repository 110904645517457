import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextSkeleton } from '@/lib/components';

import CardContacts from '@/src/modules/InsightsModule/components/CardContacts';

import {
  ICardContacts,
  InsightsAdditionalMetricsContacts,
} from '@/modules/InsightsModule/interfaces/ContactsSummary';

interface IProps {
  metrics: InsightsAdditionalMetricsContacts;
}

const ContactsAdditional = (props: IProps) => {
  const { metrics } = props;
  const { t } = useTranslation(['insights']);

  const cardsAdditional: ICardContacts[] = useMemo(
    () => [
      {
        linkTo: '/v2/contacts/groups',
        total: metrics?.groups ? (
          `${metrics?.groups.count}/${metrics?.groups.limit}`
        ) : (
          <TextSkeleton size="sm" />
        ),
        wordings: {
          title: t('contacts.additional.groups.title'),
          tooltip: t('contacts.additional.groups.tooltip'),
        },
      },
      {
        linkTo: '/v2/contacts/segments',
        total: metrics?.segments ? (
          `${metrics?.segments.count}/${metrics?.segments.limit}`
        ) : (
          <TextSkeleton size="sm" />
        ),
        wordings: {
          title: t('contacts.additional.segments.title'),
          tooltip: t('contacts.additional.segments.tooltip'),
        },
      },
      {
        linkTo: '/v2/contacts/tags',
        total: metrics?.tags ? (
          `${metrics?.tags.count}/${metrics?.tags.limit}`
        ) : (
          <TextSkeleton size="sm" />
        ),
        wordings: {
          title: t('contacts.additional.tags.title'),
          tooltip: t('contacts.additional.tags.tooltip'),
        },
      },
      {
        linkTo: '/v2/contacts/discarded',
        total:
          typeof metrics?.contacts?.discarded?.percentage === 'number' ? (
            `${metrics?.contacts?.discarded?.percentage}%`
          ) : (
            <TextSkeleton size="sm" />
          ),
        wordings: {
          title: t('contacts.additional.discarded.title'),
          tooltip: t('contacts.additional.discarded.tooltip'),
        },
      },
    ],
    [t, metrics]
  );

  return (
    <div className="grid w-full grid-cols-4 gap-4">
      {cardsAdditional.map((item: ICardContacts, key: number) => (
        <CardContacts
          key={key}
          className="p-4"
          linkTo={item.linkTo}
          total={item.total}
          wordings={item.wordings}
        />
      ))}
    </div>
  );
};

export default ContactsAdditional;
