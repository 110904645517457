import { useAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { atomFiltersAction } from '@/modules/CampaignsModule/atoms/actionsAtom';

export const useInputSearch = () => {
  const { t } = useTranslation();

  const [filtersAction, setFiltersAction] = useAtom(atomFiltersAction);
  const [reset, setReset] = useState(false);

  const inputDefaultValue = useMemo(() => filtersAction?.search ?? '', [filtersAction]);
  const inputPlaceholder = useMemo(() => t('CAMPAIGN_ACTIONS_MAIN.FILTERS.searchPlaceholder'), [t]);

  const handleOnClickSearch = useCallback(
    (querySearch: string) => {
      setFiltersAction({
        ...filtersAction,
        search: querySearch,
      });
    },
    [filtersAction, setFiltersAction]
  );

  const onClearSearch = useCallback(() => {
    setFiltersAction({
      ...filtersAction,
      search: '',
    });
    setReset(true);
  }, [filtersAction, setFiltersAction]);

  const resetInputSearch = useCallback(() => {
    setReset(true);
    setTimeout(() => setReset(false), 0);
  }, []);

  return {
    state: {
      inputDefaultValue,
      inputPlaceholder,
      inputReset: reset,
    },
    events: {
      handleOnClickSearch,
      onClearSearch,
      resetInputSearch,
    },
  };
};
