import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { memo } from 'react';

import { TableSkeleton } from '@/lib/components';
import { Flex, If } from '@/lib/v2/components';

import { CampaignsTable } from '@/src/modules/CampaignsModule/components/Campaigns/CampaignsTable';
import { CampaignsHeaderTable } from '@/src/modules/CampaignsModule/components/CampaignsHeaderTable';

import { CampaignFilterBar } from './components/CampaignFilterBar';
import { CampaignToolBar } from './components/CampaignToolBar';

import {
  atomCampaignList,
  atomTotalSelectedRows,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignAtom';

const stickyHeaderModule = true;

const TableContainer = () => {
  const campaignsList = useAtomValue(atomCampaignList);
  const totalCampaignsList = useAtomValue(atomTotalsProcess);
  const totalSelectedRows = useAtomValue(atomTotalSelectedRows);

  const wrapperActionsTable = classNames({
    'mt-[-52px]': campaignsList && campaignsList?.length > 0,
  });

  if (!campaignsList) {
    return (
      <Flex column>
        <div className="size-full py-10">
          <TableSkeleton columns={7} />
        </div>
      </Flex>
    );
  }

  return (
    <Flex column>
      <If condition={totalCampaignsList?.total > 0}>
        <CampaignsHeaderTable
          filterBar={<CampaignFilterBar />}
          selectedItems={totalSelectedRows}
          stickyHeader={stickyHeaderModule}
          toolBar={<CampaignToolBar />}
        />
      </If>
      <Flex column withGap className={wrapperActionsTable} itemAlignment="stretch">
        <CampaignsTable stickyHeader={stickyHeaderModule} />
      </Flex>
    </Flex>
  );
};

export default memo(TableContainer);
