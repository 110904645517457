import { useAtomValue, useSetAtom } from 'jotai';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dropdown, Flex, IconSvg } from '@/lib/v2/components';
import { DropdownItemProps } from '@/lib/v2/components/Dropdown';
import {
  DuplicateIconNew,
  EditPencilIconNew,
  MenuDotsIcon,
  RemoveIconNew,
} from '@/lib/v2/icons/solid';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';

import {
  atomCampaignQuickTools,
  atomDetailCampaign,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomModalTypeProcess,
  atomToggleDeleteModal,
  atomToggleDuplicateCampaignModal,
  atomToggleEditCreateCampaignModal,
} from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { ModalType } from '@/modules/CampaignsModule/interfaces/Campaigns';

const ActionButtons = ({ toggleModal }: { toggleModal: () => void }) => {
  const { t } = useTranslation();

  const showLogCampaign = useFeatureFlag('activityLogCampaign');
  const duplicateCampaignV2 = useFeatureFlag('duplicateCampaign');

  const detailCampaign = useAtomValue(atomDetailCampaign);
  const setToggleDuplicateCampaignModal = useSetAtom(atomToggleDuplicateCampaignModal);
  const setToggleDeleteModal = useSetAtom(atomToggleDeleteModal);
  const setModalTypeProcess = useSetAtom(atomModalTypeProcess);
  const setToggleEditCategoryModal = useSetAtom(atomToggleEditCreateCampaignModal);
  const setCampaignQuickTools = useSetAtom(atomCampaignQuickTools);

  const handleDuplicateCampaign = useCallback(() => {
    if (duplicateCampaignV2) {
      setModalTypeProcess(ModalType.DUPLICATE);
      setToggleEditCategoryModal(true);
    } else {
      setToggleDuplicateCampaignModal(true);
    }
  }, [
    duplicateCampaignV2,
    setModalTypeProcess,
    setToggleDuplicateCampaignModal,
    setToggleEditCategoryModal,
  ]);

  const handleDeleteCampaign = useCallback(() => {
    setCampaignQuickTools({
      id: detailCampaign.campaignId as number,
      name: detailCampaign.name as string,
      redirect: true,
    });
    setToggleDeleteModal(true);
  }, [setToggleDeleteModal, setCampaignQuickTools, detailCampaign]);

  const listDropdownActions: DropdownItemProps[] = useMemo(
    () => [
      {
        id: 'edit-campaign',
        value: 'edit-campaign',
        label: t('CAMPAIGN_ACTIONS_MAIN.HEADER.DROPDOWN.edit'),
        icon: (
          <IconSvg
            fillColor="gray-dark"
            strokeColor="gray-dark"
            svgComponent={<EditPencilIconNew />}
          />
        ),
        onClick: toggleModal,
      },
      {
        id: 'duplicate-campaign',
        value: 'duplicate-campaign',
        label: t('CAMPAIGN_ACTIONS_MAIN.HEADER.DROPDOWN.duplicate'),
        icon: (
          <IconSvg
            fillColor="gray-dark"
            strokeColor="gray-dark"
            svgComponent={<DuplicateIconNew />}
          />
        ),
        onClick: handleDuplicateCampaign,
      },
      {
        id: 'delete-campaign',
        value: 'delete-campaign',
        label: t('CAMPAIGN_ACTIONS_MAIN.HEADER.DROPDOWN.delete'),
        icon: (
          <IconSvg fillColor="gray-dark" strokeColor="gray-dark" svgComponent={<RemoveIconNew />} />
        ),
        onClick: handleDeleteCampaign,
      },
    ],
    [t, toggleModal, handleDuplicateCampaign, handleDeleteCampaign]
  );

  return (
    <Flex withGap alignment="end" gapSize="small">
      {showLogCampaign && (
        <Button outline id="log-actions-button" size="medium">
          {t('CAMPAIGN_ACTIONS_MAIN.HEADER.log')}
        </Button>
      )}

      <Dropdown
        menuOnTheLeft
        onlyIcon
        dropdownItems={listDropdownActions}
        iconRight={<IconSvg fillColor="gray-dark" svgComponent={<MenuDotsIcon />} />}
        id="options-actions-dropdown"
        label=""
      />
    </Flex>
  );
};

export default memo(ActionButtons);
