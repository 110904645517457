import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Input, Modal, Select, Text } from '@/lib/v2/components';
import { Option } from '@/lib/v2/components/Select/Select';

import { formValidations } from '@/src/constants/FormValidations';
import { atomSelectedCategories } from '@/src/modules/CampaignsModule/atoms/beefreeAtom';
import { useTemplateCreatorsPermissions } from '@/src/modules/CampaignsModule/hooks/useTemplateCreatorsPermissions';

import { useModalCatalog } from '@/modules/CampaignsModule/hooks/useModalCatalog';

export interface FormInput {
  name: string;
  collection: string;
  asEmblueTemplate: boolean;
}

const ModalTemplate = () => {
  const { t } = useTranslation();
  const [selectedCategories, setSelectedCategories] = useAtom(atomSelectedCategories);
  const isUserCreatorEmail = useTemplateCreatorsPermissions();

  const { inputLengths } = formValidations;

  const { catalog, onSave, setShowModalTemplate, loadingSave, showModalTemplate, closeModal } =
    useModalCatalog();
  const handleOnChangeCategories = useCallback(
    (e: Option | Option[]) => {
      !Array.isArray(e) && setSelectedCategories(e);
    },
    [setSelectedCategories]
  );

  const RULES = useMemo(
    () => ({
      name: {
        required: t('FORMS_RULES_MESSAGES.required'),
        minLength: {
          value: inputLengths.min,
          message: t('FORMS_RULES_MESSAGES.minLength'),
        },
      },
      collection: {},
      asEmblueTemplate: {},
    }),
    [inputLengths.min, t]
  );

  const { control, handleSubmit, reset, watch } = useForm<FormInput>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const saveAsEmblue = watch('asEmblueTemplate');

  useEffect(() => {
    return () => {
      if (!showModalTemplate) {
        reset();
        setSelectedCategories(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalTemplate]);

  return (
    <Modal noPadding open={showModalTemplate} onClose={closeModal}>
      <div className="mb-4 flex flex-col p-6 py-4">
        <Text className="mb-6" variant="headline">
          {t('BEEFREE_MODAL_TEMPLATE.title')}
        </Text>

        <div className="flex flex-col">
          <Input
            control={control}
            id="name"
            label={t('BEEFREE_MODAL_TEMPLATE.name')}
            name="name"
            placeholder={t('BEEFREE_MODAL_TEMPLATE.placeholderName')}
            rules={RULES.name}
          />
        </div>

        {isUserCreatorEmail && (
          <>
            {saveAsEmblue && (
              <>
                <div className="flex flex-col">
                  <Text>{t('BEEFREE_MODAL_TEMPLATE.categories')}</Text>
                  <Select
                    id="categories"
                    name="categories"
                    options={catalog.categories}
                    value={selectedCategories}
                    onChange={handleOnChangeCategories}
                  />
                </div>

                <div className="flex flex-col">
                  <Text>{t('BEEFREE_MODAL_TEMPLATE.collections')}</Text>
                  <Select
                    control={control}
                    id="collection"
                    name="collection"
                    options={catalog.collections}
                    rules={RULES.collection}
                  />
                </div>
              </>
            )}

            <div className="mt-4 flex flex-col">
              <Checkbox
                control={control}
                id="asEmblueTemplate"
                label={t('BEEFREE_MODAL_TEMPLATE.saveAsEmblueTemplate')}
                name="asEmblueTemplate"
                rules={RULES.asEmblueTemplate}
              />
            </div>
          </>
        )}

        <div className="mt-6 flex w-full justify-end gap-4">
          <Button outline onClick={() => setShowModalTemplate(false)}>
            {t('BEEFREE_MODAL_TEMPLATE.close')}
          </Button>
          <Button disabled={loadingSave} isLoading={loadingSave} onClick={handleSubmit(onSave)}>
            {t('BEEFREE_MODAL_TEMPLATE.save')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalTemplate;
