import { useAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { atomFiltersRecurrent } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';

export const useInputSearch = () => {
  const { t } = useTranslation();
  const [filtersRecurrent, setFiltersRecurrent] = useAtom(atomFiltersRecurrent);
  const [reset, setReset] = useState(false);

  const inputDefaultValue = useMemo(
    () => filtersRecurrent?.search ?? '',
    [filtersRecurrent?.search]
  );
  const inputPlaceholder = useMemo(() => t('CAMPAIGN_ACTIONS_MAIN.FILTERS.searchPlaceholder'), [t]);

  const handleOnClickSearch = useCallback(
    (querySearch: string) => {
      setFiltersRecurrent({
        ...filtersRecurrent,
        search: querySearch,
      });
    },
    [filtersRecurrent, setFiltersRecurrent]
  );

  const onClearSearch = useCallback(() => {
    setFiltersRecurrent({
      ...filtersRecurrent,
      search: '',
    });
    setReset(true);
  }, [filtersRecurrent, setFiltersRecurrent]);

  const resetInputSearch = useCallback(() => {
    setReset(true);
    setTimeout(() => setReset(false), 0);
  }, []);

  return {
    state: {
      inputDefaultValue,
      inputPlaceholder,
      inputReset: reset,
    },
    events: {
      handleOnClickSearch,
      onClearSearch,
      resetInputSearch,
    },
  };
};
