import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  atomActionDetailQuickTools,
  atomFiltersAction,
  atomSelectedRowsQuickTools,
} from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import { atomToggleDeleteActionsModal } from '@/src/modules/CampaignsModule/atoms/modalsCampaignAtom';

import { useActionsFilters } from './useActionsFilters';
import { useCampaignActions } from './useCampaignActions';

import { atomSelectedRowsAction } from '@/modules/AutomationModule/atoms/flows';

export const useDeleteActions = () => {
  const navigate = useNavigate();

  const actionDetailQuickTools = useAtomValue(atomActionDetailQuickTools);
  const filtersAction = useAtomValue(atomFiltersAction);
  const setToggleDeleteActionsModal = useSetAtom(atomToggleDeleteActionsModal);
  const [selectedRowsQuickTools, setSelectedRowsQuickTools] = useAtom(atomSelectedRowsQuickTools);
  const setSelectedRows = useSetAtom(atomSelectedRowsAction);

  const { deleteAction, loading } = useCampaignActions();
  const { getActions } = useActionsFilters();

  const handleCloseModal = useCallback(() => {
    setToggleDeleteActionsModal(false);
    if (selectedRowsQuickTools) {
      setSelectedRowsQuickTools(false);
      setSelectedRows([]);
    }
  }, [
    selectedRowsQuickTools,
    setSelectedRows,
    setSelectedRowsQuickTools,
    setToggleDeleteActionsModal,
  ]);

  const handleConfirm = useCallback(
    async (actionsID: number[]) => {
      if (actionsID.length === 0) return;

      await deleteAction(actionsID);

      handleCloseModal();

      if (actionDetailQuickTools?.origin === 'detail') {
        navigate(-1);
      } else {
        await getActions({ ...filtersAction });
        setSelectedRows([]);
      }
    },
    [
      deleteAction,
      handleCloseModal,
      actionDetailQuickTools?.origin,
      navigate,
      getActions,
      filtersAction,
      setSelectedRows,
    ]
  );

  return {
    state: {
      loading,
    },
    events: {
      handleCloseModal,
      handleConfirm,
    },
  };
};
