import BeefreeEN from '@/src/locales/en/beefree.json';
import BeefreeES from '@/src/locales/es/beefree.json';
import BeefreePT from '@/src/locales/pt/beefree.json';

export const BEEFREE_SIGNATURES = [
  {
    type: 'Signature',
    label: '[Subscribe]',
    link: '$FE{subscribe}',
  },
  {
    type: 'Signature',
    label: '[Share on Facebook]',
    link: '$FE{facebook}',
  },
  {
    type: 'Signature',
    label: '[Share on Twitter]',
    link: '$FE{twitter}',
  },
  {
    type: 'Signature',
    label: '[See in my browser]',
    link: '$FE{online}',
  },
  {
    type: 'Signature',
    label: '[Update Profile]',
    link: '$FE{update}',
  },
  {
    type: 'Signature',
    label: '[Legal]',
    link: '$FE{legal}',
  },
  {
    type: 'Signature',
    label: '[Sender data]',
    link: '$FE{data}',
  },
  {
    type: 'Signature',
    label: '[Unsubscribe]',
    link: '$FE{unsubscription}',
  },
];

export const BEEFREE_LANGUAGES = {
  es: {
    code: 'es-ES',
    translations: BeefreeES,
  },
  en: {
    code: 'en-US',
    translations: BeefreeEN,
  },
  pt: {
    code: 'pt-BR',
    translations: BeefreePT,
  },
};

// cspell:disable
export const BEEFREE_CUSTOM_FONTS = [
  {
    name: 'Calibri',
    fontFamily: "'Calibri', Arial, sans-serif",
  },
  {
    fontFamily: 'Cabin',
    name: 'Cabin',
    url: 'https://fonts.googleapis.com/css2?family=Cabin&display=swap',
  },
  {
    fontFamily: 'Oswald',
    name: 'Oswald',
    url: 'https://fonts.googleapis.com/css2?family=Oswald&display=swap',
  },
  {
    fontFamily: 'Carlito',
    name: 'Carlito',
    url: 'https://fonts.googleapis.com/css2?family=Carlito&display=swap',
  },
  {
    fontFamily: 'Roboto Slab',
    name: 'Roboto Slab',
    url: 'https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap',
  },
  {
    fontFamily: 'Playfair Display',
    name: 'Playfair Display',
    url: 'https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap',
  },
  {
    fontFamily: 'Poppins',
    name: 'Poppins',
    url: 'https://fonts.googleapis.com/css2?family=Poppins&display=swap',
  },
  {
    fontFamily: 'Cormorant Garamond',
    name: 'Cormorant Garamond',
    url: 'https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap',
  },
  {
    fontFamily: 'Questrial',
    name: 'Questrial',
    url: 'https://fonts.googleapis.com/css2?family=Questrial&display=swap',
  },
  {
    fontFamily: 'Shrikhand',
    name: 'Shrikhand',
    url: 'https://fonts.googleapis.com/css2?family=Shrikhand&display=swap',
  },
  {
    fontFamily: 'Impact',
    name: 'Impact',
    url: 'https://fonts.googleapis.com/css2?family=Impact&display=swap',
  },
  {
    fontFamily: 'Raleway',
    name: 'Raleway',
    url: 'https://fonts.googleapis.com/css2?family=Raleway&display=swap',
  },
  {
    fontFamily: 'Abril Fatface',
    name: 'Abril Fatface',
    url: 'https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap',
  },
  {
    fontFamily: 'Chivo',
    name: 'Chivo',
    url: 'https://fonts.googleapis.com/css2?family=Chivo&display=swap',
  },
  {
    fontFamily: 'Nunito',
    name: 'Nunito',
    url: 'https://fonts.googleapis.com/css2?family=Nunito&display=swap',
  },
  {
    fontFamily: 'Dosis',
    name: 'Dosis',
    url: 'https://fonts.googleapis.com/css2?family=Dosis&display=swap',
  },
  {
    fontFamily: 'Fira Sans',
    name: 'Fira Sans',
    url: 'https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap',
  },
  {
    fontFamily: 'Noto Serif',
    name: 'Noto Serif',
    url: 'https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap',
  },
  {
    fontFamily: 'Alegreya',
    name: 'Alegreya',
    url: 'https://fonts.googleapis.com/css2?family=Alegreya&display=swap',
  },
  {
    fontFamily: 'Permanent Marker',
    name: 'Permanent Marker',
    url: 'https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap',
  },
  {
    fontFamily: 'Libre Baskerville',
    name: 'Libre Baskerville',
    url: 'https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap',
  },
  {
    fontFamily: 'Quattrocento',
    name: 'Quattrocento',
    url: 'https://fonts.googleapis.com/css2?family=Quattrocento&display=swap',
  },
  {
    fontFamily: 'Varela Round',
    name: 'Varela Round',
    url: 'https://fonts.googleapis.com/css2?family=Varela+Round&display=swap',
  },
  {
    fontFamily: 'Catamaran',
    name: 'Catamaran',
    url: 'https://fonts.googleapis.com/css2?family=Catamaran&display=swap',
  },
  {
    fontFamily: 'Oxygen',
    name: 'Oxygen',
    url: 'https://fonts.googleapis.com/css2?family=Oxygen&display=swap',
  },
  {
    fontFamily: 'Inter',
    name: 'Inter',
    url: 'https://fonts.googleapis.com/css2?family=Inter&display=swap',
  },
  {
    fontFamily: 'Rubik',
    name: 'Rubik',
    url: 'https://fonts.googleapis.com/css2?family=Rubik&display=swap',
  },
  {
    fontFamily: 'Lora',
    name: 'Lora',
    url: 'https://fonts.googleapis.com/css2?family=Lora&display=swap',
  },
  {
    fontFamily: 'Merriweather',
    name: 'Merriweather',
    url: 'https://fonts.googleapis.com/css2?family=Merriweather&display=swap',
  },
  {
    fontFamily: 'Bodoni Moda',
    name: 'Bodoni Moda',
    url: 'https://fonts.googleapis.com/css2?family=Bodoni+Moda&display=swap',
  },
  {
    fontFamily: 'Monda',
    name: 'Monda',
    url: 'https://fonts.googleapis.com/css2?family=Monda&display=swap',
  },
  {
    fontFamily: 'Arvo',
    name: 'Arvo',
    url: 'https://fonts.googleapis.com/css2?family=Arvo&display=swap',
  },
  {
    fontFamily: 'Dancing Script',
    name: 'Dancing Script',
    url: 'https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap',
  },
  {
    fontFamily: 'Palatino Linotype',
    name: 'Palatino Linotype',
    url: 'https://fonts.googleapis.com/css2?family=Palatino+Linotype&display=swap',
  },
  {
    fontFamily: 'Book Antiqua',
    name: 'Book Antiqua',
    url: 'https://fonts.googleapis.com/css2?family=Book+Antiqua&display=swap',
  },
  {
    fontFamily: 'Palatino',
    name: 'Palatino',
    url: 'https://fonts.googleapis.com/css2?family=Palatino&display=swap',
  },
];
// cspell:enable
