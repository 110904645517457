import { Transition } from '@headlessui/react';
import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardSection } from '@/lib/v2/components';

import { PasswordRules } from '@/src/modules/AuthModule/hooks/usePasswordValidation';

import ValidatePasswordItem from './ValidatePasswordItem';

interface ValidatePasswordInformationProps {
  isShow?: boolean;
  passwordRules?: PasswordRules;
}

const ValidatePasswordInformation = ({
  isShow = false,
  passwordRules,
}: ValidatePasswordInformationProps) => {
  const { t } = useTranslation();

  return (
    <Transition
      appear
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={isShow}
    >
      <div className="absolute top-16 z-10 w-[300px] md:-right-2 md:top-5 md:translate-x-full">
        <Card>
          <CardSection className="flex flex-col gap-1">
            <ValidatePasswordItem
              isChecked={passwordRules?.minLength}
              text={t('AUTH.CHANGE_PASSWORD.textOne')}
            />
            <ValidatePasswordItem
              isChecked={passwordRules?.oneCapitalLetter}
              text={t('AUTH.CHANGE_PASSWORD.textTwo')}
            />
            <ValidatePasswordItem
              isChecked={passwordRules?.oneNumber}
              text={t('AUTH.CHANGE_PASSWORD.textThree')}
            />
            <ValidatePasswordItem
              isChecked={passwordRules?.oneLowercaseLetter}
              text={t('AUTH.CHANGE_PASSWORD.textFour')}
            />
            <ValidatePasswordItem
              isChecked={passwordRules?.oneSpecialCharacter}
              text={t('AUTH.CHANGE_PASSWORD.textFive')}
            />
            <ValidatePasswordItem
              isChecked={passwordRules?.repeatingChars}
              text={t('AUTH.CHANGE_PASSWORD.textSix')}
            />
            <ValidatePasswordItem
              isChecked={passwordRules?.sequentialChars}
              text={t('AUTH.CHANGE_PASSWORD.textSeven')}
            />
          </CardSection>
        </Card>
      </div>
    </Transition>
  );
};

export default memo(ValidatePasswordInformation);
