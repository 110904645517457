import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';

import { atomActionType, atomFiltersAction } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { EActionTypes } from '@/modules/CampaignsModule/interfaces/CampaignActions';

export const useInputDate = () => {
  const { t } = useTranslation();

  const [filtersAction, setFiltersAction] = useAtom(atomFiltersAction);
  const actionType = useAtomValue(atomActionType);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    filtersAction?.startDate ?? null,
    filtersAction?.endDate ?? null,
  ]);

  const datePlaceholder = useMemo(() => {
    return actionType === EActionTypes.FINISHED
      ? t('CAMPAIGN_ACTIONS_MAIN.FILTERS.DATE.exeDate')
      : t('CAMPAIGN_ACTIONS_MAIN.FILTERS.DATE.creationDate');
  }, [actionType, t]);

  const handleOnClickDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (Array.isArray(event)) {
        setDateRange(event as [Date | null, Date | null]);
        if (event[0] && event[1]) {
          setFiltersAction({
            ...filtersAction,
            startDate: event[0],
            endDate: event[1],
          });
        }
      }
    },
    [filtersAction, setFiltersAction]
  );

  const resetDateRange = useCallback(() => {
    setDateRange([null, null]);
  }, []);

  return {
    state: {
      dateRange,
      datePlaceholder,
    },
    events: {
      handleOnClickDate,
      resetDateRange,
    },
  };
};
