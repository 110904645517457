import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { atomFiltersDropdownCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomFiltersRecurrent } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { IFiltersDropdownTableCampaign } from '@/modules/CampaignsModule/interfaces/Campaigns';
import { clearObjectFalsy } from '@/modules/CampaignsModule/utils';

export const useDropdownFilters = () => {
  const { t } = useTranslation();

  const [filtersDropdownCampaign, setFiltersDropdownCampaign] = useAtom(
    atomFiltersDropdownCampaign
  );
  const [filtersRecurrent, setFiltersRecurrent] = useAtom(atomFiltersRecurrent);

  const countDropdownFilters = useMemo(() => {
    const { state } = filtersRecurrent ?? {};
    return state?.length ?? 0;
  }, [filtersRecurrent]);

  const placeholderFilters = useMemo(() => {
    return countDropdownFilters > 0
      ? `${t('CAMPAIGNS_MAIN.FILTERS.filters')} (${countDropdownFilters})`
      : t('CAMPAIGNS_MAIN.FILTERS.filters');
  }, [countDropdownFilters, t]);

  const handleApplyFilters = useCallback(() => {
    const { state } = filtersDropdownCampaign ?? {};
    const clearedFilters = clearObjectFalsy({
      ...filtersRecurrent,
      state,
    });

    setFiltersRecurrent(clearedFilters);
  }, [filtersDropdownCampaign, filtersRecurrent, setFiltersRecurrent]);

  const handleCheckboxChange = useCallback(
    (key: keyof IFiltersDropdownTableCampaign, value: number, checked: boolean) => {
      const updatedFilters = { ...filtersDropdownCampaign };

      if (checked) {
        if (!updatedFilters[key]) {
          updatedFilters[key] = [value];
        } else {
          (updatedFilters[key] as number[]).push(value);
        }
      } else if (updatedFilters[key]) {
        updatedFilters[key] = (updatedFilters[key] as number[]).filter((item) => item !== value);
        if ((updatedFilters[key] as number[]).length === 0) {
          delete updatedFilters[key];
        }
      }

      setFiltersDropdownCampaign(updatedFilters);
    },
    [filtersDropdownCampaign, setFiltersDropdownCampaign]
  );

  return {
    state: {
      placeholderFilters,
      filtersDropdownCampaign,
    },
    events: {
      handleCheckboxChange,
      handleApplyFilters,
    },
  };
};
