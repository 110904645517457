import { useAtom, useAtomValue } from 'jotai';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalSingleInput } from '@/lib/v2/organisms';

import {
  atomActionDetailQuickTools,
  atomSelectedActionsRowsWithID,
  atomSelectedRowActionCampaignId,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomLoadingDetail } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleDuplicateActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { TRANSLATE_MODAL_DUPLICATE } from '@/modules/CampaignsModule/constants';
import { useDuplicateActions } from '@/modules/CampaignsModule/hooks/useDuplicateActions';

const DuplicateActions = () => {
  const { t } = useTranslation();

  const [toggleDuplicateActionsModal] = useAtom(atomToggleDuplicateActionsModal);
  const loadingDetail = useAtomValue(atomLoadingDetail);
  const actionDetailQuickTools = useAtomValue(atomActionDetailQuickTools);
  const campaignId = useAtomValue(atomSelectedRowActionCampaignId);
  const rowActionId = useAtomValue(atomSelectedActionsRowsWithID);

  const actionID = useMemo(
    () => Number(rowActionId[0]) || actionDetailQuickTools?.id,
    [actionDetailQuickTools?.id, rowActionId]
  );

  const {
    state: { alertConfig, inputValue, disabledSubmitButton, VALIDATION_RULES, loading },
    events: { handleValidation, handleCloseModal, handleSubmitData },
  } = useDuplicateActions({ campaignId, actionId: actionID });

  return (
    <ModalSingleInput
      validateFieldsOnOpen
      alert={alertConfig}
      disabledSubmitButton={loading || loadingDetail || disabledSubmitButton}
      id="duplicate-action-modal"
      isLoading={loadingDetail || loading}
      isOpen={toggleDuplicateActionsModal}
      rules={VALIDATION_RULES}
      title={t(`${TRANSLATE_MODAL_DUPLICATE}.title`)}
      value={inputValue}
      onClose={handleCloseModal}
      onSubmit={handleSubmitData}
      onValidateInputFetch={handleValidation}
    />
  );
};

export default memo(DuplicateActions);
