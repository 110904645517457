import { useAtomValue, useSetAtom } from 'jotai';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TableSkeleton } from '@/lib/components';
import { Table } from '@/lib/components/Table';
import { EmptyCampaignsIcon, EmptyCampaignsNoResultIcon } from '@/lib/v2/icons/general';
import { EmptyScreenTable } from '@/lib/v2/organisms';

import { useCampaignTableColumns } from './hooks/useCampaignTableColumns';
import { useHandleEvents } from './hooks/useHandleEvents';

import {
  atomCampaignList,
  atomLoadingProcess,
  atomPagerCampaign,
  atomSelectedRows,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { EmptyTableWithoutData } from '@/modules/CampaignsModule/components/Campaigns/TableContainer/components/EmptyTableWithoutData';
import { defaultPager } from '@/modules/CampaignsModule/constants';

interface CampaignsTableProps {
  // eslint-disable-next-line react/boolean-prop-naming
  stickyHeader?: boolean;
}

const CampaignsTable = ({ stickyHeader = false }: CampaignsTableProps) => {
  const { t } = useTranslation();

  const totalsCampaignList = useAtomValue(atomTotalsProcess);
  const loadingProcess = useAtomValue(atomLoadingProcess);
  const campaignList = useAtomValue(atomCampaignList);
  const pager = useAtomValue(atomPagerCampaign);
  const setSelectedRows = useSetAtom(atomSelectedRows);

  const { campaignsColumns } = useCampaignTableColumns();
  const {
    handleClickEmptyScreen,
    handleClickEmptyScreenNoResult,
    handleChangeTablePage,
    handleRowClick,
  } = useHandleEvents();

  const gotoPageIndex = useMemo(() => (pager?.page ? pager.page - 1 : 0), [pager]);

  const totalRecords = useMemo(
    () => Math.ceil(totalsCampaignList.partial / (pager?.limit || defaultPager.limit)),
    [pager?.limit, totalsCampaignList.partial]
  );

  const withPagination = useMemo(
    () => ({
      totalPages: totalRecords,
      fetchData: handleChangeTablePage,
    }),
    [handleChangeTablePage, totalRecords]
  );

  const emptyScreen = useMemo(
    () => ({
      totalCount: totalsCampaignList.total,
      noResult: (
        <EmptyScreenTable
          actionText={t('CAMPAIGNS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsActionText')}
          description={t('CAMPAIGNS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsDescription')}
          id="noResults-campaigns"
          image={<EmptyCampaignsNoResultIcon />}
          title={t('CAMPAIGNS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsTitle')}
          onClickAction={handleClickEmptyScreenNoResult}
        />
      ),
      noData: (
        <EmptyScreenTable
          description={<EmptyTableWithoutData onClickAction={handleClickEmptyScreen} />}
          image={<EmptyCampaignsIcon />}
          title={t('CAMPAIGNS_MAIN.EMPTY_SCREEN_TABLE.emptyCampaignsTitle')}
        />
      ),
    }),
    [handleClickEmptyScreen, handleClickEmptyScreenNoResult, t, totalsCampaignList]
  );

  if (loadingProcess) {
    return <TableSkeleton columns={7} />;
  }

  return (
    <div className="w-full text-base text-[#364365] 2xl:text-14">
      <Table
        noNegativeMargin
        withCheckbox
        classNamePagination="px-4 mb-2"
        columns={campaignsColumns}
        data={campaignList}
        defaultSize={pager?.limit}
        emptyScreen={emptyScreen}
        gotoPageIndex={gotoPageIndex}
        isLoading={loadingProcess}
        lastPageViewed={pager?.page}
        setSelectedRows={setSelectedRows}
        stickyHeader={stickyHeader}
        withPagination={withPagination}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default memo(CampaignsTable);
