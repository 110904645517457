import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalConfirmation } from '@/lib/v2/organisms';

import {
  atomActionDetailQuickTools,
  atomSelectedActionsRowsWithID,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomTotalSelectedRows } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleDeleteActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useDeleteActions } from '@/modules/CampaignsModule/hooks/useDeleteActions';

const DeleteActions = () => {
  const { t } = useTranslation();
  const toggleDeleteActionsModal = useAtomValue(atomToggleDeleteActionsModal);
  const totalSelectedRows = useAtomValue(atomTotalSelectedRows);
  const rowActionId = useAtomValue(atomSelectedActionsRowsWithID);
  const actionDetailQuickTools = useAtomValue(atomActionDetailQuickTools);

  const actionsID = useMemo(() => {
    if (rowActionId.length > 0) return rowActionId;
    return actionDetailQuickTools?.id ? [Number(actionDetailQuickTools.id)] : [];
  }, [actionDetailQuickTools?.id, rowActionId]);

  const {
    state: { loading },
    events: { handleConfirm, handleCloseModal },
  } = useDeleteActions();

  return (
    <ModalConfirmation
      isLoading={loading}
      isOpen={toggleDeleteActionsModal}
      textCancelButton={t('CAMPAIGN_ACTIONS_MAIN.DELETE_ACTIONS.cancelButton')}
      textConfirmButton={t('CAMPAIGN_ACTIONS_MAIN.DELETE_ACTIONS.successButton')}
      title={
        totalSelectedRows > 1
          ? t('CAMPAIGN_ACTIONS_MAIN.DELETE_ACTIONS.MULTIPLE.title')
          : t('CAMPAIGN_ACTIONS_MAIN.DELETE_ACTIONS.SINGLE.title')
      }
      onClose={handleCloseModal}
      onConfirmButton={() => handleConfirm(actionsID)}
    />
  );
};

export default DeleteActions;
