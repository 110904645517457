import { useCallback, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { Container, If } from '@/lib/v2/components';
import { SecondaryNavigationMenu } from '@/lib/v2/components/SecondaryNavigationMenu';
import { Link } from '@/lib/v2/components/SecondaryNavigationMenu/SecondaryNavigationMenu';

import { useNavigationBar } from '@/src/application/hooks/useNavigationBar';
import { CampaignActionModals } from '@/src/modules/CampaignsModule/components/CampaignActionModals';
import { DeprecatedBanner } from '@/src/modules/CampaignsModule/components/DeprecatedBanner';
import { useActionsFilters } from '@/src/modules/CampaignsModule/hooks/useActionsFilters';
import { useDeprecatedBanner } from '@/src/modules/CampaignsModule/hooks/useDeprecatedBanner';
import { useRecurrentActionFilters } from '@/src/modules/CampaignsModule/hooks/useRecurrentActionFilters';

const CampaignsLayout = () => {
  const { campaignsNavigationLinks } = useNavigationBar();

  //TODO: remove this when the feature flag showDragDropRemovalNotice is removed
  const { showDeprecatedDragDropForPath } = useDeprecatedBanner();

  const { resetUnmount } = useActionsFilters();
  const { resetUnmount: resetUnmountRecurrent } = useRecurrentActionFilters();

  const handleClearFiltersAndPagination = useCallback(() => {
    resetUnmount();
    resetUnmountRecurrent();
  }, [resetUnmount, resetUnmountRecurrent]);

  const campaignsNavigationLinksWithOnClick = useMemo<Link[]>(
    () =>
      campaignsNavigationLinks.map((link) => ({
        ...link,
        onClick: handleClearFiltersAndPagination,
      })),
    [handleClearFiltersAndPagination, campaignsNavigationLinks]
  );

  return (
    <>
      <SecondaryNavigationMenu links={campaignsNavigationLinksWithOnClick} />
      <If condition={showDeprecatedDragDropForPath}>
        <DeprecatedBanner isNegativeMargin />
      </If>
      <Container fullHeight fluid="screen">
        <Outlet />
      </Container>
      <CampaignActionModals options={['campaigns', 'actions', 'recurrent']} />
    </>
  );
};

export default CampaignsLayout;
