import { datadogRum } from '@datadog/browser-rum';
import { MutableRefObject, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { toast } from '@/lib/v2/components';

import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  AUTH_PATHS,
  BLOCKED_USER,
  BLOCKED_USER_RESPONSE,
  DISABLED_USER,
  DISABLED_USER_RESPONSE,
  INVALID_ADMINISTRATION_USER_RESPONSE,
  INVALID_PASSWORD_RESPONSE,
  INVALID_TOKEN,
  INVALID_USER_RESPONSE,
  LOGIN_IN_MAINTENANCE,
  LOGIN_LS,
  NON_EXISTING_USER,
  PASSWORD_CHANGED,
  RECOVER_PASSWORD_OK,
  RECOVER_PASSWORD_VERY_QUICK,
  REGISTER_COMPLETED,
} from '@/src/modules/AuthModule/constants';
import { RequestParamsCompleteRegister } from '@/src/modules/AuthModule/types/Auth';

// Helper function to get a cookie by its name
const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
};

// Helper function to set a cookie
const setCookie = (name: string, value: string, days: number = 7) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useLogin = (recaptchaRef?: MutableRefObject<any>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  // const params = new URLSearchParams(window.location.search);
  // const doAuditStep = params.get('audit') === 'true';
  const doAuditStep = true;

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const service = useService();

  const checkSessionToken = useCallback(() => {
    const sessionToken = getCookie('sessionToken');
    return sessionToken;
  }, []);

  const saveSessionToken = useCallback((token: string) => {
    setCookie('sessionToken', token);
  }, []);

  const login = useCallback(
    async (email: string, password: string, reCaptcha: string) => {
      setError('');
      setIsLoading(true);
      const response = await service?.login({
        email,
        password,
        reCaptcha,
        forgeryToken: getForgeryToken(),
        doAuditStep,
      });
      if (response?.success) {
        datadogRum.addAction('loginV2.success', {
          email: email,
        });
        localStorage.setItem(LOGIN_LS, 'true');
        window.location.reload();
        return;
      } else if (response?.data === INVALID_ADMINISTRATION_USER_RESPONSE) {
        setError(t('AUTH.LOGIN.invalidAdministrationUser'));
      } else if (response?.data === INVALID_USER_RESPONSE) {
        setError(t('AUTH.LOGIN.invalidUser'));
      } else if (response?.data === INVALID_PASSWORD_RESPONSE) {
        setError(t('AUTH.LOGIN.invalidPassword'));
      } else if (response?.data === BLOCKED_USER_RESPONSE) {
        setError(t('AUTH.LOGIN.blockedUser'));
      } else if (response?.data === DISABLED_USER_RESPONSE) {
        setError(t('AUTH.LOGIN.disabledUser'));
      } else if (response?.data === LOGIN_IN_MAINTENANCE) {
        setError(t('AUTH.LOGIN.loginInMaintenance'));
      }
      setIsLoading(false);
      if (recaptchaRef && recaptchaRef.current) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        recaptchaRef.current.reset();
      }
    },
    [recaptchaRef, service, t]
  );

  const recoverPassword = useCallback(
    async (email: string) => {
      setError('');
      setIsLoading(true);
      const response = await service?.recoverPassword({ email, language: language.toUpperCase() });
      if (response?.success) {
        if (response.data === RECOVER_PASSWORD_OK) {
          toast({
            title: t('AUTH.RECOVER_PASSWORD.ok'),
            id: 'success-recover-password',
            autoCloseDelay: 8000,
          });
        } else if (response.data === RECOVER_PASSWORD_VERY_QUICK) {
          setError(t('AUTH.RECOVER_PASSWORD.recoverVeryQuick'));
        } else if (response.data === NON_EXISTING_USER) {
          setError(t('AUTH.RECOVER_PASSWORD.nonExistingUser'));
        } else if (response.data === BLOCKED_USER) {
          setError(t('AUTH.RECOVER_PASSWORD.blockedUser'));
        } else if (response?.data === DISABLED_USER) {
          setError(t('AUTH.LOGIN.disabledUser'));
        }
      } else {
        setError('Error');
      }
      setIsLoading(false);
    },
    [language, service, t]
  );

  const changePassword = useCallback(
    async (password: string, token: string, userId: string) => {
      setError('');
      setIsLoading(true);
      const response = await service?.changePassword({ password, token, userId });

      if (response?.success) {
        if (response.data === PASSWORD_CHANGED) {
          toast({
            title: t('AUTH.CHANGE_PASSWORD.ok'),
            id: 'success-password-changed',
            autoCloseDelay: 8000,
          });
          navigate(AUTH_PATHS.LOGIN_PATH);
        } else {
          setError(t('AUTH.CHANGE_PASSWORD.error'));
        }
      } else {
        setError('Error');
      }
      setIsLoading(false);
    },
    [navigate, service, t]
  );

  const checkToken = useCallback(
    async (token: string, userId: string) => {
      const response = await service?.checkToken(token, userId);
      if (response?.success) {
        if (response.data === INVALID_TOKEN) {
          navigate(AUTH_PATHS.LOGIN_PATH);
        }
      }
    },
    [navigate, service]
  );

  const completeRegister = useCallback(
    async (data: RequestParamsCompleteRegister) => {
      setError('');
      setIsLoading(true);
      const response = await service?.completeRegister(data);
      if (response?.success && response.data === REGISTER_COMPLETED) {
        toast({
          title: t('AUTH.COMPLETE_REGISTER.ok'),
          id: 'success-complete-register',
          autoCloseDelay: 8000,
        });
        navigate(AUTH_PATHS.LOGIN_PATH);
      } else {
        toast({
          title: t('AUTH.COMPLETE_REGISTER.error'),
          id: 'error-complete-register',
          variant: 'error',
          autoCloseDelay: 8000,
        });
      }
      setIsLoading(false);
    },
    [navigate, service, t]
  );

  const getForgeryToken = () => {
    const tokenInput = document.createElement('div');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    tokenInput.innerHTML = (window as any).__forgeryToken;
    const inputElement = tokenInput.querySelector('input[name="__RequestVerificationToken"]');
    const tokenValue = inputElement ? (inputElement as HTMLInputElement).value : '';
    return tokenValue;
  };

  return {
    isLoading,
    error,
    login,
    recoverPassword,
    changePassword,
    completeRegister,
    setError,
    checkToken,
    checkSessionToken,
    saveSessionToken,
  };
};
