import { useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ITableButtonProps } from '@/lib/v2/examples/TableToolsBar/components/TableToolsBarButton/TableToolsBarButton';
import { RemoveIconNew } from '@/lib/v2/icons/solid';

import { atomToggleDeleteActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';

export const useActionsToolBar = () => {
  const { t } = useTranslation();

  const setToggleDeleteActionsModal = useSetAtom(atomToggleDeleteActionsModal);

  const ICONS_LIST_ACTIONS = useMemo<ITableButtonProps[]>(
    () => [
      {
        icon: <RemoveIconNew />,
        onClick: () => {
          setToggleDeleteActionsModal(true);
        },
        tooltip: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipDelete'),
        hide: false,
        id: 'delete-campaign-action',
        label: t('CAMPAIGN_ACTIONS_MAIN.ACTIONS.tooltipDelete'),
      },
    ],
    [t, setToggleDeleteActionsModal]
  );

  return {
    state: {
      ICONS_LIST_ACTIONS,
    },
  };
};
