import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { defaultPager, defaultRecurrentOrderBy } from '@/src/modules/CampaignsModule/constants';
import {
  ICampaignDetail,
  IFiltersTableCampaign,
  IHistorySearchCampaign,
  IOrderByTable,
  IPager,
  ITableCampaignsRecurrent,
  ITotalsCampaign,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';

export const atomCampaignsRecurrentList = atomWithReset<ITableCampaignsRecurrent[] | undefined>(
  undefined
);
atomCampaignsRecurrentList.debugLabel = 'atomCampaignsRecurrentList';

export const atomCampaignsRecurrentListAll = atomWithReset<ITableCampaignsRecurrent[] | undefined>(
  []
);
atomCampaignsRecurrentListAll.debugLabel = 'atomCampaignsRecurrentListAll';

export const atomRecurrentOrderBy = atom<IOrderByTable>(defaultRecurrentOrderBy);
atomRecurrentOrderBy.debugLabel = 'atomRecurrentOrderBy';

export const atomSelectedRows = atom<number[]>([]);
atomSelectedRows.debugLabel = 'atomSelectedRows';

export const atomLoadingDetail = atom<boolean>(false);
atomLoadingDetail.debugLabel = 'atomLoadingDetail';

export const atomDetailCampaignRecurrent = atomWithReset<ICampaignDetail>({});
atomDetailCampaignRecurrent.debugLabel = 'atomDetailCampaignRecurrent';

export const atomTotalsProcess = atomWithReset<ITotalsCampaign>({ total: 0, partial: 0 });
atomTotalsProcess.debugLabel = 'atomTotalsProcess';

export const atomLoadingProcess = atomWithReset<boolean>(false);
atomLoadingProcess.debugLabel = 'atomLoadingProcess';

export const atomHistorySearchPagerRecurrent = atom<IHistorySearchCampaign | undefined>(undefined);
atomHistorySearchPagerRecurrent.debugLabel = 'atomHistorySearchPagerRecurrent';

//TODO: Delete this atoms after campaign refactor
export const atomRefetchAllData = atomWithReset<boolean>(false);
atomRefetchAllData.debugLabel = 'atomRefetchAllData';

export const atomPagerCampaignsRecurrent = atomWithReset<IPager>(defaultPager);
atomPagerCampaignsRecurrent.debugLabel = 'atomPagerCampaignsRecurrent';

export const atomTotalSelectedRows = atom<number>((get) => {
  const selectedRows = get(atomSelectedRows);
  return selectedRows.length || 0;
});
atomTotalSelectedRows.debugLabel = 'atomTotalSelectedRows';

export const atomFiltersRecurrent = atomWithReset<IFiltersTableCampaign>({ ...defaultPager });
atomFiltersRecurrent.debugLabel = 'atomFiltersRecurrent';

export const atomSelectedRowsWithID = atom((get) => {
  const selectedRowsWithID = get(atomSelectedRows)
    .map((index) => get(atomCampaignsRecurrentList)?.[index]?.campaign?.campaignId)
    .filter((campaignId) => campaignId !== undefined) as number[];
  return selectedRowsWithID;
});
atomSelectedRowsWithID.debugLabel = 'atomSelectedRowsWithID';

export const atomSelectedRowRecurrentName = atom((get) => {
  const selectedRows = get(atomSelectedRows);
  const lastIndex = selectedRows.length - 1;
  const selectedActionName = get(atomCampaignsRecurrentList)?.[selectedRows[lastIndex]]?.campaign
    ?.name;
  return selectedActionName;
});
atomSelectedRowRecurrentName.debugLabel = 'atomSelectedRowRecurrentName';

export const atomCurrentCampaignsRecurrentID = atom<number>(0);
atomCurrentCampaignsRecurrentID.debugLabel = 'atomCurrentCampaignsRecurrentID';

export const atomRefetchDetailData = atomWithReset<boolean>(false);
atomRefetchDetailData.debugLabel = 'atomRefetchDetailData';
