import { useTranslation } from 'react-i18next';

import { ModalConfirmation } from '@/lib/v2/organisms';

import { useDeleteRecurrent } from './useDeleteRecurrent.hooks';

const DeleteCampaignsRecurrent = () => {
  const { t } = useTranslation();

  const {
    state: { toggleDeleteRecurrentModal, messagesDeleteRecurrence, loading },
    events: { handleConfirmDeleteCampaignsRecurrent },
    setters: { setToggleDeleteRecurrentModal },
  } = useDeleteRecurrent();

  return (
    <ModalConfirmation
      description={messagesDeleteRecurrence.description}
      isLoading={loading}
      isOpen={toggleDeleteRecurrentModal}
      textCancelButton={t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.cancelButton')}
      textConfirmButton={t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.successButton')}
      title={messagesDeleteRecurrence.title}
      onClose={setToggleDeleteRecurrentModal}
      onConfirmButton={handleConfirmDeleteCampaignsRecurrent}
    />
  );
};

export default DeleteCampaignsRecurrent;
