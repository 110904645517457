import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivityCleanFilterIcon } from '@/lib/icon';
import { Button, ButtonProps, IconSvg } from '@/lib/v2/components';

export interface IResetButtonProps extends ButtonProps {
  id: string;
  dateRange?: [Date | null, Date | null] | null;
  onAction?: ButtonProps['onClick'];
}

const ResetButton = ({ onAction, disabled, tooltip, id, ...props }: IResetButtonProps) => {
  const { t } = useTranslation();
  const defaultTooltip = t('TABLE_FILTER_BAR.buttonClear');

  return (
    <Button
      standard
      className="shadow-none"
      disabled={disabled}
      iconLeft={<IconSvg fillColor="gray-dark" svgComponent={<ActivityCleanFilterIcon />} />}
      id={id}
      tooltip={tooltip ?? defaultTooltip}
      onClick={onAction}
      {...props}
    />
  );
};

export default memo(ResetButton);
