import { memo } from 'react';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { useTranslation } from 'react-i18next';

import { Select, Text } from '@/lib/v2/components';

import { IFormProps } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { useRuleDefaultOptions } from '@/modules/RulesModule/components/FormRule/hooks/useRuleDefaultOptions';
import {
  TRANSLATE_RULES_PLACEHOLDERS,
  TRANSLATE_RULES_SEQUENCE_SETTINGS,
} from '@/modules/RulesModule/constants';
import { IRuleForm } from '@/modules/RulesModule/interfaces';

interface ITagFrequencyProps {
  form: IFormProps<IRuleForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const TagFrequency = ({ form, rules, disabled = false }: ITagFrequencyProps) => {
  const { t } = useTranslation('rules');
  const { tagFrequencyOptions, hourOptions, minuteOptions } = useRuleDefaultOptions();

  return (
    <>
      <div className="mb-2 flex items-center gap-1">
        <Text variant="text">{t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.sendFrequency`)}</Text>
        <div className="gap flex min-w-40 items-center">
          <Select
            isRequired
            control={form.control}
            disabled={disabled}
            name="frequency"
            options={tagFrequencyOptions}
            placeholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.frequency`)}
            rules={rules.frequency}
          />
        </div>
      </div>
      <div className="flex items-center gap-1">
        <Text variant="text">{t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.atTime`)}</Text>
        <div className="min-w-16">
          <Select
            isRequired
            classNameMenuContainer="visible-scrollbar"
            control={form.control}
            disabled={disabled}
            name="hours"
            options={hourOptions}
            placeholder="-"
            rules={rules.hours}
          />
        </div>
        <Text variant="text">:</Text>
        <div className="min-w-16">
          <Select
            isRequired
            classNameMenuContainer="visible-scrollbar"
            control={form.control}
            disabled={disabled}
            name="minutes"
            options={minuteOptions}
            placeholder="-"
            rules={rules.minutes}
          />
        </div>
        <Text variant="text">{t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.timeUnit`)}</Text>
      </div>
    </>
  );
};

export default memo(TagFrequency);
