import { useAtomValue } from 'jotai';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ArrowBackIcon } from '@/lib/icon';
import { Button, IconSvg, If, Text } from '@/lib/v2/components';

import {
  atomCreateEventForm,
  atomCreateEventFormIsValid,
  atomEventData,
} from '@/modules/AutomationModule/atoms/createEventAtoms';
import { useCreateEvent } from '@/modules/AutomationModule/hooks/useCreateEvent';
interface HeaderCreateEventProps {
  isEditView?: boolean;
}
const HeaderCreateEvent = ({ isEditView }: HeaderCreateEventProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isValid = useAtomValue(atomCreateEventFormIsValid);
  const formValues = useAtomValue(atomCreateEventForm);
  const eventData = useAtomValue(atomEventData);
  const validIntegrationIds = [1, 8, 12];
  const eventName = formValues.name;
  const isIntegrationEvent = validIntegrationIds.includes(eventData?.integrationId ?? 0);

  const { handleCreateEvent, handleEditEvent, isLoading } = useCreateEvent();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="relative z-10 flex h-[72px] w-full items-center justify-between gap-4 bg-emblue-white px-6 shadow-md">
      <div className="flex h-fit basis-1/5 items-center">
        <Button standard className="mr-2" id="back-button" size="tiny" onClick={handleBack}>
          <IconSvg svgComponent={<ArrowBackIcon />} />
        </Button>
        <If condition={isEditView}>
          <If condition={isIntegrationEvent}>
            <Text fontWeight="medium">{eventName}</Text>
          </If>
          <If condition={!isIntegrationEvent}>
            <Text fontWeight="medium">{t('HeaderCreateEvent.editEventBack')}</Text>
          </If>
        </If>
        <If condition={!isEditView}>
          <Text fontWeight="medium">{t('HeaderCreateEvent.back')}</Text>
        </If>
      </div>
      <If condition={!isIntegrationEvent}>
        <div className="flex gap-2">
          <Button outline standard id="cancel" size="small" onClick={handleBack}>
            {t('HeaderCreateEvent.cancel')}
          </Button>
          <Button
            primary
            disabled={!isValid}
            id="saveOrEdit"
            isLoading={isLoading}
            size="small"
            onClick={isEditView ? handleEditEvent : handleCreateEvent}
          >
            {isEditView ? t('HeaderCreateEvent.edit') : t('HeaderCreateEvent.save')}
          </Button>
        </div>
      </If>
    </div>
  );
};

export default memo(HeaderCreateEvent);
