import { t } from 'i18next';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { isEqual } from 'lodash';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { UseFormReset, UseFormSetError } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Option } from '@/lib/v2/components';
import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';

import {
  atomDetailCampaign,
  atomFiltersCampaign,
  atomPagerCampaign,
  atomSelectedRows,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomCampaignValidation,
  atomModalTypeProcess,
} from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { TRANSLATE_MODAL } from '@/modules/CampaignsModule/components/CampaignsModal/ManagementCampaign/constants';
import { CAMPAIGNS_PATH, defaultPager } from '@/modules/CampaignsModule/constants';
import { useCampaignFilters } from '@/modules/CampaignsModule/hooks/useCampaignFilters';
import { useCampaignModal } from '@/modules/CampaignsModule/hooks/useCampaignModal';
import { useCampaign } from '@/modules/CampaignsModule/hooks/useCampaigns';
import { useValidNameResource } from '@/modules/CampaignsModule/hooks/useValidNameResource';
import { EResourceType } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import {
  IDefaultFormToCompareProps,
  IFormInput,
  ModalType,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

interface IUseHandleEventsProps {
  setHasOtherStrategy: Dispatch<SetStateAction<boolean>>;
  setDateRange: Dispatch<SetStateAction<[Date | null, Date | null]>>;
  setError: UseFormSetError<IFormInput>;
  reset: UseFormReset<IFormInput>;
  defaultFormToCompare: IDefaultFormToCompareProps | undefined;
}

export const useHandleEvents = ({
  setHasOtherStrategy,
  setDateRange,
  setError,
  reset,
  defaultFormToCompare,
}: IUseHandleEventsProps) => {
  const navigate = useNavigate();
  const { campaignId, actionType } = useParams();

  const [loadingCampaignValidation, setLoadingCampaignValidation] = useAtom(atomCampaignValidation);
  const detailCampaign = useAtomValue(atomDetailCampaign);
  const modalTypeProcess = useAtomValue(atomModalTypeProcess);
  const filtersCampaign = useAtomValue(atomFiltersCampaign);
  const pagerCampaign = useAtomValue(atomPagerCampaign);
  const setSelectedRows = useSetAtom(atomSelectedRows);

  const { createCampaign, loading, duplicateCampaign, isValidNameResource, getDetailCampaign } =
    useCampaign();
  const { getCampaigns } = useCampaignFilters();
  const { handleOnCloseModal } = useCampaignModal();
  const { fetchServiceValidName } = useValidNameResource();

  const handleChangeStrategy = useCallback(
    (value: Option | Option[]) => {
      const data = value as Option;
      setHasOtherStrategy(data?.value === 'other');
    },
    [setHasOtherStrategy]
  );

  const handleCloseModal = useCallback(() => {
    handleOnCloseModal();
    reset();
  }, [handleOnCloseModal, reset]);

  const isValidName = useCallback(
    async (formData: IFormInput) => {
      const isValidName = await fetchServiceValidName({
        name: formData.name,
        resource: EResourceType.CAMPAIGN,
      });
      if (!isValidName) {
        setError('name', {
          type: 'validate',
          message: t('CAMPAIGNS_MAIN.CREATE_EDIT_CAMPAIGNS.existCampaign'),
        });
      }
      return isValidName;
    },
    [fetchServiceValidName, setError]
  );

  const isValidStrategyName = useCallback(
    async (formData: IFormInput) => {
      const isNewStrategy = formData.otherStrategy?.length > 0;

      if (isNewStrategy) {
        const isNameAvailable = await isValidNameResource({
          name: formData.otherStrategy,
          resource: EResourceType.STRATEGY,
        });

        if (!isNameAvailable) {
          setError('otherStrategy', {
            type: 'validate',
            message: t(`${TRANSLATE_MODAL}.existStrategy`),
          });
          return false;
        }
      }

      return true;
    },
    [isValidNameResource, setError]
  );

  const getCampaignsRefetch = useCallback(async () => {
    handleCloseModal();

    if (campaignId && !actionType) {
      await getDetailCampaign({ campaignID: Number(campaignId) });
      return;
    }

    await getCampaigns({
      ...filtersCampaign,
      page: pagerCampaign?.page || defaultPager.page,
      limit: pagerCampaign?.limit || defaultPager.limit,
    });
  }, [
    campaignId,
    actionType,
    filtersCampaign,
    pagerCampaign?.page,
    pagerCampaign?.limit,
    getDetailCampaign,
    handleCloseModal,
    getCampaigns,
  ]);

  const handleSubmitCreate = useCallback(
    async (formData: IFormInput) => {
      setLoadingCampaignValidation(true);
      if ((await isValidName(formData)) && (await isValidStrategyName(formData))) {
        await createCampaign(formData);
        await getCampaignsRefetch();
      }
      setLoadingCampaignValidation(false);
    },
    [
      createCampaign,
      getCampaignsRefetch,
      isValidName,
      isValidStrategyName,
      setLoadingCampaignValidation,
    ]
  );

  const handleSubmitEdit = useCallback(
    async (formData: IFormInput) => {
      setLoadingCampaignValidation(true);
      let isValidNameResponse: boolean | undefined = true;
      let isValidStrategyNameResponse: boolean | undefined = true;
      const { name, strategyName } = detailCampaign;

      if (name !== formData.name) isValidNameResponse = await isValidName(formData);

      if (strategyName !== (formData.strategy as Option)?.name) {
        isValidStrategyNameResponse = await isValidStrategyName(formData);
      }

      if (isValidNameResponse && isValidStrategyNameResponse) {
        await createCampaign(formData);
        await getCampaignsRefetch();
      }
      setLoadingCampaignValidation(false);
    },
    [
      detailCampaign,
      setLoadingCampaignValidation,
      isValidName,
      isValidStrategyName,
      createCampaign,
      getCampaignsRefetch,
    ]
  );

  const handleSubmitDuplicate = useCallback(
    async (formData: IFormInput) => {
      setLoadingCampaignValidation(true);

      let isValidStrategyNameResponse: boolean | undefined = true;
      const { campaignId, strategyName } = detailCampaign;

      const isValidNameResponse = await isValidName(formData);

      if (strategyName !== (formData.strategy as Option)?.value) {
        isValidStrategyNameResponse = await isValidStrategyName(formData);
      }

      if (isValidNameResponse && isValidStrategyNameResponse) {
        const campaignIdDuplicate = await duplicateCampaign({
          ...formData,
          campaignId: campaignId,
        });
        if (campaignIdDuplicate) {
          setSelectedRows(RESET);
          handleCloseModal();
          navigate(`${CAMPAIGNS_PATH}/${campaignIdDuplicate}`);
        }
      }
      setLoadingCampaignValidation(false);
    },
    [
      setLoadingCampaignValidation,
      detailCampaign,
      isValidName,
      isValidStrategyName,
      duplicateCampaign,
      setSelectedRows,
      handleCloseModal,
      navigate,
    ]
  );

  const handleSubmitData = useCallback(
    async (formData: IFormInput) => {
      if (modalTypeProcess !== ModalType.DUPLICATE && isEqual(formData, defaultFormToCompare)) {
        handleCloseModal();
        return;
      }

      const submitHandlers: { [key in ModalType]: (formData: IFormInput) => Promise<void> } = {
        [ModalType.CREATE]: handleSubmitCreate,
        [ModalType.EDIT]: handleSubmitEdit,
        [ModalType.DUPLICATE]: handleSubmitDuplicate,
      };

      const submitHandler = submitHandlers[modalTypeProcess];
      if (submitHandler) return await submitHandler(formData);
    },
    [
      defaultFormToCompare,
      modalTypeProcess,
      handleSubmitCreate,
      handleSubmitEdit,
      handleSubmitDuplicate,
      handleCloseModal,
    ]
  );

  const handleOnChangeDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (Array.isArray(event)) setDateRange(event as [Date | null, Date | null]);
    },
    [setDateRange]
  );

  return {
    loading,
    loadingCampaignValidation,
    handleChangeStrategy,
    handleCloseModal,
    handleSubmitData,
    handleOnChangeDate,
  };
};
