import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';

import {
  atomLoadingProcess,
  atomTotalsProcess,
} from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import { EXCLUDE_PAGER_KEYS } from '@/src/modules/CampaignsModule/constants';
import { EActionTypes } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { IOrderSort } from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { clearObjectFalsy } from '@/src/modules/CampaignsModule/utils';

import { useActionsFilters } from './useActionsFilters';

import {
  atomActionsList,
  atomActionType,
  atomFiltersAction,
  atomFiltersDropdownAction,
  atomOrderByAction,
  atomOrderByActionValues,
  atomPagerAction,
} from '@/modules/CampaignsModule/atoms/actionsAtom';

export const useActionsData = (actionType: EActionTypes) => {
  const [filtersAction, setFiltersAction] = useAtom(atomFiltersAction);
  const loadingProcess = useAtomValue(atomLoadingProcess);
  const totalsActionsList = useAtomValue(atomTotalsProcess);
  const actionsList = useAtomValue(atomActionsList);
  const setFiltersDropdownAction = useSetAtom(atomFiltersDropdownAction);
  const setPageParams = useSetAtom(atomPagerAction);
  const setOrderByAction = useSetAtom(atomOrderByAction);
  const setTableOrderByList = useSetAtom(atomOrderByActionValues);
  const setActionType = useSetAtom(atomActionType);

  const isInitialMount = useRef(true);

  const { getActions, parseURLParams, getSortOrder } = useActionsFilters();

  const totalApplyFilters = useMemo(() => {
    return Object.keys(filtersAction).filter((key) => !EXCLUDE_PAGER_KEYS.includes(key)).length;
  }, [filtersAction]);

  useEffect(() => setActionType(actionType), [actionType, setActionType]);

  useEffect(() => {
    const urlParams = parseURLParams();

    if (isInitialMount.current) {
      const clearFilters = clearObjectFalsy(urlParams);

      setFiltersDropdownAction({
        status: clearFilters.status,
        channel: clearFilters.channel,
      });

      setFiltersAction({ ...filtersAction, ...clearFilters });
      setPageParams({ page: urlParams.page || 1, limit: urlParams.limit || 10 });
      setOrderByAction({
        sortField: String(urlParams.sortField),
        sortOrder: urlParams.sortOrder as IOrderSort,
      });
      setTableOrderByList((prev) => {
        const targetField = prev.find((item) => item.id === urlParams.sortField);
        if (!targetField) return prev;

        return prev.map((cell) => ({
          ...cell,
          isAsc:
            cell.id === urlParams.sortField ? getSortOrder(String(urlParams.sortOrder)) : undefined,
        }));
      });

      isInitialMount.current = false;
      return;
    } else {
      void getActions({
        ...filtersAction,
      });
      setPageParams({ page: filtersAction.page || 1, limit: urlParams.limit || 10 });
    }
  }, [
    getActions,
    filtersAction,
    setPageParams,
    parseURLParams,
    setFiltersDropdownAction,
    setFiltersAction,
    setOrderByAction,
    setTableOrderByList,
    getSortOrder,
    setActionType,
  ]);

  return {
    actionsList,
    loadingProcess,
    totalsActionsList,
    totalApplyFilters,
  };
};
