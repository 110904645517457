import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';

import {
  atomFiltersDropdownRecurrentAction,
  atomFiltersRecurrentAction,
  atomLoadingProcessRecurrentAction,
  atomPagerRecurrentAction,
  atomRecurrentActionsList,
  atomTotalsProcessRecurrentAction,
} from '@/src/modules/CampaignsModule/atoms/recurrentActionsAtom';
import { EXCLUDE_PAGER_KEYS } from '@/src/modules/CampaignsModule/constants';
import { clearObjectFalsy } from '@/src/modules/CampaignsModule/utils';

import { useRecurrentActionFilters } from './useRecurrentActionFilters';

export const useRecurrentActionsData = () => {
  const [filtersRecurrentAction, setFiltersRecurrentAction] = useAtom(atomFiltersRecurrentAction);
  const loadingProcessRecurrentAction = useAtomValue(atomLoadingProcessRecurrentAction);
  const totalsProcessRecurrentAction = useAtomValue(atomTotalsProcessRecurrentAction);
  const recurrentActionsList = useAtomValue(atomRecurrentActionsList);
  const setFiltersDropdownRecurrentAction = useSetAtom(atomFiltersDropdownRecurrentAction);
  const setPagerRecurrentAction = useSetAtom(atomPagerRecurrentAction);

  const isInitialMount = useRef(true);

  const { getActions, parseURLParams } = useRecurrentActionFilters();

  const totalApplyFilters = useMemo(() => {
    return Object.keys(filtersRecurrentAction).filter((key) => !EXCLUDE_PAGER_KEYS.includes(key))
      .length;
  }, [filtersRecurrentAction]);

  useEffect(() => {
    const urlParams = parseURLParams();

    if (isInitialMount.current) {
      const clearFilters = clearObjectFalsy(urlParams);

      setFiltersDropdownRecurrentAction({
        status: clearFilters.status,
      });

      setFiltersRecurrentAction({ ...filtersRecurrentAction, ...clearFilters });
      setPagerRecurrentAction({ page: urlParams.page || 1, limit: urlParams.limit || 10 });

      isInitialMount.current = false;
      return;
    } else {
      void getActions({
        ...filtersRecurrentAction,
      });
      setPagerRecurrentAction({
        page: filtersRecurrentAction.page || 1,
        limit: urlParams.limit || 10,
      });
    }
  }, [
    getActions,
    filtersRecurrentAction,
    setPagerRecurrentAction,
    parseURLParams,
    setFiltersDropdownRecurrentAction,
    setFiltersRecurrentAction,
  ]);

  return {
    recurrentActionsList,
    loadingProcessRecurrentAction,
    totalsProcessRecurrentAction,
    totalApplyFilters,
  };
};
