import { memo } from 'react';

import { DateInput } from '@/lib/v2/components';
import { DateInputProps } from '@/lib/v2/components/DateInput/DateInput';

export interface IInputDateRangeProps extends Omit<DateInputProps, 'id'> {
  id: string;
  maxDate?: Date | null;
  dateRange?: [Date | null, Date | null] | null;
  onAction?: DateInputProps['onChange'];
}

const InputDateRange = ({
  dateRange,
  onAction,
  placeholder,
  maxDate,
  id,
  ...props
}: IInputDateRangeProps) => {
  return (
    <DateInput
      isRequired
      selectsRange
      endDate={dateRange?.[1] ?? null}
      id={id}
      maxDate={maxDate ?? new Date()}
      name="date"
      placeholder={placeholder}
      startDate={dateRange?.[0] ?? null}
      onChange={onAction}
      {...props}
    />
  );
};

export default memo(InputDateRange);
