import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { memo } from 'react';

import { TableSkeleton } from '@/lib/components';
import { Flex, If } from '@/lib/v2/components';

import { CampaignsHeaderTable } from '@/src/modules/CampaignsModule/components/CampaignsHeaderTable';
import { ActionsTable } from '@/src/modules/CampaignsModule/components/RecurrentActions/ActionsTable';

import { ActionsFilterBar } from './components/ActionsFilterBar';
import { ActionsToolBar } from './components/ActionsToolBar';

import {
  atomRecurrentActionsList,
  atomTotalRecurrentActionsSelectedRows,
  atomTotalsProcessRecurrentAction,
} from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';

const stickyHeaderModule = true;

const TableContainer = () => {
  const recurrentActionsList = useAtomValue(atomRecurrentActionsList);
  const totalRecurrentActionsSelectedRows = useAtomValue(atomTotalRecurrentActionsSelectedRows);
  const totalsProcessRecurrentAction = useAtomValue(atomTotalsProcessRecurrentAction);

  //TODO: refactor in customHook
  const wrapperActionsTable = classNames({
    'mt-[-52px]': recurrentActionsList && recurrentActionsList?.length > 0,
  });

  if (!recurrentActionsList) {
    return (
      <Flex column>
        <div className="size-full py-10">
          <TableSkeleton columns={6} />
        </div>
      </Flex>
    );
  }

  return (
    <Flex column>
      <If condition={totalsProcessRecurrentAction?.total > 0}>
        <CampaignsHeaderTable
          filterBar={<ActionsFilterBar />}
          selectedItems={totalRecurrentActionsSelectedRows}
          stickyHeader={stickyHeaderModule}
          toolBar={<ActionsToolBar />}
        />
      </If>
      <Flex column withGap className={wrapperActionsTable} itemAlignment="stretch">
        <ActionsTable stickyHeader={stickyHeaderModule} />
      </Flex>
    </Flex>
  );
};

export default memo(TableContainer);
