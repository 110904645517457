import { useAtomValue } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { useTranslation } from 'react-i18next';

import { If, Option, Select } from '@/lib/v2/components';

import { IFormProps } from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { atomRuleData, atomTagList } from '@/modules/RulesModule/atoms/rules';
import { useRuleDefaultOptions } from '@/modules/RulesModule/components/FormRule/hooks/useRuleDefaultOptions';
import {
  TAG_TYPE_FIELD_OPTIONS,
  TRANSLATE_RULES_LABELS,
  TRANSLATE_RULES_PLACEHOLDERS,
} from '@/modules/RulesModule/constants';
import { IRuleForm } from '@/modules/RulesModule/interfaces';

interface ITagTypeSelectProps {
  form: IFormProps<IRuleForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const TagTypeSelect = ({ form, rules, disabled = false }: ITagTypeSelectProps) => {
  const { t } = useTranslation('rules');
  const { setValue } = form;

  const tagList = useAtomValue(atomTagList);
  const rulesData = useAtomValue(atomRuleData);

  const [tagsGroupField, setTagsGroupField] = useState<Option[]>(rulesData?.tagsGroupField || []);

  const { tagTypeOptions } = useRuleDefaultOptions();

  const tagTypeField = useWatch({ control: form.control, name: 'tagTypeField' });

  const tagListOptions: Option[] = useMemo(
    () =>
      tagList?.list
        .map((tag) => ({
          id: tag.tag.id,
          name: tag.tag.name,
          value: tag.tag.id,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [tagList]
  );

  const handleChangeTagType = useCallback(() => {
    setTagsGroupField([]);
    setValue('tagsGroupField', []);
  }, [setValue]);

  const handleChangeTagsGroup = useCallback((option: Option | Option[]) => {
    setTagsGroupField(option as Option[]);
  }, []);

  return (
    <>
      <div className="mb-5 mr-8 w-full">
        <Select
          isRequired
          control={form.control}
          disabled={disabled}
          label={t(`${TRANSLATE_RULES_LABELS}.tagTypeField`)}
          name="tagTypeField"
          options={tagTypeOptions}
          placeholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.tagType`)}
          rules={rules.tagTypeField}
          onChange={handleChangeTagType}
        />
        <If condition={tagTypeField?.value === TAG_TYPE_FIELD_OPTIONS.GROUP_TAGS}>
          <Select
            isRequired
            multiSelect
            withSearch
            control={form.control}
            disabled={disabled}
            name="tagsGroupField"
            options={tagListOptions}
            placeholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.tagsGroup`)}
            rules={rules.tagsGroupField}
            value={tagsGroupField}
            onChange={handleChangeTagsGroup}
          />
        </If>
      </div>
    </>
  );
};

export default TagTypeSelect;
