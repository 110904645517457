import { useAtomValue } from 'jotai';
import { memo } from 'react';

import { If } from '@/lib/v2/components';

import { useDropdownFilters } from '@/src/modules/CampaignsModule/components/CampaignActions/TableContainer/components/ActionsFilterBar/hooks/useDropdownFilters';

import { atomActionType } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomDetailCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';
import FilterChannel from '@/modules/CampaignsModule/components/CampaignActions/TableContainer/components/ActionsFilterBar/components/FilterActionChannel';
import FilterStatus from '@/modules/CampaignsModule/components/CampaignActions/TableContainer/components/ActionsFilterBar/components/FilterActionStatus';
import { ECampaignsTypeOptions } from '@/modules/CampaignsModule/interfaces/Campaigns';

const FiltersActionDropdown = () => {
  const actionType = useAtomValue(atomActionType);
  const detailCampaign = useAtomValue(atomDetailCampaign);
  const {
    state: { isNotDraftOrFinished },
  } = useDropdownFilters();

  return (
    <>
      <If condition={isNotDraftOrFinished}>
        <FilterStatus />
      </If>
      <If
        condition={!!actionType || detailCampaign?.campaignTypeId === ECampaignsTypeOptions.MANUAL}
      >
        <FilterChannel />
      </If>
    </>
  );
};

export default memo(FiltersActionDropdown);
