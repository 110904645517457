import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select, Text } from '@/lib/v2/components';

import { useRuleDefaultOptions } from '@/modules/RulesModule/components/FormRule/hooks/useRuleDefaultOptions';
import {
  TRANSLATE_RULES_PLACEHOLDERS,
  TRANSLATE_RULES_SEQUENCE_SETTINGS,
} from '@/modules/RulesModule/constants';
import { IFormProps, IRuleForm } from '@/modules/RulesModule/interfaces';

interface IExecutionTimeSelectProps {
  form: IFormProps<IRuleForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

export const ExecutionTimeSelect = ({
  form,
  disabled = false,
  rules,
}: IExecutionTimeSelectProps) => {
  const { t } = useTranslation('rules');
  const { executionOptions } = useRuleDefaultOptions();

  return (
    <div className="mb-5 flex items-center">
      <Text className="mr-2 whitespace-nowrap" variant="text">
        {t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.executionTime`)}
      </Text>
      <div className="min-w-40">
        <Select
          isRequired
          control={form.control}
          disabled={disabled}
          name="execution"
          options={executionOptions}
          placeholder={t(`${TRANSLATE_RULES_PLACEHOLDERS}.executionTime`)}
          rules={rules.execution}
        />
      </div>
    </div>
  );
};
