/* cspell: disable*/
export const FrequentContactsIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 25 24" width="25" xmlns="http://www.w3.org/2000/svg">
      <g id="Grupo 9228">
        <g id="Grupo 9228_2">
          <path
            d="M10.0758 12.628C13.2089 12.628 15.7488 10.0882 15.7488 6.95504C15.7488 3.82193 13.2089 1.28204 10.0758 1.28204C6.94272 1.28204 4.40283 3.82193 4.40283 6.95504C4.40283 10.0882 6.94272 12.628 10.0758 12.628Z"
            fill="#004DBC"
            id="Elipse 1696"
          />
          <path
            d="M16.2348 22.3131C16.0935 22.3518 15.9456 22.3601 15.8008 22.3375C15.656 22.3149 15.5176 22.262 15.3948 22.1821C15.2709 22.1074 15.163 22.009 15.0772 21.8926C14.9913 21.7761 14.9293 21.6439 14.8947 21.5035C14.86 21.363 14.8534 21.2171 14.8753 21.0741C14.8971 20.9311 14.9469 20.7938 15.0218 20.6701L16.7018 17.8521C16.7765 17.7282 16.8748 17.6203 16.9913 17.5344C17.1077 17.4486 17.24 17.3866 17.3804 17.3519C17.5209 17.3173 17.6668 17.3107 17.8098 17.3325C17.9528 17.3543 18.0901 17.4041 18.2138 17.4791L20.0608 18.5791L21.1608 16.7321C21.2355 16.6083 21.3339 16.5005 21.4503 16.4147C21.5667 16.329 21.6988 16.267 21.8392 16.2323C21.9795 16.1976 22.1254 16.1909 22.2683 16.2126C22.4113 16.2342 22.5485 16.2838 22.6723 16.3586C22.7961 16.4333 22.9039 16.5317 22.9897 16.6481C23.0755 16.7645 23.1374 16.8966 23.1721 17.037C23.2068 17.1773 23.2135 17.3231 23.1918 17.4661C23.1701 17.609 23.1205 17.7463 23.0458 17.8701L21.3848 20.6701C21.232 20.9156 20.9919 21.0943 20.7128 21.1701C20.4265 21.239 20.1246 21.1919 19.8728 21.0391L18.0258 19.9391L16.9058 21.8051C16.8368 21.9328 16.7413 22.0443 16.6255 22.132C16.5097 22.2196 16.3765 22.2814 16.2348 22.3131Z"
            fill="#004DBC"
            id="Trazado 3812"
          />
          <g id="Grupo 6439">
            <path
              d="M13.0808 21.976C12.8909 21.2085 13.0116 20.397 13.4168 19.718L15.0958 16.9C15.3449 16.4864 15.6907 16.1395 16.1035 15.8891C16.5164 15.6388 16.9839 15.4926 17.4658 15.463C16.8123 14.3921 15.9411 13.4702 14.9088 12.757C14.7842 12.6747 14.6382 12.6308 14.4889 12.6308C14.3395 12.6308 14.1934 12.6747 14.0688 12.757C12.8968 13.5814 11.4988 14.0238 10.0658 14.0238C8.63288 14.0238 7.23489 13.5814 6.06283 12.757C5.93991 12.6704 5.79323 12.6239 5.64285 12.6239C5.49246 12.6239 5.34578 12.6704 5.22286 12.757C3.20367 14.2997 1.7745 16.488 1.17385 18.957C1.17385 18.957 0.352851 22.316 3.67385 22.316H13.2099C13.1556 22.2073 13.1123 22.0934 13.0808 21.976Z"
              fill="#004DBC"
              id="Trazado 3813"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
