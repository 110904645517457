import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Breadcrumb, If } from '@/lib/v2/components';
import { CustomPaths } from '@/lib/v2/components/Breadcrumb/Breadcrumb';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import {
  atomDisableEditAction,
  atomGlobalLoadingAction,
  atomProcessingAction,
} from '@/src/modules/CampaignsModule/atoms/actionEmailAtom';
import {
  atomActionDetailQuickTools,
  atomHeaderCurrentStep,
} from '@/src/modules/CampaignsModule/atoms/actionsAtom';
import { atomActionInfoSms } from '@/src/modules/CampaignsModule/atoms/actionSMSAtom';
import { atomActionInfoWhatsApp } from '@/src/modules/CampaignsModule/atoms/actionWhatsAppAtom';
import { atomActionData } from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleEditActionsModal } from '@/src/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { ActionMenuTools } from '@/src/modules/CampaignsModule/components/ActionMenuTools';
import { WHATSAPP_PREFIX } from '@/src/modules/CampaignsModule/constants';
import { useActionData } from '@/src/modules/CampaignsModule/hooks/useActionData';
import { useCampaignLastPath } from '@/src/modules/CampaignsModule/hooks/useCampaignLastPath';
import {
  EChannelsID,
  ISmsInfoResponseV1,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';
import { IWhatsAppInfoResponseV1 } from '@/src/modules/CampaignsModule/interfaces/WhatsApp';
import { isActionEditable } from '@/src/modules/CampaignsModule/utils';
import { isActionFinish } from '@/src/modules/CampaignsModule/utils/isActionEditable';

import { BackToCampaignsModal } from './components/BackToCampaignsModal';
import { HeaderActionButtons } from './components/HeaderActionButtons';

const HeaderSectionActions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { actionId } = useParams();
  const { pathname } = useLocation();

  const [disableEditAction, setDisableEditAction] = useAtom(atomDisableEditAction);
  const globalLoadingAction = useAtomValue(atomGlobalLoadingAction);
  const processingAction = useAtomValue(atomProcessingAction);
  const currentStep = useAtomValue(atomHeaderCurrentStep);
  const actionData = useAtomValue(atomActionData);
  const setActionInfoSms = useSetAtom(atomActionInfoSms);
  const setActionDetailQuickTools = useSetAtom(atomActionDetailQuickTools);
  const setToggleEditActionsModal = useSetAtom(atomToggleEditActionsModal);

  const [toggleBackToCampaignsModal, setToggleBackToCampaignsModal] = useState<boolean>(false);
  const setActionInfoWhatsApp = useSetAtom(atomActionInfoWhatsApp);

  const outboundWhatsApp = useFeatureFlag('outboundWhatsApp');

  const { action, refetch } = useActionData(Number(actionId));
  const { campaignLastPath } = useCampaignLastPath();

  const isEditable = useMemo(() => isActionEditable(Number(action?.status)), [action?.status]);
  const isFinish = useMemo(() => isActionFinish(Number(action?.status)), [action?.status]);

  useEffect(() => {
    //TODO: Review this refetch & atom for update data
    refetch();
    setDisableEditAction(!isEditable);
  }, [isEditable, refetch, setDisableEditAction]);

  useEffect(() => {
    return () => setActionInfoSms({} as ISmsInfoResponseV1);
  }, [setActionInfoSms]);

  useEffect(() => {
    return () => setActionInfoWhatsApp({} as IWhatsAppInfoResponseV1);
  }, [setActionInfoWhatsApp]);

  useEffect(() => {
    if (pathname.includes('/scheduling') || pathname.includes('/summary')) {
      refetch();
    }
  }, [pathname, refetch]);

  const mainActionPath = useMemo(() => {
    const parts = pathname.replace(/^\/|\/$/g, '').split('/');
    const mainPath = parts.slice(3, 5).join('/');
    return mainPath;
  }, [pathname]);

  const handleBackHeader = useCallback(() => {
    if (pathname.includes('/scheduling') || pathname.includes('/summary')) {
      navigate(-1);
    } else {
      !isEditable ? navigate(campaignLastPath.action) : setToggleBackToCampaignsModal(true);
    }
  }, [campaignLastPath.action, isEditable, navigate, pathname]);

  const handleOpenModal = useCallback(() => {
    setActionDetailQuickTools({
      id: action?.actionId as number,
      name: action?.name as string,
      redirect: false,
      origin: 'detail',
      type: action?.channel as EChannelsID,
    });

    setToggleEditActionsModal(true);
  }, [setActionDetailQuickTools, action, setToggleEditActionsModal]);

  const handleClickItemDropdown = useCallback(() => {
    setActionDetailQuickTools({
      id: actionData?.actionId as number,
      name: actionData?.name as string,
      redirect: true,
      origin: 'detail',
      type: actionData?.channel as EChannelsID,
    });
  }, [actionData, setActionDetailQuickTools]);

  /* cspell:disable*/
  const HeaderSectionPaths = useMemo(() => {
    let breadcrumbLinks: CustomPaths[] = [
      {
        id: 'campaign-id-path',
        name: action?.campaignName as string,
        to: {
          path: campaignLastPath.action,
        },
      },
      {
        id: 'action-name-path',
        name: (() => {
          if (!action) {
            return '';
          }
          if (outboundWhatsApp) {
            return action.name.replace(WHATSAPP_PREFIX, '');
          }
          return action.name;
        })(),
        to: {
          path: mainActionPath,
        },
      },
    ];

    if (pathname.includes('/scheduling') && !isFinish) {
      breadcrumbLinks = [
        ...breadcrumbLinks,
        {
          id: 'scheduling-path',
          name: t('BREADCRUMB.sendConfiguration'),
        },
      ];
    }

    if (pathname.includes('/summary') && !isFinish) {
      breadcrumbLinks = [
        ...breadcrumbLinks,
        {
          id: 'scheduling-path',
          name: t('BREADCRUMB.sendConfiguration'),
          to: {
            path: `${mainActionPath}/scheduling`,
          },
        },
        {
          id: 'summary-path',
          name: t('BREADCRUMB.summary'),
          to: {
            path: `${mainActionPath}/summary`,
          },
        },
      ];
    }

    return breadcrumbLinks;
  }, [action, campaignLastPath.action, mainActionPath, pathname, t, outboundWhatsApp, isFinish]);
  /* cspell:enable*/

  const HeaderSectionActionButtons = useMemo(
    () => (
      <>
        <ActionMenuTools id="options-actions-dropdown" onClick={handleClickItemDropdown} />
        <HeaderActionButtons
          disabled={disableEditAction}
          loading={globalLoadingAction}
          processing={processingAction}
          step={currentStep}
        />
      </>
    ),
    [currentStep, disableEditAction, globalLoadingAction, handleClickItemDropdown, processingAction]
  );

  return (
    <div className="relative z-40 shadow-md">
      <If condition={!!action}>
        <Breadcrumb
          withoutMargin
          actionButtons={HeaderSectionActionButtons}
          customPaths={HeaderSectionPaths}
          handleOnBackClick={handleBackHeader}
          isEditable={!pathname.includes('scheduling') && !pathname.includes('summary')}
          itemId={action?.actionId}
          onEditableClick={handleOpenModal}
        />
      </If>

      <BackToCampaignsModal
        isOpen={toggleBackToCampaignsModal}
        onClose={setToggleBackToCampaignsModal}
      />
    </div>
  );
};

export default HeaderSectionActions;
