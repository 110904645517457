import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';

import { Option } from '@/lib/v2/components';

import {
  atomSelectedActionsRowsWithID,
  atomSelectedRowsAction,
  atomSelectedRowsQuickTools,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomMoveCampaignPayload } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleMoveActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaignActions } from '@/modules/CampaignsModule/hooks/useCampaignActions';

export const useHandleEvents = () => {
  const { moveAction, loading } = useCampaignActions();

  const actionId = useAtomValue(atomSelectedActionsRowsWithID);
  const [moveCampaignPayload, setMoveCampaignPayload] = useAtom(atomMoveCampaignPayload);
  const setToggleMoveActionsModal = useSetAtom(atomToggleMoveActionsModal);
  const [selectedRowsQuickTools, setSelectedRowsQuickTools] = useAtom(atomSelectedRowsQuickTools);
  const setSelectedRows = useSetAtom(atomSelectedRowsAction);

  const handleCloseModal = useCallback(() => {
    setToggleMoveActionsModal(false);
    if (selectedRowsQuickTools) {
      setSelectedRowsQuickTools(false);
      setSelectedRows([]);
    }
  }, [
    selectedRowsQuickTools,
    setSelectedRows,
    setSelectedRowsQuickTools,
    setToggleMoveActionsModal,
  ]);

  const handleSubmitData = useCallback(async () => {
    if (!moveCampaignPayload) return;

    await moveAction(moveCampaignPayload);
    handleCloseModal();
  }, [handleCloseModal, moveAction, moveCampaignPayload]);

  const handleChangeCampaigns = (value: Option | Option[]) => {
    const data = value as Option;
    /** cspell:disable */
    setMoveCampaignPayload({ campaniaId: Number(data.id), elementosId: actionId });
    /** cspell:enable */
  };

  return {
    handleCloseModal,
    handleSubmitData,
    handleChangeCampaigns,
    loading,
  };
};
