import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import useParseParamsURL from '@/src/application/hooks/useParseParamsURL';
import { atomFiltersDropdownCampaign } from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomCampaignsRecurrentList,
  atomFiltersRecurrent,
  atomPagerCampaignsRecurrent,
  atomRecurrentOrderBy,
} from '@/src/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import {
  CAMPAIGNS_RECURRENT_PATH,
  defaultPager,
  defaultRecurrentOrderBy,
  initialOrderByKeys,
  initialPagerKeys,
} from '@/src/modules/CampaignsModule/constants';
import {
  ECampaignsTypeOptions,
  ICampaignListPayload,
  IFiltersTableCampaign,
  IOrderByTable,
  IPager,
  PATH_TYPES_CAMPAIGN,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { useCampaignLastPath } from './useCampaignLastPath';
import { useCampaignRecurrent } from './useCampaignRecurrent';

export const useCampaignRecurrentFilters = () => {
  const navigate = useNavigate();

  const [filtersRecurrent, setFiltersRecurrent] = useAtom(atomFiltersRecurrent);
  const recurrentOrderBy = useAtomValue(atomRecurrentOrderBy);
  const pageParams = useAtomValue(atomPagerCampaignsRecurrent);
  const setCampaignsRecurrentList = useSetAtom(atomCampaignsRecurrentList);
  const setFiltersDropdownCampaign = useSetAtom(atomFiltersDropdownCampaign);

  const { getList } = useCampaignRecurrent();
  const { parseNumberArray, parseDateTimestamp } = useParseParamsURL();
  const { setCampaignPath } = useCampaignLastPath();

  const parseURLParams = useCallback((): Partial<IFiltersTableCampaign> => {
    const searchParams = new URLSearchParams(location.search);
    return {
      state: parseNumberArray(searchParams.get('state')),
      search: searchParams.get('search') || undefined,
      startDate: parseDateTimestamp(searchParams.get('startDate')),
      endDate: parseDateTimestamp(searchParams.get('endDate')),
      page: Number(searchParams.get('page')),
      limit: Number(searchParams.get('limit')),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateURLFromFilters = useCallback(
    (filters: ICampaignListPayload | undefined = filtersRecurrent) => {
      if (!filters) return;

      const searchParams = new URLSearchParams();
      Object.entries(filters).forEach(([key, value]) => {
        if (
          value !== undefined &&
          value !== null &&
          !(
            (initialPagerKeys.includes(key) && value === defaultPager[key as keyof IPager]) ||
            (initialOrderByKeys.includes(key) &&
              value === defaultRecurrentOrderBy[key as keyof IOrderByTable])
          )
        ) {
          if (Array.isArray(value)) {
            searchParams.set(key, value.join(','));
          } else if (value instanceof Date) {
            const timeStampCast = new Date(value).getTime() / 1000;
            searchParams.set(key, timeStampCast.toString());
          } else {
            searchParams.set(key, String(value));
          }
        }
      });

      setCampaignPath({
        pathType: PATH_TYPES_CAMPAIGN.RECURRENT,
        path: `${location.pathname}${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`,
      });

      /* navigate(`?${searchParams.toString()}`, { replace: false }); */
      window.history.replaceState(
        {},
        '',
        `${location.pathname}${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`
      );
    },
    [filtersRecurrent, setCampaignPath]
  );

  const getRecurrentCampaigns = useCallback(
    async (payload: ICampaignListPayload) => {
      await getList({
        ...payload,
        campaignTypeId: [ECampaignsTypeOptions.AUTOMATIC],
        sortField: recurrentOrderBy.sortField,
        sortOrder: recurrentOrderBy.sortOrder,
      });

      updateURLFromFilters({
        ...payload,
        sortField: recurrentOrderBy.sortField,
        sortOrder: recurrentOrderBy.sortOrder,
      });
    },
    [getList, recurrentOrderBy, updateURLFromFilters]
  );

  const CAMPAIGNS_URL = useMemo(() => {
    return pageParams.limit > defaultPager.limit
      ? `${CAMPAIGNS_RECURRENT_PATH}?limit=${pageParams.limit}`
      : CAMPAIGNS_RECURRENT_PATH;
  }, [pageParams.limit]);

  const resetFilters = useCallback(() => {
    setFiltersRecurrent({ ...pageParams, page: 1 });
    setFiltersDropdownCampaign(RESET);
    navigate(CAMPAIGNS_URL);
  }, [CAMPAIGNS_URL, navigate, pageParams, setFiltersRecurrent, setFiltersDropdownCampaign]);

  const resetUnmount = useCallback(() => {
    setCampaignsRecurrentList(RESET);
    setFiltersRecurrent(RESET);
  }, [setCampaignsRecurrentList, setFiltersRecurrent]);

  return {
    resetUnmount,
    parseURLParams,
    updateURLFromFilters,
    getRecurrentCampaigns,
    resetFilters,
  };
};
