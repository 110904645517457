/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import './i18next.config';

import { createStore, Provider } from 'jotai';
import React from 'react';
import { createRoot } from 'react-dom/client';

import ErrorBoundary from './application/components/ErrorBoundary';
import InboxProvider from './application/context/InboxProvider';
import { getEnv } from './application/hooks/util/useEnv';
import { App } from './App';
import Auth from './Auth';
import configData from './config.json';

import '@/lib/app.css';
import './index.css';

const env = getEnv();

const { JWT_DEV_TOKEN } = configData.endpoints[env];
const jwtToken = JWT_DEV_TOKEN !== '' ? JWT_DEV_TOKEN : window.__JWTToken;

const onsiteEnabled = window.__onsiteEnabled || false;

const appRoot = onsiteEnabled
  ? document.getElementById('root-onsite')
  : document.getElementById('root');
appRoot.setAttribute('notranslate', true);

const root = createRoot(appRoot);
const myStore = createStore();

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <InboxProvider>
        <Provider store={myStore}>{!jwtToken ? <Auth /> : <App />}</Provider>
      </InboxProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
