//cspell:ignore mensaje
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { CardSection, Flex, PhonePreviewWhatsApp } from '@/lib/v2/components';

import { useSmsSummaryMain } from '@/src/modules/CampaignsModule/screens/ActionManagement/Sms/SmsSummaryMain/useSmsSummaryMain.hooks';

import { atomGlobalLoadingAction } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { atomCallbackReportSmsStep } from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomActionInfoSms } from '@/modules/CampaignsModule/atoms/actionSMSAtom';
import { atomMediaUrl } from '@/modules/CampaignsModule/atoms/actionWhatsAppAtom';
import { SmsSummary } from '@/modules/CampaignsModule/components/actionManagement/SmsAction/components/SmsSummary';
import { useExtractBodyFromJson } from '@/modules/CampaignsModule/components/actionManagement/WhatsAppAction/hooks/useExtractBodyFromJson';
import { ConfirmAction } from '@/modules/CampaignsModule/components/CampaignsModal/ConfirmAction';
import { SummarySkeleton } from '@/modules/CampaignsModule/components/SummaryAction/SummarySkeleton';
import { atomWhatsAppTemplateSelected } from '@/modules/ContactsModule/atoms/WhatsApp';

const WhatsAppSummaryMain = () => {
  const actionInfoWhatsApp = useAtomValue(atomActionInfoSms);
  const globalLoadingAction = useAtomValue(atomGlobalLoadingAction);

  const {
    events: { handleConfirmAction, handleReportClick },
    state: { loading },
  } = useSmsSummaryMain();

  const setCallbackReportSmsStep = useSetAtom(atomCallbackReportSmsStep);
  const selectedTemplate = useAtomValue(atomWhatsAppTemplateSelected);
  const mediaUrl = useAtomValue(atomMediaUrl);

  useEffect(() => {
    setCallbackReportSmsStep(() => handleReportClick);
  }, [setCallbackReportSmsStep, handleReportClick]);

  const bodyMessage = useExtractBodyFromJson(
    actionInfoWhatsApp?.mensaje,
    selectedTemplate?.variables
  );

  return (
    <>
      <div className="relative z-[0] bg-white">
        <CardSection noPadding>
          <Flex column>
            <Flex itemAlignment="start">
              <div className="my-12 w-1/2 bg-white px-10">
                {globalLoadingAction ? <SummarySkeleton /> : <SmsSummary />}
              </div>
              <div className="grid min-h-[calc(100dvh-145px)] w-1/2 items-center justify-center bg-[#F4F5FA] py-4">
                {
                  /* cspell:disable */
                  <PhonePreviewWhatsApp
                    loading={globalLoadingAction || Object.keys(actionInfoWhatsApp).length === 0}
                    mediaUrl={mediaUrl}
                    message={bodyMessage}
                  />
                  /* cspell:enable */
                }
              </div>
            </Flex>
          </Flex>
        </CardSection>
      </div>
      <ConfirmAction loading={loading} onConfirm={handleConfirmAction} />
    </>
  );
};

export default WhatsAppSummaryMain;
