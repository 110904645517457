import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, CardSection, Flex, IconSvg } from '@/lib/v2/components';
import { CreateIcon } from '@/lib/v2/icons/outline';
import { CampaignsIcon } from '@/lib/v2/icons/solid';
import { SectionHeader } from '@/lib/v2/organisms/SectionHeader';

import { SectionHeaderSubtitle } from '@/src/modules/CampaignsModule/components/SectionHeaderSubtitle';
import { TotalCountDisplay } from '@/src/modules/CampaignsModule/components/TotalCountDisplay';
import { useCampaignRecurrentFilters } from '@/src/modules/CampaignsModule/hooks/useCampaignRecurrentFilters';
import { useRecurrentModal } from '@/src/modules/CampaignsModule/hooks/useRecurrentModal';

import { TableContainer } from '@/modules/CampaignsModule/components/CampaignsRecurrent/TableContainer';
import { useRecurrentData } from '@/modules/CampaignsModule/hooks/useCampaignRecurrentData';

const CampaignRecurrentMain = () => {
  const { t } = useTranslation();

  const { resetUnmount } = useCampaignRecurrentFilters();
  const { recurrentMessageList, loadingProcess, totalsCampaignRecurrentList, totalApplyFilters } =
    useRecurrentData();
  const { handleOnOpenModal } = useRecurrentModal();

  useEffect(() => {
    return () => resetUnmount();
  }, [resetUnmount]);

  return (
    <>
      <Card>
        <CardSection noPadding>
          <Flex column>
            <SectionHeader
              actionsButtons={
                <Button
                  iconLeft={<IconSvg strokeColor="white" svgComponent={<CreateIcon />} />}
                  id="create-campaigns-button"
                  size="small"
                  onClick={handleOnOpenModal}
                >
                  {t('RECURRENT_MESSAGE.button')}
                </Button>
              }
              icon={<IconSvg height="20px" svgComponent={<CampaignsIcon />} width="20px" />}
              subtitle={
                <SectionHeaderSubtitle
                  countFilters={totalApplyFilters}
                  countRecordsPartial={totalsCampaignRecurrentList.partial}
                  dataList={recurrentMessageList}
                  i18nKey="RECURRENT_MESSAGE.search"
                  loading={loadingProcess}
                />
              }
              title={t('RECURRENT_MESSAGE.title')}
              titleDetail={
                <TotalCountDisplay
                  hasItems={!!recurrentMessageList?.length}
                  isLoading={loadingProcess}
                  total={totalsCampaignRecurrentList.total}
                />
              }
            />
            <Flex column withGap gapSize="medium">
              <TableContainer />
            </Flex>
          </Flex>
        </CardSection>
      </Card>
    </>
  );
};

export default CampaignRecurrentMain;
