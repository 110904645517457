import { useAtomValue, useSetAtom } from 'jotai';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TableSkeleton } from '@/lib/components';
import { Table } from '@/lib/components/Table';
import { Text } from '@/lib/v2/components';
import { EmptyCampaignsIcon, EmptyContactsIcon } from '@/lib/v2/icons/general';
import { EmptyScreenTable } from '@/lib/v2/organisms';

import { useActionsTableColumns } from './hooks/useActionsTableColumns';
import { useHandleEvents } from './hooks/useHandleEvents';

import {
  atomLoadingProcessRecurrentAction,
  atomPagerRecurrentAction,
  atomRecurrentActionsList,
  atomSelectedRowsRecurrentAction,
  atomTotalsProcessRecurrentAction,
} from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';
import { defaultPager } from '@/modules/CampaignsModule/constants';

interface IActionsTableProps {
  // eslint-disable-next-line react/boolean-prop-naming
  stickyHeader?: boolean;
}

const ActionsTable = ({ stickyHeader = false }: IActionsTableProps) => {
  const { t } = useTranslation();

  const recurrentActionsList = useAtomValue(atomRecurrentActionsList);
  const loadingProcessRecurrentAction = useAtomValue(atomLoadingProcessRecurrentAction);
  const totalsActionList = useAtomValue(atomTotalsProcessRecurrentAction);
  const pagerRecurrentAction = useAtomValue(atomPagerRecurrentAction);
  const setSelectedRows = useSetAtom(atomSelectedRowsRecurrentAction);

  const { actionsColumns } = useActionsTableColumns();

  const { handleClickEmptyScreenNoResult, handleChangeTablePage } = useHandleEvents();

  const gotoPageIndex = useMemo(
    () => (pagerRecurrentAction?.page ? pagerRecurrentAction?.page - 1 : 0),
    [pagerRecurrentAction]
  );

  const totalRecords = useMemo(
    () => Math.ceil(totalsActionList.partial / (pagerRecurrentAction?.limit || defaultPager.limit)),
    [pagerRecurrentAction?.limit, totalsActionList.partial]
  );

  const withPagination = useMemo(
    () => ({
      totalPages: totalRecords,
      fetchData: handleChangeTablePage,
    }),
    [handleChangeTablePage, totalRecords]
  );

  const isActionActive = true;

  const emptyScreen = useMemo(
    () => ({
      totalCount: totalsActionList.total || 0,
      noResult: (
        <EmptyScreenTable
          actionText={t(
            'RECURRENT_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsActionText'
          )}
          description={t(
            'RECURRENT_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsDescription'
          )}
          id="noResults-actions"
          image={<EmptyContactsIcon />}
          title={t('RECURRENT_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_NO_RESULTS.emptyCampaignsTitle')}
          onClickAction={handleClickEmptyScreenNoResult}
        />
      ),
      noData: (
        <EmptyScreenTable
          actionText={
            <Text className="text-center !text-14 text-emblue-text" fontWeight="normal">
              {!isActionActive
                ? t(
                    'RECURRENT_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_INACTIVE.emptyCampaignsDescriptionAlt'
                  )
                : ''}
            </Text>
          }
          description={
            !isActionActive
              ? t('RECURRENT_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_INACTIVE.emptyCampaignsDescription')
              : t('RECURRENT_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_ACTIVE.emptyCampaignsDescription')
          }
          image={<EmptyCampaignsIcon />}
          title={t('RECURRENT_ACTIONS_MAIN.EMPTY_SCREEN_TABLE_INACTIVE.emptyCampaignsTitle')}
        />
      ),
    }),
    [handleClickEmptyScreenNoResult, isActionActive, t, totalsActionList.total]
  );

  if (loadingProcessRecurrentAction) {
    return <TableSkeleton columns={7} />;
  }

  return (
    <div className="w-full text-base text-[#364365] 2xl:text-14">
      <Table
        noNegativeMargin
        withCheckbox
        classNamePagination="px-4 mb-2"
        columns={actionsColumns}
        data={recurrentActionsList}
        defaultSize={pagerRecurrentAction?.limit}
        emptyScreen={emptyScreen}
        gotoPageIndex={gotoPageIndex}
        isLoading={loadingProcessRecurrentAction}
        lastPageViewed={pagerRecurrentAction?.page}
        setSelectedRows={setSelectedRows}
        stickyHeader={stickyHeader}
        withPagination={withPagination}
      />
    </div>
  );
};

export default memo(ActionsTable);
