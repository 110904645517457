import { useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useParseParamsURL from '@/src/application/hooks/useParseParamsURL';
import { atomDetailCampaignRecurrent } from '@/src/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import {
  atomActionMaster,
  atomFiltersDropdownRecurrentAction,
  atomFiltersRecurrentAction,
  atomOrderByRecurrentAction,
  atomPagerRecurrentAction,
  atomRecurrentActionsList,
} from '@/src/modules/CampaignsModule/atoms/recurrentActionsAtom';
import {
  defaultPager,
  DROPDOWN_ACTIONS_STATE_GROUPED,
  initialPagerKeys,
} from '@/src/modules/CampaignsModule/constants';
import {
  IActionStatus,
  ICampaignActionsListPayload,
  IFilterTableRecurrentActions,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import { EChannelsID, IPager } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { useRecurrentActions } from './useRecurrentActions';

export const useRecurrentActionFilters = () => {
  const navigate = useNavigate();
  const { recurrentId } = useParams();

  const orderByRecurrentAction = useAtomValue(atomOrderByRecurrentAction);
  const pagerRecurrentAction = useAtomValue(atomPagerRecurrentAction);
  const setRecurrentActionsList = useSetAtom(atomRecurrentActionsList);
  const setFiltersRecurrentAction = useSetAtom(atomFiltersRecurrentAction);
  const setFiltersDropdownRecurrentAction = useSetAtom(atomFiltersDropdownRecurrentAction);
  const setActionMaster = useSetAtom(atomActionMaster);
  const setDetailCampaignRecurrent = useSetAtom(atomDetailCampaignRecurrent);

  const { getList } = useRecurrentActions();
  const { parseDateTimestamp } = useParseParamsURL();

  const getStatusValues = useCallback((statuses: IActionStatus[]): number[] => {
    return statuses.flatMap((status) => {
      const group = DROPDOWN_ACTIONS_STATE_GROUPED.find((group) => group.label === status);
      return group ? group.values : [];
    });
  }, []);

  const parseURLParams = useCallback((): Partial<IFilterTableRecurrentActions> => {
    const searchParams = new URLSearchParams(location.search);
    return {
      status: searchParams.get('status')?.split(',') as IActionStatus[],
      search: searchParams.get('search') || undefined,
      startDate: parseDateTimestamp(searchParams.get('startDate')),
      endDate: parseDateTimestamp(searchParams.get('endDate')),
      page: Number(searchParams.get('page')),
      limit: Number(searchParams.get('limit')),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateURLFromFilters = useCallback((filters: IFilterTableRecurrentActions | undefined) => {
    if (!filters) return;

    const searchParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (
        value !== undefined &&
        value !== null &&
        !(initialPagerKeys.includes(key) && value === defaultPager[key as keyof IPager])
      ) {
        if (Array.isArray(value)) {
          searchParams.set(key, value.join(','));
        } else if (value instanceof Date) {
          const timeStampCast = new Date(value).getTime() / 1000;
          searchParams.set(key, timeStampCast.toString());
        } else {
          searchParams.set(key, String(value));
        }
      }
    });

    /* navigate(`?${searchParams.toString()}`, { replace: true }); */
    window.history.replaceState(
      {},
      '',
      `${location.pathname}${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`
    );
  }, []);

  const getActions = useCallback(
    async (payload: IFilterTableRecurrentActions) => {
      await getList({
        ...(payload as ICampaignActionsListPayload),
        campaignId: Number(recurrentId),
        channel: [EChannelsID.EMAIL],
        sortField: orderByRecurrentAction.sortField,
        sortOrder: orderByRecurrentAction.sortOrder,
      });

      updateURLFromFilters({ ...payload });
    },
    [getList, recurrentId, orderByRecurrentAction, updateURLFromFilters]
  );

  const resetFilters = useCallback(() => {
    setFiltersRecurrentAction({ ...pagerRecurrentAction, page: 1 });
    setFiltersDropdownRecurrentAction(RESET);

    const allowedParams = ['sortField', 'sortOrder', 'limit'];
    const newSearchParams = new URLSearchParams(
      Object.fromEntries(
        [...new URLSearchParams(location.search)].filter(([key]) => allowedParams.includes(key))
      )
    );

    navigate(
      { pathname: location.pathname, search: newSearchParams.toString() },
      { replace: true }
    );
  }, [
    navigate,
    pagerRecurrentAction,
    setFiltersRecurrentAction,
    setFiltersDropdownRecurrentAction,
  ]);

  const resetUnmount = useCallback(() => {
    setRecurrentActionsList(RESET);
    setFiltersDropdownRecurrentAction(RESET);
    setFiltersRecurrentAction(RESET);
    setActionMaster(RESET);
    setDetailCampaignRecurrent(RESET);
  }, [
    setRecurrentActionsList,
    setFiltersDropdownRecurrentAction,
    setFiltersRecurrentAction,
    setActionMaster,
    setDetailCampaignRecurrent,
  ]);

  return {
    getStatusValues,
    parseURLParams,
    updateURLFromFilters,
    getActions,
    resetFilters,
    resetUnmount,
  };
};
