import { Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';

import { ArrowDownTableIcon, ArrowUpTableIcon } from '@/lib/icon';
import { If } from '@/lib/v2/components';
import { Flex } from '@/lib/v2/components/Layout/Stack';

interface ITableOrder {
  getTableOrderByList: (element: { id: string; isAsc: boolean | undefined }) => void;
  elementName: string;
  setTableOrderByList?: Dispatch<SetStateAction<{ id: string; isAsc: boolean | undefined }[]>>;
  tableOrderByList?: { id: string; isAsc: boolean | undefined }[];
}

export const SortTableArrow = ({
  getTableOrderByList,
  elementName,
  setTableOrderByList,
  tableOrderByList,
}: ITableOrder) => {
  //TODO - Migrate to new version designKit
  const location = useLocation();
  const isCampaignsPage = location.pathname.includes('campaigns');

  const selectedElement =
    tableOrderByList && tableOrderByList.find((item) => item.id === elementName);

  const onAction = () => {
    const currentTableOrder =
      tableOrderByList?.map((button) => {
        return { ...button, isAsc: button.id === elementName ? !button.isAsc : undefined };
      }) ?? [];

    const currentColumn = currentTableOrder?.find((item) => item.id === elementName);

    if (currentTableOrder && setTableOrderByList) setTableOrderByList(currentTableOrder);
    if (currentColumn && getTableOrderByList) getTableOrderByList(currentColumn);
  };
  return (
    <button className="px-2" onClick={() => onAction()}>
      <Flex column>
        <div className="pb-1">
          <If
            condition={selectedElement && selectedElement.isAsc === true}
            fallback={<ArrowUpTableIcon />}
          >
            <ArrowUpTableIcon color={isCampaignsPage ? '#364365' : '#004dbc'} />
          </If>
        </div>
        <div>
          <If
            condition={selectedElement && selectedElement.isAsc === false}
            fallback={<ArrowDownTableIcon />}
          >
            <ArrowDownTableIcon color={isCampaignsPage ? '#364365' : '#004dbc'} />
          </If>
        </div>
      </Flex>
    </button>
  );
};
