import { useCallback } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select, Text } from '@/lib/v2/components';

import { useRuleDefaultOptions } from '@/modules/RulesModule/components/FormRule/hooks/useRuleDefaultOptions';
import { TRANSLATE_RULES_SEQUENCE_SETTINGS } from '@/modules/RulesModule/constants';
import { IFormProps, IRuleForm } from '@/modules/RulesModule/interfaces';

interface IExecutionDateFieldsProps {
  form: IFormProps<IRuleForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

enum EnumPeriods {
  dia = 'dia',
  mes = 'mes',
}

export const ExecutionDateFields = ({
  form,
  disabled = false,
  rules,
}: IExecutionDateFieldsProps) => {
  const { t } = useTranslation('rules');
  const { dayOptions, periodOptions, hourOptions, minuteOptions } = useRuleDefaultOptions();

  const execution = form?.watch && form.watch().execution;
  const frequency = form?.watch && form.watch().frequency;

  const getPeriodOptions = useCallback(() => {
    if (frequency && frequency.value === EnumPeriods.dia) {
      return periodOptions.filter((option) => option.value !== EnumPeriods.mes);
    }
    return periodOptions;
  }, [frequency, periodOptions]);

  return (
    <div className="flex items-baseline">
      {execution?.id !== 1 && (
        <div className="flex items-center">
          <div className="min-w-16">
            <Select
              isRequired
              classNameMenuContainer="visible-scrollbar"
              control={form.control}
              disabled={disabled}
              name="days"
              options={dayOptions}
              placeholder="-"
              rules={rules.days}
            />
          </div>
          <div className="mx-2 min-w-28">
            <Select
              isRequired
              control={form.control}
              disabled={disabled}
              name="period"
              options={getPeriodOptions()}
              placeholder="-"
              rules={rules.period}
            />
          </div>
        </div>
      )}
      <Text className="whitespace-nowrap" variant="text">
        {t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.atTime`)}
      </Text>
      <div className="mx-2 min-w-16">
        <Select
          isRequired
          classNameMenuContainer="visible-scrollbar"
          control={form.control}
          disabled={disabled}
          name="hours"
          options={hourOptions}
          placeholder="-"
          rules={rules.hours}
        />
      </div>
      <Text variant="text">:</Text>
      <div className="mx-2 min-w-16">
        <Select
          isRequired
          classNameMenuContainer="visible-scrollbar"
          control={form.control}
          disabled={disabled}
          name="minutes"
          options={minuteOptions}
          placeholder="-"
          rules={rules.minutes}
        />
      </div>
      <Text className="whitespace-nowrap" variant="text">
        {t(`${TRANSLATE_RULES_SEQUENCE_SETTINGS}.timeUnit`)}
      </Text>
    </div>
  );
};
