import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import { CampaignActionModals } from '@/src/modules/CampaignsModule/components/CampaignActionModals';
import { HeaderSectionActions } from '@/src/modules/CampaignsModule/components/HeaderSectionActions';

const ActionManagementLayout = () => {
  return (
    <div className="bg-emblue-white">
      <HeaderSectionActions />
      <Outlet />
      <CampaignActionModals options={['actions']} />
    </div>
  );
};

export default memo(ActionManagementLayout);
