import classNames from 'classnames';
import { isValidElement, ReactElement } from 'react';

import TabItem, { TabItemProps } from './TabItem';

import './Tab.tailwind.css';

export type TabNavProps = {
  /** Receives components of type TabItem v2 */
  children: ReactElement<TabItemProps> | ReactElement<TabItemProps>[];
  gap?: 'small' | 'medium' | 'large' | 'extra-large';
  withDivide?: boolean;
  className?: string;
};

const TabNav = ({ children, gap, withDivide = false, className }: TabNavProps) => {
  if (!Array.isArray(children)) {
    children = [children];
  }

  for (const child of children) {
    if (!isValidElement(child) || child.type !== TabItem) {
      throw new Error('the "children" in TabNav prop only accepts components of type TabItem v2');
    }
  }

  const classes = classNames(
    'text-sm border-text-center flex flex-wrap font-medium text-emblue-text',
    { [`gap-${gap || ''}`]: gap }
  );

  const classesNav = classNames(
    'relative w-full',
    {
      'border-b': withDivide,
    },
    className
  );

  return (
    <div className={classesNav}>
      <ul className={classes}>{children}</ul>
    </div>
  );
};

export default TabNav;
