import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ModalConfirmation } from '@/lib/v2/organisms';

import { useCampaignLastPath } from '@/modules/CampaignsModule/hooks/useCampaignLastPath';

interface BackToCampaignsModalProps {
  onClose: (value: boolean) => void;
  isOpen: boolean;
}

const MODAL_BACK_TO_CAMPAIGN = 'CAMPAIGN_ACTIONS_MAIN.MODAL_BACK_TO_CAMPAIGN';

const BackToCampaignsModal = ({ onClose, isOpen }: BackToCampaignsModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { campaignLastPath } = useCampaignLastPath();

  const handleOnConfirmModal = useCallback(() => {
    navigate(campaignLastPath.action);
  }, [campaignLastPath.action, navigate]);

  return (
    <ModalConfirmation
      description={t(`${MODAL_BACK_TO_CAMPAIGN}.description`)}
      isOpen={isOpen}
      textCancelButton={t(`${MODAL_BACK_TO_CAMPAIGN}.cancel`)}
      textConfirmButton={t(`${MODAL_BACK_TO_CAMPAIGN}.confirm`)}
      title={t(`${MODAL_BACK_TO_CAMPAIGN}.title`)}
      onClose={onClose}
      onConfirmButton={handleOnConfirmModal}
    />
  );
};

export default memo(BackToCampaignsModal);
