import { ChangeEvent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Text } from '@/lib/v2/components';

import { useDropdownFilters } from '@/src/modules/CampaignsModule/components/RecurrentActions/TableContainer/components/ActionsFilterBar/hooks/useDropdownFilters';

import { DROPDOWN_ACTIONS_STATE_GROUPED } from '@/modules/CampaignsModule/constants';
import { IActionStatus } from '@/modules/CampaignsModule/interfaces/CampaignActions';

const FilterRecurrentActionStatus = () => {
  const { t } = useTranslation();

  const {
    state: { filtersDropdownRecurrentAction },
    events: { handleCheckboxChange },
  } = useDropdownFilters();

  const handleCheck = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = event.target;
      handleCheckboxChange('status', value, checked);
    },
    [handleCheckboxChange]
  );

  const statusItems = useMemo(() => {
    return DROPDOWN_ACTIONS_STATE_GROUPED.map((state) => ({
      ...state,
      label: t(`CAMPAIGN_ACTIONS_MAIN.STATES.${state.label}`),
      checked: filtersDropdownRecurrentAction?.status?.includes(state.label as IActionStatus),
      value: state.label,
    })).sort((a, b) => a.label.localeCompare(b.label));
  }, [filtersDropdownRecurrentAction, t]);

  const classNameScrollable = 'flex flex-col gap-2 max-h-[150px]';

  return (
    <div className="mb-4">
      <Text className="mb-2" fontWeight="medium">
        {t('CAMPAIGNS_MAIN.FILTERS.state')}
      </Text>
      <div className={classNameScrollable}>
        {statusItems.map((item) => (
          <Checkbox
            key={item.id}
            checked={item.checked}
            id={`status-${item.id}`}
            label={item.label}
            value={item.value}
            onChange={handleCheck}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(FilterRecurrentActionStatus);
