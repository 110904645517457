import { memo } from 'react';

import FilterState from '@/modules/CampaignsModule/components/CampaignsRecurrent/TableContainer/components/RecurrentFilterBar/components/FilterState';

const FiltersDropdown = () => {
  return (
    <>
      <FilterState />
    </>
  );
};

export default memo(FiltersDropdown);
