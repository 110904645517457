import { memo } from 'react';

import { TableFilterBar } from '@/lib/v2/organisms';

import FiltersDropdown from './components/FiltersDropdown/FiltersDropdown';
import { useDropdownFilters } from './hooks/useDropdownFilters';
import { useInputDate } from './hooks/useInputDate';
import { useInputSearch } from './hooks/useInputSearch';
import { useResetButton } from './hooks/useResetButton';

const RecurrentFilterBar = () => {
  const {
    state: { inputDefaultValue, inputPlaceholder, inputReset },
    events: { handleOnClickSearch, onClearSearch, resetInputSearch },
  } = useInputSearch();

  const {
    state: { dateRange, datePlaceholder },
    events: { handleOnClickDate, resetDateRange },
  } = useInputDate();

  const {
    state: { placeholderFilters },
    events: { handleApplyFilters },
  } = useDropdownFilters();

  const {
    state: { buttonDisabled },
    events: { handleClickReset },
  } = useResetButton({ resets: [resetInputSearch, resetDateRange] });

  return (
    <TableFilterBar>
      <TableFilterBar.InputSearch
        defaultValue={inputDefaultValue}
        id="search-campaigns-action"
        placeholder={inputPlaceholder}
        reset={inputReset}
        onAction={handleOnClickSearch}
        onClear={onClearSearch}
      />
      <TableFilterBar.InputDateRange
        dateRange={dateRange}
        id="date-campaigns-action"
        placeholder={datePlaceholder}
        onAction={handleOnClickDate}
      />
      <TableFilterBar.DropdownFilter
        id="filter-campaigns-recurrent"
        label={placeholderFilters}
        onAction={handleApplyFilters}
      >
        <FiltersDropdown />
      </TableFilterBar.DropdownFilter>
      <TableFilterBar.ResetButton
        disabled={buttonDisabled}
        id="clear-filters-campaigns-action"
        onAction={handleClickReset}
      />
    </TableFilterBar>
  );
};

export default memo(RecurrentFilterBar);
