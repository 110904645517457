import { useAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { atomFiltersCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';

export const useInputSearch = () => {
  const { t } = useTranslation();

  const [filtersCampaign, setFiltersCampaign] = useAtom(atomFiltersCampaign);
  const [reset, setReset] = useState(false);

  const inputDefaultValue = useMemo(() => filtersCampaign?.search ?? '', [filtersCampaign]);
  const inputPlaceholder = useMemo(() => t('CAMPAIGNS_MAIN.FILTERS.searchPlaceholder'), [t]);

  const handleOnClickSearch = useCallback(
    (querySearch: string) => {
      setFiltersCampaign({
        ...filtersCampaign,
        search: querySearch,
      });
    },
    [filtersCampaign, setFiltersCampaign]
  );

  const onClearSearch = useCallback(() => {
    setFiltersCampaign({
      ...filtersCampaign,
      search: '',
    });
    setReset(true);
  }, [filtersCampaign, setFiltersCampaign]);

  const resetInputSearch = useCallback(() => {
    setReset(true);
    setTimeout(() => setReset(false), 0);
  }, []);

  return {
    state: {
      inputDefaultValue,
      inputPlaceholder,
      inputReset: reset,
    },
    events: {
      handleOnClickSearch,
      onClearSearch,
      resetInputSearch,
    },
  };
};
