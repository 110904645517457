/**
 * Hook that validates if the string is a valid JSON and extracts the content of the 'body' field.
 * If it's not a valid JSON or doesn't have the 'body' field, returns the original string.
 * @param input - The string to validate and process
 * @returns The content of the 'body' field if valid, or the original string
 */
interface JsonBody {
  body: string;
}

export const useExtractBodyFromJson = (jsonString: string, variables?: Record<string, string>) => {
  try {
    if (!jsonString) return '';

    const parsedJson = JSON.parse(jsonString) as JsonBody;
    let content = parsedJson.body || '';

    if (variables) {
      Object.entries(variables).forEach(([key, value]) => {
        content = content.replace(`{{${key}}}`, value);
      });
    }

    return content;
  } catch {
    return jsonString;
  }
};
