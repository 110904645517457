import { t } from 'i18next';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';

import { toast } from '@/lib/v2/components';

import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { DROPDOWN_ACTIONS_STATE_GROUPED } from '@/src/modules/CampaignsModule/constants';
import {
  EChannelsID,
  IActionStatus,
  ICampaignActionsListPayload,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

import {
  atomActionMaster,
  atomLoadingProcessRecurrentAction,
  atomRecurrentActionsList,
  atomTotalsProcessRecurrentAction,
} from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';

const TRANSLATE_MODAL_DELETE = 'CAMPAIGN_ACTIONS_MAIN.DELETE_ACTIONS.TOAST_MESSAGES';

export const useRecurrentActions = () => {
  const service = useCampaignsService();
  const serviceV1 = useService();

  const [recurrentActionsList, setRecurrentActionsList] = useAtom(atomRecurrentActionsList);
  const [actionMaster, setActionMaster] = useAtom(atomActionMaster);
  const setTotalsProcessRecurrentAction = useSetAtom(atomTotalsProcessRecurrentAction);
  const setLoadingProcessRecurrentAction = useSetAtom(atomLoadingProcessRecurrentAction);

  const [loading, setLoading] = useState<boolean>(false);

  const getStatusValues = useCallback((statuses: IActionStatus[]): number[] => {
    return statuses?.flatMap((status) => {
      const group = DROPDOWN_ACTIONS_STATE_GROUPED.find((group) => group.label === status);
      return group ? group.values : [];
    });
  }, []);

  const getList = useCallback(
    async (payload: ICampaignActionsListPayload) => {
      setLoadingProcessRecurrentAction(true);

      const statusPayloadValues = getStatusValues(payload?.status as unknown as IActionStatus[]);
      const response = await service.getActionsByCampaign({
        ...payload,
        status: statusPayloadValues,
      });

      setRecurrentActionsList(response?.actions || []);

      const total = response?.total ?? 0;
      const partial = response?.partial ?? 0;

      setTotalsProcessRecurrentAction({
        total: total > 0 ? total - 1 : 0,
        partial: partial || 0,
      });

      setLoadingProcessRecurrentAction(false);
    },
    [
      service,
      setLoadingProcessRecurrentAction,
      getStatusValues,
      setRecurrentActionsList,
      setTotalsProcessRecurrentAction,
    ]
  );

  const getActionMaster = useCallback(
    async (payload: ICampaignActionsListPayload) => {
      if (actionMaster) return;
      const master = await service.getActionsByCampaign({
        ...payload,
        channel: [EChannelsID.MASTER],
      });

      setActionMaster(master.actions?.[0]);
    },
    [actionMaster, service, setActionMaster]
  );

  const deleteAction = useCallback(
    async (actionsId: number[]) => {
      const isSingle: boolean = actionsId?.length === 1;
      setLoading(true);

      const { statusOK } = await serviceV1.deleteV1Actions(actionsId);

      if (!statusOK) {
        toast({
          title: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.title`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.title`),
          body: isSingle
            ? t(`${TRANSLATE_MODAL_DELETE}.ERROR.SINGLE.message`)
            : t(`${TRANSLATE_MODAL_DELETE}.ERROR.MULTIPLE.message`),
          variant: 'error',
        });
        return;
      }

      toast({
        title: isSingle
          ? t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.SINGLE.title`)
          : t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.MULTIPLE.title`),
        body: isSingle
          ? t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.SINGLE.message`)
          : t(`${TRANSLATE_MODAL_DELETE}.SUCCESS.MULTIPLE.message`),
        variant: 'success',
      });

      setLoading(false);
    },
    [serviceV1]
  );

  return {
    getList,
    recurrentActionsList,
    deleteAction,
    loading,
    getActionMaster,
  };
};
