export const DuplicateIconNew = () => {
  return (
    <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M12.25 0H5.25C4.28356 0 3.5 0.783562 3.5 1.75V8.75C3.5 9.71644 4.28356 10.5 5.25 10.5H12.25C13.2164 10.5 14 9.71644 14 8.75V1.75C14 0.783562 13.2164 0 12.25 0ZM2.625 9.1875V3.5H1.75C0.783562 3.5 0 4.28356 0 5.25V12.25C0 13.2164 0.783562 14 1.75 14H8.75C9.71644 14 10.5 13.2164 10.5 12.25V11.375H4.8125C3.84606 11.375 2.625 10.1539 2.625 9.1875Z"
        fillRule="evenodd"
      />
    </svg>
  );
};
