import { useAtomValue, useSetAtom } from 'jotai';
import { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';

import { BadgeText, Button, Flex, IconSvg, Text, Tooltip } from '@/lib/v2/components';
import { ColorsBadge } from '@/lib/v2/components/BadgeText/types';
import { ColorsIcon } from '@/lib/v2/components/IconSvg/types';
import {
  ActionMasterIcon,
  EmailActionIcon,
  EmailAPIIcon,
  EmailAttachmentIcon,
  EmailTriggerIcon,
  SmsActionIcon,
  SmsAPIIcon,
} from '@/lib/v2/icons/outline';
import { ReportIcon } from '@/lib/v2/icons/solid';

import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { IActionCampaign } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import {
  atomActionType,
  atomHistorySearchPagerActions,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import {
  atomDetailCampaign,
  atomInfoCampaignV1,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import {
  atomFiltersRecurrentAction,
  atomPagerRecurrentAction,
} from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';
import { TitleHeadCell } from '@/modules/CampaignsModule/components/TitleHeadCell';
import {
  CAMPAIGNS_PATH,
  defaultPager,
  DROPDOWN_ACTIONS_STATE,
  DROPDOWN_CHANNELS,
  LIST_ACTIONS_STATE,
  TABLE_COLUMN_WIDTH,
} from '@/modules/CampaignsModule/constants';
import {
  ChannelInfo,
  EChannelsID,
  ICampaignActions,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { DateFormat, formatDate, formattedNumber } from '@/modules/CampaignsModule/utils';
import { ELanguageMap } from '@/modules/CampaignsModule/utils/formattedNumber';

export const ChannelInfoByID = {
  [EChannelsID.MASTER]: {
    icon: <ActionMasterIcon />,
    route: 'master',
  },
  [EChannelsID.EMAIL]: {
    icon: <EmailActionIcon />,
    route: 'email',
  },
  [EChannelsID.EMAIL_TRIGGER]: {
    icon: <EmailTriggerIcon />,
    route: 'email-trigger',
  },
  [EChannelsID.EMAIL_ATTACHMENT]: {
    icon: <EmailAttachmentIcon />,
    route: 'email-attachment',
  },
  [EChannelsID.EMAIL_API]: {
    icon: <EmailAPIIcon />,
    route: 'mail-api',
  },
  [EChannelsID.SMS]: {
    icon: <SmsActionIcon />,
    route: 'sms',
  },
  [EChannelsID.SMS_API]: {
    icon: <SmsAPIIcon />,
    route: 'sms-api',
  },
};

export const useActionsTableColumns = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const createSimpleSMS = useFeatureFlag('createSimpleSMS');

  const detailCampaign = useAtomValue(atomDetailCampaign);
  const actionType = useAtomValue(atomActionType);
  const filtersRecurrentAction = useAtomValue(atomFiltersRecurrentAction);
  const pagerRecurrentAction = useAtomValue(atomPagerRecurrentAction);
  const setHistorySearchPagerAction = useSetAtom(atomHistorySearchPagerActions);
  const setInfoCampaignV1 = useSetAtom(atomInfoCampaignV1);

  const getCurrentState = useCallback(
    (status: number) => {
      const [filter] = DROPDOWN_ACTIONS_STATE.filter((item) => Number(item.value) === status);
      return t(`CAMPAIGN_ACTIONS_MAIN.STATES.${filter.label}`) ?? '-';
    },
    [t]
  );

  const getStatusColor = useCallback(
    (statusId: number): { badge: string; background: ColorsIcon } | null => {
      const group = LIST_ACTIONS_STATE.find((group) => group.values.includes(statusId));
      return group
        ? {
            badge: group.badge,
            background: group.background,
          }
        : null;
    },
    []
  );

  const getChannelLabel = useCallback(
    (channelId: number): string => {
      const labelChannel =
        DROPDOWN_CHANNELS.find((channel) => channel.value === channelId)?.label ?? 'email';
      return t(`CAMPAIGN_ACTIONS_MAIN.CHANNEL.${labelChannel}`);
    },
    [t]
  );

  const iconByChannelStatus = useCallback(
    (channel: number, status: number) => {
      const currentChannelInfo = ChannelInfoByID[
        channel as keyof typeof ChannelInfoByID
      ] as ChannelInfo;

      return (
        <IconSvg
          rounded
          bgColor={getStatusColor(status)?.background as Exclude<ColorsIcon, 'white'>}
          fillColor={getStatusColor(status)?.background as ColorsIcon}
          height="auto"
          svgComponent={currentChannelInfo.icon}
          width="40px"
          withPadding="small"
        />
      );
    },
    [getStatusColor]
  );

  const handleClickReport = useCallback(
    (event: MouseEvent<HTMLButtonElement>, value: IActionCampaign) => {
      event.stopPropagation();
      const currentChannelInfo = ChannelInfoByID[
        value.channel as keyof typeof ChannelInfoByID
      ] as ChannelInfo;
      const route = currentChannelInfo.route;
      const baseRoute = `${CAMPAIGNS_PATH}/${value.campaignId}/${route}/${value.actionId}`;

      setInfoCampaignV1({
        campaign: {
          id: Number(detailCampaign.campaignId),
          name: detailCampaign.name ?? '',
        },
        action: {
          id: value.actionId,
          name: value.name,
        },
        route: actionType
          ? `${CAMPAIGNS_PATH}/actions-${actionType}`
          : `${CAMPAIGNS_PATH}/${detailCampaign.campaignId}`,
      });

      setHistorySearchPagerAction({
        filters: { ...filtersRecurrentAction },
        pager: {
          page: pagerRecurrentAction?.page || defaultPager.page,
          limit: pagerRecurrentAction?.limit || defaultPager.limit,
        },
      });

      if (createSimpleSMS) {
        navigate(`${baseRoute}${route.includes('sms') ? '/summary' : '/report'}`);
      } else {
        navigate(`${baseRoute}${route.includes('sms') ? '' : '/report'}`);
      }
    },
    [
      detailCampaign,
      actionType,
      filtersRecurrentAction,
      pagerRecurrentAction,
      createSimpleSMS,
      setInfoCampaignV1,
      setHistorySearchPagerAction,
      navigate,
    ]
  );

  /** cspell:disable */
  const nameColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.name')} />
      </Flex>
    ),
    accessor: 'action',
    id: 'action',
    maxWidth: '340px',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => (
      <div className={`grid grid-cols-campaigns gap-4`}>
        <div className="shrink-0">{iconByChannelStatus(value.channel, value.status)}</div>
        <Tooltip isClickable noArrow alignment="start" content={value.name} position="top">
          <div className="self-start text-left !text-14">
            <div className="emblue-subheadings max-w-[85%] truncate font-medium">{value.name}</div>
            <div className="emblue-text text-left font-normal">
              {getChannelLabel(value.channel)}
            </div>
          </div>
        </Tooltip>
      </div>
    ),
  };

  const statusColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.state')} />
      </Flex>
    ),
    accessor: 'action',
    id: 'status',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <BadgeText
            text={getCurrentState(value.status)}
            variantColor={(getStatusColor(value.status)?.badge as ColorsBadge) ?? null}
          />
        </Flex>
      </Flex>
    ),
  };

  const creationDateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.creation')} />
      </Flex>
    ),
    accessor: 'action',
    id: 'creationDate',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => {
      const formattedDate = formatDate(value.creationDate, DateFormat.LongDateOutSeconds);
      return (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <Text className="self-start text-emblue-subheadings" variant="text">
              {formattedDate}
            </Text>
          </Flex>
        </Flex>
      );
    },
  };

  const executionDateColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.execution')} />
      </Flex>
    ),
    accessor: 'action',
    id: 'exeDate',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => {
      const formattedDate = value.exeDate
        ? formatDate(value.exeDate, DateFormat.LongDateOutSeconds)
        : '-';
      return (
        <Flex withGap alignment="start">
          <Flex column noGrow>
            <Text className="self-start text-emblue-subheadings" variant="text">
              {formattedDate}
            </Text>
          </Flex>
        </Flex>
      );
    },
  };

  const audienceColumn = {
    Header: () => (
      <Flex withGap alignment="start">
        <TitleHeadCell text={t('CAMPAIGN_ACTIONS_MAIN.TABLE.COLUMNS.audience')} />
      </Flex>
    ),
    accessor: 'action',
    id: 'audience',
    Cell: ({ cell: { value } }: { cell: { value: ICampaignActions } }) => (
      <Flex withGap alignment="start">
        <Flex column noGrow>
          <Text className="self-start text-emblue-subheadings" variant="text">
            {formattedNumber(Number(value.audience), i18n.language as keyof typeof ELanguageMap) ||
              '-'}
          </Text>
        </Flex>
      </Flex>
    ),
  };

  const buttonColumn = {
    accessor: 'action',
    id: 'buttons',
    width: TABLE_COLUMN_WIDTH.report,
    Cell: ({ cell: { value } }: { cell: { value: IActionCampaign } }) => {
      const status = DROPDOWN_ACTIONS_STATE.filter(
        (item) => Number(item.value) === value.status
      )?.[0];
      const disabled = !value.report || status.label.includes('draft') ? true : false;

      return (
        <Flex withGap alignment="center" className="z-10">
          <Button
            gray
            outline
            data-id={value.actionId}
            disabled={disabled}
            id="detail-actions"
            size="small"
            onClick={(event) => handleClickReport(event, value)}
          >
            <IconSvg
              fillColor="gray-dark"
              height="16px"
              svgComponent={<ReportIcon />}
              width="13px"
            />
          </Button>
        </Flex>
      );
    },
  };
  /** cspell:enable */

  const actionsColumns: Column<object>[] = [
    nameColumn,
    statusColumn,
    creationDateColumn,
    executionDateColumn,
    audienceColumn,
    buttonColumn,
  ].filter((x) => x) as Column<object>[];

  return {
    ChannelInfoByID,
    actionsColumns,
  };
};
