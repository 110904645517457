import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { IStrategy } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { atomStrategyList } from '@/modules/CampaignsModule/atoms/campaignAtom';

const atomLoading = atom<boolean>(false);

export const useStrategies = () => {
  const service = useCampaignsService();
  const [loading, setLoading] = useAtom(atomLoading);
  const [strategyList, setStrategyList] = useAtom(atomStrategyList);

  const getStrategies = useCallback(async () => {
    setLoading(true);

    const { strategies } = await service.getStrategiesByAccount();
    setStrategyList((strategies as IStrategy[]) ?? []);

    setLoading(false);
  }, [service, setLoading, setStrategyList]);

  return { getStrategies, loading, strategyList };
};
