import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import {
  DEFAULT_ORDERBY_ACTIONS,
  defaultPager,
  defaultRecurrentActionsOrderBy,
  MASTER_ACTION,
} from '@/src/modules/CampaignsModule/constants';
import {
  IFiltersDropdownTableActions,
  IFilterTableActions,
  IHistorySearchAction,
  ITableCampaignActions,
} from '@/src/modules/CampaignsModule/interfaces/CampaignActions';
import {
  IOrderByTable,
  IPager,
  ITotalsCampaign,
} from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { IOrderByCell } from '@/modules/AutomationModule/interfaces/automation';

export const atomRecurrentActionsList = atomWithReset<ITableCampaignActions[] | undefined>(
  undefined
);
atomRecurrentActionsList.debugLabel = 'atomRecurrentActionsList';

export const atomFiltersDropdownRecurrentAction = atomWithReset<
  IFiltersDropdownTableActions | undefined
>(undefined);
atomFiltersDropdownRecurrentAction.debugLabel = 'atomFiltersDropdownRecurrentAction';

export const atomActionMaster = atomWithReset<ITableCampaignActions | undefined>(undefined);
atomActionMaster.debugLabel = 'atomActionMaster';

export const atomSelectedRowsRecurrentAction = atom<number[]>([]);
atomSelectedRowsRecurrentAction.debugLabel = 'atomSelectedRowsRecurrentAction';

export const atomTotalRecurrentActionsSelectedRows = atom<number>((get) => {
  const selectedRows = get(atomSelectedRowsRecurrentAction);
  return selectedRows.length || 0;
});
atomTotalRecurrentActionsSelectedRows.debugLabel = 'atomTotalRecurrentActionsSelectedRows';

export const atomFiltersRecurrentAction = atomWithReset<IFilterTableActions>({ ...defaultPager });
atomFiltersRecurrentAction.debugLabel = 'atomFiltersRecurrentAction';

export const atomPagerRecurrentAction = atomWithReset<IPager>(defaultPager);
atomPagerRecurrentAction.debugLabel = 'atomPagerRecurrentAction';

//TODO: Remove this atom¿?
export const atomOrderByRecurrentAction = atom<IOrderByTable>(defaultRecurrentActionsOrderBy);
atomOrderByRecurrentAction.debugLabel = 'atomOrderByRecurrentAction';

//TODO: Remove this atom¿?
export const atomOrderByRecurrentActionValues =
  atomWithReset<IOrderByCell[]>(DEFAULT_ORDERBY_ACTIONS);
atomOrderByRecurrentActionValues.debugLabel = 'atomOrderByRecurrentActionValues';

export const atomLoadingProcessRecurrentAction = atomWithReset<boolean>(false);
atomLoadingProcessRecurrentAction.debugLabel = 'atomLoadingProcessRecurrentAction';

export const atomTotalsProcessRecurrentAction = atomWithReset<ITotalsCampaign>({
  total: 0,
  partial: 0,
});
atomTotalsProcessRecurrentAction.debugLabel = 'atomTotalsProcessRecurrentAction';

export const atomHistorySearchPagerActions = atomWithReset<IHistorySearchAction | undefined>(
  undefined
);
atomHistorySearchPagerActions.debugLabel = 'atomHistorySearchPagerActions';

export const atomSelectedRecurrentActionsRowsWithID = atom<number[]>((get) => {
  const selectedActionsRowsWithID = get(atomSelectedRowsRecurrentAction)
    .map((index) => {
      const action = get(atomRecurrentActionsList)?.[index]?.action;
      if (action && action.channel !== MASTER_ACTION) {
        return action.actionId;
      }
    })
    .filter((actionId) => actionId !== undefined) as number[];

  return selectedActionsRowsWithID;
});
atomSelectedRecurrentActionsRowsWithID.debugLabel = 'atomSelectedRecurrentActionsRowsWithID';

export const atomActionDragDrop = atomWithReset<boolean>(false);
atomActionDragDrop.debugLabel = 'atomActionDragDrop';
