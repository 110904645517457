export const AUTOMATION_PATHS = {
  MODULE_PATH: 'automation',
  FLOW_DETAIL_PATH: 'flow',
  EVENT_PATH: 'event',
  CREATE_EVENT_PATH: 'event/create',
  RULES_PATH: 'rules',
  JOURNEYS_PATH: 'journeys',
  RECURRENCES_PATH: 'recurring-messages',
};
export const TRANSLATE_MODAL_RENAME = 'AUTOMATION_MAIN_MODULE.RENAME_FLOW';

export const EVENT_TYPES = {
  0: 'Custom',
  1: 'VTEX',
  8: 'Facebook',
  12: 'Tokko Broker',
  99: 'onSite',
};

export const FLOWS_TYPES = {
  0: 'Custom',
  1: 'VTEX',
  8: 'Facebook',
  12: 'Tokko Broker',
};

export const FLOW_ALREADY_EXISTS = 'FLOW_ALREADY_EXISTS';
export const EVENT_ALREADY_EXISTS = 'EVENT_ALREADY_EXISTS';

export const eventValidation = {
  elementPosition: 0,
  domain: '',
  query: '',
  text: '',
  domItems: {
    active: true,
    attributes: [],
  },
  fieldsToTrack: [],
  getElements: true,
};
