/* cspell:disable*/
export const formValidations = {
  formRegExp: {
    email: /^[\wñÑ.%+-]+@[a-zA-ZñÑ\d.-]+\.[a-zA-ZñÑ]{2,}$/,
    letters: /^[a-zA-Z\s\-ñÑáéíóúÁÉÍÓÚâÂãÃàÀçêÊôÔõÕ]+$/,
    numbers: /^[\d\s-]+$/,
    lettersNumbersAndSpecials:
      /**
       * Allowed characters:
       * - All letters a-z and A-Z
       * - All numbers 0-9
       * - Underscore _
       * - Whitespace
       * - Special characters: - . , : ; ! ? ( ) { } ~ ^ ` ' " & % $ # @ * + = < > | / \ [ ]
       * - Spanish/Portuguese characters: ñ Ñ á é í ó ú Á É Í Ó Ú â Â ã Ã à À ç ê Ê ô Ô õ Õ
       */
      /^[\w\s\-.,:;!?(){}~^`'"&%$#@*+=<>|/\\[\]ñÑáéíóúÁÉÍÓÚâÂãÃàÀçêÊôÔõÕ]+$/,
    lettersAndNumbers: /^[a-zA-Z\d\s\-ñÑáéíóúÁÉÍÓÚâÂãÃàÀçêÊôÔõÕ]+$/,
    lettersAndNumbersAndQuotesTEMP: /^[a-zA-Z\d\s\-'"ñÑáéíóúÁÉÍÓÚâÂãÃàÀçêÊôÔõÕ]+$/, // same as lettersAndNumbers plus '" characters
    https: /^https:\/\//i,
  },
  inputLengths: {
    min: 2,
    max: 60,
    maxObjective: 150,
  },
};
/** cspell:enable */
