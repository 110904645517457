import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Option } from '@/lib/v2/components';
import { OptionExternal } from '@/lib/v2/components/SelectExternalSearch';

import { atomRuleData, atomToggleSidePanel } from '@/modules/RulesModule/atoms/rules';
import { useSaveDataRules } from '@/modules/RulesModule/components/FormRule/hooks/useSaveDataRules';
import { useResetRulesState } from '@/modules/RulesModule/hooks/useResetRulesState';

export interface IConditionForm {
  selectedGroupOrSegment: OptionExternal | undefined;
  presenceCondition: Option;
  touchRules: boolean;
}

export const useFormCondition = () => {
  const { t } = useTranslation();

  const rulesData = useAtomValue(atomRuleData);

  const setToggleSidePanel = useSetAtom(atomToggleSidePanel);

  const { saveRule } = useSaveDataRules();
  const { refetchData } = useResetRulesState();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IConditionForm>();

  useEffect(() => {
    setValue('selectedGroupOrSegment', rulesData?.selectedGroupOrSegment as Option);
    setValue('presenceCondition', rulesData?.presenceCondition as Option);
  }, [rulesData, setValue]);

  const onSubmit: SubmitHandler<IConditionForm> = useCallback(
    async (conditionValues) => {
      const updatedRulesData = {
        ...rulesData,
        ...conditionValues,
      };

      await saveRule(updatedRulesData);
      setToggleSidePanel(false);
      refetchData();
    },
    [rulesData, saveRule, setToggleSidePanel, refetchData]
  );

  const RULES_DATE = useMemo(() => {
    const fields = ['presenceCondition', 'selectedGroupOrSegment'];

    return fields.reduce((acc, field) => {
      acc[field] = { required: t('FORMS_RULES_MESSAGES.required') };
      return acc;
    }, {} as Record<string, { required: string }>);
  }, [t]);

  const handleCancel = useCallback(() => {
    setToggleSidePanel(false);
  }, [setToggleSidePanel]);

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    RULES_DATE,
    handleCancel,
    setValue,
    getValues,
    onSubmit,
  };
};
