import { useTranslation } from 'react-i18next';

import { ModalConfirmation } from '@/lib/v2/organisms';

import { useDeleteCampaigns } from './useDeleteCampaigns.hooks';

const DeleteCampaigns = () => {
  const { t } = useTranslation();

  const {
    state: { campaignsSelected: campaigns, toggleDeleteModal, loadingCampaign: loading },
    events: { handleConfirmDeleteCampaigns },
    setters: { setToggleDeleteModal },
  } = useDeleteCampaigns();

  return (
    <ModalConfirmation
      description={
        campaigns?.length > 1
          ? t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.MULTIPLE.description')
          : t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.SINGLE.description')
      }
      isLoading={loading}
      isOpen={toggleDeleteModal}
      textCancelButton={t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.cancelButton')}
      textConfirmButton={t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.successButton')}
      title={
        campaigns?.length > 1
          ? t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.MULTIPLE.title')
          : t('CAMPAIGNS_MAIN.DELETE_CAMPAIGNS.SINGLE.title')
      }
      onClose={setToggleDeleteModal}
      onConfirmButton={handleConfirmDeleteCampaigns}
    />
  );
};

export default DeleteCampaigns;
