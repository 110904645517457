import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  atomFiltersCampaign,
  atomInfoCampaignV1,
  atomPagerCampaign,
  atomSelectedRows,
} from '@/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleEditCreateCampaignModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useCampaignFilters } from '@/modules/CampaignsModule/hooks/useCampaignFilters';
import {
  ICampaign,
  ITableCampaigns,
  ITablePager,
} from '@/modules/CampaignsModule/interfaces/Campaigns';

export const useHandleEvents = () => {
  const navigate = useNavigate();

  const [pager, setPageParams] = useAtom(atomPagerCampaign);
  const filtersCampaign = useAtomValue(atomFiltersCampaign);
  const setInfoCampaignV1 = useSetAtom(atomInfoCampaignV1);
  const setSelectedRows = useSetAtom(atomSelectedRows);
  const setToggleEditCategoryModal = useSetAtom(atomToggleEditCreateCampaignModal);

  const { getCampaigns, resetFilters } = useCampaignFilters();

  const handleChangeTablePage = useCallback(
    (payload?: ITablePager) => {
      const { pageIndex = 1, pageSize = 10 } = payload ?? {};
      const newLimit = pageSize;
      const newPage = pageIndex + 1;

      if (pager?.page !== newPage || pager?.limit !== newLimit) {
        setPageParams({ page: newPage, limit: newLimit });
        void getCampaigns({
          ...filtersCampaign,
          page: newPage,
          limit: newLimit,
        });
      }
    },
    [filtersCampaign, pager, getCampaigns, setPageParams]
  );

  const handleClickReport = useCallback(
    (event: MouseEvent<HTMLButtonElement>, value: ICampaign) => {
      event.stopPropagation();
      navigate(`/v2/campaigns/${value.campaignId}/report`);
      setInfoCampaignV1({
        campaign: {
          id: Number(value?.campaignId),
          name: value?.name ?? '',
        },
      });
    },
    [navigate, setInfoCampaignV1]
  );

  const handleRowClick = useCallback(
    (value: ITableCampaigns) => {
      if (!value.campaign.campaignId) return;
      setSelectedRows(RESET);
      navigate(`${value.campaign.campaignId}`);
    },
    [navigate, setSelectedRows]
  );

  const handleClickEmptyScreen = useCallback(() => {
    setToggleEditCategoryModal(true);
  }, [setToggleEditCategoryModal]);

  const handleClickEmptyScreenNoResult = useCallback(() => {
    resetFilters();
  }, [resetFilters]);

  return {
    handleChangeTablePage,
    handleClickReport,
    handleClickEmptyScreen,
    handleClickEmptyScreenNoResult,
    handleRowClick,
  };
};
