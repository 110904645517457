import { useFlagsStatus } from '@unleash/proxy-client-react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { useInboxService } from '@/src/infrastructure/Protocol/Inbox/useInboxService';

import { useFeatureFlag } from './util/useFeatureFlag';

import { atomHasInbox } from '@/modules/InboxModule/atoms/inboxAtom';

export const useInboxContractStatus = () => {
  const [isLoadingCheckInbox, setIsLoadingCheckInbox] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const showInboxModule = useFeatureFlag('showInboxModule');
  const [hasInbox, setHasInbox] = useAtom(atomHasInbox);

  const inboxService = useInboxService();

  // Use this hook to check if the flags are ready
  const { flagsReady } = useFlagsStatus();

  useEffect(() => {
    // Wait until the flags are ready
    if (!flagsReady) {
      return;
    }

    const checkInbox = async () => {
      try {
        setIsLoadingCheckInbox(true);
        setError(null);

        const serviceMethod = showInboxModule
          ? inboxService.getInboxContractStatus.bind(inboxService)
          : inboxService.checkInboxAccountExists.bind(inboxService);

        const hasInbox = await serviceMethod();
        setHasInbox(hasInbox);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Error checking inbox status'));
        setHasInbox(false);
      } finally {
        setIsLoadingCheckInbox(false);
      }
    };

    void checkInbox();
  }, [inboxService, showInboxModule, setHasInbox, flagsReady]);

  return {
    hasInbox,
    isLoadingCheckInbox: !flagsReady || isLoadingCheckInbox,
    error,
  };
};
