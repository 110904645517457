import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { atomCampaignLastPath } from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import { PATH_TYPES_CAMPAIGN } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

interface ISetCampaignPathParams {
  pathType: PATH_TYPES_CAMPAIGN;
  path: string;
}

export const useCampaignLastPath = () => {
  const [campaignLastPath, setCampaignLastPath] = useAtom(atomCampaignLastPath);

  const setCampaignPath = useCallback(
    ({ pathType, path }: ISetCampaignPathParams) => {
      setCampaignLastPath((prev) => ({ ...prev, [pathType]: path }));
    },
    [setCampaignLastPath]
  );

  return { campaignLastPath, setCampaignPath };
};
