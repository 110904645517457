import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useReactFlow } from 'reactflow';

import { atomIsLoadingRuleData, atomRuleTypeSelected } from '@/src/modules/RulesModule/atoms/rules';
import { NODE_WIDTH, VERTICAL_SPACING } from '@/src/modules/RulesModule/constants';
import { RuleTypes } from '@/src/modules/RulesModule/interfaces';

/**
 * Custom hook to automatically layout the nodes in a graph
 * Positions nodes based on their type and relationships.
 */
const useAutoLayout = () => {
  const reactFlowInstance = useReactFlow();
  const nodesLength = reactFlowInstance.getNodes().length;

  const isLoadingRuleData = useAtomValue(atomIsLoadingRuleData);
  const ruleTypeSelected = useAtomValue(atomRuleTypeSelected);

  const applyLayout = () => {
    const nodes = reactFlowInstance.getNodes();

    if (nodes.length === 0) return;

    // Calculate the center x position based on the node width
    const centerX = window.innerWidth / 2 - NODE_WIDTH / 2;

    // Find the specific nodes
    const triggerNode = nodes.find((node) => node.type === 'trigger');
    const conditionNode = nodes.find((node) => node.type === 'condition');
    const selectorNode = nodes.find((node) => node.type === 'selector');
    const actionNode = nodes.find((node) => node.type === 'action');
    const emptyNode = nodes.find((node) => node.type === 'empty');

    // Define the yPos values for each scenario
    let conditionYPos = 0;
    let selectorYPos = 0;
    let actionYPos = 0;
    let emptyYPos = 0;

    const verticalSpacingWithoutContent =
      ruleTypeSelected?.value === RuleTypes.FLOW_TEMPLATE ? 140 : VERTICAL_SPACING;

    // Scenario 1: Trigger node alone
    if (triggerNode) {
      triggerNode.position = { x: centerX, y: 20 };

      // Scenario 2: Trigger + Condition
      if (conditionNode) {
        conditionYPos = triggerNode.position.y + VERTICAL_SPACING;
        conditionNode.position = { x: centerX, y: conditionYPos };
      }

      // Scenario 3: Trigger + Action
      if (!conditionNode && actionNode) {
        actionYPos = triggerNode.position.y + verticalSpacingWithoutContent;
        actionNode.position = { x: centerX, y: actionYPos };
      }

      // Scenario 4: Trigger + Selector
      if (!conditionNode && selectorNode) {
        selectorYPos = triggerNode.position.y + VERTICAL_SPACING;
        selectorNode.position = { x: centerX, y: selectorYPos };
      }

      // Scenario 5: Trigger + Condition + Selector
      if (conditionNode && selectorNode) {
        selectorYPos = conditionNode.position.y + VERTICAL_SPACING;
        selectorNode.position = { x: centerX, y: selectorYPos };
      }

      // Scenario 6: Trigger + Selector + Action
      if (selectorNode && actionNode) {
        actionYPos = selectorNode.position.y + VERTICAL_SPACING;
        actionNode.position = { x: centerX, y: actionYPos };
      }

      // Scenario 7: Trigger + Condition + Action
      if (conditionNode && !selectorNode && actionNode) {
        actionYPos = conditionNode.position.y + VERTICAL_SPACING;
        actionNode.position = { x: centerX, y: actionYPos };
      }

      // Scenario 8: Handling the Empty Node
      if (!actionNode && emptyNode) {
        // Determine the position of the empty node as the last element
        const lastNode = conditionNode || selectorNode || triggerNode;
        emptyYPos = lastNode.position.y + VERTICAL_SPACING;
        emptyNode.position = { x: centerX, y: emptyYPos };
      }
    }

    // Update node positions
    reactFlowInstance.setNodes(nodes);
  };

  useEffect(() => {
    applyLayout();
  }, [reactFlowInstance, nodesLength, isLoadingRuleData]);

  useEffect(() => {
    const handleResize = () => {
      applyLayout();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [reactFlowInstance, nodesLength]);

  /*
  Remove the resizeObserver error
  TODO: Remove this when the issue is fixed in the library or update to new version 12.x.x
  */
  useEffect(() => {
    const errorHandler = (event: ErrorEvent) => {
      if (
        event.message.includes('ResizeObserver loop completed with undelivered notifications') ||
        event.message.includes('ResizeObserver loop limit exceeded')
      ) {
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.style.display = 'none';
        }
      }
    };
    window.addEventListener('error', errorHandler);

    return () => window.removeEventListener('error', errorHandler);
  }, []);
};

export default useAutoLayout;
