export const PlayIcon = () => {
  return (
    <svg fill="none" height="40" viewBox="0 0 59 40" width="59" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6366_6941)">
        <rect fill="#D3DDEA" height="39.9607" rx="8" width="58.827" />
        <path
          d="M29.2686 0C35.3078 0.228199 41.3479 0.435896 47.3862 0.69975C48.8092 0.762149 50.2358 0.944886 51.6395 1.19002C55.0464 1.7837 57.2301 4.01755 57.8758 7.49758C58.7599 12.2666 58.9386 17.0882 58.771 21.9107C58.654 25.2819 58.3188 28.6505 57.9808 32.0102C57.733 34.4723 56.6996 36.5653 54.4578 37.9639C53.0892 38.8179 51.5446 39.0657 49.977 39.2252C43.9157 39.8448 37.8287 39.9865 31.7434 39.9571C25.5781 39.9277 19.4118 39.7476 13.2483 39.5551C11.2755 39.4936 9.29985 39.2484 7.34359 38.9703C3.98644 38.4934 1.69769 36.2631 1.00323 32.9114C0.310619 29.5651 0.148518 26.1671 0.0462838 22.7709C-0.0872652 18.3014 0.0554941 13.8372 0.641268 9.39627C0.772975 8.39968 0.94797 7.40131 1.20678 6.42879C2.00163 3.44616 4.51787 1.43961 7.85383 1.03492C13.6001 0.337841 19.3805 0.279009 25.159 0.18452C26.5267 0.162235 27.8954 0.180955 29.264 0.180955L29.2696 0H29.2686ZM23.5942 11.5579V28.5792C28.6985 25.7339 33.7384 22.9242 38.8621 20.0672C33.7227 17.2032 28.6902 14.3979 23.5942 11.557V11.5579Z"
          fill="#8543D3"
        />
        <path
          d="M22 11C27.096 13.84 32.1285 16.6452 37.2679 19.5102C32.1442 22.3663 27.1043 25.176 22 28.0222V11.0009V11Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6366_6941">
          <rect fill="white" height="39.9607" rx="8" width="58.827" />
        </clipPath>
      </defs>
    </svg>
  );
};
