export const MoveIconNew = () => {
  return (
    <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M4.34849 2H13.7425C15.0394 2 16.091 3.05154 16.091 4.34849V11.3333C15.4515 10.8785 14.6695 10.6111 13.8251 10.6111C11.6634 10.6111 9.91095 12.3636 9.91095 14.5253C9.91095 15.082 10.0272 15.6115 10.2366 16.091H4.34849C3.05154 16.091 2 15.0394 2 13.7425V4.34849C2 3.05154 3.05154 2 4.34849 2Z"
        fill="#5E6984"
        fillRule="evenodd"
      />
      <path
        d="M14.7803 14.3989H11.3571C11.2569 14.3989 11.1723 14.3604 11.1034 14.2834C11.0345 14.2064 11 14.1119 11 14C11 13.8881 11.0345 13.7936 11.1034 13.7166C11.1723 13.6396 11.2569 13.6011 11.3571 13.6011H14.7803L13.9551 12.6793C13.8848 12.6007 13.8507 12.5077 13.8526 12.4002C13.8546 12.2927 13.8888 12.1997 13.9551 12.1213C14.0254 12.0427 14.1096 12.0023 14.2078 12.0001C14.306 11.9979 14.3903 12.036 14.4606 12.1145L15.8987 13.7188C15.9338 13.7581 15.9595 13.8017 15.9757 13.8496C15.9919 13.8975 16 13.9476 16 14C16 14.0524 15.9919 14.1025 15.9757 14.1504C15.9595 14.1983 15.9338 14.2419 15.8987 14.2812L14.4606 15.8855C14.3903 15.964 14.306 16.0021 14.2078 15.9999C14.1096 15.9977 14.0254 15.9573 13.9551 15.8787C13.8888 15.8003 13.8546 15.7073 13.8526 15.5998C13.8507 15.4923 13.8848 15.3993 13.9551 15.3207L14.7803 14.3989Z"
        fill="#5E6984"
      />
    </svg>
  );
};
