import { PlusIcon } from '@heroicons/react/outline';
import { useAtom } from 'jotai';
import { memo, useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, IconSvg, Input, Modal, Option, Select, Text, toast } from '@/lib/v2/components';
import { DeleteIcon } from '@/lib/v2/icons/solid';

import { BillingFormStateBody } from '@/src/infrastructure/interfaces/IRequestParams.interface';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { atomBillingInformation } from '@/src/modules/MyPlanModule/atoms/BillingAndConsumption';

import { useCountriesOptions } from '@/modules/MyPlanModule/hooks/useCountriesOptions';
import { BillingInfo } from '@/modules/MyPlanModule/types';

export const billingEmailHelper = {
  mapExtraBillingEmailsToBody: (billingEmails: BillingEmails[]) =>
    billingEmails.map((email) => email.value),
  mapExtraBillingEmailsToForm: (extraBillingEmails?: string[]) =>
    extraBillingEmails?.map((email) => ({ value: email } as BillingEmails)),
};

interface ModalInformationProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  canEdit?: boolean;
}

export interface BillingEmails {
  value: string;
}

export type BillingFormState = {
  businessName: string;
  fantasyName: string;
  rut: string;
  address: string;
  country?: Option;
  billingContact: string;
  billingEmail: string;
  extraBillingEmails: BillingEmails[];
};

const MAX_EXTRA_BILLING_EMAILS = 4;

const ModalInformation = ({ isOpen, onClose, canEdit }: ModalInformationProps) => {
  const { t } = useTranslation();
  const service = useService();
  const [billingInformation, setBillingInformation] = useAtom(atomBillingInformation);
  const { getCountryOption, countriesOptions } = useCountriesOptions();
  const [updateInfoIsLoading, setUpdateInfoIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
    trigger,
  } = useForm<BillingFormState>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    fields: billingEmailFields,
    append: appendBillingEmail,
    remove: removeBillingEmail,
  } = useFieldArray({
    control,
    name: 'extraBillingEmails',
  });

  useEffect(() => {
    if (billingInformation && isOpen) {
      setValue('businessName', billingInformation.businessName ?? '');
      setValue('fantasyName', billingInformation.fantasyName ?? '');
      setValue('rut', billingInformation.rut ?? '');
      setValue('address', billingInformation.address?.toString() ?? '');
      setValue('country', getCountryOption(billingInformation.country ?? ''));
      setValue('billingContact', billingInformation.billingContact ?? '');
      setValue('billingEmail', billingInformation.billingEmail ?? '');
      const billingEmails = billingEmailHelper.mapExtraBillingEmailsToForm(
        billingInformation.extraBillingEmails ?? []
      );
      setValue('extraBillingEmails', billingEmails ?? []);
      void trigger();
    }
  }, [billingInformation, getCountryOption, isOpen, trigger, setValue]);

  const RULES: Record<keyof BillingFormState, object> = {
    businessName: {
      required: t('CREATE_SEGMENT_MODAL.emptyField'),
    },
    fantasyName: {
      required: t('CREATE_SEGMENT_MODAL.emptyField'),
    },
    rut: {
      required: t('CREATE_SEGMENT_MODAL.emptyField'),
      maxLength: {
        value: 15,
        message: t('MY_PLAN.BILLING.MODAL_INFORMATION.maxlengthRut'),
      },
    },
    address: {
      required: t('CREATE_SEGMENT_MODAL.emptyField'),
    },
    country: {
      required: t('CREATE_SEGMENT_MODAL.emptyField'),
    },
    billingContact: {
      required: t('CREATE_SEGMENT_MODAL.emptyField'),
    },
    billingEmail: {
      required: t('CREATE_SEGMENT_MODAL.emptyField'),
      pattern: {
        value: /^[\w.%+-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
        message: t('AUTH.LOGIN.invalidEmail'),
      },
    },
    extraBillingEmails: {
      pattern: {
        value: /^[\w.%+-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
        message: t('AUTH.LOGIN.invalidEmail'),
      },
    },
  };

  const handleRemoveAttribute = useCallback(
    async (index: number) => {
      removeBillingEmail(index);
      await trigger('billingEmail');
    },
    [removeBillingEmail, trigger]
  );

  const getBillingInformation = useCallback(async () => {
    const response = await service.getBillingInformation();
    setBillingInformation(JSON.parse(JSON.stringify(response)) as BillingInfo);
  }, [service, setBillingInformation]);

  const handleUpdateData = handleSubmit(async (data) => {
    setUpdateInfoIsLoading(true);
    const billingInfoBody: BillingFormStateBody = {
      ...data,
      country: Number(data.country?.value),
      extraBillingEmails: billingEmailHelper.mapExtraBillingEmailsToBody(data.extraBillingEmails),
    };
    const response = await service.updateBillingInfo(billingInfoBody);
    if (response.success) {
      await getBillingInformation();
      toast({
        id: 'success',
        title: t('MY_PLAN.BILLING.MODAL_INFORMATION.success'),
      });
    } else {
      toast({
        id: 'error',
        variant: 'error',
        title: t('MY_PLAN.BILLING.MODAL_INFORMATION.error'),
      });
    }
    setUpdateInfoIsLoading(false);
    onClose(false);
  });

  const addBillingEmailIsDisabled =
    billingEmailFields.length >= MAX_EXTRA_BILLING_EMAILS || errors.billingEmail;

  return (
    <Modal withAuto open={isOpen} onClose={onClose}>
      <div className="relative w-full sm:w-5/6 md:w-[650px]">
        <div className="w-full border-b pb-6">
          <Text fontWeight="medium" variant="sub-headline">
            {t('MY_PLAN.BILLING.MODAL_INFORMATION.title')}
          </Text>
        </div>
        <form className="mt-8 flex w-full flex-col gap-4" onSubmit={handleUpdateData}>
          <div className="visible-scrollbar flex h-[450px] flex-col gap-4 overflow-y-scroll">
            <div className="flex gap-4 px-6">
              <Input
                isRequired
                control={control}
                disabled={!canEdit}
                id="businessName"
                label={t('MY_PLAN.BILLING.MODAL_INFORMATION.businessName')}
                name="businessName"
                rules={RULES.businessName}
              />
              <Input
                isRequired
                control={control}
                disabled={!canEdit}
                id="fantasyName"
                label={t('MY_PLAN.BILLING.MODAL_INFORMATION.fantasyName')}
                name="fantasyName"
                rules={RULES.fantasyName}
              />
            </div>
            <div className="flex gap-4 px-6">
              <Input
                isRequired
                control={control}
                disabled={!canEdit}
                id="rut"
                label={t('MY_PLAN.BILLING.MODAL_INFORMATION.rut')}
                name="rut"
                rules={RULES.rut}
              />
              <Input
                isRequired
                control={control}
                disabled={!canEdit}
                id="address"
                label={t('MY_PLAN.BILLING.MODAL_INFORMATION.address')}
                name="address"
                rules={RULES.address}
              />
            </div>
            <div className="flex gap-4 px-6">
              <Select
                isRequired
                withSearch
                classNameMenuContainer="h-[200px]"
                control={control}
                disabled={!canEdit}
                id="country"
                label={t('MANAGE_PLAN.BILLING_FORM.inputFive')}
                name="country"
                options={countriesOptions}
                rules={RULES.country}
              />
              <Input
                disabled
                defaultValue={billingInformation?.contractId.toString()}
                label={t('MY_PLAN.BILLING.MODAL_INFORMATION.contractId')}
              />
            </div>
            <div className="flex gap-4 px-6">
              <Input
                disabled
                defaultValue={billingInformation?.executive ?? ''}
                label={t('MY_PLAN.BILLING.MODAL_INFORMATION.accountExecutive')}
              />
              <Input
                disabled
                defaultValue={billingInformation?.executiveEmail ?? ''}
                label={t('MY_PLAN.BILLING.MODAL_INFORMATION.executiveEmail')}
              />
            </div>
            <div className="flex gap-4 px-6">
              <Input
                disabled
                defaultValue={billingInformation?.appAdminName}
                label={t('MY_PLAN.BILLING.MODAL_INFORMATION.adminNamePlatform')}
              />
              <Input
                disabled
                defaultValue={billingInformation?.appAdminEmail}
                label={t('MY_PLAN.BILLING.MODAL_INFORMATION.adminPlatform')}
              />
            </div>
            <div className="flex w-full gap-4">
              <div className="w-1/2 pl-6">
                <Input
                  isRequired
                  control={control}
                  disabled={!canEdit}
                  id="billingContact"
                  label={t('MY_PLAN.BILLING.MODAL_INFORMATION.adminContact')}
                  name="billingContact"
                  rules={RULES.billingContact}
                />
              </div>
              <div className="flex w-1/2 flex-col">
                <div className="w-full pr-6">
                  <Input
                    isRequired
                    control={control}
                    disabled={!canEdit}
                    id={'billingEmail'}
                    label={t('MY_PLAN.BILLING.MODAL_INFORMATION.adminEmail')}
                    name="billingEmail"
                    rules={RULES.billingEmail}
                  />
                </div>
                {billingEmailFields.map((billingEmail, index) => {
                  const name = `extraBillingEmails.${index}.value`;

                  return (
                    <div key={billingEmail.id} className="flex gap-2">
                      <Input
                        isRequired
                        control={control}
                        disabled={!canEdit}
                        id={name}
                        name={name}
                        rules={RULES.extraBillingEmails}
                      />
                      <div className="flex h-[46px] items-center">
                        <IconSvg
                          fillColor="destructive"
                          id="deleteAttribute"
                          svgComponent={<DeleteIcon />}
                          onClick={() => void handleRemoveAttribute(index)}
                        />
                      </div>
                    </div>
                  );
                })}
                <Text
                  {...(!addBillingEmailIsDisabled && { link: true })}
                  as={'span'}
                  className="flex items-center gap-1"
                  color={addBillingEmailIsDisabled ? 'gray' : 'primary'}
                  id="addAttribute"
                  variant="text"
                  {...(!addBillingEmailIsDisabled && {
                    onClick: () => appendBillingEmail({ value: '' }),
                  })}
                >
                  <PlusIcon className="-mt-0.5 size-4" /> {t('FormCreateEvent.add')}
                </Text>
              </div>
            </div>
          </div>
          <div className="mt-4 flex w-full justify-end gap-2">
            <Button outline size="small" onClick={() => onClose(false)}>
              {t('MY_PLAN.BILLING.MODAL_INFORMATION.cancel')}
            </Button>
            <Button
              disabled={!isValid || !canEdit}
              isLoading={updateInfoIsLoading}
              size="small"
              type="submit"
            >
              {t('MY_PLAN.BILLING.MODAL_INFORMATION.save')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default memo(ModalInformation);
