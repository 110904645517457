import { useAtomValue } from 'jotai';
import { memo } from 'react';

import { TableControlPanel } from '@/lib/v2/organisms';

import { atomSelectedRowsQuickTools } from '@/src/modules/CampaignsModule/atoms/actionsAtom';

interface CampaignsHeaderTableProps {
  stickyHeader?: boolean;
  selectedItems?: number;
  toolBar: React.ReactNode;
  filterBar: React.ReactNode;
}

const CampaignsHeaderTable = ({
  stickyHeader = false,
  selectedItems = 0,
  toolBar,
  filterBar,
}: CampaignsHeaderTableProps) => {
  const selectedRowsQuickTools = useAtomValue(atomSelectedRowsQuickTools);

  return (
    <TableControlPanel isSticky={stickyHeader}>
      {selectedItems > 0 && !selectedRowsQuickTools ? (
        <TableControlPanel.Tools selectedItems={selectedItems ?? 0}>
          {toolBar}
        </TableControlPanel.Tools>
      ) : (
        <TableControlPanel.Filters>{filterBar}</TableControlPanel.Filters>
      )}
    </TableControlPanel>
  );
};

export default memo(CampaignsHeaderTable);
