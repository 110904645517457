import { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/lib/v2/components';

interface TableFiltersProps {
  children: ReactNode;
  selectedItems?: number;
}

const TableTools = ({ children, selectedItems = 0 }: TableFiltersProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex">
      <div className="mr-4 flex min-w-[120px] items-center">
        <Text as="span" color="primary" fontWeight="medium" variant="text">
          {selectedItems > 1
            ? `${selectedItems} ${t('CAMPAIGNS_MAIN.ACTIONS.selected')}`
            : `${selectedItems} ${t('CAMPAIGNS_MAIN.ACTIONS.select')}`}
        </Text>
      </div>
      {children}
    </div>
  );
};

export default memo(TableTools);
