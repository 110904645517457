import { memo } from 'react';

import FilterCampaignState from '@/src/modules/CampaignsModule/components/Campaigns/TableContainer/components/CampaignFilterBar/components/FilterCampaignState';
import FilterCampaignStrategy from '@/src/modules/CampaignsModule/components/Campaigns/TableContainer/components/CampaignFilterBar/components/FilterCampaignStrategy';

const FiltersCampaignDropdown = () => {
  return (
    <>
      <FilterCampaignState />
      <FilterCampaignStrategy />
    </>
  );
};

export default memo(FiltersCampaignDropdown);
