import { DropdownItemProps } from '@/lib/v2/components/Dropdown';

import { STRATEGY_OTHER } from '@/src/modules/CampaignsModule/constants';
import { IStrategy, IStrategyDropdown } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { STRATEGY_OPTIONS } from '@/modules/CampaignsModule/components/CampaignsModal/ManagementCampaign/constants';

/**
 * The function `strategyOrderOtherOption` returns an array of strategy options, including an "Other"
 * option if specified, based on the input strategies and a flag indicating whether the "Other" option
 * should be included.
 * @param {IStrategy[]} strategies - An array of strategies (IStrategy[]).
 * @param {boolean} [hasOther] - A boolean value indicating whether the "Other" option should be
 * included in the list of strategies.
 * @returns The function `strategyOrderOtherOption` returns an array of `DropdownItemProps`.
 */
export const strategyOrderOtherOption = (strategies: IStrategy[], hasOther?: boolean) => {
  if (strategies?.length === 0) {
    const castStrategyDefault = castOrderStrategies(STRATEGY_OPTIONS);
    return !hasOther
      ? (castStrategyDefault as DropdownItemProps[])
      : ([...castStrategyDefault, STRATEGY_OTHER] as DropdownItemProps[]);
  }

  const castStrategyDefault = castOrderStrategies(strategies);
  return !hasOther
    ? (castStrategyDefault as DropdownItemProps[])
    : ([...castStrategyDefault, STRATEGY_OTHER] as DropdownItemProps[]);
};

/**
 * The function `castOrderStrategies` takes an array of `IStrategy` objects, maps them to
 * `IStrategyDropdown` objects with modified properties, and sorts them alphabetically by the `label`
 * property.
 * @param {IStrategy[]} strategies - An array of objects representing different strategies. Each object
 * has the following properties:
 * @returns The function `castOrderStrategies` returns an array of `IStrategyDropdown` objects.
 */
const castOrderStrategies = (strategies: IStrategy[]) => {
  return strategies
    .map((option): IStrategyDropdown => {
      return {
        id: option.strategyId,
        value: option.strategyId,
        label: option.name,
      };
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};
