import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardSection, Flex } from '@/lib/v2/components';
import { SectionHeader } from '@/lib/v2/organisms/SectionHeader';

import { TableContainer } from '@/src/modules/CampaignsModule/components/CampaignActions/TableContainer';
import { SectionHeaderSubtitle } from '@/src/modules/CampaignsModule/components/SectionHeaderSubtitle';
import { TotalCountDisplay } from '@/src/modules/CampaignsModule/components/TotalCountDisplay';
import { useActionsData } from '@/src/modules/CampaignsModule/hooks/useActionsData';
import { useActionsFilters } from '@/src/modules/CampaignsModule/hooks/useActionsFilters';
import { EActionTypes } from '@/src/modules/CampaignsModule/interfaces/CampaignActions';

const ActionsFinished = () => {
  const { t } = useTranslation();

  const { actionsList, loadingProcess, totalsActionsList, totalApplyFilters } = useActionsData(
    EActionTypes.FINISHED
  );
  const { resetUnmount } = useActionsFilters();

  useEffect(() => {
    return () => {
      resetUnmount();
    };
  }, [resetUnmount]);

  return (
    <>
      <Card>
        <CardSection noPadding>
          <Flex column>
            <SectionHeader
              subtitle={
                <SectionHeaderSubtitle
                  countFilters={totalApplyFilters}
                  countRecordsPartial={totalsActionsList.partial}
                  dataList={actionsList}
                  i18nKey="CAMPAIGN_ACTIONS_MAIN.search"
                  loading={loadingProcess}
                />
              }
              title={t('CAMPAIGN_ACTIONS_MAIN.titleFinished')}
              titleDetail={
                <TotalCountDisplay
                  hasItems={!!actionsList?.length}
                  isLoading={loadingProcess}
                  total={totalsActionsList.total}
                />
              }
            />
            <Flex column withGap gapSize="medium">
              <TableContainer />
            </Flex>
          </Flex>
        </CardSection>
      </Card>
    </>
  );
};

export default ActionsFinished;
