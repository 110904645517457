import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormChangePassword } from '@/src/modules/AuthModule/types/Auth';

type ValidationFunctionKeys = keyof typeof validationFunctions;

export type PasswordRules = Record<ValidationFunctionKeys, boolean>;

export const validationFunctions = {
  minLength: (value: string) => value.length >= 10,
  oneCapitalLetter: (value: string) => /^(?=.*[A-Z])/.test(value),
  oneNumber: (value: string) => /^(?=.*\d)/.test(value),
  oneLowercaseLetter: (value: string) => /^(?=.*[a-z])/.test(value),
  oneSpecialCharacter: (value: string) => /^(?=.*[!@#$&])/.test(value),
  repeatingChars: (value: string) => value.length >= 4 && !/(.)\1{3,}/.test(value),
  sequentialChars: (value: string) => value.length >= 4 && hasSequentialChars(value),
};

const hasSequentialChars = (value: string) => {
  /** cspell: disable */
  const sequentialRegex =
    // eslint-disable-next-line regexp/no-unused-capturing-group
    /(1234|2345|3456|4567|5678|6789|abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)/i;
  return !sequentialRegex.test(value);
  /** cspell:enable */
};

export const usePasswordValidation = (
  setValue: UseFormSetValue<FormChangePassword>,
  watch: UseFormWatch<FormChangePassword>
) => {
  const [passwordErrors, setPasswordErrors] = useState<PasswordRules>();
  const [isFocusPasswordInput, setIsFocusPasswordInput] = useState(false);
  const { t } = useTranslation();

  const handleOnChangePassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const entries = Object.entries(validationFunctions);
      const validate: Array<[string, boolean]> = entries.map(([key, func]) => [key, func(value)]);
      setValue('password', value);
      const passwordWithErrorsMap = Object.fromEntries(validate) as PasswordRules;
      setPasswordErrors(passwordWithErrorsMap);
    },
    [setValue]
  );

  const validateAllRulesPassword = useCallback((validationObj?: PasswordRules): boolean => {
    const values = Object.values(validationObj ?? []);
    return values.every((value) => value === true);
  }, []);

  const password = watch('password');
  const repeatPassword = watch('repeatPassword');

  const passwordRulesAreValid = useMemo(
    () =>
      validateAllRulesPassword(passwordErrors) && !!passwordErrors && password === repeatPassword,
    [passwordErrors, validateAllRulesPassword, password, repeatPassword]
  );

  const handleFocusPasswordInput = useCallback(() => {
    setIsFocusPasswordInput(true);
  }, []);

  const handleBlurPasswordInput = useCallback(() => {
    setIsFocusPasswordInput(false);
  }, []);

  const CHANGE_RULES = useMemo(
    () => ({
      password: {
        required: t('AUTH.CHANGE_PASSWORD.passwordRequired'),
      },
      repeatPassword: {
        required: t('AUTH.CHANGE_PASSWORD.repeatPasswordRequired'),
        validate: (value: string) => {
          return value === password || t('AUTH.CHANGE_PASSWORD.passwordsDoNotMatch');
        },
      },
    }),
    [password, t]
  );

  return {
    passwordErrors,
    isFocusPasswordInput,
    passwordRulesAreValid,
    handleOnChangePassword,
    handleFocusPasswordInput,
    handleBlurPasswordInput,
    CHANGE_RULES,
  };
};
