import { useAtomValue } from 'jotai';
import { memo } from 'react';

import { atomToggleDuplicateRecurrentModal } from '@/src/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { ManagementRecurrentAction } from '@/src/modules/CampaignsModule/components/CampaignRecurrentModal/CreateRecurrentAction';
import { DeleteCampaignsRecurrent } from '@/src/modules/CampaignsModule/components/CampaignRecurrentModal/DeleteCampaignsRecurrent';
import { DuplicateCampaignRecurrent } from '@/src/modules/CampaignsModule/components/CampaignRecurrentModal/DuplicateCampaignRecurrent';
import {
  DeleteActions,
  DeleteCampaigns,
  DuplicateActions,
  EditActions,
  ManagementCampaign,
  MoveActions,
} from '@/src/modules/CampaignsModule/components/CampaignsModal';

interface CampaignActionModalsProps {
  options: Array<'campaigns' | 'recurrent' | 'actions'>;
}

const CampaignActionModals = ({ options }: CampaignActionModalsProps) => {
  const toggleDuplicateRecurrentModal = useAtomValue(atomToggleDuplicateRecurrentModal);
  return (
    <>
      {options.includes('campaigns') && (
        <>
          <ManagementCampaign />
          <DeleteCampaigns />
        </>
      )}
      {options.includes('actions') && (
        <>
          <EditActions />
          <MoveActions />
          <DeleteActions />
          <DuplicateActions />
        </>
      )}
      {options.includes('recurrent') && (
        <>
          <ManagementRecurrentAction />
          {toggleDuplicateRecurrentModal && <DuplicateCampaignRecurrent />}
          <DeleteCampaignsRecurrent />
        </>
      )}
    </>
  );
};

export default memo(CampaignActionModals);
