import { useTranslation } from 'react-i18next';

import { Input, Select, Text } from '@/lib/v2/components';
import { Option } from '@/lib/v2/components/Select';

import { useCountriesOptions } from '@/modules/MyPlanModule/hooks/useCountriesOptions';
import { useFormManagePlan } from '@/modules/MyPlanModule/hooks/useFormManagePlan';

const BillingForm = () => {
  const { t } = useTranslation();
  const { countriesOptions } = useCountriesOptions();
  const {
    formState: { register },
    billingEmails,
  } = useFormManagePlan();

  return (
    <div className="flex w-full flex-col gap-8 pr-8">
      <div className="w-full border-b pb-1">
        <Text color="primary" fontWeight="medium">
          {t('MANAGE_PLAN.BILLING_FORM.title')}
        </Text>
      </div>
      <div className="flex w-full gap-4">
        <Input
          isRequired
          label={t('MANAGE_PLAN.BILLING_FORM.inputOne')}
          {...register('businessName')}
        />
        <Input
          isRequired
          label={t('MANAGE_PLAN.BILLING_FORM.inputTwo')}
          {...register('fantasyName')}
        />
      </div>
      <div className="flex w-full gap-4">
        <Input isRequired label={t('MY_PLAN.BILLING.MODAL_INFORMATION.rut')} {...register('rut')} />
        <Input
          isRequired
          label={t('MANAGE_PLAN.BILLING_FORM.inputThree')}
          {...register('address')}
        />
      </div>
      <div className="flex w-full gap-4">
        <Input isRequired label={t('MANAGE_PLAN.BILLING_FORM.inputFour')} {...register('city')} />
        <Select
          isRequired
          withSearch
          classNameMenuContainer="h-[200px]"
          label={t('MANAGE_PLAN.BILLING_FORM.inputFive')}
          options={countriesOptions}
          {...register<Option>('country')}
        />
      </div>
      <div className="flex w-full gap-4">
        <div className="w-1/2">
          <Input
            isRequired
            label={t('MANAGE_PLAN.BILLING_FORM.inputSix')}
            {...register('billingContact')}
          />
        </div>
        <div className="flex w-1/2 flex-col gap-1">
          <Input
            isRequired
            label={t('MANAGE_PLAN.BILLING_FORM.inputSeven')}
            {...register('billingEmail')}
          />
          {billingEmails &&
            billingEmails.map((billingEmail) => {
              return <Input key={billingEmail.value} disabled value={billingEmail.value} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default BillingForm;
