import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { atomBeefreePrevent } from '@/src/modules/CampaignsModule/atoms/beefreeAtom';

export const usePreventExit = () => {
  const [prevent, setPrevent] = useAtom(atomBeefreePrevent);

  const setPreventRedirect = useCallback((url: string) => {
    localStorage.setItem('@beefree/save.redirect', url);
  }, []);

  const getRedirectPrevent = useCallback(() => {
    const redirect = localStorage.getItem('@beefree/save.redirect');

    return redirect ?? false;
  }, []);

  const removePreventRedirect = useCallback(() => {
    localStorage.removeItem('@beefree/save.redirect');
  }, []);

  const handleClosePrevent = useCallback(() => {
    setPrevent(false);
  }, [setPrevent]);

  const handleOpenPrevent = useCallback(
    (url: string) => {
      setPrevent(true);
      setPreventRedirect(url);
    },
    [setPrevent, setPreventRedirect]
  );

  return {
    prevent,
    handleClosePrevent,
    handleOpenPrevent,
    getRedirectPrevent,
    removePreventRedirect,
  };
};
