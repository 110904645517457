import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import { Button, Card, CardSection, Container, Flex, IconSvg, Text } from '@/lib/v2/components';
import { EditPencilIcon } from '@/lib/v2/icons/outline';
import { CampaignsIcon, ReportIcon as ReportIconSolid } from '@/lib/v2/icons/solid';
import { ModalSingleInput } from '@/lib/v2/organisms';
import { SectionHeader } from '@/lib/v2/organisms/SectionHeader';

import { EventListenerType } from '@/src/application/hooks/enums/EventListenerEnum';
import { useFeatureFlag } from '@/src/application/hooks/util/useFeatureFlag';
import { SendActionsPostMessage } from '@/src/compat/sendActionsPostMessage';
import useMessage from '@/src/compat/useMessage';
import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import {
  atomIframeDocument,
  atomInfoCampaignV1,
} from '@/src/modules/CampaignsModule/atoms/campaignAtom';
import { atomToggleEditActionsModal } from '@/src/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { CAMPAIGNS_PATH, CAMPAIGNS_RECURRENT_PATH } from '@/src/modules/CampaignsModule/constants';
import { useCampaignLastPath } from '@/src/modules/CampaignsModule/hooks/useCampaignLastPath';
import useEmailButtonsEditorV1 from '@/src/modules/CampaignsModule/hooks/useEmailButtonsEditorV1';
import { ActionTypeName } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { useEditActions } from '@/modules/CampaignsModule/hooks/useEditActions';

export type OutletContextActionsLayoutProps = {
  showSpinnerByAction: boolean;
  showErrorCampaign: boolean;
  campaignId: string;
};

const ActionsLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const service = useCampaignsService();
  const { campaignId, actionId, actionType } = useParams();
  const showRecurrentMessage = useFeatureFlag('showRecurrentMessage');

  const [infoCampaignV1, setInfoCampaignV1] = useAtom(atomInfoCampaignV1);
  const [toggleEditActionsModal, setToggleEditActionsModal] = useAtom(atomToggleEditActionsModal);

  const [showSpinnerByAction, setShowSpinnerByAction] = useState(true);
  const [showErrorCampaign, setShowErrorCampaign] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasReport, setHasReport] = useState(false);

  const iframeDocument = useAtomValue(atomIframeDocument);

  const {
    state: { VALIDATION_RULES, loading },
    events: { handleCloseModal, handleValidation, handleApplyRename },
  } = useEditActions({ campaignId, actionId });
  const { emailButtonEditorToggle, hideEmailButtonEditorContainerV1 } = useEmailButtonsEditorV1();
  const { campaignLastPath } = useCampaignLastPath();

  const handleOpenModal = useCallback(() => {
    setToggleEditActionsModal(true);
  }, [setToggleEditActionsModal]);

  const haveData = useMemo(() => infoCampaignV1?.campaign?.name, [infoCampaignV1]);
  const createSimpleEmail = useFeatureFlag('createSimpleEmail');

  const isActionsPath = useMemo(() => {
    const validRoutes: ActionTypeName[] = [
      'master',
      'email',
      'email-split',
      'email-remarketing',
      'email-attachment',
      'email-trigger',
      'mail-api',
      'sms',
      'sms-api',
    ];

    return validRoutes.some((route) => route === actionType);
  }, [actionType]);

  useMessage(EventListenerType.Loaded, (_) => {
    showSpinnerByAction && setIsLoaded(true);
  });

  useEffect(() => {
    const type = actionType as ActionTypeName;
    const isAutomatic = type === 'master' || infoCampaignV1?.campaign?.isAutomatic;
    isLoaded &&
      showSpinnerByAction &&
      SendActionsPostMessage('Campaign', 'ViewCampaign', {
        id: isAutomatic ? campaignId : actionId,
        type,
        isAutomatic: isAutomatic,
        campaignName: infoCampaignV1?.campaign?.name,
      });
  }, [showSpinnerByAction, isLoaded, actionId, actionType, infoCampaignV1, campaignId]);

  useMessage(EventListenerType.LoadedCampaign, (_, payload) => {
    if (isLoaded && showSpinnerByAction) {
      createSimpleEmail && hideEmailButtonEditorContainerV1(iframeDocument);

      if (!haveData) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        // cspell:disable
        setInfoCampaignV1({
          ...infoCampaignV1,
          campaign: {
            id: parseInt(campaignId || '0'),
            name: payload.campaniaNombre,
          },
          action: {
            id: Number(actionId),
            name: payload.nombre,
          },
        });
      }
      setShowSpinnerByAction(false);
    }
  });

  useMessage(EventListenerType.ErrorCampaign, (_) => {
    setShowSpinnerByAction(false);
    setShowErrorCampaign(true);
  });

  useMessage(EventListenerType.RedirectConfiguration, (_) => {
    navigate && navigate(`/v2/configuration`);

    SendActionsPostMessage('Campaign', 'ViewGroupTest', {});
  });

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  useMessage(EventListenerType.DeleteAction, async (_) => {
    if (!actionId) return;
    await service.deleteMailContent(+actionId);
    SendActionsPostMessage('Action', 'SuccessDeleteAction', {});
  });

  useMessage(EventListenerType.GoToConfiguration, (_) => {
    navigate && navigate(`/v2/configuration`);

    SendActionsPostMessage('goToConfiguration', 'redirected');
  });

  useMessage(EventListenerType.OpenBeefree, (_) => {
    navigate && navigate(`${window.location.href.replace(window.location.origin, '')}/editor`);
  });

  useMessage(EventListenerType.OpenHtmlEditor, (_) => {
    navigate && navigate(`${window.location.href.replace(window.location.origin, '')}/html-editor`);
  });

  useMessage(EventListenerType.LoadEditorOptions, (_) => {
    createSimpleEmail && emailButtonEditorToggle(iframeDocument, actionType as string);
  });

  useEffect(() => {
    setTimeout(() => {
      SendActionsPostMessage('Campaign', 'VerifyLoaded');
    }, 500);
  }, []);

  const getAction = useCallback(async () => {
    const id: number = parseInt(actionId || '0');

    if (actionType?.includes('sms')) return;

    const response = await service.getActionByID(id);

    if (response.action?.report) setHasReport(true);
  }, [actionId, actionType, service]);

  useEffect(() => {
    if (actionType?.includes('sms') && hasReport) setHasReport(false);

    void getAction();
  }, [actionType, getAction, hasReport]);

  const handleBack = useCallback(() => {
    if (actionType === 'master') {
      showRecurrentMessage
        ? navigate(`${CAMPAIGNS_RECURRENT_PATH}/${campaignId}`)
        : navigate(`${CAMPAIGNS_PATH}/${campaignId}`);
    } else {
      navigate(campaignLastPath.action);
    }
  }, [campaignLastPath, actionType, showRecurrentMessage, navigate, campaignId]);

  const handleReport = useCallback(() => {
    navigate(`/v2/campaigns/${campaignId}/${actionType}/${actionId}/report`);
  }, [actionId, actionType, campaignId, navigate]);

  const actionsButtons = useMemo(
    () => (
      <>
        <Button
          outline
          secondary
          iconLeft={
            <IconSvg
              fillColor="gray-dark"
              height="16px"
              svgComponent={<ReportIconSolid />}
              width="16px"
            />
          }
          id="report-button"
          size="small"
          onClick={() => handleReport()}
        >
          {t('REPORT')}
        </Button>
      </>
    ),
    [handleReport, t]
  );

  if (!isActionsPath) {
    return <Navigate to={CAMPAIGNS_PATH} />;
  }

  return (
    <Container fullHeight>
      <Card>
        <CardSection noPadding>
          <Flex column>
            <SectionHeader
              backButton
              sticky
              actionsButtons={hasReport ? actionsButtons : null}
              icon={!showErrorCampaign ? <CampaignsIcon /> : null}
              isLoading={showSpinnerByAction}
              titleNode={
                !showErrorCampaign && (
                  <Text
                    as="h3"
                    className="!2xl:text-20 !text-16"
                    fontWeight="medium"
                    variant="title-1"
                  >
                    <Flex withGap>
                      {infoCampaignV1?.campaign?.name}
                      {actionType !== 'master' && (
                        <>
                          {' / '}
                          <Text color="primary">{infoCampaignV1?.action?.name}</Text>
                          <Button
                            link
                            className="!h-auto !p-0"
                            iconLeft={
                              <IconSvg
                                height="16px"
                                svgComponent={<EditPencilIcon />}
                                width="16px"
                              />
                            }
                            id="back-to-campaigns-button"
                            onClick={handleOpenModal}
                          />
                        </>
                      )}
                    </Flex>
                  </Text>
                )
              }
              onBack={handleBack}
            />
            <Outlet
              context={
                {
                  showSpinnerByAction,
                  showErrorCampaign,
                  campaignId,
                } as OutletContextActionsLayoutProps
              }
            />
          </Flex>
        </CardSection>
        <ModalSingleInput
          disabledUntilOnChange
          id="edit-action-table"
          isLoading={loading}
          isOpen={toggleEditActionsModal}
          rules={VALIDATION_RULES}
          title={t('CAMPAIGN_ACTIONS_MAIN.EDIT_ACTIONS.title')}
          value={infoCampaignV1.action?.name}
          onClose={handleCloseModal}
          onSubmit={handleApplyRename}
          onValidateInputFetch={handleValidation}
        />
      </Card>
    </Container>
  );
};

export default ActionsLayout;
