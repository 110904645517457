import { useAtom } from 'jotai';
import { memo } from 'react';

import { Modal } from '@/lib/v2/components';

import { atomContentDialogSaveRows } from '@/src/modules/CampaignsModule/atoms/beefreeAtom';
import ModalSaveRow from '@/src/modules/CampaignsModule/components/ModalSaveRow';

const BeefreeModal = () => {
  const [config] = useAtom(atomContentDialogSaveRows);
  const { open = false } = config;

  return (
    <Modal open={open} showCloseButton={false}>
      <ModalSaveRow onCancel={config.onFail} onSuccess={config.onSuccess} />
    </Modal>
  );
};

export default memo(BeefreeModal);
