import { useAtomValue, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Handle, Node, Position, useReactFlow } from 'reactflow';

import { Button, Card, If, Text } from '@/lib/v2/components';

import {
  atomCurrentFormType,
  atomRuleData,
  atomToggleSidePanel,
} from '@/src/modules/RulesModule/atoms/rules';
import { NODE_WIDTH, TRANSLATE_RULES_CALL_TO_ACTION } from '@/src/modules/RulesModule/constants';
import { createEdge } from '@/src/modules/RulesModule/hooks/useFlowManagement';
import { FormTypes, RuleTypes } from '@/src/modules/RulesModule/interfaces';
import { NodeTypes } from '@/src/modules/RulesModule/types';
import { getId } from '@/src/modules/RulesModule/utils/getId';

import { CAMPAIGNS_PATH } from '@/modules/CampaignsModule/constants';

const SelectorNode = () => {
  const { t } = useTranslation('rules');
  const { campaignId, actionId, actionType } = useParams();
  const navigate = useNavigate();
  const { setNodes, setEdges, getNodes } = useReactFlow();
  const nodes = getNodes();
  const rulesData = useAtomValue(atomRuleData);
  const setToggleSidePanel = useSetAtom(atomToggleSidePanel);
  const setCurrentFormType = useSetAtom(atomCurrentFormType);

  const showAddCondition = useMemo(
    () =>
      ![RuleTypes.API_INTEGRATOR, RuleTypes.FLOW_TEMPLATE, RuleTypes.EXTERNAL_CALL].includes(
        rulesData.trigger?.value as RuleTypes
      ),
    [rulesData.trigger?.value]
  );

  const onOpenPanel = (formType: FormTypes) => {
    if (formType === FormTypes.CONDITION) {
      setCurrentFormType(formType);
      setToggleSidePanel(true);
    } else if (formType === FormTypes.ACTION && campaignId && actionId) {
      navigate(`${CAMPAIGNS_PATH}/${campaignId}/${actionType}/${actionId}/email`);
    }
  };

  const createNewNode = (type: NodeTypes, selectorNode: Node) => ({
    id: getId(),
    type,
    position: { ...selectorNode.position },
    data: { parentId: selectorNode.data.parentId },
    width: NODE_WIDTH,
  });

  const centerX = window.innerWidth / 2 - NODE_WIDTH / 2;
  const createEmptyNode = (parentNode: Node) => ({
    id: getId(),
    type: 'empty',
    position: { x: centerX, y: 0 },
    data: { parentId: parentNode.id },
    width: NODE_WIDTH,
  });

  const handleSelectorClick = (type: FormTypes) => {
    onOpenPanel(type);

    const selectorNode = nodes.find((node) => node.type === 'selector');
    if (!selectorNode) return;

    const newNode = createNewNode(type, selectorNode);
    const emptyNode = createEmptyNode(newNode);
    const newEdge = createEdge({ source: newNode.id, target: emptyNode.id });

    const actionNode = nodes.find((node) => node.type === FormTypes.ACTION);

    setNodes((nds) => {
      let updatedNodes = nds
        .map((node) => (node.id === selectorNode.id ? newNode : node))
        .concat(emptyNode);

      if (type === FormTypes.CONDITION && actionNode) {
        const movedActionNode = {
          ...actionNode,
        };
        updatedNodes = updatedNodes
          .filter((node) => node.id !== actionNode.id)
          .concat(movedActionNode);
      }

      return updatedNodes;
    });

    setEdges((eds) => {
      let updatedEdges = eds
        .map((edge) => (edge.target === selectorNode.id ? { ...edge, target: newNode.id } : edge))
        .concat(newEdge);

      if (type === FormTypes.CONDITION && actionNode) {
        const conditionToActionEdge = createEdge({ source: newNode.id, target: actionNode.id });
        updatedEdges = updatedEdges.concat(conditionToActionEdge);
      }

      return updatedEdges;
    });
  };

  return (
    <div className="flex w-72 items-center justify-center">
      <Handle className="handle" isConnectable={false} position={Position.Top} type="target" />
      <Card className="w-64 items-center !p-0">
        <div className="flex w-full items-center justify-center">
          <If
            condition={nodes.every((node) => node.type !== FormTypes.CONDITION) && showAddCondition}
          >
            <div className="flex flex-1 items-center justify-center border-r border-gray-300">
              <Button
                link
                className="w-full"
                onClick={() => handleSelectorClick(FormTypes.CONDITION)}
              >
                <Text className="text-nowrap" fontWeight="medium" variant="text-sm">
                  {t(`${TRANSLATE_RULES_CALL_TO_ACTION}.addCondition`)}
                </Text>
              </Button>
            </div>
          </If>
          <If condition={nodes.every((node) => node.type !== FormTypes.ACTION)}>
            <div className="flex flex-1 items-center justify-center border-r border-gray-300">
              <Button link className="w-full" onClick={() => handleSelectorClick(FormTypes.ACTION)}>
                <Text className="text-nowrap" fontWeight="medium" variant="text-sm">
                  {t(`${TRANSLATE_RULES_CALL_TO_ACTION}.addAction`)}
                </Text>
              </Button>
            </div>
          </If>
        </div>
      </Card>
      <Handle className="handle" isConnectable={false} position={Position.Bottom} type="source" />
    </div>
  );
};

export default SelectorNode;
