import { RegisterOptions } from 'react-hook-form';

import { CustomFieldSelect } from '@/modules/RulesModule/components/FormRule/components/CustomFieldSelect';
import { ExecutionDateFields } from '@/modules/RulesModule/components/FormRule/components/ExecutionDateFields';
import { ExecutionTimeSelect } from '@/modules/RulesModule/components/FormRule/components/ExecutionTimeSelect';
import { FrequencySelect } from '@/modules/RulesModule/components/FormRule/components/FrequencySelect';
import { IFormProps, IRuleForm } from '@/modules/RulesModule/interfaces';

interface IFormDateProps {
  form: IFormProps<IRuleForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const FormDate = ({ form, rules, disabled = false }: IFormDateProps) => {
  return (
    <>
      <CustomFieldSelect disabled={disabled} form={form} rules={rules} />
      <FrequencySelect disabled={disabled} form={form} rules={rules} />
      <ExecutionTimeSelect disabled={disabled} form={form} rules={rules} />
      <ExecutionDateFields disabled={disabled} form={form} rules={rules} />
    </>
  );
};

export default FormDate;
