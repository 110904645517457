import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { RegisterOptions } from 'react-hook-form';

import { Option, Select } from '@/lib/v2/components';

import { atomRuleTypeSelected } from '@/modules/RulesModule/atoms/rules';
import { useRuleDefaultOptions } from '@/modules/RulesModule/components/FormRule/hooks/useRuleDefaultOptions';
import { IFormProps, IRuleForm } from '@/modules/RulesModule/interfaces';

interface IRuleDropdownTypeProps {
  form: IFormProps<IRuleForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const RuleDropdownType = ({ form, disabled = false, rules }: IRuleDropdownTypeProps) => {
  const { triggerOptions } = useRuleDefaultOptions();

  const [ruleTypeSelected, setRuleTypeSelected] = useAtom(atomRuleTypeSelected);

  const handleTriggerChange = useCallback(
    (value: Option | Option[]) => {
      if (Array.isArray(value)) return;
      setRuleTypeSelected(value);
    },
    [setRuleTypeSelected]
  );

  return (
    <div className="mb-5 mr-8 w-full">
      <Select
        isRequired
        control={form.control}
        disabled={disabled}
        name="trigger"
        options={triggerOptions}
        rules={rules.trigger}
        value={ruleTypeSelected ?? triggerOptions[0]}
        onChange={handleTriggerChange}
      />
    </div>
  );
};

export default RuleDropdownType;
