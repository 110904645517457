import { RegisterOptions } from 'react-hook-form';

import TagFrequency from './components/TagFrequency/TagFrequency';
import TagTypeSelect from './components/TagTypeSelect/TagTypeSelect';

import { IFormProps, IRuleForm } from '@/modules/RulesModule/interfaces';

interface IFormTagProps {
  form: IFormProps<IRuleForm>;
  rules: Record<string, RegisterOptions>;
  disabled?: boolean;
}

const FormTag = ({ form, rules, disabled = false }: IFormTagProps) => {
  return (
    <>
      <TagTypeSelect disabled={disabled} form={form} rules={rules} />
      <TagFrequency disabled={disabled} form={form} rules={rules} />
    </>
  );
};

export default FormTag;
