import { useAtom, useSetAtom } from 'jotai';
import { useCallback } from 'react';

import { useCampaignsService } from '@/src/infrastructure/Protocol/Campaigns/useCampaignsService';
import { useRulesService } from '@/src/infrastructure/Protocol/Rules/useRulesService';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import {
  atomIsLoadingRuleData,
  atomRuleData,
  atomRuleDataRaw,
  atomRuleTypeSelected,
  atomTagList,
} from '@/src/modules/RulesModule/atoms/rules';
import { PayloadSaveCloseDateRule } from '@/src/modules/RulesModule/interfaces';
import { parseRuleDataRaw } from '@/src/modules/RulesModule/utils/parseRuleDataRaw';

import useRuleDataHelper from './useRuleDataHelper';

import { atomActionInfoEmail } from '@/modules/CampaignsModule/atoms/actionEmailAtom';
import { IEmailInfoResponse } from '@/modules/CampaignsModule/interfaces/CampaignActions';

const useRuleData = (actionId: number) => {
  const [isLoadingRuleData, setIsLoadingRuleData] = useAtom(atomIsLoadingRuleData);
  /* const [processingRule, setProcessingRule] = useAtom(atomProcessingRule); */

  const setRulesDataRaw = useSetAtom(atomRuleDataRaw);
  const [rulesData, setRulesData] = useAtom(atomRuleData);
  const setActionInfoEmail = useSetAtom(atomActionInfoEmail);
  const setRuleTypeSelected = useSetAtom(atomRuleTypeSelected);
  const setTagList = useSetAtom(atomTagList);

  const service = useService();
  const rulesService = useRulesService();
  const campaignService = useCampaignsService();
  const { parseRuleData } = useRuleDataHelper();

  //TODO: REFACTOR TO UNIQUE RESPONSIBILITY:  ONLY GET RULE DATA
  const getRuleData = useCallback(async () => {
    if (!actionId) return;

    setIsLoadingRuleData(true);

    const ruleData = await rulesService.getRuleById(actionId);
    const recipientsResponse = await campaignService.getAddressees({
      searchById: Number(ruleData.group_rules),
      offset: 0,
      groupQuantity: 1,
      filterType: {
        user: true,
        profile: true,
        integration: false,
        interest: false,
      },
    });

    const tagList = await service.getTagCloudInfo({
      identifier: 'TAG_CLOUD',
      search: '',
      tagsCategoriesFilter: [],
      tagsDataSourceFilter: [],
      lastRefresh: 0,
    });

    const parsedDataRaw = parseRuleDataRaw(ruleData);
    const parsedData = parseRuleData(ruleData, recipientsResponse, tagList);
    setRulesDataRaw(parsedDataRaw);
    setRulesData(parsedData);
    setRuleTypeSelected(parsedData.trigger);
    setTagList(tagList);

    const emailInfo = await campaignService.getEmailInfo(actionId);
    setActionInfoEmail(emailInfo as IEmailInfoResponse);

    setIsLoadingRuleData(false);
  }, [
    actionId,
    setIsLoadingRuleData,
    rulesService,
    campaignService,
    service,
    parseRuleData,
    setRulesDataRaw,
    setRulesData,
    setRuleTypeSelected,
    setTagList,
    setActionInfoEmail,
  ]);

  const refetch = useCallback(() => {
    void getRuleData();
  }, [getRuleData]);

  //TODO: improve activate rule
  const activateRule = useCallback(async () => {
    setIsLoadingRuleData(true);

    //cspell:disable
    const payloadSaveCloseDate: PayloadSaveCloseDateRule = {
      activoIndefinido: true,
      elementoId: actionId,
      hora: null,
      milis: null,
      minutos: null,
    };
    //cspell:enable

    await rulesService.saveCloseDate(payloadSaveCloseDate);
    await rulesService.activateRule(Number(actionId));

    setIsLoadingRuleData(false);
  }, [rulesService, actionId, setIsLoadingRuleData]);

  const pauseRule = useCallback(async () => {
    setIsLoadingRuleData(true);
    await rulesService.inactivateRule(Number(actionId));
    setIsLoadingRuleData(false);
  }, [rulesService, actionId, setIsLoadingRuleData]);

  return {
    rule: rulesData,
    refetch,
    getRuleData,
    isLoadingRuleData,
    activateRule,
    pauseRule,
  };
};

export default useRuleData;
