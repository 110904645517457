/* cspell: disable*/

export const AsleepContactsIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 25 24" width="25" xmlns="http://www.w3.org/2000/svg">
      <g id="Grupo 9231">
        <g id="Grupo 9232">
          <path
            d="M10.2488 12.881C13.5752 12.881 16.2718 10.1848 16.2718 6.85897C16.2718 3.53312 13.5752 0.836975 10.2488 0.836975C6.92242 0.836975 4.22583 3.53312 4.22583 6.85897C4.22583 10.1848 6.92242 12.881 10.2488 12.881Z"
            fill="#9C59DF"
            id="Elipse 1698"
          />
          <path
            d="M23.3038 17.7749H19.2228C19.1259 17.7745 19.0312 17.7459 18.9502 17.6927C18.8692 17.6394 18.8055 17.5638 18.7668 17.475C18.7283 17.386 18.7172 17.2875 18.735 17.1922C18.7529 17.0969 18.7987 17.0091 18.8668 16.94L21.7787 14.028H18.9498C18.8791 14.0375 18.8072 14.0319 18.7389 14.0113C18.6706 13.9908 18.6075 13.9559 18.5538 13.9089C18.5001 13.862 18.457 13.8041 18.4276 13.7392C18.3981 13.6742 18.3828 13.6038 18.3828 13.5325C18.3828 13.4611 18.3981 13.3907 18.4276 13.3257C18.457 13.2608 18.5001 13.2029 18.5538 13.156C18.6075 13.1091 18.6706 13.0742 18.7389 13.0536C18.8072 13.0331 18.8791 13.0274 18.9498 13.037H22.9918C23.0887 13.0374 23.1834 13.066 23.2643 13.1192C23.3453 13.1725 23.409 13.2481 23.4478 13.337C23.4862 13.426 23.4973 13.5244 23.4795 13.6197C23.4617 13.715 23.4158 13.8029 23.3478 13.872L20.4358 16.784H23.3088C23.3797 16.7739 23.4519 16.7791 23.5206 16.7994C23.5893 16.8196 23.6529 16.8544 23.707 16.9013C23.7611 16.9482 23.8044 17.0062 23.8342 17.0714C23.8639 17.1366 23.8793 17.2074 23.8793 17.279C23.8793 17.3506 23.8639 17.4214 23.8342 17.4866C23.8044 17.5517 23.7611 17.6097 23.707 17.6567C23.6529 17.7036 23.5893 17.7383 23.5206 17.7586C23.4519 17.7788 23.3797 17.7841 23.3088 17.774L23.3038 17.7749Z"
            fill="#9C59DF"
            id="Trazado 3805"
          />
          <path
            d="M19.2629 23.045H16.4499C16.353 23.0445 16.2583 23.0159 16.1774 22.9626C16.0964 22.9094 16.0327 22.8337 15.9939 22.7449C15.9553 22.6559 15.9442 22.5575 15.962 22.4621C15.9798 22.3668 16.0257 22.279 16.0939 22.2099L17.8369 20.467H16.2559C16.1852 20.4765 16.1133 20.4708 16.045 20.4503C15.9767 20.4298 15.9136 20.3948 15.8599 20.3479C15.8062 20.301 15.7632 20.2431 15.7337 20.1781C15.7042 20.1132 15.689 20.0427 15.689 19.9714C15.689 19.9001 15.7042 19.8296 15.7337 19.7647C15.7632 19.6998 15.8062 19.6419 15.8599 19.595C15.9136 19.548 15.9767 19.5131 16.045 19.4926C16.1133 19.4721 16.1852 19.4664 16.2559 19.4759H19.0499C19.1468 19.4764 19.2415 19.505 19.3224 19.5582C19.4034 19.6115 19.4672 19.6871 19.5059 19.7759C19.5444 19.8649 19.5554 19.9634 19.5376 20.0587C19.5198 20.154 19.4739 20.2419 19.4059 20.311L17.6629 22.0539H19.2879C19.3539 22.0526 19.4196 22.0646 19.4808 22.0892C19.5421 22.1139 19.5978 22.1507 19.6445 22.1974C19.6912 22.2441 19.728 22.2997 19.7526 22.361C19.7773 22.4223 19.7893 22.4879 19.7879 22.5539C19.7858 22.6207 19.7706 22.6864 19.743 22.7472C19.7155 22.8081 19.6762 22.8629 19.6274 22.9086C19.5787 22.9542 19.5214 22.9897 19.4588 23.0131C19.3962 23.0365 19.3296 23.0474 19.2629 23.045Z"
            fill="#9C59DF"
            id="Trazado 3806"
          />
          <path
            d="M14.2498 21.4C13.9805 21.0316 13.8186 20.5957 13.7822 20.1409C13.7457 19.686 13.8362 19.23 14.0435 18.8234C14.2507 18.4169 14.5667 18.0758 14.9562 17.8381C15.3457 17.6004 15.7935 17.4754 16.2498 17.477H16.7498C16.7204 17.1209 16.769 16.7627 16.8921 16.4274C17.0153 16.092 17.21 15.7874 17.4628 15.535C17.2036 15.3453 16.9844 15.1064 16.8178 14.8318C16.6512 14.5572 16.5404 14.2525 16.4918 13.935C16.1415 13.6004 15.7638 13.2957 15.3628 13.024C15.2306 12.9368 15.0757 12.8903 14.9173 12.8903C14.7589 12.8903 14.604 12.9368 14.4718 13.024C13.2274 13.8993 11.7432 14.369 10.2218 14.369C8.70042 14.369 7.21615 13.8993 5.9718 13.024C5.84128 12.932 5.68551 12.8826 5.52581 12.8826C5.36612 12.8826 5.21035 12.932 5.07983 13.024C1.73183 15.263 0.779779 19.601 0.779779 19.601C0.779779 19.601 -0.0921915 23.167 3.43481 23.167H14.0538C13.8987 22.574 13.9685 21.9446 14.2498 21.4Z"
            fill="#9C59DF"
            id="Trazado 3807"
          />
          <path
            d="M19.4008 20.291L17.6578 22.034H19.2828C19.4028 22.0502 19.5128 22.1093 19.5924 22.2004C19.672 22.2916 19.7159 22.4085 19.7159 22.5295C19.7159 22.6505 19.672 22.7674 19.5924 22.8585C19.5128 22.9496 19.4028 23.0088 19.2828 23.025H16.4698C16.3731 23.0243 16.2786 22.9957 16.1979 22.9424C16.1171 22.8892 16.0535 22.8136 16.0148 22.725C15.9763 22.636 15.9653 22.5375 15.9832 22.4422C16.001 22.3469 16.0468 22.2591 16.1149 22.19L17.8578 20.447H16.2748C16.1549 20.4308 16.0449 20.3716 15.9653 20.2805C15.8856 20.1894 15.8418 20.0725 15.8418 19.9515C15.8418 19.8305 15.8856 19.7136 15.9653 19.6224C16.0449 19.5313 16.1549 19.4722 16.2748 19.456H18.0378C18.4012 19.6599 18.8102 19.7689 19.2269 19.7729H19.5038C19.5425 19.8593 19.5534 19.9556 19.535 20.0484C19.5165 20.1412 19.4696 20.226 19.4008 20.291Z"
            fill="#9C59DF"
            id="Trazado 3808"
          />
          <path
            d="M23.3037 17.7739H20.2497C19.8863 17.57 19.4774 17.4611 19.0607 17.457H18.7837C18.7452 17.368 18.7342 17.2695 18.752 17.1742C18.7698 17.0789 18.8157 16.991 18.8837 16.9219L21.7957 14.0099H18.9497C18.8788 14.02 18.8066 14.0148 18.7379 13.9946C18.6692 13.9743 18.6056 13.9396 18.5515 13.8926C18.4974 13.8457 18.454 13.7877 18.4243 13.7225C18.3946 13.6574 18.3792 13.5866 18.3792 13.515C18.3792 13.4433 18.3946 13.3725 18.4243 13.3074C18.454 13.2422 18.4974 13.1842 18.5515 13.1373C18.6056 13.0903 18.6692 13.0556 18.7379 13.0353C18.8066 13.0151 18.8788 13.0099 18.9497 13.02H22.9917C23.0886 13.0204 23.1833 13.049 23.2643 13.1022C23.3452 13.1555 23.4089 13.2311 23.4477 13.3199C23.4862 13.4089 23.4972 13.5074 23.4794 13.6027C23.4616 13.698 23.4158 13.7858 23.3477 13.8549L20.4357 16.767H23.3087C23.3748 16.7656 23.4404 16.7776 23.5016 16.8022C23.5629 16.8269 23.6186 16.8636 23.6653 16.9103C23.712 16.957 23.7488 17.0127 23.7734 17.074C23.7981 17.1352 23.8101 17.2009 23.8087 17.267C23.8087 17.4011 23.7555 17.5297 23.6609 17.6248C23.5662 17.7198 23.4378 17.7734 23.3037 17.7739Z"
            fill="#9C59DF"
            id="Trazado 3809"
          />
          <g id="Grupo 6437">
            <path
              d="M23.3038 17.7749H19.2228C19.1259 17.7745 19.0312 17.7459 18.9502 17.6927C18.8692 17.6394 18.8055 17.5638 18.7668 17.475C18.7283 17.386 18.7172 17.2875 18.735 17.1922C18.7529 17.0969 18.7987 17.0091 18.8668 16.94L21.7787 14.028H18.9498C18.8791 14.0375 18.8072 14.0319 18.7389 14.0113C18.6706 13.9908 18.6075 13.9559 18.5538 13.9089C18.5001 13.862 18.457 13.8041 18.4276 13.7392C18.3981 13.6742 18.3828 13.6038 18.3828 13.5325C18.3828 13.4611 18.3981 13.3907 18.4276 13.3257C18.457 13.2608 18.5001 13.2029 18.5538 13.156C18.6075 13.1091 18.6706 13.0742 18.7389 13.0536C18.8072 13.0331 18.8791 13.0274 18.9498 13.037H22.9918C23.0887 13.0374 23.1834 13.066 23.2643 13.1192C23.3453 13.1725 23.409 13.2481 23.4478 13.337C23.4862 13.426 23.4973 13.5244 23.4795 13.6197C23.4617 13.715 23.4158 13.8029 23.3478 13.872L20.4358 16.784H23.3088C23.3797 16.7739 23.4519 16.7791 23.5206 16.7994C23.5893 16.8196 23.6529 16.8544 23.707 16.9013C23.7611 16.9482 23.8044 17.0062 23.8342 17.0714C23.8639 17.1366 23.8793 17.2074 23.8793 17.279C23.8793 17.3506 23.8639 17.4214 23.8342 17.4866C23.8044 17.5517 23.7611 17.6097 23.707 17.6567C23.6529 17.7036 23.5893 17.7383 23.5206 17.7586C23.4519 17.7788 23.3797 17.7841 23.3088 17.774L23.3038 17.7749Z"
              fill="#9C59DF"
              id="Trazado 3810"
            />
          </g>
          <g id="Grupo 6438">
            <path
              d="M19.2629 23.045H16.4499C16.353 23.0445 16.2583 23.0159 16.1774 22.9626C16.0964 22.9094 16.0327 22.8337 15.9939 22.7449C15.9553 22.6559 15.9442 22.5575 15.962 22.4621C15.9798 22.3668 16.0257 22.279 16.0939 22.2099L17.8369 20.467H16.2559C16.1852 20.4765 16.1133 20.4708 16.045 20.4503C15.9767 20.4298 15.9136 20.3948 15.8599 20.3479C15.8062 20.301 15.7632 20.2431 15.7337 20.1781C15.7042 20.1132 15.689 20.0427 15.689 19.9714C15.689 19.9001 15.7042 19.8296 15.7337 19.7647C15.7632 19.6998 15.8062 19.6419 15.8599 19.595C15.9136 19.548 15.9767 19.5131 16.045 19.4926C16.1133 19.4721 16.1852 19.4664 16.2559 19.4759H19.0499C19.1468 19.4764 19.2415 19.505 19.3224 19.5582C19.4034 19.6115 19.4672 19.6871 19.5059 19.7759C19.5444 19.8649 19.5554 19.9634 19.5376 20.0587C19.5198 20.154 19.4739 20.2419 19.4059 20.311L17.6629 22.0539H19.2879C19.3539 22.0526 19.4196 22.0646 19.4808 22.0892C19.5421 22.1139 19.5978 22.1507 19.6445 22.1974C19.6912 22.2441 19.728 22.2997 19.7526 22.361C19.7773 22.4223 19.7893 22.4879 19.7879 22.5539C19.7858 22.6207 19.7706 22.6864 19.743 22.7472C19.7155 22.8081 19.6762 22.8629 19.6274 22.9086C19.5787 22.9542 19.5214 22.9897 19.4588 23.0131C19.3962 23.0365 19.3296 23.0474 19.2629 23.045Z"
              fill="#9C59DF"
              id="Trazado 3811"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
