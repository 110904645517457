import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';

import { AddCirclePlusIcon, BookIcon, ChangeAccount, FlowsIcon } from '@/lib/icon';
import {
  atomFoundsSmartFinder,
  atomShowModalSignal,
  atomShowSmartFinder,
  atomSmartFinderView,
} from '@/lib/v2/atoms/atomsSmartFinder';
import { IconSvg } from '@/lib/v2/components';
import { NavigationItemModule } from '@/lib/v2/components/SmartFinder/SmartFinder.interface';
import { CampaignIcon } from '@/lib/v2/icons/general/CampaignIcon';
import { ContactsIcon } from '@/lib/v2/icons/outline';
import { ReportIcon, WidgetIcon } from '@/lib/v2/icons/solid';

import configData from '@/src/config.json';
import { HappinessFilterIcon } from '@/src/ContactsModule/Icons/HappinessFilterIcon';
import { useService } from '@/src/infrastructure/Protocol/useEmblue';
import { DashboardIcon } from '@/src/presentation/util/icon';

import { IData, IDataSet } from './SmartFinder.worker';

export const useShowSmartFinder = () => {
  const [showSmartFinder, setShowSmartFinder] = useAtom(atomShowSmartFinder);
  const [smartFinderView, setSmartFinderView] = useAtom(atomSmartFinderView);

  const openSmartFinder = useCallback(() => {
    setSmartFinderView('principal');
    setShowSmartFinder(true);
  }, [setShowSmartFinder, setSmartFinderView]);

  const closeSmartFinder = useCallback(() => {
    setShowSmartFinder(false);
  }, [setShowSmartFinder]);

  const goToPrincipal = () => {
    setSmartFinderView('principal');
  };

  useHotkeys(
    'ctrl+k',
    openSmartFinder,
    {
      preventDefault: true,
    },
    [openSmartFinder]
  );

  useHotkeys(
    'esc',
    () => {
      showSmartFinder && closeSmartFinder();
    },
    {
      enabled: showSmartFinder,
      preventDefault: true,
    },
    [openSmartFinder, showSmartFinder]
  );

  return {
    showSmartFinder,
    openSmartFinder,
    closeSmartFinder,
    smartFinderView,
    goToStep1: goToPrincipal,
  };
};

interface IUseItemsSmartFinder {
  navigate?: (url: string) => void;
  onClose: () => void;
}

export const useItemsSmartFinder = (opts: IUseItemsSmartFinder) => {
  const { t } = useTranslation();
  const { navigate, onClose } = opts;
  const setShowModalSignal = useSetAtom(atomShowModalSignal);
  const setSmartFinderView = useSetAtom(atomSmartFinderView);
  const [textSearch, setTextSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [itemsFound, setItemsFound] = useAtom(atomFoundsSmartFinder);
  const haveResults = useMemo(() => Object.keys(itemsFound).length > 0, [itemsFound]);
  const service = useService();
  const createWorker = useMemo(() => createWorkerFactory(() => import('./SmartFinder.worker')), []);
  const worker = useWorker(createWorker);

  const iconCreate = useMemo(
    () => (
      <IconSvg
        fillColor="gray-dark"
        height="16px"
        svgComponent={<AddCirclePlusIcon />}
        width="16px"
      />
    ),
    []
  );
  const itemsGeneralQuickAction = useMemo(
    () => [
      {
        id: 'create',
        title: t('SMART_FINDER.create'),
        content: '',
        type: 'module',
        handleOnClick: () => setSmartFinderView('create'),
        children: [],
        icon: iconCreate,
      },
    ],
    [iconCreate, setSmartFinderView, t]
  );

  const handleGoTo = useCallback(
    (url: string) => {
      const { pathname } = window.location;
      const isMatch = /^\/v2\/campaigns\/\d+\/\w+\/\d+$/.test(pathname);
      if (isMatch || ['/v2/onsite', '/v2/automation'].includes(pathname)) {
        if (window.confirm(t('SMART_FINDER.confirmExit'))) {
          navigate && navigate(url);
          onClose();
        }
      } else {
        navigate && navigate(url);
        onClose();
      }
    },
    [navigate, onClose, t]
  );

  const handleSwitchAccount = useCallback(() => {
    setSmartFinderView('switchAccount');
  }, [setSmartFinderView]);

  const itemsAdminTools: NavigationItemModule[] = useMemo(
    () => [
      {
        id: 'change-account',
        title: t('SMART_FINDER.ADMIN_TOOLS.changeAccount'),
        handleOnClick: () => handleSwitchAccount(),
        children: [],
        icon: (
          <IconSvg
            fillColor="gray-dark"
            height="16px"
            svgComponent={<ChangeAccount />}
            width="16px"
          />
        ),
      },
    ],
    [t, handleSwitchAccount]
  );

  const [itemsNavigation, setItemsNavigation] = useState([
    {
      id: 'dashboard',
      title: t('TOP_BAR.dashboard'),
      content: '',
      type: 'module',
      handleOnClick: () => handleGoTo('/v2/dashboard'),
      children: [],
      findMatch: false,
      icon: (
        <IconSvg
          fillColor="gray-dark"
          height="16px"
          svgComponent={<DashboardIcon />}
          width="16px"
        />
      ),
    },
    {
      id: 'contacts',
      title: t('TOP_BAR.contacts'),
      icon: <IconSvg svgComponent={<ContactsIcon color="#374464" />} />,
      content: '',
      type: 'module',
      findMatch: false,
      handleOnClick: () => handleGoTo('/v2/contacts'),
      children: [
        {
          id: 'contacts-all',
          title: t('CONTACTS_MAIN.all'),
          isDefault: true,
          content: '',
          icon: <IconSvg svgComponent={<ContactsIcon color="#374464" />} />,
          parent: 'Contactos',
          handleOnClick: () => handleGoTo('/v2/contacts'),
        },
        {
          id: 'contacts-groups',
          title: t('CONTACTS_MAIN.contacts'),
          content: '',
          icon: <IconSvg svgComponent={<ContactsIcon />} />,
          parent: 'Contactos',
          handleOnClick: () => handleGoTo('/v2/contacts/groups'),
        },
        {
          id: 'contacts-segments',
          title: t('CONTACTS_MENU_BAR.segments'),
          content: '',
          icon: <IconSvg svgComponent={<ContactsIcon />} />,
          parent: 'Contactos',
          handleOnClick: () => handleGoTo('/v2/contacts/segments'),
        },
        {
          id: 'contacts-tags',
          title: t('CONTACTS_MENU_BAR.tags'),
          content: '',
          icon: <IconSvg svgComponent={<ContactsIcon />} />,
          parent: 'Contactos',
          handleOnClick: () => handleGoTo('/v2/contacts/tags'),
        },
        {
          id: 'contacts-items',
          title: t('CONTACTS_MENU_BAR.items'),
          content: '',
          icon: <IconSvg svgComponent={<ContactsIcon />} />,
          parent: 'Contactos',
          handleOnClick: () => handleGoTo('/v2/contacts/items'),
        },
        {
          id: 'contacts-discarded',
          title: t('CONTACTS_MENU_BAR.discarded'),
          content: '',
          icon: <IconSvg svgComponent={<ContactsIcon />} />,
          parent: 'Contactos',
          handleOnClick: () => handleGoTo('/v2/contacts/discarded'),
        },
        {
          id: 'contacts-import',
          title: t('CONTACTS_MENU_BAR.import'),
          content: '',
          icon: <IconSvg svgComponent={<ContactsIcon />} />,
          parent: 'Contactos',
          handleOnClick: () => handleGoTo('/v2/contacts/import'),
        },
        {
          id: 'contacts-happiness',
          title: t('CONTACTS_MENU_BAR.happiness'),
          content: '',
          icon: <IconSvg svgComponent={<ContactsIcon />} />,
          parent: 'Contactos',
          handleOnClick: () => handleGoTo('/v2/contacts/happiness'),
        },
      ],
    },
    {
      id: 'campaigns',
      title: t('TOP_BAR.campaigns'),
      content: '',
      findMatch: false,
      icon: (
        <IconSvg
          fillColor="gray-dark"
          height="24px"
          svgComponent={<CampaignIcon bgColor="transparent" />}
          width="24px"
        />
      ),
      type: 'module',
      handleOnClick: () => handleGoTo('/v2/campaigns'),
      children: [],
    },
    {
      id: 'reports',
      title: t('TOP_BAR.reports'),
      content: '',
      type: 'module',
      findMatch: false,
      handleOnClick: () => handleGoTo('/v2/reports'),
      children: [],
      icon: (
        <IconSvg fillColor="gray-dark" height="18px" svgComponent={<ReportIcon />} width="18px" />
      ),
    },
    {
      id: 'automation',
      title: t('TOP_BAR.automation'),
      type: 'module',
      findMatch: false,
      content: '',
      handleOnClick: () => handleGoTo('/v2/automation'),
      children: [],
      icon: (
        <IconSvg fillColor="gray-dark" height="18px" svgComponent={<FlowsIcon />} width="18px" />
      ),
    },
    {
      id: 'onsite',
      title: t('TOP_BAR.on_site'),
      content: '',
      type: 'module',
      findMatch: false,
      handleOnClick: () => handleGoTo('/v2/onsite'),
      children: [],
      icon: (
        <IconSvg
          bgColor="transparent"
          fillColor="gray-dark"
          height="18px"
          svgComponent={<WidgetIcon />}
          width="18px"
        />
      ),
    },
    {
      id: 'nps',
      type: 'module',
      title: t('TOP_BAR.nps'),
      findMatch: false,
      icon: (
        <IconSvg
          fillColor="gray-dark"
          height="20px"
          svgComponent={<HappinessFilterIcon />}
          width="20px"
        />
      ),
      content: '',
      handleOnClick: () => handleGoTo('/v2/nps'),
      children: [],
    },
    {
      id: 'help',
      findMatch: false,
      type: 'module',
      content: '',
      icon: (
        <IconSvg fillColor="gray-dark" height="20px" svgComponent={<BookIcon />} width="20px" />
      ),
      title: t('SMART_FINDER.help'),
      handleOnClick: () => window.open(configData.TOP_BAR.MANUAL.url, '_blank'),
      children: [],
    },
  ]);

  const handleQuickCreate = useCallback(
    (url: string, type: string) => {
      handleGoTo(url);
      setShowModalSignal(type);
    },
    [handleGoTo, setShowModalSignal]
  );

  const itemsQuickAction = useMemo(
    () => [
      {
        id: 'import-contacts',
        title: t('SMART_FINDER.contactsImport'),
        content: '',
        type: 'module',
        handleOnClick: () => handleGoTo('/v2/contacts/import'),
        children: [],
        icon: iconCreate,
      },
      {
        id: 'create-contact',
        title: t('SMART_FINDER.createContact'),
        content: '',
        type: 'module',
        handleOnClick: () => handleQuickCreate('/v2/contacts', 'CreateContact'),
        children: [],
        icon: iconCreate,
      },
      {
        id: 'create-campaign',
        title: t('SMART_FINDER.createCampaign'),
        content: '',
        type: 'module',
        handleOnClick: () => handleQuickCreate('/v2/campaigns', 'CreateCampaign'),
        children: [],
        icon: iconCreate,
      },
      {
        id: 'create-segment',
        title: t('SMART_FINDER.createSegment'),
        content: '',
        type: 'module',
        handleOnClick: () => handleQuickCreate('/v2/contacts/segments', 'CreateSegment'),
        children: [],
        icon: iconCreate,
      },

      {
        id: 'create-tags',
        title: t('SMART_FINDER.createTags'),
        content: '',
        type: 'module',
        handleOnClick: () => handleQuickCreate('/v2/contacts/tags', 'CreateTags'),
        children: [],
        icon: iconCreate,
      },
      {
        id: 'create-group',
        title: t('SMART_FINDER.createGroup'),
        content: '',
        type: 'module',
        handleOnClick: () => handleQuickCreate('/v2/contacts/groups', 'CreateGroup'),
        children: [],
        icon: iconCreate,
      },
    ],
    [handleGoTo, handleQuickCreate, iconCreate, t]
  );

  const initSmartFinder = useCallback(async () => {
    const exist = await worker.existData();
    if (exist) return;

    const response = await service.getSmartFinder();
    void worker.addDataSet(response as IData[]);
  }, [service, worker]);

  useEffect(() => {
    void initSmartFinder();
  }, [initSmartFinder]);

  useEffect(() => {
    let haveChanges = false;

    const result = itemsNavigation.map((item) => {
      const findMatch =
        textSearch === '' ? true : item.title.toLowerCase().includes(textSearch.toLowerCase());

      if (item.findMatch !== findMatch) haveChanges = true;

      return {
        ...item,
        findMatch: findMatch,
      };
    });

    if (haveChanges) setItemsNavigation(result);
  }, [itemsNavigation, textSearch]);

  const searchItem = useCallback(
    async (value: string) => {
      if (value.length <= 3) {
        return;
      }
      const data = await worker.searchData(value);
      setTextSearch(value);
      setItemsFound(value === '' ? {} : data);
    },
    [setItemsFound, worker]
  );

  const searchMoreEntity = useCallback(
    async (type: keyof IDataSet) => {
      setLoading(true);
      const response = await service.searchMoreSmartFinder(type, textSearch);
      await worker.updateDataSet(type, response);
      void searchItem(textSearch);
      setLoading(false);
    },
    [searchItem, service, textSearch, worker]
  );

  return {
    itemsNavigation,
    itemsAdminTools,
    itemsGeneralQuickAction,
    itemsQuickAction,
    itemsFound,
    searchItem,
    textSearch,
    haveResults,
    searchMoreEntity,
    loading,
  };
};
