import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

import { atomFiltersRecurrent } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { EXCLUDE_PAGER_KEYS } from '@/modules/CampaignsModule/constants';
import { useCampaignRecurrentFilters } from '@/modules/CampaignsModule/hooks/useCampaignRecurrentFilters';

interface resetButtonProps {
  resets: Array<() => void>;
}

export const useResetButton = ({ resets }: resetButtonProps) => {
  const filtersRecurrent = useAtomValue(atomFiltersRecurrent);
  const { resetFilters } = useCampaignRecurrentFilters();

  const filteredKeys = useMemo(() => {
    return Object.keys(filtersRecurrent).filter((key) => !EXCLUDE_PAGER_KEYS.includes(key));
  }, [filtersRecurrent]);

  const buttonDisabled = useMemo(() => filteredKeys.length === 0, [filteredKeys]);

  const handleClickReset = useCallback(() => {
    resets.forEach((reset) => reset());
    resetFilters();
  }, [resetFilters, resets]);

  return {
    state: {
      buttonDisabled,
    },
    events: {
      handleClickReset,
    },
  };
};
