import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  atomFiltersDropdownRecurrentAction,
  atomFiltersRecurrentAction,
} from '@/modules/CampaignsModule/atoms/recurrentActionsAtom';
import {
  IActionStatus,
  IFiltersDropdownTableActions,
} from '@/modules/CampaignsModule/interfaces/CampaignActions';
import { clearObjectFalsy } from '@/modules/CampaignsModule/utils';

export const useDropdownFilters = () => {
  const { t } = useTranslation();

  const [filtersRecurrentAction, setFiltersRecurrentAction] = useAtom(atomFiltersRecurrentAction);
  const [filtersDropdownRecurrentAction, setFiltersDropdownRecurrentAction] = useAtom(
    atomFiltersDropdownRecurrentAction
  );

  const countDropdownFilters = useMemo(() => {
    const { status } = filtersRecurrentAction ?? {};
    return status?.length ?? 0;
  }, [filtersRecurrentAction]);

  const placeholderFilters = useMemo(() => {
    return countDropdownFilters > 0
      ? `${t('TABLE_FILTER_BAR.filters')} (${countDropdownFilters})`
      : t('TABLE_FILTER_BAR.filters');
  }, [countDropdownFilters, t]);

  const handleApplyFilters = useCallback(() => {
    const { status } = filtersDropdownRecurrentAction ?? {};

    const clearedFilters = clearObjectFalsy({
      ...filtersRecurrentAction,
      status,
    });

    setFiltersRecurrentAction(clearedFilters);
  }, [filtersDropdownRecurrentAction, filtersRecurrentAction, setFiltersRecurrentAction]);

  const handleCheckboxChange = useCallback(
    (key: keyof IFiltersDropdownTableActions, value: string | number, checked: boolean) => {
      const updatedFilters = { ...filtersDropdownRecurrentAction };

      if (key === 'status') {
        if (checked) {
          if (!updatedFilters[key]) {
            updatedFilters[key] = [value as IActionStatus];
          } else {
            (updatedFilters[key] as IActionStatus[]).push(value as IActionStatus);
          }
        } else if (updatedFilters[key]) {
          updatedFilters[key] = (updatedFilters[key] as IActionStatus[]).filter(
            (item) => item !== value
          );
          if ((updatedFilters[key] as IActionStatus[]).length === 0) {
            delete updatedFilters[key];
          }
        }
      }

      setFiltersDropdownRecurrentAction(updatedFilters);
    },
    [filtersDropdownRecurrentAction, setFiltersDropdownRecurrentAction]
  );

  return {
    state: {
      placeholderFilters,
      filtersDropdownRecurrentAction,
    },
    events: {
      handleCheckboxChange,
      handleApplyFilters,
    },
  };
};
