import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

import { atomFiltersCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';
import { EXCLUDE_PAGER_KEYS } from '@/modules/CampaignsModule/constants';
import { useCampaignFilters } from '@/modules/CampaignsModule/hooks/useCampaignFilters';

interface resetButtonProps {
  resets: Array<() => void>;
}

export const useResetButton = ({ resets }: resetButtonProps) => {
  const filtersCampaign = useAtomValue(atomFiltersCampaign);
  const { resetFilters } = useCampaignFilters();

  const filteredKeys = useMemo(() => {
    return Object.keys(filtersCampaign).filter((key) => !EXCLUDE_PAGER_KEYS.includes(key));
  }, [filtersCampaign]);

  const buttonDisabled = useMemo(() => filteredKeys.length === 0, [filteredKeys]);

  const handleClickReset = useCallback(() => {
    resets.forEach((reset) => reset());
    resetFilters();
  }, [resetFilters, resets]);

  return {
    state: {
      buttonDisabled,
    },
    events: {
      handleClickReset,
    },
  };
};
