import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { atomDetailCampaignRecurrent } from '@/src/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import { EStates } from '@/src/modules/CampaignsModule/interfaces/Campaigns';

import { useCampaignRecurrent } from './useCampaignRecurrent';
import { useRecurrentActions } from './useRecurrentActions';

export const useRecurrentMaster = () => {
  const { recurrentId } = useParams();

  const detailRecurrent = useAtomValue(atomDetailCampaignRecurrent);

  const [loadingMaster, setLoadingMaster] = useState(false);

  const { getDetailRecurrentMessage } = useCampaignRecurrent();
  const { getActionMaster } = useRecurrentActions();

  const fetchMasterData = useCallback(async () => {
    if (!recurrentId) return;
    setLoadingMaster(true);
    try {
      const promises = [
        getActionMaster({ campaignId: Number(recurrentId) }),
        getDetailRecurrentMessage({ campaignID: Number(recurrentId) }),
      ];

      await Promise.allSettled(promises);
    } catch (error) {
      console.error('Error resolve useRecurrentMaster:', error);
    } finally {
      setLoadingMaster(false);
    }
  }, [getActionMaster, getDetailRecurrentMessage, recurrentId]);

  useEffect(() => void fetchMasterData(), []);

  const activeCampaign = useMemo(() => {
    return detailRecurrent?.state === EStates.ACTIVE;
  }, [detailRecurrent?.state]);

  return {
    state: {
      activeCampaign,
      loadingMaster,
    },
  };
};
