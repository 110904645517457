import { timeout } from '@/src/compat/util';

import { IInsightsService } from './IInsightsService';

import {
  InsightsAdditionalMetricsContacts,
  InsightsContactsMetrics,
} from '@/modules/InsightsModule/interfaces/ContactsSummary';

export class StubInsightsService implements IInsightsService {
  static create(): IInsightsService {
    return new StubInsightsService();
  }

  async getContactsMetrics(): Promise<InsightsContactsMetrics | null> {
    try {
      await timeout(2000);
      return null;
    } catch (error) {
      console.error('Error en StubInsightsService.getContactsMetrics:', error);
      return null;
    }
  }

  async getContactsAdditionalMetrics(): Promise<InsightsAdditionalMetricsContacts | null> {
    try {
      await timeout(2000);
      return null;
    } catch (error) {
      console.error('Error en StubInsightsService.getContactsAdditionalMetrics:', error);
      return null;
    }
  }
}
