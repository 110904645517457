import { useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useParams } from 'react-router-dom';

import useRuleData from './useRuleData';

import { useActionData } from '@/modules/CampaignsModule/hooks/useActionData';
import {
  atomCurrentFormType,
  atomIsActionComplete,
  atomIsConditionFormComplete,
  atomIsTriggerFormComplete,
  atomRuleData,
  atomRuleDataRaw,
  atomRuleTypeSelected,
  atomToggleActivateRuleModal,
  atomTouchRules,
} from '@/modules/RulesModule/atoms/rules';

export const useResetRulesState = () => {
  const { actionId } = useParams();

  const setCurrentFormType = useSetAtom(atomCurrentFormType);
  const setToggleActivateRuleModal = useSetAtom(atomToggleActivateRuleModal);

  const setIsTriggerFormComplete = useSetAtom(atomIsTriggerFormComplete);
  const setIsConditionFormComplete = useSetAtom(atomIsConditionFormComplete);
  const setIsActionComplete = useSetAtom(atomIsActionComplete);
  const setRuleTypeSelected = useSetAtom(atomRuleTypeSelected);
  const setTouchRules = useSetAtom(atomTouchRules);

  const setRuleData = useSetAtom(atomRuleData);
  const setRuleDataRaw = useSetAtom(atomRuleDataRaw);

  const { refetch: refetchRuleData } = useRuleData(Number(actionId));
  const { refetch: refetchActionData } = useActionData(Number(actionId));

  const resetUIStates = () => {
    setCurrentFormType(RESET);
    setToggleActivateRuleModal(RESET);
  };

  const resetFormStates = () => {
    setIsTriggerFormComplete(RESET);
    setIsConditionFormComplete(RESET);
    setIsActionComplete(RESET);
    setRuleTypeSelected(RESET);
    setTouchRules(RESET);
  };

  const resetDataStates = () => {
    setRuleData(RESET);
    setRuleDataRaw(RESET);
  };

  const resetAllAtomStates = () => {
    resetUIStates();
    resetFormStates();
    resetDataStates();
  };

  const refetchData = () => {
    refetchRuleData();
    refetchActionData();
  };

  return {
    refetchData,
    resetAllAtomStates,
  };
};
