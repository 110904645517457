import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalSingleInput } from '@/lib/v2/organisms';

import { useRecurrentForm } from './hooks/useRecurrentForm';

import { atomSelectedRowRecurrentName } from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';
import {
  atomModalTypeProcess,
  atomToggleEditCreateRecurrentModal,
} from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { ModalType } from '@/modules/CampaignsModule/interfaces/Campaigns';

const ManagementRecurrentAction = () => {
  const { t } = useTranslation();

  const toggleEditCreateRecurrentModal = useAtomValue(atomToggleEditCreateRecurrentModal);
  const selectedRowRecurrentName = useAtomValue(atomSelectedRowRecurrentName);
  const modalTypeProcess = useAtomValue(atomModalTypeProcess);

  const { VALIDATION_RULES, loading, handleCloseModal, handleSubmitData, handleValidation } =
    useRecurrentForm();

  const handleSubmitData1 = useCallback(
    (recurrentName: string) => {
      void handleSubmitData({ name: recurrentName });
    },
    [handleSubmitData]
  );

  return (
    <ModalSingleInput
      disabledUntilOnChange
      id="create-recurrent-action-modal"
      isLoading={loading}
      isOpen={toggleEditCreateRecurrentModal}
      rules={VALIDATION_RULES}
      title={
        modalTypeProcess === ModalType.EDIT
          ? t(`RECURRENT_MESSAGE.CREATE_RECURRENT.titleModalEdit`)
          : t(`RECURRENT_MESSAGE.CREATE_RECURRENT.titleModal`)
      }
      value={selectedRowRecurrentName ?? ''}
      onClose={handleCloseModal}
      onSubmit={handleSubmitData1}
      onValidateInputFetch={handleValidation}
    />
  );
};
export default ManagementRecurrentAction;
