/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosError } from 'axios';

import { APIService } from '@/src/infrastructure/Protocol/APIService';

import { IEmblueApiMethodNameMap, IInsightsService } from './IInsightsService';

import { IInsightsParamStart } from '@/modules/CampaignsModule/interfaces/Insights';
import {
  InsightsAdditionalMetricsContacts,
  InsightsContactsMetrics,
} from '@/modules/InsightsModule/interfaces/ContactsSummary';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class InsightsService implements IInsightsService {
  private api: APIService;
  private pathReports = '/api/v2.1/reports';

  private constructor(payload: IInsightsParamStart) {
    this.api = new APIService(payload.url_ema_app_v2, payload.jwt);
  }

  /**
   * @desc Catch errors from API
   */
  private catchErrors(error: AxiosError<Error>, method: string): void {
    const { message } = error;
    console.error(`❌ EM_Error InsightsService[${method}]:`, message);
  }

  /**
   * @desc Create new instance of InsightsService
   */
  static create(payload: IInsightsParamStart): IInsightsService {
    return new InsightsService(payload);
  }

  async getContactsMetrics(): Promise<InsightsContactsMetrics | null> {
    try {
      const { data } = await this.api.get<{ status: InsightsContactsMetrics }>(
        `${this.pathReports}/contacts/status`
      );
      return data.status;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getContactsMetrics');
      return null;
    }
  }

  async getContactsAdditionalMetrics(): Promise<InsightsAdditionalMetricsContacts | null> {
    try {
      const { data } = await this.api.get<InsightsAdditionalMetricsContacts>(
        `${this.pathReports}/contacts/statistics`
      );
      return data;
    } catch (error) {
      this.catchErrors(error as AxiosError<Error>, 'getContactsAdditionalMetrics');
      return null;
    }
  }
}

export const InsightsServiceMethods: IEmblueApiMethodNameMap = Object.getOwnPropertyNames(
  InsightsService.prototype
).reduce((h: any, k: any) => {
  h[k] = k;
  return h;
}, {} as typeof InsightsService);
