import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Stepper, Steps } from '@/lib/v2/components';

import { atomCurrentStep, atomSelectedPlanData } from '@/modules/MyPlanModule/atoms/ManagePlan';
import { PlansIds } from '@/modules/MyPlanModule/types';

const HeaderManagePlan = () => {
  const [currentStep, setCurrentStep] = useAtom(atomCurrentStep);
  const [selectedPlanData] = useAtom(atomSelectedPlanData);
  const { t } = useTranslation();

  const steps: Steps = useMemo(() => {
    return [
      {
        id: 'one',
        stepName: t('MANAGE_PLAN.STEP_ONE.STEPPER.STEP_ONE.stepName'),
        title: t('MANAGE_PLAN.STEP_ONE.STEPPER.STEP_ONE.title'),
        onClick: () => {
          setCurrentStep(1);
        },
      },
      {
        id: 'two',
        stepName: t('MANAGE_PLAN.STEP_ONE.STEPPER.STEP_TWO.stepName'),
        title: t('MANAGE_PLAN.STEP_ONE.STEPPER.STEP_TWO.title'),
        onClick: () => {
          const initial: PlansIds = 'plan-free';
          selectedPlanData.planId !== initial && setCurrentStep(2);
        },
      },
      {
        id: 'three',
        stepName: t('MANAGE_PLAN.STEP_ONE.STEPPER.STEP_THREE.stepName'),
        title: t('MANAGE_PLAN.STEP_ONE.STEPPER.STEP_THREE.title'),
        onClick: () => {
          const initial: PlansIds = 'plan-free';
          selectedPlanData.planId !== initial && setCurrentStep(3);
        },
      },
    ];
  }, [selectedPlanData, setCurrentStep, t]);

  return (
    <div className="flex w-full justify-center gap-4 overflow-hidden bg-emblue-white">
      <div className="relative basis-4/5 translate-x-[14%]">
        <Stepper isHorizontal currentStep={currentStep} steps={steps} />
      </div>
    </div>
  );
};

export default HeaderManagePlan;
