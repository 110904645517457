import { useAtomValue } from 'jotai';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalSingleInput } from '@/lib/v2/organisms';

import {
  atomActionDetailQuickTools,
  atomSelectedActionsRowsWithID,
  atomSelectedRowActionCampaignId,
} from '@/modules/CampaignsModule/atoms/actionsAtom';
import { atomToggleEditActionsModal } from '@/modules/CampaignsModule/atoms/modalsCampaignAtom';
import { useEditActions } from '@/modules/CampaignsModule/hooks/useEditActions';

const EditActions = () => {
  const { t } = useTranslation();

  const actionDetailQuickTools = useAtomValue(atomActionDetailQuickTools);
  const toggleEditActionsModal = useAtomValue(atomToggleEditActionsModal);
  const actionId = useAtomValue(atomSelectedActionsRowsWithID);
  const campaignId = useAtomValue(atomSelectedRowActionCampaignId);

  const actionID = useMemo(
    () => Number(actionId[0]) || actionDetailQuickTools?.id,
    [actionDetailQuickTools?.id, actionId]
  );

  const {
    events: { handleSubmitData, handleCloseModal, handleValidation },
    state: { VALIDATION_RULES, loading, actionName },
  } = useEditActions({ actionId: actionID, campaignId });

  return (
    <ModalSingleInput
      disabledUntilOnChange
      id="edit-action-table"
      isLoading={loading}
      isOpen={toggleEditActionsModal}
      rules={VALIDATION_RULES}
      title={t('CAMPAIGN_ACTIONS_MAIN.EDIT_ACTIONS.title')}
      value={actionName}
      onClose={handleCloseModal}
      onSubmit={handleSubmitData}
      onValidateInputFetch={handleValidation}
    />
  );
};

export default memo(EditActions);
