import { memo, ReactElement } from 'react';

import { InputSearchProps } from '@/lib/v2/components/InputSearch/InputSearch';

import DropdownFilter, { IDropdownFilterProps } from './components/DropdownFilter';
import DropdownSimple, { IDropdownSimpleProps } from './components/DropdownSimple';
import InputDateRange, { IInputDateRangeProps } from './components/InputDateRange';
import InputSearchFilter from './components/InputSearchFilter';
import ResetButton, { IResetButtonProps } from './components/ResetButton';

type FiltersType =
  | IDropdownFilterProps
  | IDropdownSimpleProps
  | IInputDateRangeProps
  | InputSearchProps
  | IResetButtonProps;

export interface ITableFilterBarProps {
  children: ReactElement<FiltersType>[];
}

const TableFilterBar = ({ children }: ITableFilterBarProps) => {
  return <div className="z-10 flex max-w-fit items-center gap-2">{children}</div>;
};

const MemoizedTableFilterBar = memo(TableFilterBar) as unknown as typeof TableFilterBar & {
  DropdownFilter: typeof DropdownFilter;
  DropdownSimple: typeof DropdownSimple;
  InputDateRange: typeof InputDateRange;
  InputSearch: typeof InputSearchFilter;
  ResetButton: typeof ResetButton;
};

MemoizedTableFilterBar.DropdownFilter = DropdownFilter;
MemoizedTableFilterBar.DropdownSimple = DropdownSimple;
MemoizedTableFilterBar.InputDateRange = InputDateRange;
MemoizedTableFilterBar.InputSearch = InputSearchFilter;
MemoizedTableFilterBar.ResetButton = ResetButton;

export default MemoizedTableFilterBar;
