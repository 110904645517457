import { memo } from 'react';

import { Dropdown, IconSvg } from '@/lib/v2/components';
import { DropdownProps } from '@/lib/v2/components/Dropdown/Dropdown';
export interface IDropdownSimpleProps extends Omit<DropdownProps, 'id'> {
  id: string;
  checkBox?: boolean;
}

const DropdownSimple = ({
  icon,
  label,
  searchPlaceholder,
  dropdownItems,
  checkBox = false,
  isScrollable = false,
}: IDropdownSimpleProps) => {
  return (
    <Dropdown
      checkBox={checkBox}
      dropdownItems={dropdownItems}
      iconRight={<IconSvg strokeColor="gray-dark" svgComponent={icon} />}
      isScrollable={isScrollable}
      label={label}
      searchPlaceholder={searchPlaceholder}
    />
  );
};

export default memo(DropdownSimple);
