import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';

import { EXCLUDE_PAGER_KEYS } from '@/src/modules/CampaignsModule/constants';
import { clearObjectFalsy } from '@/src/modules/CampaignsModule/utils';

import { useCampaignRecurrentFilters } from './useCampaignRecurrentFilters';

import {
  atomCampaignsRecurrentList,
  atomFiltersRecurrent,
  atomLoadingProcess,
  atomPagerCampaignsRecurrent,
  atomTotalsProcess,
} from '@/modules/CampaignsModule/atoms/campaignRecurrentAtom';

//TODO: Delete this atoms after refactor: atomHistorySearchPagerRecurrent, atomRefetchAllData,atomOrderBy,

export const useRecurrentData = () => {
  const [filtersRecurrent, setFiltersRecurrent] = useAtom(atomFiltersRecurrent);
  const recurrentMessageList = useAtomValue(atomCampaignsRecurrentList);
  const loadingProcess = useAtomValue(atomLoadingProcess);
  const totalsCampaignRecurrentList = useAtomValue(atomTotalsProcess);
  const setPageParams = useSetAtom(atomPagerCampaignsRecurrent);

  const isInitialMount = useRef(true);

  const { getRecurrentCampaigns, parseURLParams } = useCampaignRecurrentFilters();

  const totalApplyFilters = useMemo(() => {
    return Object.keys(filtersRecurrent).filter((key) => !EXCLUDE_PAGER_KEYS.includes(key)).length;
  }, [filtersRecurrent]);

  useEffect(() => {
    const urlParams = parseURLParams();
    if (isInitialMount.current) {
      const clearFilters = clearObjectFalsy(urlParams);

      setFiltersRecurrent({ ...filtersRecurrent, ...clearFilters });
      setPageParams({ page: urlParams.page || 1, limit: urlParams.limit || 10 });

      isInitialMount.current = false;
      return;
    } else {
      void getRecurrentCampaigns({
        ...filtersRecurrent,
      });
      setPageParams({ page: filtersRecurrent.page || 1, limit: urlParams.limit || 10 });
    }
  }, [getRecurrentCampaigns, filtersRecurrent, setPageParams, parseURLParams, setFiltersRecurrent]);

  return {
    getRecurrentCampaigns,
    recurrentMessageList,
    loadingProcess,
    totalsCampaignRecurrentList,
    totalApplyFilters,
  };
};
