import { useAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { eventOnChangeDateInput } from '@/lib/v2/components/DateInput/DateInput';

import { atomFiltersCampaign } from '@/modules/CampaignsModule/atoms/campaignAtom';

export const useInputDate = () => {
  const { t } = useTranslation();

  const [filtersCampaign, setFiltersCampaign] = useAtom(atomFiltersCampaign);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    filtersCampaign?.startDate ?? null,
    filtersCampaign?.endDate ?? null,
  ]);

  const datePlaceholder = useMemo(() => t('CAMPAIGNS_MAIN.FILTERS.update'), [t]);

  const handleOnClickDate: eventOnChangeDateInput = useCallback(
    (event) => {
      if (Array.isArray(event)) {
        setDateRange(event as [Date | null, Date | null]);
        if (event[0] && event[1]) {
          setFiltersCampaign({
            ...filtersCampaign,
            startDate: event[0],
            endDate: event[1],
          });
        }
      }
    },
    [filtersCampaign, setFiltersCampaign]
  );

  const resetDateRange = useCallback(() => {
    setDateRange([null, null]);
  }, []);

  return {
    state: {
      dateRange,
      datePlaceholder,
    },
    events: {
      handleOnClickDate,
      resetDateRange,
    },
  };
};
