import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IField } from '@/lib/v2/atoms/Form.atoms';
import { Option } from '@/lib/v2/components';
import { useForm } from '@/lib/v2/hooks/useForm';

import { atomBillingInformation } from '@/src/modules/MyPlanModule/atoms/BillingAndConsumption';
import { billingEmailHelper } from '@/src/modules/MyPlanModule/screens/BillingAndConsumption/components/ModalInformation';

import { useCountriesOptions } from './useCountriesOptions';

export const useFormManagePlan = () => {
  const { getCountryOption } = useCountriesOptions();
  const { t } = useTranslation();
  const billingInformation = useAtomValue(atomBillingInformation);

  const billingEmails = useMemo(() => {
    return (
      billingEmailHelper.mapExtraBillingEmailsToForm(billingInformation?.extraBillingEmails) ?? []
    );
  }, [billingInformation?.extraBillingEmails]);

  const options = useMemo(
    () => ({
      businessName: {
        id: 'businessName',
        placeholder: t('MANAGE_PLAN.placeholderBusinessName'),
        required: t('CREATE_SEGMENT_MODAL.emptyField'),
        defaultValue: billingInformation?.businessName ?? '',
      },
      fantasyName: {
        id: 'fantasyName',
        placeholder: t('MANAGE_PLAN.placeholderFantasyName'),
        required: t('CREATE_SEGMENT_MODAL.emptyField'),
        defaultValue: billingInformation?.fantasyName ?? '',
      },
      address: {
        id: 'address',
        placeholder: t('MANAGE_PLAN.placeholderAddress'),
        required: t('CREATE_SEGMENT_MODAL.emptyField'),
        defaultValue: billingInformation?.address?.toString() ?? '',
      },
      country: {
        id: 'country',
        defaultValue: getCountryOption(billingInformation?.country ?? ''),
        required: t('CREATE_SEGMENT_MODAL.emptyField'),
        validate: (name: string, field: IField<Option>) => {
          return field?.value !== undefined ? true : field?.defaultValue ? true : false;
        },
      },
      rut: {
        id: 'rut',
        placeholder: t('MANAGE_PLAN.placeholderRut'),
        required: t('CREATE_SEGMENT_MODAL.emptyField'),
        defaultValue: billingInformation?.rut ?? '',
        maxLength: 15,
      },
      billingContact: {
        id: 'billingContact',
        placeholder: t('MANAGE_PLAN.placeholderBillingContact'),
        defaultValue: billingInformation?.billingContact ?? '',
        required: t('CREATE_SEGMENT_MODAL.emptyField'),
      },
      billingEmail: {
        id: 'billingEmail',
        placeholder: t('MANAGE_PLAN.placeholderBillingEmail'),
        required: t('CREATE_SEGMENT_MODAL.emptyField'),
        defaultValue: billingInformation?.billingEmail ?? '',
        pattern: {
          value: '^[\\w.%+-]+@[a-zA-Z\\d.-]+\\.[a-zA-Z]{2,}$',
          message: t('AUTH.LOGIN.invalidEmail'),
        },
      },
      city: {
        id: 'city',
        placeholder: t('MANAGE_PLAN.placeholderCity'),
        required: t('CREATE_SEGMENT_MODAL.emptyField'),
        defaultValue: billingInformation?.city ?? '',
        pattern: {
          /** cspell:disable */
          value: '^[A-Za-záéíóúÁÉÍÓÚñÑüÜ ]+$',
          /** cspell:enable */
          message: t('MANAGE_PLAN.STEP_THREE.rules.cityOnlyLetters'),
        },
      },
    }),
    [
      billingEmails,
      billingInformation?.address,
      billingInformation?.billingContact,
      billingInformation?.businessName,
      billingInformation?.city,
      billingInformation?.country,
      billingInformation?.fantasyName,
      billingInformation?.rut,
      getCountryOption,
      t,
    ]
  );
  const formState = useForm<Option>('manage-plan', options);

  return { formState, billingEmails };
};
