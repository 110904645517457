import { ChangeEvent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Text } from '@/lib/v2/components';

import { useDropdownFilters } from '@/src/modules/CampaignsModule/components/Campaigns/TableContainer/components/CampaignFilterBar/hooks/useDropdownFilters';

import { DROPDOWN_STATE } from '@/modules/CampaignsModule/constants';

const FilterCampaignState = () => {
  const { t } = useTranslation();

  const {
    state: { filtersDropdownCampaign },
    events: { handleCheckboxChange },
  } = useDropdownFilters();

  const handleCheck = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = event.target;
      handleCheckboxChange('state', Number(value), checked);
    },
    [handleCheckboxChange]
  );

  const statusItems = useMemo(() => {
    return DROPDOWN_STATE.map((state) => ({
      ...state,
      label: t(`CAMPAIGNS_MAIN.STATES.${state.label}`),
      checked: filtersDropdownCampaign?.state?.includes(Number(state.value)),
    }));
  }, [filtersDropdownCampaign, t]);

  const classNameScrollable = 'flex flex-col gap-2 max-h-[150px]';

  return (
    <div className="mb-4" id="filters-campaign-state">
      <Text className="mb-2" fontWeight="medium">
        {t('CAMPAIGNS_MAIN.FILTERS.state')}
      </Text>
      <div className={classNameScrollable}>
        {statusItems.map((item) => (
          <Checkbox
            key={item.id}
            checked={item.checked}
            id={`state-${item.id}`}
            label={item.label}
            value={item.value}
            onChange={handleCheck}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(FilterCampaignState);
